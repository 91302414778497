import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { Checkbox } from "@mui/material";

const dummyData = {
  name: "Total Emissions",
  children: [
    {
      name: "Scope 1",
      children: [
        { name: "Stationary", value: 30 },
        { name: "Mobile", value: 20 },
        { name: "Fugitive", value: 10 },
      ],
    },
    {
      name: "Scope 2",
      value: 40,
    },
    {
      name: "Scope 3",
      children: [
        { name: "Category 1", value: 50 },
        { name: "Category 11", value: 40 },
        { name: "Category 12", value: 60 },
      ],
    },
  ],
};

const SunburstDiagram = () => {
  const [visibleSeries, setVisibleSeries] = useState({
    scope1: true,
    scope2: true,
    scope3: true,
  });
  const chartRef = useRef(null);

  useEffect(() => {
    renderSunburstDiagram();
  }, [visibleSeries]);

  const renderSunburstDiagram = () => {
    const width = 600;
    const height = 300;
    const radius = Math.min(width, height) / 2;

    // Clear any existing SVG
    d3.select(chartRef.current).select("svg").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2},${height / 2})`);

    const partition = d3.partition().size([2 * Math.PI, radius]);

    const root = d3.hierarchy(dummyData).sum((d) => d.value);

    partition(root);

    // Define custom colors for Scope 1, Scope 2, and Scope 3
    const color = d3
      .scaleOrdinal()
      .domain(["Scope 1", "Scope 2", "Scope 3"])
      .range(["#e38322", "#f2c79c", "#166963"]);

    svg
      .selectAll("path")
      .data(root.descendants())
      .enter()
      .append("path")
      .attr(
        "d",
        d3
          .arc()
          .startAngle((d) => d.x0)
          .endAngle((d) => d.x1)
          .innerRadius((d) => d.y0)
          .outerRadius((d) => d.y1)
      )
      .style("fill", (d) => {
        // Hide paths based on visible series
        if (
          (d.data.name === "Scope 1" && !visibleSeries.scope1) ||
          (d.data.name === "Scope 2" && !visibleSeries.scope2) ||
          (d.data.name === "Scope 3" && !visibleSeries.scope3)
        ) {
          return "none"; // No fill, effectively hiding the element
        }
        return color(d.data.name);
      })
      .style("stroke", "#fff")
      .on("mouseover", (event, d) => {
        d3.select(event.currentTarget).style("opacity", 0.7);
      })
      .on("mouseout", (event) => {
        d3.select(event.currentTarget).style("opacity", 1);
      });

    // Labels
    svg
      .selectAll("text")
      .data(root.descendants())
      .enter()
      .append("text")
      .attr("transform", (d) => {
        const [x, y] = d3.arc().centroid({
          startAngle: d.x0,
          endAngle: d.x1,
          innerRadius: d.y0,
          outerRadius: d.y1,
        });
        return `translate(${x}, ${y})`;
      })
      .style("text-anchor", "middle")
      .style("font-size", "10px")
      .text((d) => (d.depth ? d.data.name : ""));
  };

  const handleCheckboxChange = (key) => {
    setVisibleSeries((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  return (
    <div>
      <h3 style={{ margin: "25px", fontSize: "18px" }}>
        Emissions Breakdown by Scope
      </h3>

      <div ref={chartRef} style={{ textAlign: "center" }} />

      {/* Legends */}
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <div style={{ display: "inline-block", marginRight: "20px" }}>
          <Checkbox
            checked={visibleSeries["scope1"]}
            onChange={() => handleCheckboxChange("scope1")}
            style={{
              color: "#e38322",
              marginRight: 4,
              fontSize: "20px",
            }}
          />
          <span style={{ color: "#555", fontSize: "14px" }}>Scope 1</span>
        </div>
        <div style={{ display: "inline-block", marginRight: "20px" }}>
          <Checkbox
            checked={visibleSeries["scope2"]}
            onChange={() => handleCheckboxChange("scope2")}
            style={{
              color: "#f2c79c",
              marginRight: 4,
              fontSize: "20px",
            }}
          />
          <span style={{ color: "#555", fontSize: "14px" }}>Scope 2</span>
        </div>
        <div style={{ display: "inline-block" }}>
          <Checkbox
            checked={visibleSeries["scope3"]}
            onChange={() => handleCheckboxChange("scope3")}
            style={{
              color: "#166963",
              marginRight: 4,
              fontSize: "20px",
            }}
          />
          <span style={{ color: "#555", fontSize: "14px" }}>Scope 3</span>
        </div>
      </div>
    </div>
  );
};

export default SunburstDiagram;
