import React, { useEffect } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.heat"; // Import leaflet.heat plugin

const WaterStressHeatMap = () => {
  useEffect(() => {
    // Initialize the map on component mount
    const map = L.map("map").setView([37.7749, -122.4194], 10); // Example location (San Francisco)

    // Add OpenStreetMap tile layer
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(
      map
    );

    // Example data: Locations with [latitude, longitude, water stress level]
    const waterStressData = [
      [37.7749, -122.4194, 0.9], // High stress in San Francisco
      [37.8044, -122.2711, 0.8], // High stress in Oakland
      [37.6879, -122.4702, 0.7], // Moderate stress in Bay Area
      [37.4419, -122.143, 0.4], // Low stress in Palo Alto
      [37.3541, -121.9552, 0.6], // Moderate stress in San Jose
    ];

    // Prepare the data for heatmap (latitude, longitude, intensity)
    const heatData = waterStressData.map((item) => [item[0], item[1], item[2]]);

    // Create heatmap layer
    L.heatLayer(heatData, {
      radius: 25,
      blur: 15,
      maxZoom: 17,
      gradient: {
        0.4: "#00FF00", // Low stress: Green
        0.6: "#FFFF00", // Moderate stress: Yellow
        0.8: "#FF4500", // High stress: Orange
        1.0: "#FF0000", // Very high stress: Red
      },
    }).addTo(map);

    // Cleanup map on component unmount
    return () => {
      map.remove();
    };
  }, []);

  return (
    <div style={{ height: "600px", width: "100%" }}>
      <h3 style={{ fontSize: "18px" }}>
        Areas of Water Withdrawal which are in Water Stress Area
      </h3>
      <div id="map" style={{ height: "90%" }}></div>
    </div>
  );
};

export default WaterStressHeatMap;
