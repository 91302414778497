export const UnderDevelopment = () => {
    return (
      <div className='flex align-items-center' style={{flexDirection:'column'}}>
        <img
          src={require("../assets/images/under-development.png").default}
          alt="under-dev"
          style={{
            width:'30%'
          }}
        />
        <h6 style={{  marginTop: 15 }}>
          Page Under Development
        </h6>
      </div>
    );
  };