import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { Checkbox, FormControlLabel } from "@mui/material";

const EmissionsBubbleChart = () => {
  const graphRef = useRef(null);

  const [visibleScopes, setVisibleScopes] = useState({
    scope1: true,
    scope2: true,
    scope3: true,
  });

  const data = [
    { scope: "Scope 1", source: "Fuel", value: 100 },
    { scope: "Scope 1", source: "Electricity", value: 120 },
    { scope: "Scope 2", source: "Fuel", value: 150 },
    { scope: "Scope 2", source: "Electricity", value: 170 },
    { scope: "Scope 3", source: "Transport", value: 300 },
    { scope: "Scope 3", source: "Waste", value: 330 },
  ];

  const colors = { scope1: "#4f94cd", scope2: "#6db86b", scope3: "#f39c12" };

  useEffect(() => {
    renderGraph();
  }, [visibleScopes]);

  const renderGraph = () => {
    const width = 600;
    const height = 300;
    const margin = { top: 40, right: 50, bottom: 40, left: 80 };

    d3.select(graphRef.current).selectAll("*").remove();

    const svg = d3
      .select(graphRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .style("display", "block")
      .style("margin", "0 auto");

    // Adjust the x and y scales for the bubble chart
    const xScale = d3
      .scaleBand()
      .domain(["Scope 1", "Scope 2", "Scope 3"])
      .range([margin.left, width - margin.right])
      .padding(0.1);

    const yScale = d3
      .scaleBand()
      .domain(["Fuel", "Electricity", "Transport", "Waste"])
      .range([height - margin.bottom, margin.top])
      .padding(0.1);

    // Adjust the radius scale to make bubbles visible
    const radiusScale = d3
      .scaleSqrt()
      .domain([0, d3.max(data, (d) => d.value)])
      .range([10, 50]); // Adjusted range for visibility

    svg
      .selectAll("circle")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", (d) => xScale(d.scope) + xScale.bandwidth() / 2)
      .attr("cy", (d) => yScale(d.source) + yScale.bandwidth() / 2)
      .attr("r", (d) => radiusScale(d.value))
      .attr("fill", (d) =>
        d.scope === "Scope 1"
          ? colors.scope1
          : d.scope === "Scope 2"
          ? colors.scope2
          : colors.scope3
      )
      .attr("opacity", (d) => (visibleScopes[d.scope.toLowerCase()] ? 1 : 0.2)); // Changed opacity to make it less hidden

    svg
      .append("g")
      .attr("transform", `translate(0, ${height - margin.bottom})`)
      .call(d3.axisBottom(xScale));

    svg
      .append("g")
      .attr("transform", `translate(${margin.left}, 0)`)
      .call(d3.axisLeft(yScale));

    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", height - 10)
      .text("Scope")
      .style("text-anchor", "middle")
      .style("font-size", "14px");
  };

  const handleCheckboxChange = (scope) => {
    setVisibleScopes((prev) => ({ ...prev, [scope]: !prev[scope] }));
  };

  return (
    <div>
      <div>
        <h3 style={{ margin: "25px", fontSize: "18px" }}>
          Emissions by Scope and Source
        </h3>
      </div>
      <div ref={graphRef}></div>

      <div style={{ textAlign: "center" }}>
        {Object.keys(visibleScopes).map((scope) => (
          <FormControlLabel
            key={scope}
            control={
              <Checkbox
                checked={visibleScopes[scope]}
                onChange={() => handleCheckboxChange(scope)}
                style={{ color: colors[scope] }}
              />
            }
            label={scope.charAt(0).toUpperCase() + scope.slice(1)}
          />
        ))}
      </div>
    </div>
  );
};

export default EmissionsBubbleChart;
