import React, { useEffect, useState } from "react";
import GaugeChart from "react-gauge-chart";

const dummyData = {
  baseline: 1000, // baseline emissions in the baseline year
  currentEmissions: 600, // current emissions
};

const GHGReductionGauge = () => {
  const progress =
    ((dummyData.baseline - dummyData.currentEmissions) / dummyData.baseline) *
    100;

  return (
    <div style={{ fontFamily: "Lato" }}>
      <div style={{ marginTop: "30px" }}>
        <h3 style={{ margin: "25px", fontSize: "18px" }}>
          GHG Emission Reduction Progress
        </h3>
        <p style={{ margin: "25px", fontSize: "14px" }}>
          Progress against GHG emissions reduction targets.{" "}
        </p>
      </div>

      {/* Gauge Chart */}
      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <GaugeChart
          id="ghg-progress-gauge"
          nrOfLevels={30}
          arcsLength={[0.5, 0.3, 0.2]} // Define color zones for progress
          colors={["#e74c3c", "#f39c12", "#16a085"]}
          textColor="#e74c3c" // Red, Yellow, Green
          percent={progress / 100} // Progress as a percentage (0-1 scale)
          needleColor="#000000"
          arcPadding={0.02}
          style={{ width: 600, height: 300 }} // Adjusted size
        />

        {/* Label for the gauge */}
        <div
          style={{
            marginTop: "10px",
            marginBottom: "20px",
            fontSize: "16px",
            fontWeight: "bold",
            color: "#16a085", // Green color for the label
          }}
        >
          Percentage Reduction: {progress.toFixed(2)}%
        </div>
      </div>
    </div>
  );
};

export default GHGReductionGauge;
