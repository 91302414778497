import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const SafetyCommitteeNetwork = () => {
  const chartRef = useRef(null);

  const data = {
    nodes: [
      { id: "Group A", group: 1 },
      { id: "Group B", group: 2 },
      { id: "Group C", group: 3 },
      { id: "Group D", group: 4 },
      { id: "Group E", group: 5 },
    ],
    links: [
      { source: "Group A", target: "Group B", strength: 3 },
      { source: "Group B", target: "Group C", strength: 2 },
      { source: "Group C", target: "Group D", strength: 4 },
      { source: "Group D", target: "Group E", strength: 1 },
      { source: "Group E", target: "Group A", strength: 3 },
    ],
  };

  useEffect(() => {
    renderNetwork();
  }, []);

  const renderNetwork = () => {
    const width = 600;
    const height = 472;

    // Clear any previous SVG
    d3.select(chartRef.current).select("svg").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    // Simulation setup with forces
    const simulation = d3
      .forceSimulation(data.nodes)
      .force(
        "link",
        d3
          .forceLink(data.links)
          .id((d) => d.id)
          .strength((d) => d.strength / 10)
      )
      .force("charge", d3.forceManyBody().strength(-200))
      .force("center", d3.forceCenter(width / 2, height / 2))
      .force("collision", d3.forceCollide().radius(50));

    // Links
    const link = svg
      .append("g")
      .attr("stroke", "#999")
      .attr("stroke-opacity", 0.6)
      .selectAll("line")
      .data(data.links)
      .enter()
      .append("line")
      .attr("stroke-width", (d) => d.strength * 2); // Link thickness based on strength

    // Nodes
    const node = svg
      .append("g")
      .selectAll("circle")
      .data(data.nodes)
      .enter()
      .append("circle")
      .attr("r", 25)
      .attr("fill", (d) => d3.schemeCategory10[d.group % 10])
      .call(
        d3
          .drag()
          .on("start", (event, d) => {
            if (!event.active) simulation.alphaTarget(0.3).restart();
            d.fx = d.x;
            d.fy = d.y;
          })
          .on("drag", (event, d) => {
            d.fx = event.x;
            d.fy = event.y;
          })
          .on("end", (event, d) => {
            if (!event.active) simulation.alphaTarget(0);
            d.fx = null;
            d.fy = null;
          })
      );

    // Node labels
    const labels = svg
      .append("g")
      .selectAll("text")
      .data(data.nodes)
      .enter()
      .append("text")
      .attr("dy", 5)
      .attr("dx", 30)
      .style("font-size", "14px")
      .style("fill", "#333")
      .text((d) => d.id);

    // Update simulation on each tick
    simulation.on("tick", () => {
      link
        .attr("x1", (d) => d.source.x)
        .attr("y1", (d) => d.source.y)
        .attr("x2", (d) => d.target.x)
        .attr("y2", (d) => d.target.y);

      node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);
      labels.attr("x", (d) => d.x).attr("y", (d) => d.y);
    });
  };

  return (
    <div>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Safety Committee Network
      </h3>
      {/* <p>
        This network shows worker participation in safety committees, where
        nodes represent groups and links represent shared memberships. The
        thickness of the links indicates collaboration strength.
      </p> */}
      <div
        ref={chartRef}
        style={{ display: "flex", justifyContent: "center" }}
      ></div>
    </div>
  );
};

export default SafetyCommitteeNetwork;
