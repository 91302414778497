import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const data = [
  { demographic: "Group A", coverage: 85 },
  { demographic: "Group B", coverage: 75 },
  { demographic: "Group C", coverage: 90 },
  { demographic: "Group D", coverage: 70 },
];

const InsuranceCoverageChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    renderChart();
  }, []);

  const renderChart = () => {
    const width = 600;
    const height = 400;
    const margin = { top: 50, right: 150, bottom: 100, left: 60 };

    // Remove any existing SVG for re-rendering
    d3.select(chartRef.current).select("svg").remove();

    // Create SVG canvas
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // X and Y scales
    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.demographic))
      .range([0, chartWidth])
      .padding(0.4);

    const y = d3
      .scaleLinear()
      .domain([0, 100]) // Percentage range from 0 to 100
      .range([chartHeight, 0]);

    // Colors for different demographic groups
    const color = d3
      .scaleOrdinal()
      .domain(data.map((d) => d.demographic))
      .range(["#6fa8dc", "#f9cb9c", "#e06666", "#9e5c5c"]);

    // Add X-axis
    g.append("g")
      .attr("transform", `translate(0,${chartHeight})`)
      .call(d3.axisBottom(x));

    // Add Y-axis
    g.append("g").call(d3.axisLeft(y).ticks(5));

    // Add X-axis label
    g.append("text")
      .attr("x", chartWidth / 2)
      .attr("y", chartHeight + 40)
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .text("Demographic Group");

    // Add Y-axis label
    g.append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -chartHeight / 2)
      .attr("y", -40)
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .text("Percentage of Coverage");

    // Draw bars
    g.selectAll("rect")
      .data(data)
      .enter()
      .append("rect")
      .attr("x", (d) => x(d.demographic))
      .attr("y", (d) => y(d.coverage))
      .attr("height", (d) => chartHeight - y(d.coverage))
      .attr("width", x.bandwidth())
      .attr("fill", (d) => color(d.demographic))
      .append("title")
      .text((d) => `${d.demographic}: ${d.coverage}%`);

    // Add labels to bars
    g.selectAll("text.bar-label")
      .data(data)
      .enter()
      .append("text")
      .attr("class", "bar-label")
      .attr("x", (d) => x(d.demographic) + x.bandwidth() / 2) // Center the label
      .attr("y", (d) => y(d.coverage) - 5) // Slightly above the bar
      .style("text-anchor", "middle")
      .style("font-size", "12px")
      .text((d) => `${d.coverage}%`);
  };

  return (
    <>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Insurance Coverage for Employees
      </h3>
      <div
        ref={chartRef}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      />
    </>
  );
};

export default InsuranceCoverageChart;
