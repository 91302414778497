import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { Checkbox, FormControlLabel } from "@mui/material";

// Updated data: emissions by Scope for 2023 and 2024 only
const data = [
  { year: 2023, scope1: 45, scope2: 55, scope3: 65 },
  { year: 2024, scope1: 50, scope2: 60, scope3: 70 }, // Added data for 2024
];

const TotalScopeStacked = () => {
  const chartRef = useRef(null);
  const [visibleScopes, setVisibleScopes] = useState({
    scope1: true,
    scope2: true,
    scope3: true,
  });

  useEffect(() => {
    renderStackedBarChart();
  }, [visibleScopes]);

  const handleToggle = (scope) => {
    setVisibleScopes((prev) => ({
      ...prev,
      [scope]: !prev[scope],
    }));
  };

  const renderStackedBarChart = () => {
    const margin = { top: 30, right: 30, bottom: 40, left: 60 }; // Increased bottom margin for legend
    const width = 600 - margin.left - margin.right;
    const height = 300 - margin.top - margin.bottom;

    // Clear any existing SVG
    d3.select(chartRef.current).select("svg").remove();

    // Set up the SVG container
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Filter data based on visibleScopes
    const filteredData = data.map((d) => ({
      year: d.year,
      scope1: visibleScopes.scope1 ? d.scope1 : 0,
      scope2: visibleScopes.scope2 ? d.scope2 : 0,
      scope3: visibleScopes.scope3 ? d.scope3 : 0,
    }));

    // Define the X and Y scales
    const x = d3
      .scaleBand()
      .domain(filteredData.map((d) => d.year))
      .range([0, width])
      .padding(0.1);
    const y = d3
      .scaleLinear()
      .domain([0, d3.max(filteredData, (d) => d.scope1 + d.scope2 + d.scope3)])
      .nice()
      .range([height, 0]);

    // Define color scale for the stacked bars
    const color = d3
      .scaleOrdinal()
      .domain(["scope1", "scope2", "scope3"])
      .range(["#e38322", "#f2c79c", "#166963"]);

    // Stack the data
    const stack = d3.stack().keys(["scope1", "scope2", "scope3"]);
    const stackedData = stack(filteredData);

    // Create the X and Y axes
    svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x));

    svg.append("g").attr("class", "y-axis").call(d3.axisLeft(y).ticks(5));

    svg
      .append("text")
      .attr("x", width / 2 - 20)
      .attr("y", height - margin.bottom + 70)
      .attr("text-anchor", "middle")
      .text("Year");

    svg
      .append("text")
      .attr("x", -height / 2)
      .attr("y", -30)
      .attr("text-anchor", "middle")
      .attr("transform", "rotate(-90)")
      .text("Emissions in tCO2e");

    // Add the bars for the stacked chart (setting width of bars to 60)
    svg
      .selectAll(".bar")
      .data(stackedData)
      .enter()
      .append("g")
      .attr("class", "bar")
      .attr("fill", (d) => color(d.key))
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("transform", `translate(40,0)`)
      .attr("x", (d) => x(d.data.year))
      .attr("y", (d) => y(d[1]))
      .attr("height", (d) => y(d[0]) - y(d[1]))
      .attr("width", 60); // Set width of bars to 60
  };

  return (
    <div>
      <div>
        <h3 style={{ fontSize: "18px", margin: "25px" }}>
          Total Emissions by Scope (2023 & 2024)
        </h3>
        <div style={{ margin: "25px" }}>
          Breakdown of total emissions for 2023 and 2024.
        </div>
      </div>

      {/* Chart */}
      <div ref={chartRef}></div>

      {/* Legend with Material-UI Checkboxes */}
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {["scope1", "scope2", "scope3"].map((scope) => (
          <FormControlLabel
            key={scope}
            control={
              <Checkbox
                checked={visibleScopes[scope]}
                onChange={() => handleToggle(scope)}
                style={{
                  color: d3
                    .scaleOrdinal()
                    .domain(["scope1", "scope2", "scope3"])
                    .range(["#e38322", "#f2c79c", "#166963"])(scope),
                }}
              />
            }
            label={
              scope === "scope1"
                ? "Scope 1"
                : scope === "scope2"
                ? "Scope 2"
                : "Scope 3"
            }
          />
        ))}
      </div>
    </div>
  );
};

export default TotalScopeStacked;
