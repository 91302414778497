import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { Diversity1 } from "@mui/icons-material";

const data = [
  { year: 2020, diversityMetric: 40 },
  { year: 2021, diversityMetric: 45 },
  { year: 2022, diversityMetric: 50 },
  { year: 2023, diversityMetric: 55 },
  { year: 2024, diversityMetric: 60 },
];

const DiversityPyramid = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!chartRef.current) return; // Prevent null ref errors
    renderChart();
  }, []);

  const renderChart = () => {
    const width = 600;
    const height = 400;
    const margin = { top: 20, right: 30, bottom: 60, left: 60 };

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    // Scale for X-axis (Year)
    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.year))
      .range([margin.left, width - margin.right])
      .padding(0.1);

    // Scale for Y-axis (Diversity Metric Value)
    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.diversityMetric)])
      .nice()
      .range([height - margin.bottom, margin.top]);

    // Draw bars (pyramid-like)
    svg
      .selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("x", (d) => x(d.year))
      .attr("y", (d) => y(d.diversityMetric))
      .attr("width", x.bandwidth())
      .attr("height", (d) => height - margin.bottom - y(d.diversityMetric))
      .attr("fill", "#66cc66"); // Green color for bars

    // Draw trendline (line chart)
    const line = d3
      .line()
      .x((d) => x(d.year) + x.bandwidth() / 2)
      .y((d) => y(d.diversityMetric));

    svg
      .append("path")
      .data([data])
      .attr("class", "line")
      .attr("d", line)
      .attr("fill", "none")
      .attr("stroke", "#0077b3") // Blue color for trendline
      .attr("stroke-width", 2);

    // Add X-axis labels (Year)
    svg
      .append("g")
      .selectAll("text")
      .data(x.domain())
      .enter()
      .append("text")
      .attr("x", (d) => x(d) + x.bandwidth() / 2)
      .attr("y", height - margin.bottom + 10)
      .attr("text-anchor", "middle")
      .text((d) => d)
      .style("font-size", "12px");

    // Add Y-axis labels (Diversity Metric Value)
    svg
      .append("g")
      .selectAll("text")
      .data(y.ticks(5))
      .enter()
      .append("text")
      .attr("x", margin.left - 10)
      .attr("y", (d) => y(d) + 5)
      .attr("dy", ".35em")
      .style("text-anchor", "end")
      .style("font-size", "12px")
      .text((d) => d);

    // Axis Labels
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", height - 5)
      .style("text-anchor", "middle")
      .text("Year");

    svg
      .append("text")
      .attr("x", -height / 2)
      .attr("y", 15)
      .style("text-anchor", "middle")
      .style("transform", "rotate(-90deg)")
      .text("Diversity Metric Value");
  };

  return (
    <div>
      <h3 style={{ fontSize: "18px" }}>Year-over-Year Change in Diversity</h3>
      <div
        ref={chartRef}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      />
    </div>
  );
};

export default DiversityPyramid;
