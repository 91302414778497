import Axios from "axios";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { API } from "../../../../constants/api_url";

import { useHistory } from "react-router-dom";
import { TabMenu } from 'primereact/tabmenu';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column"
import { Tooltip } from "primereact/tooltip";
import Swal from 'sweetalert2'
import APIServices from "../../../../service/APIService";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { MultiSelect } from "primereact/multiselect";
import EnvironmentMetrics from "./EnvironmentKPITableComponent";

const { DateTime } = require('luxon')


const EnvironmentKPIDB = ()=>{
    const login_data = useSelector((state) => state.user.userdetail);
    const admin_data = useSelector((state) => state.user.admindetail);
    const { fymonth } = useSelector((state) => state.user.fyStartMonth);
    const [filter, setFilter] = useState({ year: null, category: 0, country: 0, city: null, location: null,section:7 })
    const [locList, setLocList] = useState({ country: [], city: [], location: [] })
    const forceUpdate = useForceUpdate()
    const [yearoptions, setYearOptions] = useState([])
    const [section,setSection]= useState([{name:'Sustainability',id:1},{name:'Health',id:2},{name:'Safety Central',id:3},{name:'Safety Operational',id:4},{name:'Supply Chain',id:5},{name:'Dealership Sustainability',id:6},{name:'Environmental',id:7},{name:'Social',id:8},{name:'Governance',id:9}])
    const [rawsitelist, setRawSiteList] = useState([])
    useEffect(() => {
        let selectedYear = null
        let yrOptions = getFiscalYearsFromStartDate(admin_data.information.startdate)
        console.log(yrOptions)
        if (yrOptions.length !== 0) {
            selectedYear = yrOptions[yrOptions.length - 1].name
            setFilter((prev) => ({ ...prev, year: selectedYear }))
        }
        console.log(yrOptions)
        setYearOptions(yrOptions)
        let uriString = {
            include: [
                {
                    relation: "locationTwos",
                    scope: { include: [{ relation: "locationThrees" }] },
                },
            ],
        };
        let ef_complete = API.EF_Std + `?filter=%7B%20%22include%22%3A%20%5B%7B%20%22relation%22%3A%20%22newEfDates%22,%20%22scope%22%3A%20%7B%20%22include%22%3A%20%5B%7B%20%22relation%22%3A%20%22newEfs%22,%20%22scope%22%3A%20%7B%20%22include%22%3A%20%5B%7B%20%22relation%22%3A%20%22newEfItems%22%20%7D%5D%20%7D%20%7D%5D%20%7D%20%7D%5D%7D`

        const promise1 = APIServices.get(API.QN_Submit_UP(login_data.clientId))

        const promise2 = APIServices.get(
            API.LocationOne_UP(login_data.information.cid) +
            `?filter=${encodeURIComponent(JSON.stringify(uriString))}`)


        Promise.all([promise1, promise2]).then((values) => {
            let qnsubmissions = values[0].data
        
            
            const shapedSite = values[1].data
                .map((item) => {
                    if (item.locationTwos) {
                        item.locationTwos = item.locationTwos.filter(
                            (locationTwo) =>
                                locationTwo.locationThrees &&
                                locationTwo.locationThrees.length > 0
                        );
                    }
                    return item;
                })
                .filter((item) => item.locationTwos && item.locationTwos.length > 0);
                
            setRawSiteList(shapedSite)
            setLocList((prev) => ({ ...prev, country: [{ name: 'Enterprise-wide', id: 0 }, ...shapedSite.map(i => ({ name: i.name, id: i.id }))] }))

            if (selectedYear) {
                setFilter((prev) => ({ ...prev, year: selectedYear }))
            }


        })
    }, [])
    function getFiscalYearsFromStartDate(start_date) {
        console.log(start_date);
        const startDate = DateTime.fromISO(start_date, { zone: 'utc' }).plus({ months: 1 });
        const currentDate = DateTime.now();

        let startFiscalYear, currentFiscalYear;
        const fiscalYears = [];

        if (fymonth === 1) {
            // When fiscal month is January, it's a single year
            startFiscalYear = startDate.year;
            currentFiscalYear = currentDate.year;

            for (let year = startFiscalYear; year <= currentFiscalYear; year++) {
                fiscalYears.push({ name: year, label: `${year}` });
            }
        } else {
            // Normal fiscal year spanning two calendar years
            startFiscalYear = startDate.month >= fymonth ? startDate.year : startDate.year - 1;
            currentFiscalYear = currentDate.month >= fymonth ? currentDate.year : currentDate.year - 1;

            for (let year = startFiscalYear; year <= currentFiscalYear; year++) {
                const label = `${year}-${(year + 1).toString().slice(-2)}`;
                fiscalYears.push({ name: year + 1, label });
            }

            // Include the current fiscal year only if the current month is before the fiscal year start month
            if (currentDate.month < fymonth) {
                fiscalYears.pop();
            }
        }

        return fiscalYears;
    }
    const updateLocationFilter = (obj, val) => {
        let item = { ...filter, [obj]: val }
        let selected_item = { country: 0, city: 0, location: 0 }
        let country_list = [{ name: 'Enterprise-wide', id: 0 }]
        let city_list = [{ name: 'All Regions', id: 0 }]
        let location_list = [{ name: 'All Business Unit', id: 0 }]
        rawsitelist.forEach((country) => {
            country_list.push({ name: country.name, id: country.id })
            if (country.id === item.country || item.country === 0) {
                if (country.locationTwos) {
                    country.locationTwos.forEach((city) => {
                        city_list.push({ name: city.name, id: city.id })
                        if (city.id === item.city || item.city === 0) {
                            if (city.locationThrees) {
                                city.locationThrees.forEach((site) => {
                                    location_list.push({ name: site.name, id: site.id })

                                })
                            }
                        }
                    })

                }

            }

        })
        if (obj === 'country') {
            item.city = val === 0 ? null : 0
            item.location = null
        }
        else if (obj === 'city') {

            item.location = val === 0 ? null : 0
        }

        setFilter(item)

        setLocList((prev) => ({ ...prev, 'country': country_list, 'city': city_list, 'location': location_list }))
     
    }
    const renderContent = () => {
     return   <EnvironmentMetrics />
        
    };
    return (
        <div>
            <div className="tabs-container">
                <div className='flex justify-content-between'>
                  
                    <div className='col-2 grid m-0' >
                        <div className='col-12'>Year :</div>
                        <div className='grid m-0 col-12' >
                            <div className='col-12' >

                                <Dropdown value={filter.year} options={yearoptions} onChange={(e) => { updateLocationFilter('year', e.value) }} optionLabel='label' optionValue='name' placeholder="Select Year" style={{ width: '100%' }} />
                            </div>

                        </div>
                    </div>
                    <div className='col-2 grid m-0' >
                        <div className='col-12'>Section :</div>
                        <div className='grid m-0 col-12' >
                            <div className='col-12' >

                                <Dropdown value={filter.section} options={section} onChange={(e) => { setFilter((prev)=> ({...prev,section: e.value})) }} optionLabel='name' optionValue='id' placeholder="Select Section" style={{ width: '100%' }} />
                            </div>

                        </div>
                    </div>
                    <div className='col-8 grid m-0' >
                        <div className='col-12'>Location :</div>
                        <div className='grid m-0 col-12' >
                            <div className='col-4' >

                                <Dropdown value={filter.country} options={locList.country} onChange={(e) => { updateLocationFilter('country', e.value) }} optionLabel='name' optionValue='id' placeholder="Select Country" style={{ width: '100%' }} />
                            </div>
                            {(filter.country !== 0 && (filter.city !== null || filter.city === 0)) && <div className='col-4' >
                                <Dropdown value={filter.city} options={locList.city} onChange={(e) => { updateLocationFilter('city', e.value) }} optionLabel='name' optionValue='id' placeholder="Select Region" disabled={!locList.city.length} style={{ width: '100%' }} />
                            </div>}
                            {(filter.city !== 0 && filter.location !== null) && <div className='col-4' >
                                <Dropdown value={filter.location} options={locList.location} onChange={(e) => { updateLocationFilter('location', e.value) }} optionLabel='name' optionValue='id' placeholder="Select Business Unit" disabled={!locList.location.length} style={{ width: '100%' }} />
                            </div>}
                        </div>
                    </div>
                </div>

            </div>
          {renderContent()}
            </div>
    )

}


const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(EnvironmentKPIDB, comparisonFn);