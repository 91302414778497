import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { interpolateYlGn } from "d3-scale-chromatic"; // Use a subtle color scale (green shades)

const dummyData = {
  sites: ["Site A", "Site B", "Site C", "Site D", "Site E"],
  policies: ["Policy 1", "Policy 2", "Policy 3", "Policy 4", "Policy 5"],
  compliance: {
    "Site A": [90, 80, 70, 60, 50],
    "Site B": [80, 70, 60, 90, 85],
    "Site C": [75, 65, 55, 80, 90],
    "Site D": [95, 90, 85, 90, 80],
    "Site E": [60, 70, 80, 75, 65],
  },
};

const PolicyCompilanceHeatmap = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    renderHeatMap();
  }, []);

  const renderHeatMap = () => {
    const margin = { top: 50, right: 50, bottom: 50, left: 50 };
    const width = 650 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    // Clear any existing SVG
    d3.select(chartRef.current).select("svg").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Define the color scale for the heatmap
    const colorScale = d3
      .scaleSequential(interpolateYlGn) // Use a subtle green color scale
      .domain([
        d3.min(Object.values(dummyData.compliance).flat()),
        d3.max(Object.values(dummyData.compliance).flat()),
      ]);

    const sites = dummyData.sites;
    const policies = dummyData.policies;

    // Define the X and Y scales
    const xScale = d3.scaleBand().domain(sites).range([0, width]).padding(0.05);
    const yScale = d3
      .scaleBand()
      .domain(policies)
      .range([0, height])
      .padding(0.05);

    // Add X axis (Sites)
    svg
      .append("g")
      .selectAll(".x-axis")
      .data(sites)
      .enter()
      .append("text")
      .attr("x", (d, i) => xScale(d) + xScale.bandwidth() / 2)
      .attr("y", height + 15)
      .attr("text-anchor", "middle")
      .text((d) => d)
      .style("font-size", "12px");

    // Add Y axis (Policies)
    svg
      .append("g")
      .selectAll(".y-axis")
      .data(policies)
      .enter()
      .append("text")
      .attr("x", -23)
      .attr("y", (d, i) => yScale(d) + yScale.bandwidth() / 2)
      .attr("text-anchor", "middle")
      .text((d) => d)
      .style("font-size", "12px")
      .style("dominant-baseline", "middle");

    // Create the heatmap cells
    svg
      .selectAll("rect")
      .data(
        sites.flatMap((site, i) =>
          policies.map((policy, j) => ({
            site,
            policy,
            value: dummyData.compliance[site][j],
          }))
        )
      )
      .enter()
      .append("rect")
      .attr("x", (d) => xScale(d.site))
      .attr("y", (d) => yScale(d.policy))
      .attr("width", xScale.bandwidth())
      .attr("height", yScale.bandwidth())
      .style("fill", (d) => colorScale(d.value))
      .on("mouseover", (event, d) => {
        d3.select(event.currentTarget).style("opacity", 0.7);
        svg
          .append("text")
          .attr("x", xScale(d.site) + xScale.bandwidth() / 2)
          .attr("y", yScale(d.policy) + yScale.bandwidth() / 2)
          .attr("text-anchor", "middle")
          .attr("dy", ".35em")
          .style("font-size", "14px")
          .style("fill", "black")
          .text(d.value);
      })
      .on("mouseout", (event, d) => {
        d3.select(event.currentTarget).style("opacity", 1);
        svg.selectAll("text").remove();
      });
  };

  return (
    <div>
      <div
        style={{
          fontFamily: "Lato",
          fontSize: "18px",
          fontWeight: 700,
          lineHeight: "19.2px",
          textAlign: "left",
          margin: "25px",
        }}
      >
        Policy Compliance Heatmap
        <div style={{ fontWeight: 200, fontSize: "14px" }}>
          Visualizing compliance across sites and policies with a color scale.
        </div>
      </div>
      <div ref={chartRef} style={{ textAlign: "center" }} />

      {/* Legends */}
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <span style={{ fontSize: "14px" }}>
          Color scale represents compliance level: higher compliance has darker
          shades.
        </span>
      </div>
    </div>
  );
};

export default PolicyCompilanceHeatmap;
