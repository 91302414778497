import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { sankey as d3Sankey, sankeyLinkHorizontal } from "d3-sankey";

const WaterFlowDiagram = () => {
  const svgRef = useRef();

  useEffect(() => {
    const width = 700;
    const height = 300;

    // Data for the Sankey diagram
    const data = {
      nodes: [
        { id: "Source A" },
        { id: "Source B" },
        { id: "Treatment Plant" },
        { id: "Industrial Usage" },
        { id: "Garden Usage" },
        { id: "Domestic Usage" },
        { id: "Wastewater" },
      ],
      links: [
        { source: "Source A", target: "Treatment Plant", value: 100 },
        { source: "Source B", target: "Treatment Plant", value: 80 },
        { source: "Treatment Plant", target: "Industrial Usage", value: 50 },
        { source: "Treatment Plant", target: "Garden Usage", value: 40 },
        { source: "Treatment Plant", target: "Domestic Usage", value: 70 },
        { source: "Industrial Usage", target: "Wastewater", value: 30 },
        { source: "Garden Usage", target: "Wastewater", value: 10 },
        { source: "Domestic Usage", target: "Wastewater", value: 50 },
      ],
    };

    // Remove any existing SVG content
    d3.select(svgRef.current).selectAll("*").remove();

    // Create the SVG container
    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height);

    // Create the Sankey generator
    const sankey = d3Sankey()
      .nodeId((d) => d.id)
      .nodeWidth(10) // Thinner node width
      .nodePadding(20) // Space between nodes
      .extent([
        [0, 0],
        [width, height],
      ]);

    // Compute the node and link positions
    const { nodes, links } = sankey({
      nodes: data.nodes.map((d) => ({ ...d })),
      links: data.links.map((d) => ({ ...d })),
    });

    // Render the links (flows)
    svg
      .append("g")
      .selectAll("path")
      .data(links)
      .join("path")
      .attr("d", sankeyLinkHorizontal())
      .attr("stroke", (d, i) => d3.schemeCategory10[i % 10]) // Link color coding
      .attr("fill", "none")
      .attr("stroke-width", (d) => Math.max(1, d.width))
      .attr("opacity", 0.8)
      .append("title")
      .text((d) => `${d.source.id} → ${d.target.id}: ${d.value}`);

    // Add flow labels on links
    svg
      .append("g")
      .selectAll("text")
      .data(links)
      .join("text")
      .attr("x", (d) => (d.source.x1 + d.target.x0) / 2)
      .attr(
        "y",
        (d) => (d.source.y0 + d.source.y1 + d.target.y0 + d.target.y1) / 4
      )
      .attr("text-anchor", "middle")
      .attr("font-size", "12px")
      .attr("fill", "#000")
      .text((d) => d.value);

    // Render the nodes
    svg
      .append("g")
      .selectAll("rect")
      .data(nodes)
      .join("rect")
      .attr("x", (d) => d.x0)
      .attr("y", (d) => d.y0)
      .attr("width", (d) => d.x1 - d.x0)
      .attr("height", (d) => d.y1 - d.y0)
      .attr("fill", (d, i) => d3.schemeCategory10[i % 10]) // Node color coding
      .append("title")
      .text((d) => `${d.id}: ${d.value || "N/A"}`);

    // Add node labels
    svg
      .append("g")
      .selectAll("text")
      .data(nodes)
      .join("text")
      .attr("x", (d) => d.x0 - 10)
      .attr("y", (d) => (d.y0 + d.y1) / 2)
      .attr("text-anchor", "end")
      .attr("font-size", "14px")
      .attr("fill", "#000")
      .text((d) => d.id);

    // Add output labels for nodes on the right
    svg
      .append("g")
      .selectAll(".output-label")
      .data(nodes)
      .join("text")
      .filter((d) => d.x1 === width)
      .attr("x", (d) => d.x1 + 10)
      .attr("y", (d) => (d.y0 + d.y1) / 2)
      .attr("text-anchor", "start")
      .attr("font-size", "14px")
      .attr("fill", "#000")
      .text((d) => d.id);
  }, []);

  return (
    <>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>Water Flow Diagram</h3>
      <div>
        <svg ref={svgRef} />
      </div>
    </>
  );
};

export default WaterFlowDiagram;
