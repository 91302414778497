import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { interpolateYlOrRd } from "d3-scale-chromatic"; // Use a red-yellow gradient for impact levels

const dummyData = {
  operationalAreas: ["Area 1", "Area 2", "Area 3", "Area 4", "Area 5"],
  legalActions: [
    "Lawsuit",
    "Fines",
    "Regulatory Action",
    "Settlement",
    "Warnings",
  ],
  impacts: {
    "Area 1": [5, 15, 25, 35, 10],
    "Area 2": [10, 20, 30, 25, 5],
    "Area 3": [15, 25, 35, 20, 10],
    "Area 4": [20, 30, 10, 15, 25],
    "Area 5": [25, 35, 20, 10, 5],
  },
};

const LegalActionImpactHeatmap = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    renderHeatMap();
  }, []);

  const renderHeatMap = () => {
    const margin = { top: 50, right: 50, bottom: 50, left: 120 };
    const width = 650 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    // Clear any existing SVG
    d3.select(chartRef.current).select("svg").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Define the color scale for the heatmap
    const colorScale = d3
      .scaleSequential(interpolateYlOrRd) // Use red-yellow for impact levels
      .domain([
        d3.min(Object.values(dummyData.impacts).flat()),
        d3.max(Object.values(dummyData.impacts).flat()),
      ]);

    const operationalAreas = dummyData.operationalAreas;
    const legalActions = dummyData.legalActions;

    // Define the X and Y scales
    const xScale = d3
      .scaleBand()
      .domain(legalActions)
      .range([0, width])
      .padding(0.05);

    const yScale = d3
      .scaleBand()
      .domain(operationalAreas)
      .range([0, height])
      .padding(0.05);

    // Add X axis (Legal Actions)
    svg
      .append("g")
      .selectAll(".x-axis")
      .data(legalActions)
      .enter()
      .append("text")
      .attr("x", (d) => xScale(d) + xScale.bandwidth() / 2)
      .attr("y", height + 20)
      .attr("text-anchor", "middle")
      .text((d) => d)
      .style("font-size", "12px");

    // Add Y axis (Operational Areas)
    svg
      .append("g")
      .selectAll(".y-axis")
      .data(operationalAreas)
      .enter()
      .append("text")
      .attr("x", -10)
      .attr("y", (d) => yScale(d) + yScale.bandwidth() / 2)
      .attr("text-anchor", "end")
      .text((d) => d)
      .style("font-size", "12px")
      .style("dominant-baseline", "middle");

    // Create the heatmap cells
    svg
      .selectAll("rect")
      .data(
        operationalAreas.flatMap((area, i) =>
          legalActions.map((action, j) => ({
            area,
            action,
            value: dummyData.impacts[area][j],
          }))
        )
      )
      .enter()
      .append("rect")
      .attr("x", (d) => xScale(d.action))
      .attr("y", (d) => yScale(d.area))
      .attr("width", xScale.bandwidth())
      .attr("height", yScale.bandwidth())
      .style("fill", (d) => colorScale(d.value))
      .on("mouseover", (event, d) => {
        d3.select(event.currentTarget).style("opacity", 0.7);
        svg
          .append("text")
          .attr("x", xScale(d.action) + xScale.bandwidth() / 2)
          .attr("y", yScale(d.area) + yScale.bandwidth() / 2)
          .attr("text-anchor", "middle")
          .attr("dy", ".35em")
          .style("font-size", "14px")
          .style("fill", "black")
          .text(d.value);
      })
      .on("mouseout", () => {
        d3.selectAll(".hover-text").remove();
        d3.selectAll("rect").style("opacity", 1);
      });
  };

  return (
    <div>
      <div
        style={{
          fontFamily: "Lato",
          fontSize: "18px",
          fontWeight: 700,
          lineHeight: "19.2px",
          textAlign: "left",
          margin: "25px",
        }}
      >
        Legal Action Impact Heatmap
        <div style={{ fontWeight: 200, fontSize: "14px" }}>
          Visualizing the impact levels of various legal actions across
          operational areas.
        </div>
      </div>
      <div ref={chartRef} style={{ textAlign: "center" }} />

      {/* Legend */}
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <span style={{ fontSize: "14px" }}>
          Color scale represents impact level: higher impact is shown in darker
          shades of red.
        </span>
      </div>
    </div>
  );
};

export default LegalActionImpactHeatmap;
