import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const data = [
  {
    category: "Complaints Received",
    received: 50,
    resolved: 30,
  },
  {
    category: "Complaints Resolved",
    received: 60,
    resolved: 50,
  },
];

const ComplaintsChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    renderChart();
  }, []);

  const renderChart = () => {
    const width = 600;
    const height = 400;
    const margin = { top: 50, right: 150, bottom: 100, left: 60 };

    // Remove any existing SVG for re-rendering
    d3.select(chartRef.current).select("svg").remove();

    // Create SVG canvas
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Keys for stacking
    const keys = ["received", "resolved"];

    // Stack data
    const stackedData = d3.stack().keys(keys)(data);

    // X and Y scales
    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.category))
      .range([0, chartWidth])
      .padding(0.4);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.received + d.resolved)]) // Maximum value of received + resolved complaints
      .range([chartHeight, 0]);

    // Colors for bars
    const color = d3.scaleOrdinal().domain(keys).range(["#6fa8dc", "#e06666"]);

    // Add X-axis
    g.append("g")
      .attr("transform", `translate(0,${chartHeight})`)
      .call(d3.axisBottom(x));

    // Add Y-axis
    g.append("g").call(d3.axisLeft(y).ticks(5));

    // Add X-axis label
    g.append("text")
      .attr("x", chartWidth / 2)
      .attr("y", chartHeight + 40)
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .text("Complaint Categories");

    // Add Y-axis label
    g.append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -chartHeight / 2)
      .attr("y", -40)
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .text("Number of Complaints");

    // Draw stacked bars with fixed width of 60
    g.selectAll("g.stack")
      .data(stackedData)
      .enter()
      .append("g")
      .attr("fill", (d) => color(d.key))
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("x", (d) => x(d.data.category))
      .attr("y", (d) => y(d[1]))
      .attr("height", (d) => y(d[0]) - y(d[1]))
      .attr("width", 60) // Set the width of bars to 60
      .append("title")
      .text((d, i) => {
        const key = keys[i % keys.length];
        return `${key}: ${d[1] - d[0]}`;
      });

    // Add legend below the graph and horizontally place items next to each other
    const legend = svg
      .append("g")
      .attr(
        "transform",
        `translate(${width / 3},${height - margin.bottom + 60})`
      ); // Position legend below chart

    // Set starting X position for the legend
    let legendX = 0;

    keys.forEach((key, i) => {
      // Add the legend rectangles
      legend
        .append("rect")
        .attr("x", legendX)
        .attr("y", 0)
        .attr("width", 15)
        .attr("height", 15)
        .attr("fill", color(key));

      // Add the legend labels
      legend
        .append("text")
        .attr("x", legendX + 20)
        .attr("y", 12)
        .style("font-size", "12px")
        .text(key.charAt(0).toUpperCase() + key.slice(1));

      // Update X position for next legend item
      legendX += 80; // Adjust space between items (15px for rect, 20px for text, and 45px for spacing)
    });
  };

  return (
    <>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Complaints related to Anti-Competitive Behavior
      </h3>

      <div
        ref={chartRef}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      />
    </>
  );
};

export default ComplaintsChart;
