import React from "react";
import { Box, Typography, LinearProgress } from "@mui/material";

const SafetyBriefingProgress = () => {
  const data = [
    {
      label: "Total Number of Employee",
      value: 60, // Percentage of volunteers who completed the briefing
    },
    {
      label: "New Hires",
      value: 75, // Percentage of new hires who completed the briefing
    },
  ];

  const getColor = (value) => {
    if (value === 100) return "success";
    if (value >= 50) return "warning";
    return "error";
  };

  return (
    <>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Safety Briefing Completion Progress
      </h3>

      <Box
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
        padding="20px"
      >
        {data.map((item, index) => (
          <Box
            key={index}
            display="flex"
            flexDirection="column"
            alignItems="center"
            padding="10px"
            width="45%"
          >
            <Typography variant="h6" style={{ marginBottom: "10px" }}>
              {item.label}
            </Typography>
            <Box display="flex" alignItems="center" width="100%">
              <LinearProgress
                variant="determinate"
                value={item.value}
                color={getColor(item.value)}
                style={{
                  height: 20,
                  width: "100%",
                  borderRadius: 10,
                  backgroundColor: "#e0e0e0", // Subtle background color
                }}
              />
            </Box>
            <Typography
              variant="body1"
              style={{ marginTop: "10px", fontWeight: "bold" }}
            >
              {`${item.value}% Completed`}
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default SafetyBriefingProgress;
