import React, { useRef, useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { wasteData, WasteManagement } from "./WasteManagementChart";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Checkbox } from "@material-ui/core";
import { Menu } from "primereact/menu";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const initialData = [
  {
    name: "2019",
    preparedReuse: 5,
    recycled: 15,
    otherRecovery: 20,
    incinerationEnergy: 10,
    incinerationNoEnergy: 8,
    landfill: 5,
  },
  {
    name: "2020",
    preparedReuse: 4,
    recycled: 14,
    otherRecovery: 19,
    incinerationEnergy: 9,
    incinerationNoEnergy: 7,
    landfill: 6,
  },
  {
    name: "2021",
    preparedReuse: 3,
    recycled: 13,
    otherRecovery: 18,
    incinerationEnergy: 11,
    incinerationNoEnergy: 9,
    landfill: 5,
  },
  {
    name: "2022",
    preparedReuse: 6,
    recycled: 15,
    otherRecovery: 21,
    incinerationEnergy: 12,
    incinerationNoEnergy: 8,
    landfill: 7,
  },
  {
    name: "2023",
    preparedReuse: 5,
    recycled: 14,
    otherRecovery: 20,
    incinerationEnergy: 10,
    incinerationNoEnergy: 8,
    landfill: 6,
  },
];

const OverviewNonHazardous = () => {
  const [data, setData] = useState(initialData);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const menuRef = useRef(null);
  const tableRef = useRef(null);
  const chartRef = useRef(null);
  const [visibleSeries, setVisibleSeries] = useState({
    preparedReuse: true,
    recycled: true,
    otherRecovery: true,
    incinerationEnergy: true,
    incinerationNoEnergy: true,
    landfill: true,
  });
  const [activeMode, setActiveMode] = useState(true);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCheckboxChange = (key) => {
    setVisibleSeries((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
    setData((prevData) =>
      prevData.map((item) => ({
        ...item,
        [key]: visibleSeries[key]
          ? 0
          : initialData.find((d) => d.year === item.year)[key],
      }))
    );
  };

  const CustomWasteLegend = (props) => {
    const { payload } = props;
    return (
      <ul
        style={{
          display: "flex",
          listStyleType: "none",
          justifyContent: "center",
        }}
      >
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ color: entry.color }}>
            <Checkbox
              checked={visibleSeries[entry.dataKey]}
              onChange={() => handleCheckboxChange(entry.dataKey)}
              style={{
                color: entry.color,
                marginRight: 4,
                fontSize: "20px",
              }}
            />

            <span style={{ color: "#555", fontSize: "14px" }}>
              {entry.value}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  const panelItems = [
    {
      items: [
        {
          label: "Export as Excel",
          icon: "pi pi-file-excel",
          command: () => {
            downloadExcelWithImage(chartRef);
          },
        },
        {
          label: "Export as PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            downloadPdfWithImage(chartRef);
          },
        },
        {
          label: "Export as JPG",
          icon: "pi pi-image",
          command: () => {
            downloadChartAsJpg(chartRef);
          },
        },
        activeMode && {
          label: "Print",
          icon: "pi pi-print",
          command: () => {
            printChart(chartRef);
          },
        },
      ],
    },
  ];

  const downloadExcelWithImage = async (chartRef) => {
    // Create a new workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Non-Hazardous waste Data");

    // Add title in A1 and merge across columns
    worksheet.mergeCells("A1:E1");
    worksheet.getCell("A1").value = "Non-Hazardous waste, Filtered location";
    worksheet.getCell("A1").font = { bold: true, size: 16 };
    worksheet.getCell("A1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add timestamp in A2 and merge across columns
    const timestamp = new Date().toLocaleString();
    worksheet.mergeCells("A2:E2");
    worksheet.getCell("A2").value = `Generated on: ${timestamp}`;
    worksheet.getCell("A2").font = { italic: true, size: 12 };
    worksheet.getCell("A2").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Leave an empty row for spacing (Row 3)
    worksheet.getRow(3).values = [];

    // Manually set the column headers in row 4
    worksheet.getRow(4).values = [
      "Year",
      visibleSeries.preparedReuse && "Prepared Reuse",
      visibleSeries.recycled && "Recycled",
      visibleSeries.otherRecovery && "Other Recovery",
      visibleSeries.incinerationEnergy && "Incineration Energy",
      visibleSeries.incinerationNoEnergy && "Incineration No Energy",
      visibleSeries.landfill && "Landfill",
    ];

    // Define columns for the table (this does NOT affect Row 4 headers)
    worksheet.columns = [
      { key: "year", width: 10 },
      visibleSeries.preparedReuse && { key: "preparedReuse", width: 15 },
      visibleSeries.recycled && { key: "recycled", width: 15 },
      visibleSeries.otherRecovery && { key: "otherRecovery", width: 15 },
      visibleSeries.incinerationEnergy && {
        key: "incinerationEnergy",
        width: 15,
      },
      visibleSeries.incinerationNoEnergy && {
        key: "incinerationNoEnergy",
        width: 15,
      },
      visibleSeries.landfill && { key: "landfill", width: 15 },
    ];

    // Populate data rows starting from row 5
    initialData.forEach((row) => worksheet.addRow(row));

    // Capture the chart as an image
    //chartRef.current.data.dataset[0].data = initialData;
    const canvas = await html2canvas(chartRef.current);

    const imageData = canvas.toDataURL("image/png");

    // Add the image to the workbook below the data
    const imageId = workbook.addImage({
      base64: imageData,
      extension: "png",
    });

    worksheet.addImage(imageId, {
      tl: { col: 0.5, row: data.length + 6 }, // Adjust row based on data length
      ext: { width: 500, height: 300 },
    });

    // Download the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "Non-Hazardous.xlsx");
  };

  const downloadPdfWithImage = async (chartRef) => {
    // Create a new jsPDF instance
    const doc = new jsPDF();

    // Add title
    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.text("Non-Hazardous waste management Report", 20, 20); // Adjust position (x, y)

    // Add timestamp
    const timestamp = new Date().toLocaleString();
    doc.setFontSize(12);
    doc.setFont("helvetica", "italic");
    doc.text(`Generated on: ${timestamp}`, 20, 30); // Adjust position (x, y)

    // Add a line break
    doc.text("\n", 20, 40);

    // Define column headers
    const headers = [
      "Year",
      visibleSeries.preparedReuse && "Prepared Reuse",
      visibleSeries.recycled && "Recycled",
      visibleSeries.otherRecovery && "Other Recovery",
      visibleSeries.incinerationEnergy && "Incineration Energy",
      visibleSeries.incinerationNoEnergy && "Incineration No Energy",
      visibleSeries.landfill && "Landfill",
    ];
    const dataRows = initialData.map((row) => [
      row.year,
      row.preparedReuse,
      row.recycled,
      row.otherRecovery,
      row.incinerationEnergy,
      row.incinerationNoEnergy,
      row.landfill,
    ]);

    // Add table (with headers and data rows)
    doc.autoTable({
      head: [headers],
      body: dataRows,
      startY: 50, // Start after the title and timestamp
      theme: "grid",
      headStyles: { fillColor: [0, 0, 0] }, // Dark background for header
      styles: { cellPadding: 2, fontSize: 10 },
    });

    // Capture the chart as an image
    const canvas = await html2canvas(chartRef.current);
    const imageData = canvas.toDataURL("image/png");

    // Add the chart image below the table
    doc.addImage(imageData, "PNG", 20, doc.lastAutoTable.finalY + 10, 180, 100); // Adjust position and size

    // Download the PDF file
    doc.save("Non-Hazardous.pdf");
  };

  const downloadChartAsJpg = async (chartRef) => {
    // Capture the chart as an image using html2canvas
    const canvas = await html2canvas(chartRef.current);
    // Convert the canvas to JPG data
    const imageData = canvas.toDataURL("image/jpeg");
    // Create a link element to trigger the download
    const link = document.createElement("a");
    link.href = imageData;
    link.download = "Non-Hazardous.jpg"; // Filename for the image
    link.click(); // Programmatically click the link to trigger the download
  };

  const printChart = async (chartRef) => {
    // Capture the chart as an image using html2canvas
    const canvas = await html2canvas(chartRef.current);
    const chartImage = canvas.toDataURL("image/png");

    // Create a new window for printing
    const printWindow = window.open("", "", "height=600,width=800");

    // Add some basic styles for the printed content
    const styles = `
      <style>
        body { font-family: Arial, sans-serif; }
        h1 { text-align: center; }
        h2 { margin-top: 20px; }
        img { max-width: 100%; height: auto; margin-bottom: 20px; }
        .table-container { margin-top: 20px; }
        table { width: 100%; border-collapse: collapse; }
        th, td { padding: 10px; text-align: left; border: 1px solid #ddd; }
      </style>
    `;

    // Set the content of the print window
    printWindow.document.write(
      "<html><head><title>Print Report</title>" + styles + "</head><body>"
    );
    printWindow.document.write("<h1>Hazardous Report</h1>");

    // Add chart image
    printWindow.document.write("<h2>Chart</h2>");
    printWindow.document.write(`<img src="${chartImage}" />`);

    // Add table content

    // Check if table content is available and print it

    printWindow.document.write("</body></html>");

    // Close the document and trigger the print dialog
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px",
          }}
        >
          Non-Hazardous waste by disposal modes - %
          <div style={{ fontWeight: 200, fontSize: "14px" }}>
            Percentage of Non-Hazardous waste through different disposal modes
          </div>
        </div>
        <div style={{ margin: "18px 10px", display: "flex" }}>
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => setActiveMode(false)}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => setActiveMode(true)}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <div ref={menuRef}>
            <Button
              style={{
                color: "black",
                height: "30px",
                marginLeft: "3px",
                background: "#F0F2F4",
                border: "0px",
                padding: "6px",
                position: "relative",
              }}
              onClick={() => {
                setDropdownOpen(!dropdownOpen);
              }}
            >
              <i className="pi pi-angle-down fs-19" />
            </Button>
            {dropdownOpen && (
              <Menu
                model={panelItems}
                style={{
                  position: "absolute",
                  right: 45,
                  zIndex: "1",
                  padding: 0,
                }}
              ></Menu>
            )}
          </div>
        </div>
      </div>
      {activeMode && (
        <ResponsiveContainer width="100%" height={500} ref={chartRef}>
          <BarChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            barSize={70}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend content={CustomWasteLegend} />
            <Bar
              dataKey="preparedReuse"
              stackId="a"
              fill="#00876C"
              name="Prepared Reuse"
            />
            <Bar
              dataKey="recycled"
              stackId="a"
              fill="#7A5195"
              name="Recycled"
            />
            <Bar
              dataKey="otherRecovery"
              stackId="a"
              fill="#EF5675"
              name="Other Recovery"
            />
            <Bar
              dataKey="incinerationEnergy"
              stackId="a"
              fill="#FFA600"
              name="Incineration with Energy"
            />
            <Bar
              dataKey="incinerationNoEnergy"
              stackId="a"
              fill="#003F5C"
              name="Incineration without Energy"
            />
            <Bar
              dataKey="landfill"
              stackId="a"
              fill="#D45087"
              name="Landfill"
            />
          </BarChart>
        </ResponsiveContainer>
      )}
      {!activeMode && (
        <div>
          <DataTable
            ref={tableRef}
            value={wasteData[2023]}
            tableClassName="font-lato"
          >
            {visibleSeries.preparedReuse && (
              <Column
                header="Reused %"
                style={{ minWidth: "8%" }}
                field="preparedReuse"
                emptyMessage="No Assignment(s)"
              />
            )}
            {visibleSeries.recycled && (
              <Column
                header="Recycled %"
                style={{ minWidth: "8%" }}
                field="recycled"
                emptyMessage="No Assignment(s)"
              />
            )}
            {visibleSeries.otherRecovery && (
              <Column
                header="Other Recovery %"
                style={{ minWidth: "8%" }}
                field="otherRecovery"
                emptyMessage="No Assignment(s)"
              />
            )}
            {visibleSeries.incinerationNoEnergy && (
              <Column
                header="Composed %"
                style={{ minWidth: "8%" }}
                field="incineration"
                emptyMessage="No Assignment(s)"
              />
            )}
            {visibleSeries.incinerationEnergy && (
              <Column
                header="Energy Recovery %"
                style={{ minWidth: "8%" }}
                field="incinerationEnergy"
                emptyMessage="No Assignment(s)"
              />
            )}
            {visibleSeries.landfill && (
              <Column
                header="Landfill %"
                style={{ minWidth: "8%" }}
                field="landfill"
                emptyMessage="No Assignment(s)"
              />
            )}
          </DataTable>
        </div>
      )}
    </>
  );
};

export default OverviewNonHazardous;
