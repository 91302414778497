import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { Checkbox, FormControlLabel } from "@mui/material";

// Sample data for initial Scope 1 emissions by facility
const initialData = [
  { facility: "Hosur", totalEmissions: 100 },
  { facility: "Mysore", totalEmissions: 200 },
  { facility: "Nalgargh", totalEmissions: 150 },
];

// Data for drill-down breakdown by emission type for each facility
const breakdownData = {
  Hosur: [
    { type: "Stationary", emissions: 40 },
    { type: "Mobile", emissions: 30 },
    { type: "Fugitive", emissions: 30 },
  ],
  Mysore: [
    { type: "Stationary", emissions: 80 },
    { type: "Mobile", emissions: 60 },
    { type: "Fugitive", emissions: 60 },
  ],
  Nalgargh: [
    { type: "Stationary", emissions: 50 },
    { type: "Mobile", emissions: 50 },
    { type: "Fugitive", emissions: 50 },
  ],
};

const Scope1Breakdown = () => {
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [selectedEmissions, setSelectedEmissions] = useState({
    stationary: true,
    mobile: true,
    fugitive: true,
  });

  const handleBarClick = (data) => {
    setSelectedFacility(data.facility);
  };

  const handleCheckboxChange = (event) => {
    setSelectedEmissions({
      ...selectedEmissions,
      [event.target.name]: event.target.checked,
    });
  };

  const renderDrillDownChart = () => {
    const data = breakdownData[selectedFacility].filter(
      (d) => selectedEmissions[d.type.toLowerCase()]
    );

    return (
      <ResponsiveContainer width={600} height={300}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="type" />
          <YAxis />
          <Tooltip />
          <Legend />
          {data.map((entry, index) => (
            <Bar
              key={`bar-${index}`}
              dataKey="emissions"
              fill="#82ca9d"
              barSize={60}
            >
              <Cell
                key={`cell-${index}`}
                fill={
                  entry.type === "Stationary"
                    ? "#8cc1a7"
                    : entry.type === "Mobile"
                    ? "#a6d8ea"
                    : "#ffc1a1"
                }
              />
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    );
  };

  return (
    <div>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Scope 1 Emissions Breakdown
      </h3>
      <ResponsiveContainer width={600} height={350} style={{ margin: "auto" }}>
        <BarChart data={initialData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="facility" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar
            dataKey="totalEmissions"
            fill="#b3a7d6"
            onClick={handleBarClick}
            barSize={60}
          >
            {initialData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill="#d0c7e8" />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>

      {selectedFacility && (
        <div>
          <h4>Drill-down: {selectedFacility}</h4>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedEmissions.stationary}
                  onChange={handleCheckboxChange}
                  name="stationary"
                  color="primary"
                />
              }
              label="Stationary"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedEmissions.mobile}
                  onChange={handleCheckboxChange}
                  name="mobile"
                  color="primary"
                />
              }
              label="Mobile"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedEmissions.fugitive}
                  onChange={handleCheckboxChange}
                  name="fugitive"
                  color="primary"
                />
              }
              label="Fugitive"
            />
          </div>
          {renderDrillDownChart()}
        </div>
      )}
    </div>
  );
};

export default Scope1Breakdown;
