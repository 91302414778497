import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const PreventiveMeasureImpactScorecard = () => {
  const chartRef = useRef(null);

  // Data for the chart
  const data = [
    { measure: "Training Programs", score: 85 },
    { measure: "Safety Drills", score: 75 },
    { measure: "Equipment Upgrades", score: 90 },
    { measure: "Policy Updates", score: 70 },
    { measure: "Employee Feedback", score: 80 },
  ];

  useEffect(() => {
    renderChart();
  }, []);

  const renderChart = () => {
    const width = 800;
    const height = 500;
    const margin = { top: 50, right: 30, bottom: 100, left: 60 };

    // Clear previous chart
    d3.select(chartRef.current).select("svg").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Scale for X-axis
    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.measure))
      .range([0, chartWidth])
      .padding(0.4);

    // Scale for Y-axis
    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.score)])
      .nice()
      .range([chartHeight, 0]);

    // Color scale
    const color = d3.scaleOrdinal(d3.schemePastel1);

    // Add X-axis
    g.append("g")
      .attr("transform", `translate(0,${chartHeight})`)
      .call(d3.axisBottom(x))
      .selectAll("text")
      .style("text-anchor", "end")
      .style("font-size", "12px");

    // Add Y-axis
    g.append("g").call(d3.axisLeft(y));

    // Add X-axis label
    svg
      .append("text")
      .attr("x", margin.left + chartWidth / 2)
      .attr("y", height - 20)
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .text("Preventive Measure");

    // Add Y-axis label
    svg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -margin.top - chartHeight / 2)
      .attr("y", margin.left - 40)
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .text("Impact Score");

    // Add bars
    g.selectAll("rect")
      .data(data)
      .enter()
      .append("rect")
      .attr("x", (d) => x(d.measure))
      .attr("y", (d) => y(d.score))
      .attr("width", x.bandwidth())
      .attr("height", (d) => chartHeight - y(d.score))
      .attr("fill", (d) => color(d.measure));

    // Add bar labels
    g.selectAll("text.bar-label")
      .data(data)
      .enter()
      .append("text")
      .attr("class", "bar-label")
      .attr("x", (d) => x(d.measure) + x.bandwidth() / 2)
      .attr("y", (d) => y(d.score) - 5)
      .style("text-anchor", "middle")
      .style("font-size", "12px")
      .text((d) => d.score);
  };

  return (
    <>
      <h3 style={{ fontSize: "18px" }}>Preventive Measure Impact Scorecard</h3>
      <div>
        A bar chart showing the impact scores of various preventive measures
        implemented to improve outcomes.
      </div>
      <div
        ref={chartRef}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      />
    </>
  );
};

export default PreventiveMeasureImpactScorecard;
