import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const data = [
  { stage: "Complaints Received", value: 100 },
  { stage: "Complaints Reviewed", value: 80 },
  { stage: "Investigations Initiated", value: 60 },
  { stage: "Resolutions Provided", value: 40 },
];

const POSHFunnelChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    renderChart();
  }, []);

  const renderChart = () => {
    const width = 800;
    const height = 600;
    const margin = { top: 50, right: 50, bottom: 50, left: 50 };

    d3.select(chartRef.current).select("svg").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const funnelWidth = width - margin.left - margin.right;
    const funnelHeight = height - margin.top - margin.bottom;

    const colors = d3
      .scaleOrdinal()
      .domain(data.map((d) => d.stage))
      .range(["#f4a582", "#d6604d", "#b2182b", "#67001f"]);

    const totalValue = data[0].value;

    // Define the funnel geometry
    const funnelPath = (d, i) => {
      const stepHeight = funnelHeight / data.length;
      const topWidth = (funnelWidth * d.value) / totalValue;
      const bottomWidth =
        i < data.length - 1
          ? (funnelWidth * data[i + 1].value) / totalValue
          : 0;
      const y = i * stepHeight;

      return `
        M ${funnelWidth / 2 - topWidth / 2},${y}
        L ${funnelWidth / 2 + topWidth / 2},${y}
        L ${funnelWidth / 2 + bottomWidth / 2},${y + stepHeight}
        L ${funnelWidth / 2 - bottomWidth / 2},${y + stepHeight}
        Z
      `;
    };

    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Draw the funnel sections
    g.selectAll(".funnel-section")
      .data(data)
      .enter()
      .append("path")
      .attr("d", (d, i) => funnelPath(d, i))
      .attr("fill", (d) => colors(d.stage))
      .attr("stroke", "#fff")
      .attr("stroke-width", 2)
      .append("title")
      .text((d) => `${d.stage}: ${d.value}`);

    // Add stage labels
    g.selectAll(".funnel-label")
      .data(data)
      .enter()
      .append("text")
      .attr("x", funnelWidth / 2)
      .attr("y", (d, i) => i * (funnelHeight / data.length) + 20)
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .style("fill", "#000")
      .text((d) => `${d.stage} (${d.value})`);
  };

  return (
    <>
      <h3 style={{ fontSize: "18px" }}>POSH Complaint Resolution Funnel</h3>
      <div>
        Visualizing the complaint resolution process and highlighting
        bottlenecks or areas of concern at different stages.
      </div>
      <div
        ref={chartRef}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      />
    </>
  );
};

export default POSHFunnelChart;
