import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const YearOnYearChart = () => {
  const chartRef = useRef(null);

  // Hardcoded data for Year-on-Year Energy Intensity Index
  const data = [
    { year: 2015, energyIndex: 140 },
    { year: 2016, energyIndex: 130 },
    { year: 2017, energyIndex: 125 },
    { year: 2018, energyIndex: 120 },
    { year: 2019, energyIndex: 110 },
    { year: 2020, energyIndex: 100 },
    { year: 2021, energyIndex: 95 },
    { year: 2022, energyIndex: 85 },
  ];

  useEffect(() => {
    renderChart();
  }, []);

  const renderChart = () => {
    const width = 800;
    const height = 500;
    const margin = { top: 60, right: 40, bottom: 80, left: 80 };

    // Remove any existing SVG for re-rendering
    d3.select(chartRef.current).select("svg").remove();

    // Create SVG canvas
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Scales
    const xScale = d3
      .scaleBand()
      .domain(data.map((d) => d.year))
      .range([0, chartWidth])
      .padding(0.4);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.energyIndex)])
      .range([chartHeight, 0])
      .nice();

    // Add X-axis
    g.append("g")
      .attr("transform", `translate(0,${chartHeight})`)
      .call(d3.axisBottom(xScale).tickFormat((d) => d.toString()))
      .selectAll("text")
      .style("text-anchor", "middle");

    // Add Y-axis
    g.append("g").call(d3.axisLeft(yScale));

    // Add X-axis label
    g.append("text")
      .attr("x", chartWidth / 2)
      .attr("y", chartHeight + 50)
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .text("Year");

    // Add Y-axis label
    g.append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -chartHeight / 2)
      .attr("y", -50)
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .text("Energy Intensity Index");

    // Draw the line chart
    const line = d3
      .line()
      .x((d) => xScale(d.year) + xScale.bandwidth() / 2)
      .y((d) => yScale(d.energyIndex))
      .curve(d3.curveMonotoneX);

    g.append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "#007acc")
      .attr("stroke-width", 2)
      .attr("d", line);

    // Add data points
    g.selectAll(".data-point")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", (d) => xScale(d.year) + xScale.bandwidth() / 2)
      .attr("cy", (d) => yScale(d.energyIndex))
      .attr("r", 6)
      .attr("fill", "#007acc")
      .attr("stroke", "#ffffff")
      .attr("stroke-width", 2)
      .append("title")
      .text((d) => `Year: ${d.year}, Index: ${d.energyIndex}`);
  };

  return (
    <>
      <h3 style={{ fontSize: "18px" }}>Energy Intensity Index Over Time</h3>
      <div style={{ color: "#555" }}>
        Visualizing the year-on-year trend of the Energy Intensity Index.
      </div>
      <div
        ref={chartRef}
        style={{ display: "flex", justifyContent: "center" }}
      ></div>
    </>
  );
};

export default YearOnYearChart;
