import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

// Static data for injury trends over time
const data = [
  { time: "Jan", fatalities: 1, recordable: 5, ltifr: 2 },
  { time: "Feb", fatalities: 0, recordable: 3, ltifr: 1.5 },
  { time: "Mar", fatalities: 2, recordable: 6, ltifr: 2.5 },
  { time: "Apr", fatalities: 0, recordable: 4, ltifr: 1 },
  { time: "May", fatalities: 1, recordable: 5, ltifr: 2 },
  { time: "Jun", fatalities: 0, recordable: 3, ltifr: 1.5 },
  { time: "Jul", fatalities: 1, recordable: 4, ltifr: 2 },
  { time: "Aug", fatalities: 0, recordable: 2, ltifr: 1 },
  { time: "Sep", fatalities: 0, recordable: 3, ltifr: 1.2 },
  { time: "Oct", fatalities: 1, recordable: 5, ltifr: 2 },
  { time: "Nov", fatalities: 0, recordable: 4, ltifr: 1.8 },
  { time: "Dec", fatalities: 1, recordable: 6, ltifr: 2.5 },
];

const InjuryTrendsLineChart = () => {
  return (
    <div style={{ fontFamily: "Lato", margin: "25px" }}>
      <h3 style={{ fontSize: "18px" }}>Injury Trends Over Time</h3>
      <p>
        Tracking injury trends broken down by type: fatalities, recordable
        injuries, and LTIFR.
      </p>

      {/* Responsive Container for Recharts */}
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="time"
            label={{ value: "Time", position: "insideBottom", offset: -5 }}
          />
          <YAxis
            label={{
              value: "Number of Injuries",
              angle: -90,
              position: "insideLeft",
            }}
          />
          <Tooltip />
          <Legend />

          {/* Injury Types */}
          <Line
            type="monotone"
            dataKey="fatalities"
            stroke="#e74c3c"
            strokeWidth={2}
            name="Fatalities"
          />
          <Line
            type="monotone"
            dataKey="recordable"
            stroke="#f39c12"
            strokeWidth={2}
            name="Recordable Injuries"
          />
          <Line
            type="monotone"
            dataKey="ltifr"
            stroke="#16a085"
            strokeWidth={2}
            name="LTIFR"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default InjuryTrendsLineChart;
