import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const data = [
  { type: "2023", value: 30, color: "#3498db" }, // Blue for less severe
  { type: "2024", value: 20, color: "#2ecc71" }, // Green for minor
];

const InjuryLollipopChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    renderChart();
  }, []);

  const renderChart = () => {
    const width = 600;
    const height = 400;
    const margin = { top: 60, right: 30, bottom: 50, left: 50 };

    // Clear previous chart
    d3.select(chartRef.current).select("svg").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // X scale for years
    const x = d3
      .scaleBand()
      .domain(["2023", "2024"])
      .range([0, chartWidth])
      .padding(0.5);

    // Y scale
    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.value)])
      .nice()
      .range([chartHeight, 0]);

    // Draw X Axis
    g.append("g")
      .attr("transform", `translate(0, ${chartHeight})`)
      .call(d3.axisBottom(x))
      .selectAll("text")
      .style("text-anchor", "middle");

    // Draw Y Axis
    g.append("g").call(d3.axisLeft(y));

    // Draw lines
    g.selectAll(".line")
      .data(data)
      .enter()
      .append("line")
      .attr("x1", (d) => x(d.type) + x.bandwidth() / 2)
      .attr("x2", (d) => x(d.type) + x.bandwidth() / 2)
      .attr("y1", chartHeight)
      .attr("y2", (d) => y(d.value))
      .attr("stroke", "#ccc")
      .attr("stroke-width", 2);

    // Draw circles with larger size
    g.selectAll(".circle")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", (d) => x(d.type) + x.bandwidth() / 2)
      .attr("cy", (d) => y(d.value))
      .attr("r", 50) // Increased circle size
      .attr("fill", (d) => d.color);

    // X-axis label
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", height - 10)
      .attr("text-anchor", "middle")
      .style("font-size", "12px")
      .style("font-family", "Lato")
      .text("Year");

    // Y-axis label
    svg
      .append("text")
      .attr("x", -height / 2)
      .attr("y", 15)
      .attr("transform", "rotate(-90)")
      .attr("text-anchor", "middle")
      .style("font-size", "12px")
      .style("font-family", "Lato")
      .text("Severity or Frequency");
  };

  return (
    <>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Injury Severity and Frequency
      </h3>

      <div
        ref={chartRef}
        style={{ display: "flex", justifyContent: "center" }}
      />
    </>
  );
};

export default InjuryLollipopChart;
