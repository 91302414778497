import { DateTime } from "luxon";
import { getRPTextFormat } from "../components/BGHF/helper";


const getComparisionData = (dcfId,tier0_id,tier1_id,tier2_id,tier3_id,arr,submission_list,self,newObj2,efstdlist,subcat) =>{

    if (dcfId === 11 || dcfId === 257  ) {
        return getValueByMonthlyHC3Sub(dcfId,
            tier0_id,
            tier1_id,
            tier2_id,
            tier3_id,
            arr,
            dcfId === 11  ? ['DPA0131', 'DPA0132', 'DPA0336'] : ['DPAN096', 'DPAN099', 'DPAN098'],
            submission_list, efstdlist, subcat, newObj2.standard, '', newObj2.self, newObj2)
    }else if (dcfId === 10 || dcfId === 16 ) {
       return getValueByMonthlyHC1Sub(dcfId,
            tier0_id,
            tier1_id,
            tier2_id,
            tier3_id,
            arr,
            dcfId === 10 ? ['DPA0136', 'DPA0138'] : ['DPA0287', 'DPA0289'],
            submission_list.filter(i => i.dcfId === dcfId), efstdlist, subcat, newObj2.standard, dcfId === 10 ? 'kg' : 'USD', newObj2.self, newObj2)

    } else if (dcfId === 275 || dcfId === 263) {
        
        return getValueByMonthlyEmpCat275(dcfId,
            tier0_id,
            tier1_id,
            tier2_id,
            tier3_id,
            arr,
            submission_list.filter(i => i.dcfId === dcfId), 'nos', newObj2.self, newObj2)

    }else if (dcfId === 284) {
        
     return   getValueByMonthlyEmpNewTurn284(dcfId,
            tier0_id,
            tier1_id,
            tier2_id,
            tier3_id,
            arr,
            submission_list.filter(i => i.dcfId === dcfId), 'nos', newObj2.self, newObj2)

    }
    

} 
const getValueByMonthlyByDP = (
    dcfId,
    tier0,
    tier1,
    tier2,
    tier3,
    arr, qn_submit_list, unit, self, refobj
) => {



    let final_result = {};

    arr.forEach((obj) => {
        console.log(obj)
        let result = {
            [obj]: [refobj],
        };
        let result_arr = [];
        let index = qn_submit_list.findIndex((i) => {
            console.log(getRPTextFormat(i.reporting_period), obj);
            return (
                i.dcfId === dcfId &&

                i.tier0_id === tier0 &&
                i.tier1_id === tier1 &&
                i.tier2_id === tier2 &&
                i.tier3_id === tier3 &&
                getRPTextFormat(i.reporting_period) === obj
            );
        });
        console.log(index);
        if (index !== -1) {
            let index2 = qn_submit_list.findIndex((i) => {
                console.log(getPreviousPeriod(obj.includes("to") ? 2 : 1, obj), getRPTextFormat(
                    i.reporting_period[i.reporting_period.length - 1]
                ), obj)
                return (
                    i.dcfId === dcfId &&
                    (i.type == 0 ? i.reject === 1 : true) &&
                    i.tier0_id === tier0 &&
                    i.tier1_id === tier1 &&
                    i.tier2_id === tier2 &&
                    i.tier3_id === tier3 &&
                    getRPTextFormat(
                        [i.reporting_period[i.reporting_period.length - 1]]
                    ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                );
            });
            console.log(index2, obj)
            if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                const { type1, reject1 } = qn_submit_list[index2];
                const { type, reject } = qn_submit_list[index];
                let value = null,
                    value2 = null;
                result_arr = [];
                let list_arr = []
                let list_arr2 = []
                qn_submit_list[index2].response.forEach((i) => {
                    
                    let ind = list_arr2.findIndex((j) => j['DPS0184'] === i['DPS0184'] )

                    if (ind !== -1) {
                        list_arr2[ind]['DPS0187'] += i['DPS0187']
                    } else {
                        list_arr2.push(i)
                    }


                })
                qn_submit_list[index].response.forEach((i) => {

                    let ind = list_arr.findIndex((j) => j['DPS0184'] === i['DPS0184'] )
                    if (ind !== -1) {
                        list_arr[ind]['DPS0187'] += i['DPS0187']
                        list_arr[ind].current = list_arr[ind]['DPS0187']
                        let value = list_arr[ind]['DPS0187'], value2 = null
                        let oldIndex = list_arr2.findIndex((j) => j['DPS0184'] === i['DPS0184'] )
                        if (oldIndex !== -1) {
                            value2 = list_arr2[oldIndex]['DPS0187']
                            list_arr[ind].percentage = calculatePercentage(value, value2)
                            list_arr[ind].diff = value === value2 ? null : value > value2 ? false : true
                            list_arr[ind].category = 2
                        }

                    } else {
                        let value = i['DPS0187'], value2 = null
                        let oldIndex = list_arr2.findIndex((j) => j['DPS0184'] === i['DPS0184'] )
                        if (oldIndex !== -1) {
                            value2 = list_arr2[oldIndex]['DPS0187']
                            list_arr.push({
                                ...i, remarks: qn_submit_list[index].return_remarks,
                                col: getNumberOfColumn(obj),
                                id: qn_submit_list[index].id,
                                category: 2,
                                form_type: 2,
                                diff: value === value2 ? null : value > value2 ? false : true,
                                current: i['DPS0187'],
                                month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                percentage: calculatePercentage(value, value2),
                                type, self,
                                text:
                                    type === 0 && (reject === 0 || !reject) ? "Drafted" :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? "Unlocked for Resubmission"
                                            : type === 1 && reject === 2
                                                ? "Unlocked for Review Again"
                                                : type === 1
                                                    ? "Pending Review"
                                                    : type === 2
                                                        ? "Pending Approval"
                                                        : type === 3
                                                            ? "Approved & Locked"
                                                            : "Pending Submission",
                                status:
                                    type === 0 && (reject === 0 || !reject) ? 0 :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? 1
                                            : type === 1 && reject === 2
                                                ? 2
                                                : type === 1
                                                    ? 3
                                                    : type === 2
                                                        ? 4
                                                        : type === 3
                                                            ? 5
                                                            : null
                            })
                        } else {
                            list_arr.push({
                                ...i, remarks: qn_submit_list[index].return_remarks,
                                col: getNumberOfColumn(obj),
                                id: qn_submit_list[index].id,
                                category: 1,
                                form_type: 2,
                                diff: false,
                                current: i['DPS0187'],
                                month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                percentage: '-',
                                type, self,
                                text:
                                    type === 0 && (reject === 0 || !reject) ? "Drafted" :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? "Unlocked for Resubmission"
                                            : type === 1 && reject === 2
                                                ? "Unlocked for Review Again"
                                                : type === 1
                                                    ? "Pending Review"
                                                    : type === 2
                                                        ? "Pending Approval"
                                                        : type === 3
                                                            ? "Approved & Locked"
                                                            : "Pending Submission",
                                status:
                                    type === 0 && (reject === 0 || !reject) ? 0 :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? 1
                                            : type === 1 && reject === 2
                                                ? 2
                                                : type === 1
                                                    ? 3
                                                    : type === 2
                                                        ? 4
                                                        : type === 3
                                                            ? 5
                                                            : null
                            })
                        }

                    }


                })
                console.log(qn_submit_list[index], qn_submit_list[index2])
                result = { [obj]: attachObj(list_arr, refobj) };
            } else {
                const { type, reject } = qn_submit_list[index];
                let value = null;
                result_arr = [];
                let list_arr = []
                if (qn_submit_list[index].response.length) {
                    qn_submit_list[index].response.forEach((i) => {

                        let ind = list_arr.findIndex((j) => j['DPS0184'] === i['DPS0184'] )
                        if (ind !== -1) {
                            list_arr[ind]['DPS0187'] += i['DPS0187']
                            list_arr[ind].current = list_arr[ind]['DPAN1119']
                        } else {
                            list_arr.push({
                                ...i, remarks: qn_submit_list[index].return_remarks,
                                col: getNumberOfColumn(obj),
                                id: qn_submit_list[index].id,
                                form_type: 2,
                                category: 1,
                                diff: false,
                                current: i['DPS0187'],
                                month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                percentage: "-",
                                type, self,
                                text:
                                    type === 0 && (reject === 0 || !reject) ? "Drafted" :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? "Unlocked for Resubmission"
                                            : type === 1 && reject === 2
                                                ? "Unlocked for Review Again"
                                                : type === 1
                                                    ? "Pending Review"
                                                    : type === 2
                                                        ? "Pending Approval"
                                                        : type === 3
                                                            ? "Approved & Locked"
                                                            : "Pending Submission",
                                status:
                                    type === 0 && (reject === 0 || !reject) ? 0 :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? 1
                                            : type === 1 && reject === 2
                                                ? 2
                                                : type === 1
                                                    ? 3
                                                    : type === 2
                                                        ? 4
                                                        : type === 3
                                                            ? 5
                                                            : null
                            })
                        }


                    })
                }
                else {
                    list_arr.push({
                        edit: 1,
                        remarks: qn_submit_list[index].return_remarks,
                        col: getNumberOfColumn(obj),
                        id: qn_submit_list[index].id,
                        form_type: 2,
                        category: 1,
                        diff: false,
                        current: '-',
                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                        percentage: "-",
                        type, self,
                        text:
                            type === 0 && (reject === 0 || !reject) ? "Drafted" :
                                (type === 0 && (reject === 1 || reject === 2))
                                    ? "Unlocked for Resubmission"
                                    : type === 1 && reject === 2
                                        ? "Unlocked for Review Again"
                                        : type === 1
                                            ? "Pending Review"
                                            : type === 2
                                                ? "Pending Approval"
                                                : type === 3
                                                    ? "Approved & Locked"
                                                    : "Pending Submission",
                        status:
                            type === 0 && (reject === 0 || !reject) ? 0 :
                                (type === 0 && (reject === 1 || reject === 2))
                                    ? 1
                                    : type === 1 && reject === 2
                                        ? 2
                                        : type === 1
                                            ? 3
                                            : type === 2
                                                ? 4
                                                : type === 3
                                                    ? 5
                                                    : null
                    })
                }



                result = { [obj]: attachObj(list_arr, refobj) };
            }
        }

        final_result = { ...final_result, ...result };
    });

    Object.values(final_result).forEach((j) => {
        console.log(j)
        let find = Object.values(j).filter(i => (i['DPS0184'] !== undefined));

        Object.values(j).forEach((k) => {
            if (k.category !== null && find.length) {

                let name1 = k['DPS0184']
                

                k.dp = ((!name1 ? 'NA' : name1) + ' ^ '+unit)



            } else {
                k.dp = null
            }
        })
    })
    console.log(final_result)
    return final_result;
};
const getValueByMonthlyHC1Sub = (
    dcfId,
    tier0,
    tier1,
    tier2,
    tier3,
    arr,
    dpnames,
    qn_submit_list, efstdlist, subcat, standardId, unit, self, refobj
) => {
    // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]

    let stdindx = efstdlist.findIndex(i => i.id === standardId)

    const [a, x] = dpnames

    let final_result = {};
    if (stdindx !== -1) {
        arr.forEach((obj) => {
            console.log(obj)
            let result = {
                [obj]: [refobj],
            };
            let result_arr = [];
            let index = qn_submit_list.findIndex((i) => {
                console.log(getRPTextFormat(i.reporting_period), obj);
                return (
                    i.dcfId === dcfId && i.standard === standardId &&

                    i.tier0_id === tier0 &&
                    i.tier1_id === tier1 &&
                    i.tier2_id === tier2 &&
                    i.tier3_id === tier3 &&
                    getRPTextFormat(i.reporting_period) === obj
                );
            });
            console.log(index);
            if (index !== -1) {
                let index2 = qn_submit_list.findIndex((i) => {
                    console.log(getPreviousPeriod(obj.includes("to") ? 2 : 1, obj), getRPTextFormat(
                        i.reporting_period[i.reporting_period.length - 1]
                    ), obj)
                    return (
                        i.dcfId === dcfId && i.standard === standardId &&
                        (i.type == 0 ? ((i.reject === 1 || i.reject === 2) ? true : false) : true) &&
                        i.tier0_id === tier0 &&
                        i.tier1_id === tier1 &&
                        i.tier2_id === tier2 &&
                        i.tier3_id === tier3 &&
                        getRPTextFormat(
                            [i.reporting_period[i.reporting_period.length - 1]]
                        ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                    );
                });
                console.log(index2, obj)
                if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                    const { type1, reject1 } = qn_submit_list[index2];
                    const { type, reject } = qn_submit_list[index];
                    let value = null,
                        value2 = null;
                    result_arr = [];
                    let list_arr = []
                    let list_arr2 = []
                    qn_submit_list[index2].response.forEach((i) => {
                        let ind = list_arr2.findIndex((j) => j[a] === i[a])
                        if (ind !== -1) {
                            list_arr2[ind][x] += i[x]
                        } else {
                            list_arr2.push(i)
                        }
                    })
                    qn_submit_list[index].response.forEach((i) => {
                        let ind = list_arr.findIndex((j) => j[a] === i[a])
                        if (ind !== -1) {
                            list_arr[ind][x] += i[x]
                            list_arr[ind].current = list_arr[ind][x]
                            let value = list_arr[ind][x], value2 = null
                            let oldIndex = list_arr2.findIndex((j) => j[a] === i[a])
                            if (oldIndex !== -1) {
                                value2 = list_arr2[oldIndex][x]
                                list_arr[ind].percentage = calculatePercentage(value, value2)
                                list_arr[ind].diff = value === value2 ? null : value > value2 ? false : true
                                list_arr[ind].category = 2
                            }

                        } else {
                            let value = i[x], value2 = null
                            let oldIndex = list_arr2.findIndex((j) => j[a] === i[a])
                            if (oldIndex !== -1) {
                                value2 = list_arr2[oldIndex][x]
                                list_arr.push({
                                    ...i, remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 2,
                                    form_type: 2,
                                    diff: value === value2 ? null : value > value2 ? false : true,
                                    current: i[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: calculatePercentage(value, value2),
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            } else {
                                list_arr.push({
                                    ...i, remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 1,
                                    form_type: 2,
                                    diff: false, self,
                                    current: i[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: '-',
                                    type,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            }

                        }
                    })
                    console.log(qn_submit_list[index], qn_submit_list[index2])
                    result = { [obj]: attachObj(list_arr, refobj) };
                } else {
                    const { type, reject } = qn_submit_list[index];
                    let value = null;
                    result_arr = [];
                    let list_arr = []
                    if (qn_submit_list[index].response.length) {
                        qn_submit_list[index].response.forEach((i) => {
                            let ind = list_arr.findIndex((j) => j[a] === i[a])
                            if (ind !== -1) {
                                list_arr[ind][x] += i[x]
                                list_arr[ind].current = list_arr[ind][x]
                            } else {
                                list_arr.push({
                                    ...i, remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    form_type: 2,
                                    category: 1,
                                    diff: false,
                                    current: i[x], self,
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: "-",
                                    type,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            }
                        })
                    }
                    else {
                        list_arr.push({
                            edit: 1,
                            remarks: qn_submit_list[index].return_remarks,
                            col: getNumberOfColumn(obj),
                            id: qn_submit_list[index].id,
                            form_type: 2,
                            category: 1,
                            diff: false, self,
                            current: '-',
                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                            percentage: "-",
                            type,
                            text:
                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? "Unlocked for Resubmission"
                                        : type === 1 && reject === 2
                                            ? "Unlocked for Review Again"
                                            : type === 1
                                                ? "Pending Review"
                                                : type === 2
                                                    ? "Pending Approval"
                                                    : type === 3
                                                        ? "Approved & Locked"
                                                        : "Pending Submission",
                            status:
                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? 1
                                        : type === 1 && reject === 2
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null
                        })
                    }



                    result = { [obj]: attachObj(list_arr, refobj) };
                }
            }

            final_result = { ...final_result, ...result };
        });
    } else {
        arr.forEach((obj) => {
            let result = {
                [obj]: [],
            };
            final_result = { ...final_result, ...result };
        })

    }
    Object.values(final_result).forEach((j) => {
        let find = Object.values(j).filter(i => i[a] !== undefined)
        Object.values(j).forEach((k) => {
            if (k.category !== null && find.length) {
                let name1 = subcat['cat1'].find(i => i.id === k[a])

                k.dp = (name1 === undefined ? 'NA' : name1.title + '^' + unit)
            } else {
                k.dp = null
            }
        })
    })
    return final_result;
};
const getValueByMonthlyEmpCat275 = (
    dcfId,
    tier0,
    tier1,
    tier2,
    tier3,
    arr, qn_submit_list, unit, self, refobj
) => {
    // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]
    let land_veh_list = ['Standard Taxi / Sedan', 'Hybrid Vehicle', 'Executive Taxi', 'Dual Purpose 4 x 4 (SUV)', 'Electric Vehicle (EV)', 'Van/ Coach']
    const value_arr = ['a1', 'b1', 'c1', 'd1', 'e1', 'f1']
    let final_result = {};

    console.log(tier0, tier1, tier2, tier3)
    arr.forEach((obj) => {
        console.log(obj)
        let result = {
            [obj]: [refobj],
        };
        let result_arr = [];
        let index = qn_submit_list.findIndex((i) => {
            console.log(getRPTextFormat(i.reporting_period) === obj, dcfId === i.dcfId, i.tier0_id === tier0,
                i.tier1_id === tier1,
                i.tier2_id === tier2,
                i.tier3_id === tier3, i.id, i.tier3_id, tier3);
            return (
                i.dcfId === dcfId &&
                i.tier0_id === tier0 &&
                i.tier1_id === tier1 &&
                i.tier2_id === tier2 &&
                i.tier3_id === tier3 &&
                getRPTextFormat(i.reporting_period) === obj
            );
        });
        console.log(index, obj);
        if (index !== -1) {
            let index2 = qn_submit_list.findIndex((i) => {
                console.log(getPreviousPeriod(obj.includes("to") ? 2 : 1, obj), getRPTextFormat(
                    i.reporting_period[i.reporting_period.length - 1]
                ), obj)
                return (
                    i.dcfId === dcfId &&
                    (i.type == 0 ? ((i.reject === 1 || i.reject === 2) ? true : false) : true) &&
                    i.tier0_id === tier0 &&
                    i.tier1_id === tier1 &&
                    i.tier2_id === tier2 &&
                    i.tier3_id === tier3 &&
                    getRPTextFormat(
                        [i.reporting_period[i.reporting_period.length - 1]]
                    ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                );
            });
            console.log(index2, obj)
            if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                const { type1, reject1 } = qn_submit_list[index2];
                const { type, reject } = qn_submit_list[index];
                let value = null,
                    value2 = null;
                result_arr = [];
                let list_arr = []
                let list_arr2 = []
                qn_submit_list[index2].response.forEach((i) => {
                    let abc = {}

                    abc['a1'] = i['DPAN613'];
                    abc['b1'] = i['DPAN614'];
                    abc['c1'] = i['DPAN615'];
                    abc['d1'] = i['DPAN616'];
                    abc['e1'] = i['DPAN617'];
                    abc['f1'] = i['DPAN618'];
                    value_arr.forEach((x) => {
                        list_arr2.push({
                            ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                            col: getNumberOfColumn(obj),
                            id: qn_submit_list[index].id,
                            category: 1,
                            form_type: 2,
                            diff: false,
                            current: abc[x],
                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                            percentage: '-',
                            type, self,
                            text:
                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? "Unlocked for Resubmission"
                                        : type === 1 && reject === 2
                                            ? "Unlocked for Review Again"
                                            : type === 1
                                                ? "Pending Review"
                                                : type === 2
                                                    ? "Pending Approval"
                                                    : type === 3
                                                        ? "Approved & Locked"
                                                        : "Pending Submission",
                            status:
                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? 1
                                        : type === 1 && reject === 2
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null
                        })
                    })


                })
                qn_submit_list[index].response.forEach((i) => {
                    let abc = {}
                    abc['a1'] = i['DPAN613'];
                    abc['b1'] = i['DPAN614'];
                    abc['c1'] = i['DPAN615'];
                    abc['d1'] = i['DPAN616'];
                    abc['e1'] = i['DPAN617'];
                    abc['f1'] = i['DPAN618'];

                    value_arr.forEach((x) => {
                        console.log(list_arr2)
                        let oldIndex = list_arr2.findIndex((j) => j[x] !== undefined)
                        if (oldIndex !== -1) {
                            value2 = list_arr2[oldIndex][x]

                            list_arr.push({
                                ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                col: getNumberOfColumn(obj),
                                id: qn_submit_list[index].id,
                                category: 2,
                                form_type: 2,
                                diff: abc[x] === value2 ? null : abc[x] > value2 ? false : true,
                                current: abc[x],
                                month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                percentage: calculatePercentage(abc[x], value2),
                                type, self,
                                text:
                                    type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? "Unlocked for Resubmission"
                                            : type === 1 && reject === 2
                                                ? "Unlocked for Review Again"
                                                : type === 1
                                                    ? "Pending Review"
                                                    : type === 2
                                                        ? "Pending Approval"
                                                        : type === 3
                                                            ? "Approved & Locked"
                                                            : "Pending Submission",
                                status:
                                    type === 0 && (reject === 0 || reject === null) ? 0 :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? 1
                                            : type === 1 && reject === 2
                                                ? 2
                                                : type === 1
                                                    ? 3
                                                    : type === 2
                                                        ? 4
                                                        : type === 3
                                                            ? 5
                                                            : null
                            })

                        } else {

                            list_arr.push({
                                ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                col: getNumberOfColumn(obj),
                                id: qn_submit_list[index].id,
                                category: 2,
                                form_type: 2,
                                diff: false,
                                current: abc[x],
                                month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                percentage: '100%',
                                type, self,
                                text:
                                    type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? "Unlocked for Resubmission"
                                            : type === 1 && reject === 2
                                                ? "Unlocked for Review Again"
                                                : type === 1
                                                    ? "Pending Review"
                                                    : type === 2
                                                        ? "Pending Approval"
                                                        : type === 3
                                                            ? "Approved & Locked"
                                                            : "Pending Submission",
                                status:
                                    type === 0 && (reject === 0 || reject === null) ? 0 :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? 1
                                            : type === 1 && reject === 2
                                                ? 2
                                                : type === 1
                                                    ? 3
                                                    : type === 2
                                                        ? 4
                                                        : type === 3
                                                            ? 5
                                                            : null
                            })
                        }
                    })





                })


                result = { [obj]: attachObj(list_arr, refobj) };
            } else {

                const { type, reject } = qn_submit_list[index];
                let value = null;
                result_arr = [];
                let list_arr = []
                console.log(qn_submit_list[index])
                if (qn_submit_list[index].response.length) {

                    qn_submit_list[index].response.forEach((i) => {
                        let abc = {}

                        abc['a1'] = i['DPAN613'];
                        abc['b1'] = i['DPAN614'];
                        abc['c1'] = i['DPAN615'];
                        abc['d1'] = i['DPAN616'];
                        abc['e1'] = i['DPAN617'];
                        abc['f1'] = i['DPAN618'];
                        value_arr.forEach((x) => {
                            list_arr.push({
                                ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                col: getNumberOfColumn(obj),
                                id: qn_submit_list[index].id,
                                form_type: 2,
                                category: 1,
                                diff: false,
                                current: abc[x],
                                month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                percentage: "-",
                                type, self,
                                text:
                                    type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? "Unlocked for Resubmission"
                                            : type === 1 && reject === 2
                                                ? "Unlocked for Review Again"
                                                : type === 1
                                                    ? "Pending Review"
                                                    : type === 2
                                                        ? "Pending Approval"
                                                        : type === 3
                                                            ? "Approved & Locked"
                                                            : "Pending Submission",
                                status:
                                    type === 0 && (reject === 0 || reject === null) ? 0 :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? 1
                                            : type === 1 && reject === 2
                                                ? 2
                                                : type === 1
                                                    ? 3
                                                    : type === 2
                                                        ? 4
                                                        : type === 3
                                                            ? 5
                                                            : null
                            })
                        })




                    })
                }
                else {
                    list_arr.push({
                        edit: 1,
                        remarks: qn_submit_list[index].return_remarks,
                        col: getNumberOfColumn(obj),
                        id: qn_submit_list[index].id,
                        form_type: 2,
                        category: 1,
                        diff: false,
                        current: '-',
                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                        percentage: "-",
                        type, self,
                        text:
                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                (type === 0 && (reject === 1 || reject === 2))
                                    ? "Unlocked for Resubmission"
                                    : type === 1 && reject === 2
                                        ? "Unlocked for Review Again"
                                        : type === 1
                                            ? "Pending Review"
                                            : type === 2
                                                ? "Pending Approval"
                                                : type === 3
                                                    ? "Approved & Locked"
                                                    : "Pending Submission",
                        status:
                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                (type === 0 && (reject === 1 || reject === 2))
                                    ? 1
                                    : type === 1 && reject === 2
                                        ? 2
                                        : type === 1
                                            ? 3
                                            : type === 2
                                                ? 4
                                                : type === 3
                                                    ? 5
                                                    : null
                    })
                }



                result = { [obj]: attachObj(list_arr, refobj) };
            }
        }

        final_result = { ...final_result, ...result };
    });

    Object.values(final_result).forEach((j) => {
        console.log(j, arr)
        let find = Object.values(j).filter(i => (i['a1'] !== undefined));

        Object.values(j).forEach((k) => {
            console.log(k)
            if (k.category !== null && find.length) {
                let name1 = 'NA'
                if (k['a1'] || k['a1'] === 0) {
                    name1 = 'Senior Management (Male)'
                } else if (k['b1'] || k['b1'] === 0) {
                    console.log('xyz')
                    name1 = 'Senior Management (Female)'
                } else if (k['c1'] || k['c1'] === 0) {
                    name1 = 'Middle Management (Male)'
                } else if (k['d1'] || k['d1'] === 0) {
                    name1 = 'Middle Management (Female)'
                }
                else if (k['e1'] || k['e1'] === 0) {
                    name1 = 'Non-Management (Male)'
                }
                else if (k['f1'] || k['f1'] === 0) {
                    name1 = 'Non-Management (Female)'
                }


                k.dp = (name1 === undefined ? 'NA' : name1 + ' ^ ' + unit)



            } else {
                k.dp = null
            }
        })
    })
    console.log(final_result)
    return final_result;
};
 const getValueByMonthlyEmpNewTurn284 = (
    dcfId,
    tier0,
    tier1,
    tier2,
    tier3,
    arr, qn_submit_list, unit, self, refobj
) => {
    // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]
    let land_veh_list = ['Standard Taxi / Sedan', 'Hybrid Vehicle', 'Executive Taxi', 'Dual Purpose 4 x 4 (SUV)', 'Electric Vehicle (EV)', 'Van/ Coach']
    const value_arr = ['a1', 'b1', 'c1', 'd1']
    let final_result = {};

    console.log(tier0, tier1, tier2, tier3)
    arr.forEach((obj) => {
        console.log(obj)
        let result = {
            [obj]: [refobj],
        };
        let result_arr = [];
        let index = qn_submit_list.findIndex((i) => {
            console.log(getRPTextFormat(i.reporting_period) === obj, dcfId === i.dcfId, i.tier0_id === tier0,
                i.tier1_id === tier1,
                i.tier2_id === tier2,
                i.tier3_id === tier3, i.id, i.tier3_id, tier3);
            return (
                i.dcfId === dcfId &&
                i.tier0_id === tier0 &&
                i.tier1_id === tier1 &&
                i.tier2_id === tier2 &&
                i.tier3_id === tier3 &&
                getRPTextFormat(i.reporting_period) === obj
            );
        });
        console.log(index, obj);
        if (index !== -1) {
            let index2 = qn_submit_list.findIndex((i) => {
                console.log(getPreviousPeriod(obj.includes("to") ? 2 : 1, obj), getRPTextFormat(
                    i.reporting_period[i.reporting_period.length - 1]
                ), obj)
                return (
                    i.dcfId === dcfId &&
                    (i.type == 0 ? i.reject === 1 : true) &&
                    i.tier0_id === tier0 &&
                    i.tier1_id === tier1 &&
                    i.tier2_id === tier2 &&
                    i.tier3_id === tier3 &&
                    getRPTextFormat(
                        [i.reporting_period[i.reporting_period.length - 1]]
                    ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                );
            });
            console.log(index2, obj)
            if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                const { type1, reject1 } = qn_submit_list[index2];
                const { type, reject } = qn_submit_list[index];
                let value = null,
                    value2 = null;
                result_arr = [];
                let list_arr = []
                let list_arr2 = []
                qn_submit_list[index2].response.forEach((i) => {
                    let abc = {}

                    abc['a1'] = i['DPAN641'] + i['DPAN642'] + i['DPAN643'];
                    abc['b1'] = i['DPAN644'] + i['DPAN645'] + i['DPAN646'];
                    abc['c1'] = i['DPAN672'] + i['DPAN673'] + i['DPAN674']
                    abc['d1'] = i['DPAN675'] + i['DPAN676'] + i['DPAN677'];

                    value_arr.forEach((x) => {
                        list_arr2.push({
                            ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                            col: getNumberOfColumn(obj),
                            id: qn_submit_list[index].id,
                            category: 1,
                            form_type: 2,
                            diff: false,
                            current: abc[x],
                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                            percentage: '-',
                            type, self,
                            text:
                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? "Unlocked for Resubmission"
                                        : type === 1 && reject === 2
                                            ? "Unlocked for Review Again"
                                            : type === 1
                                                ? "Pending Review"
                                                : type === 2
                                                    ? "Pending Approval"
                                                    : type === 3
                                                        ? "Approved & Locked"
                                                        : "Pending Submission",
                            status:
                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? 1
                                        : type === 1 && reject === 2
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null
                        })
                    })


                })
                qn_submit_list[index].response.forEach((i) => {
                    let abc = {}
                    abc['a1'] = i['DPAN641'] + i['DPAN642'] + i['DPAN643'];
                    abc['b1'] = i['DPAN644'] + i['DPAN645'] + i['DPAN646'];
                    abc['c1'] = i['DPAN672'] + i['DPAN673'] + i['DPAN674']
                    abc['d1'] = i['DPAN675'] + i['DPAN676'] + i['DPAN677'];

                    value_arr.forEach((x) => {
                        console.log(list_arr2)
                        let oldIndex = list_arr2.findIndex((j) => j[x] !== undefined)
                        if (oldIndex !== -1) {
                            value2 = list_arr2[oldIndex][x]

                            list_arr.push({
                                ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                col: getNumberOfColumn(obj),
                                id: qn_submit_list[index].id,
                                category: 2,
                                form_type: 2,
                                diff: abc[x] === value2 ? null : abc[x] > value2 ? false : true,
                                current: abc[x],
                                month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                percentage: calculatePercentage(abc[x], value2),
                                type, self,
                                text:
                                    type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? "Unlocked for Resubmission"
                                            : type === 1 && reject === 2
                                                ? "Unlocked for Review Again"
                                                : type === 1
                                                    ? "Pending Review"
                                                    : type === 2
                                                        ? "Pending Approval"
                                                        : type === 3
                                                            ? "Approved & Locked"
                                                            : "Pending Submission",
                                status:
                                    type === 0 && (reject === 0 || reject === null) ? 0 :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? 1
                                            : type === 1 && reject === 2
                                                ? 2
                                                : type === 1
                                                    ? 3
                                                    : type === 2
                                                        ? 4
                                                        : type === 3
                                                            ? 5
                                                            : null
                            })

                        } else {

                            list_arr.push({
                                ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                col: getNumberOfColumn(obj),
                                id: qn_submit_list[index].id,
                                category: 2,
                                form_type: 2,
                                diff: false,
                                current: abc[x],
                                month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                percentage: '100%',
                                type, self,
                                text:
                                    type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? "Unlocked for Resubmission"
                                            : type === 1 && reject === 2
                                                ? "Unlocked for Review Again"
                                                : type === 1
                                                    ? "Pending Review"
                                                    : type === 2
                                                        ? "Pending Approval"
                                                        : type === 3
                                                            ? "Approved & Locked"
                                                            : "Pending Submission",
                                status:
                                    type === 0 && (reject === 0 || reject === null) ? 0 :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? 1
                                            : type === 1 && reject === 2
                                                ? 2
                                                : type === 1
                                                    ? 3
                                                    : type === 2
                                                        ? 4
                                                        : type === 3
                                                            ? 5
                                                            : null
                            })
                        }
                    })





                })


                result = { [obj]: attachObj(list_arr, refobj) };
            } else {

                const { type, reject } = qn_submit_list[index];
                let value = null;
                result_arr = [];
                let list_arr = []
                console.log(qn_submit_list[index])
                if (qn_submit_list[index].response.length) {

                    qn_submit_list[index].response.forEach((i) => {
                        let abc = {}

                        abc['a1'] = i['DPAN641'] + i['DPAN642'] + i['DPAN643'];
                        abc['b1'] = i['DPAN644'] + i['DPAN645'] + i['DPAN646'];
                        abc['c1'] = i['DPAN672'] + i['DPAN673'] + i['DPAN674']
                        abc['d1'] = i['DPAN675'] + i['DPAN676'] + i['DPAN677'];
                        value_arr.forEach((x) => {
                            list_arr.push({
                                ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                col: getNumberOfColumn(obj),
                                id: qn_submit_list[index].id,
                                form_type: 2,
                                category: 1,
                                diff: false,
                                current: abc[x],
                                month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                percentage: "-",
                                type, self,
                                text:
                                    type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? "Unlocked for Resubmission"
                                            : type === 1 && reject === 2
                                                ? "Unlocked for Review Again"
                                                : type === 1
                                                    ? "Pending Review"
                                                    : type === 2
                                                        ? "Pending Approval"
                                                        : type === 3
                                                            ? "Approved & Locked"
                                                            : "Pending Submission",
                                status:
                                    type === 0 && (reject === 0 || reject === null) ? 0 :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? 1
                                            : type === 1 && reject === 2
                                                ? 2
                                                : type === 1
                                                    ? 3
                                                    : type === 2
                                                        ? 4
                                                        : type === 3
                                                            ? 5
                                                            : null
                            })
                        })




                    })
                }
                else {
                    list_arr.push({
                        edit: 1,
                        remarks: qn_submit_list[index].return_remarks,
                        col: getNumberOfColumn(obj),
                        id: qn_submit_list[index].id,
                        form_type: 2,
                        category: 1,
                        diff: false,
                        current: '-',
                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                        percentage: "-",
                        type, self,
                        text:
                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                (type === 0 && (reject === 1 || reject === 2))
                                    ? "Unlocked for Resubmission"
                                    : type === 1 && reject === 2
                                        ? "Unlocked for Review Again"
                                        : type === 1
                                            ? "Pending Review"
                                            : type === 2
                                                ? "Pending Approval"
                                                : type === 3
                                                    ? "Approved & Locked"
                                                    : "Pending Submission",
                        status:
                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                (type === 0 && (reject === 1 || reject === 2))
                                    ? 1
                                    : type === 1 && reject === 2
                                        ? 2
                                        : type === 1
                                            ? 3
                                            : type === 2
                                                ? 4
                                                : type === 3
                                                    ? 5
                                                    : null
                    })
                }



                result = { [obj]: attachObj(list_arr, refobj) };
            }
        }

        final_result = { ...final_result, ...result };
    });

    Object.values(final_result).forEach((j) => {
        console.log(j, arr)
        let find = Object.values(j).filter(i => (i['a1'] !== undefined));

        Object.values(j).forEach((k) => {
            console.log(k)
            if (k.category !== null && find.length) {
                let name1 = 'NA'
                if (k['a1'] || k['a1'] === 0) {
                    name1 = 'Total Number of Hires (Male)'
                } else if (k['b1'] || k['b1'] === 0) {
                    console.log('xyz')
                    name1 = 'Total Number of Hires (Female)'
                } else if (k['c1'] || k['c1'] === 0) {
                    name1 = 'Total Employee Turnover (Male)'
                } else if (k['d1'] || k['d1'] === 0) {
                    name1 = 'Total Employee Turnover (Female)'
                }


                k.dp = (name1 === undefined ? 'NA' : name1 + ' ^ ' + unit)



            } else {
                k.dp = null
            }
        })
    })
    // Object.values(final_result).forEach((y)=>{
    //     y.splice(1, 0, {dp:'Male'})
    //     y.splice(5, 0, {dp:'Female'})
    // })
    console.log(final_result)
    return final_result;
};
const getValueByMonthlyHC3Sub = (
    dcfId,
    tier0,
    tier1,
    tier2,
    tier3,
    arr,
    dpnames,
    qn_submit_list, efstdlist, subcat, standardId, categoryId, self, refobj
) => {
    // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]

    let stdindx = efstdlist.findIndex(i => i.id === standardId)

    const [a, b, x] = dpnames

    let final_result = {};
    if (stdindx !== -1) {
        arr.forEach((obj) => {
            console.log(obj)
            let result = {
                [obj]: [refobj],
            };
            let result_arr = [];
            let index = qn_submit_list.findIndex((i) => {
                console.log(getRPTextFormat(i.reporting_period), obj);
                return (
                    i.dcfId === dcfId && i.standard === standardId &&

                    i.tier0_id === tier0 &&
                    i.tier1_id === tier1 &&
                    i.tier2_id === tier2 &&
                    i.tier3_id === tier3 &&
                    getRPTextFormat(i.reporting_period) === obj
                );
            });
            console.log(index);
            if (index !== -1) {
                let index2 = qn_submit_list.findIndex((i) => {
                    console.log(getPreviousPeriod(obj.includes("to") ? 2 : 1, obj), getRPTextFormat(
                        i.reporting_period[i.reporting_period.length - 1]
                    ), obj)
                    return (
                        i.dcfId === dcfId && i.standard === standardId &&
                        (i.type == 0 ? ((i.reject === 1 || i.reject === 2) ? true : false) : true) &&
                        i.tier0_id === tier0 &&
                        i.tier1_id === tier1 &&
                        i.tier2_id === tier2 &&
                        i.tier3_id === tier3 &&
                        getRPTextFormat(
                            [i.reporting_period[i.reporting_period.length - 1]]
                        ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                    );
                });
                console.log(index2, obj)
                if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                    const { type1, reject1 } = qn_submit_list[index2];
                    const { type, reject } = qn_submit_list[index];
                    let value = null,
                        value2 = null;
                    result_arr = [];
                    let list_arr = []
                    let list_arr2 = []
                    qn_submit_list[index2].response.forEach((i) => {
                        let ind = list_arr2.findIndex((j) => j[a] === i[a] && j[b] === i[b])
                        if (ind !== -1) {
                            list_arr2[ind][x] += i[x]
                        } else {
                            list_arr2.push(i)
                        }
                    })
                    qn_submit_list[index].response.forEach((i) => {
                        let ind = list_arr.findIndex((j) => j[a] === i[a] && j[b] === i[b])
                        if (ind !== -1) {
                            list_arr[ind][x] += i[x]
                            list_arr[ind].current = list_arr[ind][x]
                            let value = list_arr[ind][x], value2 = null
                            let oldIndex = list_arr2.findIndex((j) => j[a] === i[a] && j[b] === i[b])
                            if (oldIndex !== -1) {
                                value2 = list_arr2[oldIndex][x]
                                list_arr[ind].percentage = calculatePercentage(value, value2)
                                list_arr[ind].diff = value === value2 ? null : value > value2 ? false : true
                                list_arr[ind].category = 2
                            }

                        } else {
                            let value = i[x], value2 = null
                            let oldIndex = list_arr2.findIndex((j) => j[a] === i[a] && j[b] === i[b])
                            if (oldIndex !== -1) {
                                value2 = list_arr2[oldIndex][x]
                                list_arr.push({
                                    ...i, remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 2,
                                    form_type: 2,
                                    diff: value === value2 ? null : value > value2 ? false : true,
                                    current: i[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: calculatePercentage(value, value2),
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            } else {
                                list_arr.push({
                                    ...i, remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 1,
                                    form_type: 2,
                                    diff: false,
                                    current: i[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: '-',
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            }

                        }
                    })
                    console.log(qn_submit_list[index], qn_submit_list[index2])
                    result = { [obj]: attachObj(list_arr, refobj) };
                } else {
                    const { type, reject } = qn_submit_list[index];
                    let value = null;
                    result_arr = [];
                    let list_arr = []
                    if (qn_submit_list[index].response.length) {
                        qn_submit_list[index].response.forEach((i) => {
                            let ind = list_arr.findIndex((j) => j[a] === i[a] && j[b] === i[b])
                            if (ind !== -1) {
                                list_arr[ind][x] += i[x]
                                list_arr[ind].current = list_arr[ind][x]
                            } else {
                                list_arr.push(
                                    {
                                        ...i, remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        form_type: 2,
                                        category: 1,
                                        diff: false,
                                        current: i[x],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: "-",
                                        type, self,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                            }
                        })
                    } else {
                        list_arr.push({
                            edit: 1,
                            remarks: qn_submit_list[index].return_remarks,
                            col: getNumberOfColumn(obj),
                            id: qn_submit_list[index].id,
                            form_type: 2,
                            category: 1,
                            diff: false,
                            current: '-',
                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                            percentage: "-",
                            type, self,
                            text:
                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? "Unlocked for Resubmission"
                                        : type === 1 && reject === 2
                                            ? "Unlocked for Review Again"
                                            : type === 1
                                                ? "Pending Review"
                                                : type === 2
                                                    ? "Pending Approval"
                                                    : type === 3
                                                        ? "Approved & Locked"
                                                        : "Pending Submission",
                            status:
                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? 1
                                        : type === 1 && reject === 2
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null
                        })
                    }

                    result = { [obj]: attachObj(list_arr, refobj) };
                }
            }

            final_result = { ...final_result, ...result };
        });
    } else {
        arr.forEach((obj) => {
            let result = {
                [obj]: [],
            };
            final_result = { ...final_result, ...result };
        })

    }
    Object.values(final_result).forEach((j) => {
        let find = Object.values(j).filter(i => i[a] !== undefined && i[b] !== undefined)
        console.log(find, j)
        Object.values(j).forEach((k) => {
            if (k.category !== null && find.length) {
                let name1 = subcat['cat2'].find(i => i.id === k[a])
                let name2 = subcat['cat3'].find(i => i.id === k[b])
                k.dp = (name1 === undefined ? 'NA' : name1.title) + '^' + (name2 === undefined ? 'NA' : name2.title)
            } else {
                k.dp = null
            }
        })
    })
    return final_result;
};
function getPreviousPeriod(frequency, key) {
    if (frequency === 1) {
        const [month, year] = key.split("-");
        const previousDate = DateTime.fromFormat(
            `${month}-1-${year}`,
            "LLL-d-yyyy"
        ).minus({ months: 1 });
        const previousMonth = previousDate.toFormat("LLL");
        const previousYear = previousDate.year;
        return previousMonth + "-" + previousYear;
    } else {
        const endMonth = key.split(" to ")[0].split("-")[0].trim();
        const endYear = key.split(" to ")[0].split("-")[1].trim();
        const previousDate = DateTime.fromFormat(
            `${endMonth}-1-${endYear}`,
            "LLL-d-yyyy"
        ).minus({ months: 1 });
        const previousMonth = previousDate.toFormat("LLL");
        const previousYear = previousDate.year;
        return previousMonth + "-" + previousYear;
    }
}
const attachObj = (arr, refobj) => {
    for (let i = 0; i < arr.length; i++) {
        arr[i] = { ...arr[i], refobj:refobj.refobj }
    }
    return arr
}
function calculatePercentage(value1, value2) {
    if ((value1 === null || value1 === 0) && (value2 === null || value2 === 0)) {
        return '-'
    }
    if (value2 === 0) {
        return '100 %'
    }
    console.log(value1, value2)
    return parseInt(Math.abs(((value1 - value2) / value2) * 100)) + '%'
}
function getNumberOfColumn(monthString) {
    if (monthString.includes("to")) {
        const [startMonth, endMonth] = monthString.split(" to ");

        const startDate = DateTime.fromFormat(startMonth, "LLL-yyyy");
        let endDate = DateTime.local(); // Default to current month and year
        if (endMonth) {
            endDate = DateTime.fromFormat(endMonth, "LLL-yyyy");
        }

        // Calculate the difference in months
        const diffMonths = endDate.diff(startDate, "months").months + 1;

        return diffMonths;
    } else {
        return 1;
    }
}
export {getComparisionData}