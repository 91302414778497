
import Axios from "axios"
import moment from "moment"
import { API } from "../../constants/api_url"
import { setPPFListData } from "../../RTK/Background/pendingData"
import APIServices from "../../service/APIService"
import { DateTime } from "luxon"

//get location hierarchy data based on location [],roleassigned [], $roles[]
const getLocationData = (locationData, roleAssignments, roles) => {
    // Filter role assignments based on roles
    const relevantAssignments = roleAssignments.filter(assignment =>
        assignment.roles.some(role => roles.includes(role))
    );

    // Initialize the result as an empty array
    let result = [];

    // Loop through each relevant assignment
    relevantAssignments.forEach(assignment => {
        if (assignment.tier1_id === 0) {
            // If tier1_id is 0, return all locations
            result = locationData;
        } else {
            // Find the matching tier1 (country)
            let country = locationData.find(loc => loc.id === assignment.tier1_id);
            if (!country) return;

            if (assignment.tier2_id === 0) {
                // If tier2_id is 0, return the country and its regions
                result.push(country);
            } else {
                // Find the matching tier2 (region)
                let region = country.locationTwos.find(loc => loc.id === assignment.tier2_id);
                if (!region) return;

                if (assignment.tier3_id === 0) {
                    // If tier3_id is 0, return the region and its sites
                    result.push({
                        ...country,
                        locationTwos: [region]
                    });
                } else {
                    // Find the matching tier3 (site)
                    let site = region.locationThrees.find(loc => loc.id === assignment.tier3_id);
                    if (!site) return;

                    result.push({
                        ...country,
                        locationTwos: [{
                            ...region,
                            locationThrees: [site]
                        }]
                    });
                }
            }
        }
    });

    // Remove duplicate locations from the result
    result = result.filter((item, index, self) =>
        index === self.findIndex(t => t.id === item.id)
    );

    return result;
}
function checkRoleAccessByRoleIds(user_ids, roles, level, tier_id, roleAssignments, locationData, adminId) {
    // Step 1: Filter roleAssignments based on user_ids and roles
    const filteredAssignments = roleAssignments.filter(assignment =>
        user_ids.includes(assignment.user_id) &&
        assignment.roles.some(role => roles.includes(role))
    );

    // Step 2: Create a set of user IDs who have access
    const userIdsWithAccess = new Set();
    if (adminId) {
        userIdsWithAccess.add(adminId);
    }

    // Step 3: Check each filtered assignment for access
    filteredAssignments.forEach(assignment => {
        const { user_id, tier1_id, tier2_id, tier3_id } = assignment;

        // Get valid tier IDs for this specific assignment
        const validTierIds = getValidTierIdsForAssignment(tier1_id, tier2_id, tier3_id, locationData);

        if (level === 0) {
            // Global access
            userIdsWithAccess.add(user_id);
        } else if (level === 1 && validTierIds.countries.includes(tier_id) &&  !assignment.tier2_id ) {
            // Country level access
            userIdsWithAccess.add(user_id);
        } else if (level === 2 && (validTierIds.regions.includes(tier_id) || validTierIds.countries.includes(tier_id) ) &&  !assignment.tier3_id ) {
            // Region level access
            userIdsWithAccess.add(user_id);
        } else if (level === 3 && (validTierIds.businessUnits.includes(tier_id) || validTierIds.regions.includes(tier_id) || validTierIds.countries.includes(tier_id))) {
            // Business Unit level access
            userIdsWithAccess.add(user_id);
        }
    });

    // Step 4: Return the list of user IDs who have access
    return Array.from(userIdsWithAccess);
}

function getValidTierIdsForAssignment(tier1_id, tier2_id, tier3_id, locationData) {
    let countries = new Set();
    let regions = new Set();
    let businessUnits = new Set();

    locationData.forEach(country => {
        if (tier1_id === country.id || tier1_id === 0 || tier1_id === null) {
            countries.add(country.id);
            country.locationTwos.forEach(region => {
                if (tier2_id === 0 || tier2_id === region.id || tier2_id === null) {
                    regions.add(region.id);
                    region.locationThrees.forEach(bu => {
                        if (tier3_id === 0 || tier3_id === bu.id || tier3_id === null) {
                            businessUnits.add(bu.id);
                        }
                    });
                }
            });
        }
    });

    return {
        countries: Array.from(countries),
        regions: Array.from(regions),
        businessUnits: Array.from(businessUnits)
    };
}
const getRPTextFormat = (item) => {
    if (item.length !== 0) {
        if (item.length >= 2) {
            console.log(item);
            const startDate = DateTime.fromFormat(item[0], 'MM-yyyy').toFormat('LLL-yyyy');
            const endDate = DateTime.fromFormat(item[item.length - 1], 'MM-yyyy').toFormat('LLL-yyyy');
            return `${startDate} to ${endDate}`;
        } else {
            return DateTime.fromFormat(item[0], 'MM-yyyy').toFormat('LLL-yyyy');
        }
    }
};
const getFiscalYearRange = (year, fymonth) => {
    let startDate, endDate;
    

    if (fymonth === 1) {
        startDate = DateTime.fromObject({ year, month: 1, day: 1 }).startOf('day');
        endDate = DateTime.fromObject({ year, month: 12, day: 31 }).endOf('day');
    } else {
        startDate = DateTime.fromObject({ year: year - 1, month: fymonth, day: 1 }).startOf('day');
        endDate = DateTime.fromObject({ year, month: fymonth - 1, day: 1 }).endOf('month');
    }

    return { startDate, endDate };
};
const filterAssignmentsByFiscalYear = (assignments, year,fymonth) => {
    const { startDate, endDate } = getFiscalYearRange(year, fymonth);
    const currentDate = DateTime.local().startOf('day');

    return assignments.filter(assignment => {
        
        const assignmentStartDate = assignment.start_date ? DateTime.fromISO(assignment.start_date, { zone: 'utc' }).startOf('day') : currentDate;
        const assignmentEndDate = assignment.end_date ? DateTime.fromISO(assignment.end_date, { zone: 'utc' }).startOf('day') : currentDate;

        return (assignmentStartDate >= startDate && assignmentStartDate <= endDate) ||
            (assignmentEndDate >= startDate && assignmentEndDate <= endDate) ||
            (assignmentStartDate <= startDate && assignmentEndDate >= endDate);
    });
};
const filterSubmissionsByFiscalYear = (submissions, year,fymonth) => {
    const { startDate, endDate } = getFiscalYearRange(year, fymonth);

    return submissions.filter(submission => {
        const allDatesWithinRange = submission.reporting_period.every(period => {
            const periodDate = DateTime.fromFormat(period, 'MM-yyyy').startOf('month');
            return periodDate >= startDate && periodDate <= endDate;
        });

        return allDatesWithinRange;
    });
};
const getRPLuxon = (months) => {
    if (months.includes('to')) {
        let startDate = DateTime.fromFormat(months.split('to')[0].trim(), 'LLL-yyyy')
        let endDate = DateTime.fromFormat(months.split('to')[1].trim(), 'LLL-yyyy')
        let rp = []
        while (startDate <= endDate) {
            rp.push(startDate.toFormat('LL-yyyy'));
            startDate = startDate.plus({ months: 1 })
        }
        return rp
    } else {
        return [DateTime.fromFormat(months, 'LLL-yyyy').toFormat('LL-yyyy')]
    }
}

function isReportingPeriodWithinFiscalYear(year, fymonth, reporting_period) {
    let startOfFiscalYear, endOfFiscalYear;

    // Adjust the start and end based on fymonth
    if (fymonth === 1) {
        // Fiscal year is the calendar year
        startOfFiscalYear = DateTime.utc(year, 1, 1);
        endOfFiscalYear = DateTime.utc(year, 12, 31);
    } else {
        // Fiscal year starts in the previous year
        startOfFiscalYear = DateTime.utc(year - 1, fymonth, 1);
        endOfFiscalYear = startOfFiscalYear.plus({ years: 1 }).minus({ days: 1 });
    }

    // Check if each reporting_period date falls within the fiscal year
    return reporting_period.every(dateStr => {
        const date = DateTime.fromFormat(dateStr, "MM-yyyy", { zone: "utc" });
        return date >= startOfFiscalYear && date <= endOfFiscalYear;
    });
}
  function getReportingFiscalYearByReportingperiod(reporting_period, fymonth) {
    const parseDate = (str) => {
      // Try both formats to parse different date strings
      return DateTime.fromFormat(str, "MMM-yyyy").isValid
        ? DateTime.fromFormat(str, "MMM-yyyy")
        : DateTime.fromFormat(str, "MM-yyyy");
    };
  
    const determineFiscalYear = (date) => {
      // Determine fiscal year based on fymonth
      if (fymonth === 1) {
        return date.year; // Calendar year if fiscal year starts in January
      } else {
        const fiscalYearStart = DateTime.fromObject({ year: date.year, month: fymonth, day: 1 });
        return date >= fiscalYearStart ? date.year + 1 : date.year;
      }
    };
  
    // If reporting_period is an array, parse the last date in the array
    if (Array.isArray(reporting_period)) {
      const lastPeriod = reporting_period[reporting_period.length - 1];
      const date = parseDate(lastPeriod);
      return date.isValid ? determineFiscalYear(date) : null;
    }
  
    // If reporting_period is a single date or range string
    if (typeof reporting_period === "string") {
      if (reporting_period.includes(" to ")) {
        // Parse the end date in the range "Jan-2022 to Mar-2022"
        const [, endDateStr] = reporting_period.split(" to ");
        const endDate = parseDate(endDateStr.trim());
        return endDate.isValid ? determineFiscalYear(endDate) : null;
      } else {
        // Single date format, e.g., "Jan-2022" or "02-2022"
        const date = parseDate(reporting_period);
        return date.isValid ? determineFiscalYear(date) : null;
      }
    }
  
    return null; // Return null if format is invalid
  }
  const filterDataByTierAndLocation = (data, locationData, tier1_id, tier2_id, tier3_id) => {
    if (tier1_id === 0 && tier2_id === null && tier3_id === null) {
        return data; // If tier is 0, null, null return the given data
    }

    const { countries, regions, businessUnits } = getValidTierIds(locationData, tier1_id, tier2_id, tier3_id);


    return data.filter(item => {
        if (tier1_id !== 0 && tier2_id === 0 && tier3_id === null) {
            // Case when we want all regions and sites under a country
            return (item.level === 1 && countries.includes(item.locationId)) ||
                (item.level === 2 && regions.includes(item.locationId)) ||
                (item.level === 3 && businessUnits.includes(item.locationId));
        } else if (tier1_id !== 0 && tier2_id !== 0 && tier3_id === 0) {
            // Case when we want a specific region and all its sites
            return (item.level === 2 && regions.includes(item.locationId)) ||
                (item.level === 3 && businessUnits.includes(item.locationId));
        } else if (tier1_id !== 0 && tier2_id !== 0 && tier3_id !== 0) {
            // Case when we want a specific site
            return item.level === 3 && businessUnits.includes(item.locationId);
        } else {
            // Case when we want only the specific country
            return item.level === 1 && countries.includes(item.locationId);
        }
    });
};
const getValidTierIds = (locationData, tier1_id, tier2_id, tier3_id) => {
    const countries = new Set();
    const regions = new Set();
    const businessUnits = new Set();

    locationData.forEach(country => {
        if (tier1_id === 0 || tier1_id === country.id) {
            countries.add(country.id);

            country.locationTwos.forEach(region => {
                if (tier2_id === 0 || tier2_id === region.id) {
                    regions.add(region.id);

                    region.locationThrees.forEach(businessUnit => {
                        if (tier3_id === 0 || (tier2_id === 0 && tier3_id === null) || tier3_id === businessUnit.id) {
                            businessUnits.add(businessUnit.id);
                        }
                    });
                }
            });
        }
    });

    return { countries: Array.from(countries), regions: Array.from(regions), businessUnits: Array.from(businessUnits) };
};
const  generateFiscalYearsByAssignment = (assignments, fymonth) => {
    const fiscalYears = [];

    assignments.forEach(({ start_date, end_date }) => {
        const startDate = DateTime.fromISO(start_date, { zone: "utc" });
        const endDate = end_date
            ? DateTime.fromISO(end_date, { zone: "utc" })
            : DateTime.utc(); // Use current UTC date if end_date is null

        // Determine the fiscal year of the start date
        let startFY = startDate.year;
        if (fymonth !== 1 && startDate.month < fymonth) {
            startFY -= 1;
        }

        // Determine the fiscal year of the end date
        let endFY = endDate.year;
        if (fymonth !== 1 && endDate.month < fymonth) {
            endFY -= 1;
        }

        // Add all fiscal years in the range to the result
        for (let year = startFY; year <= endFY; year++) {
            const value = fymonth === 1 ? year : year + 1;
            const title = fymonth === 1 
                ? `${year}` 
                : `${year}-${String(year + 1).slice(-2)}`;

            // Avoid duplicates
            if (!fiscalYears.some(fy => fy.value === value)) {
                fiscalYears.push({ value, title });
            }
        }
    });

    // Sort fiscal years by value
    return fiscalYears.sort((a, b) => a.value - b.value);
}
  
export { getLocationData,checkRoleAccessByRoleIds,getRPTextFormat,filterAssignmentsByFiscalYear,filterSubmissionsByFiscalYear,getRPLuxon,isReportingPeriodWithinFiscalYear ,getReportingFiscalYearByReportingperiod,filterDataByTierAndLocation,generateFiscalYearsByAssignment }
