import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { Checkbox, FormControlLabel } from "@mui/material";

// Sample data for initial Scope 3 emissions by category
const initialData = [
  { category: "Purchased Goods and Services", totalEmissions: 300 },
  { category: "Transportation and Distribution", totalEmissions: 150 },
  { category: "Waste Disposal", totalEmissions: 100 },
];

// Data for drill-down breakdown by emissions type for each category
const breakdownData = {
  "Purchased Goods and Services": [
    { type: "Raw Materials", emissions: 150 },
    { type: "Manufacturing", emissions: 100 },
    { type: "Logistics", emissions: 50 },
  ],
  "Transportation and Distribution": [
    { type: "Road Transport", emissions: 80 },
    { type: "Air Transport", emissions: 40 },
    { type: "Sea Transport", emissions: 30 },
  ],
  "Waste Disposal": [
    { type: "Landfill", emissions: 60 },
    { type: "Recycling", emissions: 40 },
  ],
};

const Scope3Breakdown = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedEmissions, setSelectedEmissions] = useState({
    rawMaterials: true,
    manufacturing: true,
    logistics: true,
    roadTransport: true,
    airTransport: true,
    seaTransport: true,
    landfill: true,
    recycling: true,
  });

  const handleBarClick = (data) => {
    setSelectedCategory(data.category);
  };

  const handleCheckboxChange = (event) => {
    setSelectedEmissions({
      ...selectedEmissions,
      [event.target.name]: event.target.checked,
    });
  };

  const renderDrillDownChart = () => {
    const data = breakdownData[selectedCategory];

    return (
      <ResponsiveContainer width={600} height={300}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="type" />
          <YAxis />
          <Tooltip />
          <Legend />
          {selectedEmissions.rawMaterials && (
            <Bar
              dataKey="emissions"
              name="Raw Materials"
              fill="#2ecc71"
              barSize={60}
            />
          )}
          {selectedEmissions.manufacturing && (
            <Bar
              dataKey="emissions"
              name="Manufacturing"
              fill="#3498db"
              barSize={60}
            />
          )}
          {selectedEmissions.logistics && (
            <Bar
              dataKey="emissions"
              name="Logistics"
              fill="#e74c3c"
              barSize={60}
            />
          )}
          {selectedEmissions.roadTransport && (
            <Bar
              dataKey="emissions"
              name="Road Transport"
              fill="#f39c12"
              barSize={60}
            />
          )}
          {selectedEmissions.airTransport && (
            <Bar
              dataKey="emissions"
              name="Air Transport"
              fill="#9b59b6"
              barSize={60}
            />
          )}
          {selectedEmissions.seaTransport && (
            <Bar
              dataKey="emissions"
              name="Sea Transport"
              fill="#16a085"
              barSize={60}
            />
          )}
          {selectedEmissions.landfill && (
            <Bar
              dataKey="emissions"
              name="Landfill"
              fill="#8e44ad"
              barSize={60}
            />
          )}
          {selectedEmissions.recycling && (
            <Bar
              dataKey="emissions"
              name="Recycling"
              fill="#e67e22"
              barSize={60}
            />
          )}
        </BarChart>
      </ResponsiveContainer>
    );
  };

  return (
    <div>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Scope 3 Emissions Breakdown
      </h3>
      <ResponsiveContainer width={600} height={300} style={{ margin: "auto" }}>
        <BarChart data={initialData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="category" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar
            dataKey="totalEmissions"
            fill="#8e44ad"
            onClick={handleBarClick}
            barSize={60}
          >
            {initialData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill="#9b59b6" />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>

      {selectedCategory && (
        <div>
          <h4>Drill-down: {selectedCategory}</h4>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedEmissions.rawMaterials}
                  onChange={handleCheckboxChange}
                  name="rawMaterials"
                  color="primary"
                />
              }
              label="Raw Materials"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedEmissions.manufacturing}
                  onChange={handleCheckboxChange}
                  name="manufacturing"
                  color="primary"
                />
              }
              label="Manufacturing"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedEmissions.logistics}
                  onChange={handleCheckboxChange}
                  name="logistics"
                  color="primary"
                />
              }
              label="Logistics"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedEmissions.roadTransport}
                  onChange={handleCheckboxChange}
                  name="roadTransport"
                  color="primary"
                />
              }
              label="Road Transport"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedEmissions.airTransport}
                  onChange={handleCheckboxChange}
                  name="airTransport"
                  color="primary"
                />
              }
              label="Air Transport"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedEmissions.seaTransport}
                  onChange={handleCheckboxChange}
                  name="seaTransport"
                  color="primary"
                />
              }
              label="Sea Transport"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedEmissions.landfill}
                  onChange={handleCheckboxChange}
                  name="landfill"
                  color="primary"
                />
              }
              label="Landfill"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedEmissions.recycling}
                  onChange={handleCheckboxChange}
                  name="recycling"
                  color="primary"
                />
              }
              label="Recycling"
            />
          </div>
          {renderDrillDownChart()}
        </div>
      )}
    </div>
  );
};

export default Scope3Breakdown;
