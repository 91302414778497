import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
} from "@mui/material";
import { CheckCircle, HourglassEmpty, DoNotDisturb } from "@mui/icons-material";

const correctiveActions = [
  {
    action: "Fix safety hazard in warehouse",
    status: "completed",
    dueDate: "2024-12-15",
  },
  {
    action: "Review training protocol",
    status: "in progress",
    dueDate: "2024-12-05",
  },
  {
    action: "Inspect fire extinguishers",
    status: "not started",
    dueDate: "2024-12-10",
  },
];

const getStatusIcon = (status) => {
  switch (status) {
    case "completed":
      return <CheckCircle style={{ color: "green" }} />;
    case "in progress":
      return <HourglassEmpty style={{ color: "orange" }} />;
    case "not started":
      return <DoNotDisturb style={{ color: "red" }} />;
    default:
      return null;
  }
};

const CorrectiveActionStatusTable = () => {
  return (
    <>
      <h3 style={{ fontSize: "18px", margin: "20px" }}>
        Corrective action status table
      </h3>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h6">Corrective Action</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">Status</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">Due Date</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {correctiveActions.map((action, index) => (
              <TableRow key={index}>
                <TableCell>{action.action}</TableCell>
                <TableCell>
                  <IconButton disabled>
                    {getStatusIcon(action.status)}
                  </IconButton>
                </TableCell>
                <TableCell>{action.dueDate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CorrectiveActionStatusTable;
