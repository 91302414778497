import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const data = [
  { department: "HR", time: "Morning", value: 20 },
  { department: "HR", time: "Afternoon", value: 30 },
  { department: "HR", time: "Evening", value: 10 },
  { department: "IT", time: "Morning", value: 40 },
  { department: "IT", time: "Afternoon", value: 20 },
  { department: "IT", time: "Evening", value: 30 },
  { department: "Sales", time: "Morning", value: 25 },
  { department: "Sales", time: "Afternoon", value: 35 },
  { department: "Sales", time: "Evening", value: 15 },
  { department: "Finance", time: "Morning", value: 30 },
  { department: "Finance", time: "Afternoon", value: 25 },
  { department: "Finance", time: "Evening", value: 20 },
];

const DiversityHeatMap = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    renderChart();
  }, []);

  const renderChart = () => {
    const width = 800;
    const height = 500;
    const margin = { top: 50, right: 50, bottom: 100, left: 100 };

    d3.select(chartRef.current).select("svg").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Get unique departments and times
    const departments = [...new Set(data.map((d) => d.department))];
    const times = [...new Set(data.map((d) => d.time))];

    // Scales
    const x = d3
      .scaleBand()
      .domain(departments)
      .range([0, chartWidth])
      .padding(0.1);

    const y = d3.scaleBand().domain(times).range([0, chartHeight]).padding(0.1);

    const color = d3
      .scaleSequential(d3.interpolateBlues)
      .domain([0, d3.max(data, (d) => d.value)]);

    // X-axis
    g.append("g")
      .attr("transform", `translate(0,${chartHeight})`)
      .call(d3.axisBottom(x));

    // Y-axis
    g.append("g").call(d3.axisLeft(y));

    // X-axis label
    g.append("text")
      .attr("x", chartWidth / 2)
      .attr("y", chartHeight + 40)
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .text("Department");

    // Y-axis label
    g.append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -chartHeight / 2)
      .attr("y", -60)
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .text("Time of Day");

    // Draw heatmap cells
    g.selectAll("rect")
      .data(data)
      .enter()
      .append("rect")
      .attr("x", (d) => x(d.department))
      .attr("y", (d) => y(d.time))
      .attr("width", x.bandwidth())
      .attr("height", y.bandwidth())
      .attr("fill", (d) => color(d.value))
      .append("title")
      .text((d) => `${d.department} - ${d.time}: ${d.value}`);

    // Add legend
    const legendWidth = 300;
    const legendHeight = 20;

    const legend = svg
      .append("g")
      .attr(
        "transform",
        `translate(${chartWidth / 2 - legendWidth / 2}, ${
          height - margin.bottom + 30
        })`
      );

    const legendScale = d3
      .scaleLinear()
      .domain(color.domain())
      .range([0, legendWidth]);

    const legendAxis = d3
      .axisBottom(legendScale)
      .ticks(5)
      .tickSize(-legendHeight);

    legend
      .selectAll("rect")
      .data(
        d3.range(color.domain()[0], color.domain()[1], color.domain()[1] / 20)
      )
      .enter()
      .append("rect")
      .attr("x", (d) => legendScale(d))
      .attr("y", 0)
      .attr("width", legendWidth / 20)
      .attr("height", legendHeight)
      .attr("fill", (d) => color(d));

    legend
      .append("g")
      .attr("transform", `translate(0,${legendHeight})`)
      .call(legendAxis);
  };

  return (
    <>
      <h3 style={{ fontSize: "18px" }}>
        Diversity Heat Map: Department-wise Breakdown
      </h3>
      <div>
        This heat map visualizes the department-wise breakdown of activity
        across different times of the day.
      </div>
      <div
        ref={chartRef}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      />
    </>
  );
};

export default DiversityHeatMap;
