import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const dummyData = {
  name: "CEO",
  children: [
    {
      name: "CFO",
      department: "Finance",
      children: [
        { name: "Finance Manager", department: "Finance" },
        { name: "Accountant", department: "Finance" },
      ],
    },
    {
      name: "CTO",
      department: "Technology",
      children: [
        {
          name: "Engineering Manager",
          department: "Technology",
          children: [
            { name: "Software Engineer", department: "Technology" },
            { name: "QA Engineer", department: "Technology" },
          ],
        },
        { name: "IT Support", department: "Technology" },
      ],
    },
    {
      name: "COO",
      department: "Operations",
      children: [
        { name: "Logistics Manager", department: "Operations" },
        { name: "Operations Coordinator", department: "Operations" },
      ],
    },
  ],
};

const GovernanceStructureChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    renderOrgChart();
  }, []);

  const renderOrgChart = () => {
    const margin = { top: 40, right: 90, bottom: 50, left: 90 };
    const width = 800 - margin.left - margin.right;
    const height = 550;

    // Clear any existing SVG
    d3.select(chartRef.current).select("svg").remove();

    // Create SVG
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const tree = d3.tree().size([width, height]);

    // Convert data to hierarchy
    const root = d3.hierarchy(dummyData);

    // Assign the layout (x, y positions)
    tree(root);

    // Define color scale for departments
    const departmentColor = d3
      .scaleOrdinal()
      .domain(["Finance", "Technology", "Operations"])
      .range(["#16a085", "#2980b9", "#e67e22"]);

    // Draw links
    svg
      .selectAll(".link")
      .data(root.links())
      .enter()
      .append("path")
      .attr("class", "link")
      .attr(
        "d",
        d3
          .linkHorizontal()
          .x((d) => d.y)
          .y((d) => d.x)
      )
      .style("fill", "none")
      .style("stroke", "#ccc")
      .style("stroke-width", 1.5);

    // Draw nodes
    const nodes = svg
      .selectAll(".node")
      .data(root.descendants())
      .enter()
      .append("g")
      .attr("class", "node")
      .attr("transform", (d) => `translate(${d.y},${d.x})`);

    nodes
      .append("circle")
      .attr("r", 8)
      .style("fill", (d) => departmentColor(d.data.department || "Other"));

    nodes
      .append("text")
      .attr("dy", ".35em")
      .attr("x", (d) => (d.children ? -15 : 15))
      .style("text-anchor", (d) => (d.children ? "end" : "start"))
      .style("font-size", "12px")
      .text((d) => d.data.name);
  };

  return (
    <div>
      <div
        style={{
          fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "19.2px",
          textAlign: "left",
          margin: "25px",
        }}
      >
        Governance Structure
      </div>
      <div ref={chartRef} style={{ textAlign: "center" }} />

      {/* Legend */}
      <div
        style={{ marginTop: "20px", marginBottom: "20px", textAlign: "center" }}
      >
        <div style={{ fontSize: "14px", fontWeight: "bold" }}>Legend</div>
        <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
          <span style={{ color: "#16a085" }}>● Finance</span>
          <span style={{ color: "#2980b9" }}>● Technology</span>
          <span style={{ color: "#e67e22" }}>● Operations</span>
        </div>
      </div>
    </div>
  );
};

export default GovernanceStructureChart;
