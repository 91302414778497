import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";

const EnergyConsumptionBreakdown = () => {
  // Sample data for energy consumption
  const data = [
    { name: "Energy Consumed Inside Organization", value: 70 }, // 70% inside
    { name: "Energy Consumed Outside Organization", value: 30 }, // 30% outside
  ];

  // Define colors for the slices
  const COLORS = ["#00C49F", "#FF8042"];

  return (
    <div>
      <h3 style={{ fontSize: "18px" }}>Energy Consumption Breakdown</h3>
      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          <Pie
            data={data}
            cx="50%" // Center of the pie chart
            cy="50%" // Center of the pie chart
            innerRadius={60} // Makes the pie chart into a donut shape
            outerRadius={80} // Outer radius for the donut
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index]} />
            ))}
            {/* Add Data Labels */}
            <Label
              value={`${(data[0].value / 100).toFixed(2)}%`}
              position="center"
              style={{ fontSize: "20px", fontWeight: "bold", fill: "#333" }}
            />
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default EnergyConsumptionBreakdown;
