import React from "react";
import GaugeChart from "react-gauge-chart";

const WasteDiversionRateGauge = () => {
  // Dummy data for waste diversion rates (as percentage)
  const diversionRates = {
    hazardousWaste: 65, // Hazardous Waste diversion rate
    nonHazardousWaste: 80, // Non-Hazardous Waste diversion rate
    hazardousRecycled: 40, // Hazardous Recycled diversion rate
    hazardousReused: 25, // Hazardous Reused diversion rate
    nonHazardousRecycled: 60, // Non-Hazardous Recycled diversion rate
    nonHazardousReused: 20, // Non-Hazardous Reused diversion rate
  };

  return (
    <div style={{ fontFamily: "Lato", padding: "20px" }}>
      <div style={{ marginTop: "30px" }}>
        <h3 style={{ margin: "0", fontSize: "18px" }}>
          Percentage of waste diverted from disposal
        </h3>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "25px",
          justifyContent: "center",
        }}
      >
        {/* Hazardous Waste Section */}
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginRight: "30px",
          }}
        >
          <h4>Hazardous Waste</h4>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {/* Hazardous Recycled Gauge */}
            <div>
              <h5>Recycled</h5>
              <GaugeChart
                id="hazardous-recycled-gauge"
                nrOfLevels={30}
                arcsLength={[0.5, 0.3, 0.2]} // Define color zones for progress
                colors={["#e74c3c", "#f39c12", "#16a085"]} // Red, Yellow, Green
                textColor="#e74c3c"
                percent={diversionRates.hazardousRecycled / 100} // Convert percentage to 0-1 scale
                needleColor="#000000"
                arcPadding={0.02}
                style={{ width: "150px", height: "75px" }} // Adjusted size for side-by-side
              />
              <div
                style={{
                  marginTop: "10px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#16a085", // Green color for the label
                }}
              >
                {diversionRates.hazardousRecycled}% Recycled
              </div>
            </div>

            {/* Hazardous Reused Gauge */}
            <div>
              <h5>Reused</h5>
              <GaugeChart
                id="hazardous-reused-gauge"
                nrOfLevels={30}
                arcsLength={[0.5, 0.3, 0.2]} // Define color zones for progress
                colors={["#e74c3c", "#f39c12", "#16a085"]} // Red, Yellow, Green
                textColor="#e74c3c"
                percent={diversionRates.hazardousReused / 100} // Convert percentage to 0-1 scale
                needleColor="#000000"
                arcPadding={0.02}
                style={{ width: "150px", height: "75px" }} // Adjusted size for side-by-side
              />
              <div
                style={{
                  marginTop: "10px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#16a085", // Green color for the label
                }}
              >
                {diversionRates.hazardousReused}% Reused
              </div>
            </div>
          </div>
        </div>

        {/* Non-Hazardous Waste Section */}
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h4>Non-Hazardous Waste</h4>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {/* Non-Hazardous Recycled Gauge */}
            <div>
              <h5>Recycled</h5>
              <GaugeChart
                id="non-hazardous-recycled-gauge"
                nrOfLevels={30}
                arcsLength={[0.5, 0.3, 0.2]} // Define color zones for progress
                colors={["#e74c3c", "#f39c12", "#16a085"]} // Red, Yellow, Green
                textColor="#e74c3c"
                percent={diversionRates.nonHazardousRecycled / 100} // Convert percentage to 0-1 scale
                needleColor="#000000"
                arcPadding={0.02}
                style={{ width: "150px", height: "75px" }} // Adjusted size for side-by-side
              />
              <div
                style={{
                  marginTop: "10px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#16a085", // Green color for the label
                }}
              >
                {diversionRates.nonHazardousRecycled}% Recycled
              </div>
            </div>

            {/* Non-Hazardous Reused Gauge */}
            <div>
              <h5>Reused</h5>
              <GaugeChart
                id="non-hazardous-reused-gauge"
                nrOfLevels={30}
                arcsLength={[0.5, 0.3, 0.2]} // Define color zones for progress
                colors={["#e74c3c", "#f39c12", "#16a085"]} // Red, Yellow, Green
                textColor="#e74c3c"
                percent={diversionRates.nonHazardousReused / 100} // Convert percentage to 0-1 scale
                needleColor="#000000"
                arcPadding={0.02}
                style={{ width: "150px", height: "75px" }} // Adjusted size for side-by-side
              />
              <div
                style={{
                  marginTop: "10px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#16a085", // Green color for the label
                }}
              >
                {diversionRates.nonHazardousReused}% Reused
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WasteDiversionRateGauge;
