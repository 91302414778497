import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js";
import {
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  CategoryScale,
  LinearScale,
} from "chart.js";

// Register necessary Chart.js components
Chart.register(
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  CategoryScale,
  LinearScale
);

const WasteGenerationTrends = () => {
  const chartRef = useRef();

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");

    // Data for the Area Chart
    const data = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ], // Time Period (Months)
      datasets: [
        {
          label: "Hazardous Waste",
          data: [30, 40, 45, 50, 60, 55, 70, 80, 65, 90, 100, 110], // Example data for hazardous waste
          fill: true,
          backgroundColor: "rgba(255, 99, 132, 0.2)", // Red with transparency
          borderColor: "rgba(255, 99, 132, 1)", // Red color for border
          borderWidth: 1,
        },
        {
          label: "Non-Hazardous Waste",
          data: [20, 25, 35, 40, 50, 60, 80, 90, 85, 95, 110, 120], // Example data for non-hazardous waste
          fill: true,
          backgroundColor: "rgba(75, 192, 192, 0.2)", // Greenish-blue with transparency
          borderColor: "rgba(75, 192, 192, 1)", // Greenish-blue color for border
          borderWidth: 1,
        },
      ],
    };

    // Chart Configuration
    const config = {
      type: "line",
      data: data,
      options: {
        responsive: true,
        plugins: {
          tooltip: {
            mode: "index",
            intersect: false,
            callbacks: {
              label: function (context) {
                return context.dataset.label + ": " + context.raw + " units";
              },
            },
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Time Period", // X-Axis Label
            },
          },
          y: {
            title: {
              display: true,
              text: "Waste Quantity", // Y-Axis Label
            },
            beginAtZero: true,
          },
        },
        elements: {
          line: {
            tension: 0.4, // Makes the line smoother
          },
        },
      },
    };

    // Create the Area Chart
    new Chart(ctx, config);

    // Cleanup chart on component unmount
    return () => {
      if (ctx) {
        const chartInstance = Chart.getChart(ctx);
        if (chartInstance) {
          chartInstance.destroy();
        }
      }
    };
  }, []);

  return (
    <>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Waste Generation by Type of waste
      </h3>

      <div style={{ maxWidth: "800px", margin: "0 auto" }}>
        <canvas ref={chartRef} />
      </div>
    </>
  );
};

export default WasteGenerationTrends;
