import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
// Sample data for committees
const data = [
  {
    committee: "Audit Committee",
    independent: 60,
    nonIndependent: 40,
  },
  {
    committee: "Nomination Committee",
    independent: 70,
    nonIndependent: 30,
  },
  {
    committee: "Compensation Committee",
    independent: 80,
    nonIndependent: 20,
  },
  {
    committee: "Risk Committee",
    independent: 50,
    nonIndependent: 50,
  },
];

const RatioBar = () => {
  return (
    <div style={{ width: "100%", height: 400 }}>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Ratio of independent to non-independent directors across committees:
      </h3>
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 30 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="committee" />
          <YAxis>
            <Label
              value="Percentage"
              angle={-90}
              position="insideLeft"
              style={{ textAnchor: "middle", fill: "#333", fontSize: "14px" }}
            />
          </YAxis>
          <Tooltip />
          <Legend />
          {/* Independent Directors Bar */}
          <Bar
            dataKey="independent"
            stackId="a"
            barSize={60} // Set bar size to 60
            fill="#B3CDE0" // Subtle color for independent directors
          />
          {/* Non-Independent Directors Bar */}
          <Bar
            dataKey="nonIndependent"
            stackId="a"
            barSize={60} // Set bar size to 60
            fill="#F1C6B0" // Subtle color for non-independent directors
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RatioBar;
