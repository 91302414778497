import Axios from "axios";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { API } from "../constants/api_url";
import LazyView from "../components/LazyView";
import { useHistory } from "react-router-dom";
import { TabMenu } from 'primereact/tabmenu';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column"
import { Tooltip } from "primereact/tooltip";
import Swal from 'sweetalert2'
import APIServices from "../service/APIService";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { checkRoleAccessByRoleIds, getLocationData } from "../components/BGHF/helper";
const { DateTime } = require('luxon')


const UserDashboard = () => {

    const login_data = useSelector((state) => state.user.userdetail)
    const admin_data = useSelector((state) => state.user.admindetail)

    const navigate = useHistory()
    const [submittedQuantitative, setSubmittedQuantitative] = useState([])
    const [reviewQuantitative, setReviewQuantitative] = useState([])
    const [approveQuantitative, setApproveQuantitative] = useState([])
    const [rawsitelist, setRawSitelist] = useState([])
    const [present, setPresent] = useState([])
    const [past, setPast] = useState([])
    const [future, setFuture] = useState([])
    const frequency_list = [{ name: 'Monthly', id: 1 }, { name: 'Bi-Monthly', id: 2 }, { name: 'Quartely', id: 3 }, { name: 'Annually', id: 4 }, { name: 'Bi-Annually', id: 5 }, { name: 'Undefined', id: 6 }]
    useEffect(() => {

        let uriString = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }
        let uriString2 = {
            "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]


        }
        let Overall = API.Categories + `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`;

        const promise0 = APIServices.get(API.DCF)
        const promise1 = APIServices.get(API.QN_Submit_UP(admin_data.id))
        const promise2 = APIServices.get(API.LocationOne_UP(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`)
        const promise3 = APIServices.get(API.DCF_Entity_UP(admin_data.id))
        const promise4 = APIServices.get(API.DF_Entity_UP(admin_data.id))
        const promise5 = APIServices.get(API.SRF_Entity_UP(admin_data.id))
        const promise6 = APIServices.get(API.DCF_Entity_User_UP(admin_data.id))
        const promise7 = APIServices.get(API.DF_Entity_User_UP(admin_data.id))
        const promise8 = APIServices.get(API.SRF_Entity_User_UP(admin_data.id))
        const promise9 = APIServices.get(
            API.AssignDCFClient_UP(admin_data.id)
        );
        const promise10 = APIServices.get(
            Overall
        );
        const promise11 = APIServices.get(
            API.GetRoleByUserId(admin_data.id, login_data.id)
        );
        Promise.all([promise0, promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8, promise9, promise10, promise11]).then((values) => {
            let quantitative_submitted = values[1].data, qn_submit_list = [], qn_review_list = [], qn_approve_list = [], dcf_list = values[0].data, quantitative_ass_list = values[6].data, qualitative_ass_list = values[7].data, srf_ass_list = values[8].data
            let quantitative_entity_list = values[3].data, qualitative_entity_list = values[4].data, srf_entity_list = values[5].data

            let curated_dcf_ids = []
            if (values[9].data && values[9].data.length > 0) {
                values[10].data.forEach((cat) => {
                    if (cat.newTopics !== undefined) {

                        cat.newTopics.forEach((topic) => {

                            if (topic.newMetrics !== undefined && values[9].data[0].topic_ids.includes(topic.id) && (topic.tag === null || parseFloat(topic.tag) === admin_data.id)) {

                                topic.newMetrics.forEach((metric) => {
                                    if (Array.isArray(metric.data1) && values[9].data[0].metric_ids.includes(metric.id) && metric.data1[0].type !== undefined && metric.data1[0].type === 0 && (metric.tag === null || metric.tag === admin_data.id)) {
                                        if (metric.newDataPoints !== undefined) {
                                            metric.newDataPoints.forEach((dp) => {
                                                if (Array.isArray(dp.data1) && dp.data1.length !== 0 && dp.data1[0].datasource !== null && typeof dp.data1[0].datasource === 'number') {
                                                    let dcf_index = values[0].data.findIndex(i => i.id === dp.data1[0].datasource)
                                                    if (!curated_dcf_ids.includes(dp.data1[0].datasource) && dcf_index !== -1) {

                                                        if (values[0].data[dcf_index].tags === null || !values[0].data[dcf_index].tags.length || values[0].data[dcf_index].tags.includes(admin_data.id)) {
                                                            curated_dcf_ids.push(dp.data1[0].datasource)
                                                        }

                                                    }
                                                }
                                            })
                                        }
                                    }
                                })
                            }
                        })
                    }


                })
            }


            let shapedSite = values[2].data.map(item => {
                if (item.locationTwos) {
                    item.locationTwos = item.locationTwos.filter(locationTwo =>
                        locationTwo.locationThrees && locationTwo.locationThrees.length > 0
                    );
                }
                return item;
            }).filter(item => item.locationTwos && item.locationTwos.length > 0)
          
           

            console.log(shapedSite)
            let filtered_qn_ass_reporter = quantitative_ass_list.filter(i => dcf_list.map(j => j.id).includes(i.dcfId) && curated_dcf_ids.includes(i.dcfId) && (checkRoleAccessByRoleIds([login_data.id],[1],i.level,i[`tier${i.level}_id`], values[11].data.filter(i => i.user_id === login_data.id && i.userProfileId === admin_data.id),shapedSite).includes(login_data.id) || login_data.role === 'clientadmin') )
            let filtered_qn_ass_reviewer = quantitative_ass_list.filter(i => dcf_list.map(j => j.id).includes(i.dcfId) && curated_dcf_ids.includes(i.dcfId) && (checkRoleAccessByRoleIds([login_data.id],[4],i.level,i[`tier${i.level}_id`], values[11].data.filter(i => i.user_id === login_data.id && i.userProfileId === admin_data.id),shapedSite).includes(login_data.id) || login_data.role === 'clientadmin') )
            let filtered_qn_ass_approver = quantitative_ass_list.filter(i => dcf_list.map(j => j.id).includes(i.dcfId) && curated_dcf_ids.includes(i.dcfId) && (checkRoleAccessByRoleIds([login_data.id],[3],i.level,i[`tier${i.level}_id`], values[11].data.filter(i => i.user_id === login_data.id && i.userProfileId === admin_data.id),shapedSite).includes(login_data.id) || login_data.role === 'clientadmin') )

            console.log(filtered_qn_ass_reporter)
            // Quantitative
            filtered_qn_ass_reporter.forEach((item) => {
                if (item.reporter_ids.includes(login_data.id)) {
                    item.dcf_ = dcf_list.filter((k) => { return k.id === item.dcfId })[0]
                    item.frequency_ = frequency_list.filter((k) => { return k.id === item.frequency })[0]
                    let st_date = DateTime.fromISO(item.start_date, { zone: 'utc' }).toLocal(), ed_date = DateTime.utc().toLocal()
                    if (typeof item.end_date === 'string') {
                        ed_date = DateTime.fromISO(item.end_date, { zone: 'utc' }).toLocal()
                    }
                    getQuantitativeSubmissionData(st_date, ed_date, item.frequency, item, quantitative_submitted)
                }
            })
            console.log(quantitative_submitted)
            quantitative_submitted.forEach((item) => {

                let filtered_qn_ass_index1 = filtered_qn_ass_reporter.findIndex(i => { console.log(i.id, item.entityUserAssId, i.entityAssId, item.entityAssId); return (i.id === item.entityUserAssId && i.entityAssId === item.entityAssId) })
                let filtered_qn_ass_index2 = filtered_qn_ass_reviewer.findIndex(i => { console.log(i.id, item.entityUserAssId, i.entityAssId, item.entityAssId); return (i.id === item.entityUserAssId && i.entityAssId === item.entityAssId) })
                let filtered_qn_ass_index3 = filtered_qn_ass_approver.findIndex(i => { console.log(i.id, item.entityUserAssId, i.entityAssId, item.entityAssId); return (i.id === item.entityUserAssId && i.entityAssId === item.entityAssId) })

                if (filtered_qn_ass_index1 !== -1) {

                    if (filtered_qn_ass_reporter[filtered_qn_ass_index1].reporter_ids.includes(login_data.id)) {
                        console.log(item)
                        qn_submit_list.push(item)
                    }
                }
                if (filtered_qn_ass_index2 !== -1) {
                    if (filtered_qn_ass_reviewer[filtered_qn_ass_index2].reviewer_ids.includes(login_data.id)) {
                        qn_review_list.push(item)
                    }
                }
                if (filtered_qn_ass_index3 !== -1) {
                    if (filtered_qn_ass_approver[filtered_qn_ass_index3].approver_ids.includes(login_data.id)) {
                        qn_approve_list.push(item)
                    }
                }
            })
            setSubmittedQuantitative(qn_submit_list)
            setReviewQuantitative(qn_review_list)
            setApproveQuantitative(qn_approve_list)
        })

    }, [])

    const checkEnity = (rowData, entity_list, rawsite, obj) => {
        let index = entity_list.findIndex(k => k[obj] === rowData[obj])
        if (index !== -1) {
            let entity = entity_list[index]
            console.log(entity)
            if (rowData.level === 0) {
                return entity.tier0_ids.includes(0)
            } else if (rowData.level === 1) {
                return entity.tier1_ids.includes(rowData.locationId) && getCoverageText(rowData, rawsite)
            } else if (rowData.level === 2) {
                return entity.tier2_ids.includes(rowData.locationId) && getCoverageText(rowData, rawsite)
            } else if (rowData.level === 3) {
                return entity.tier3_ids.includes(rowData.locationId) && getCoverageText(rowData, rawsite)
            }
        } else {
            return false
        }

    }
    const getCoverageText = (rowData, rawsitelist) => {
        let text = ''
        console.log(rowData)
        if (rowData.level === 0) {
            text = 'Corporate'
        } else if (rowData.level === 1) {
            let country_index = rawsitelist.findIndex(i => i.id === rowData.locationId)
            if (country_index !== -1) {
                text = rawsitelist[country_index].name
            }
        } else if (rowData.level === 2) {
            let city_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.city_id === rowData.locationId })
            if (city_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[city_index].city_name
            }

        } else if (rowData.level === 3) {
            let site_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.site_id === rowData.locationId })
            if (site_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[site_index].site_name
            }
        }
        return text
    }
    const compareArrays = (a, b) => {


        return JSON.stringify(a) === JSON.stringify(b);
    };
    const splitArray = (array, chunkSize) => {


        return array.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / chunkSize)

            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [] // start a new chunk
            }

            resultArray[chunkIndex].push(item)

            return resultArray
        }, [])
    }
    const getRPLuxon = (months) => {
        if (months.includes('to')) {
            let startDate = DateTime.fromFormat(months.split('to')[0].trim(), 'LLL-yyyy')
            let endDate = DateTime.fromFormat(months.split('to')[1].trim(), 'LLL-yyyy')
            let rp = []
            while (startDate <= endDate) {
                rp.push(startDate.toFormat('LL-yyyy'));
                startDate = startDate.plus({ months: 1 })
            }
            return rp
        } else {
            return [DateTime.fromFormat(months, 'LLL-yyyy').toFormat('LL-yyyy')]
        }
    }
    const checkSubmission = (dcf, level, locationId, rp, old) => {
        // let rps = getRP(rp)
        let rps = getRPLuxon(rp)

        let result = {}
        let loc = JSON.parse(JSON.stringify(old))

        let index = loc.findIndex((i) => {
            return compareArrays(i.reporting_period, rps) && i.level === level && i.locationId === locationId && i.dcfId === dcf.id && i.type === 0
        })
        let index2 = loc.findIndex((i) => {
            return compareArrays(i.reporting_period, rps) && i.level === level && i.locationId === locationId && i.dcfId === dcf.id && (i.type !== 0)
        })

        if (index === -1) {

            result = { result: false, data: index2 === -1 ? true : false }
        } else {
            result = {
                result: true, data: loc[index], list: loc.filter((i) => {
                    return compareArrays(i.reporting_period, rps) && i.level === level && i.locationId === locationId && i.dcfId === dcf.id && i.type === 0
                })
            }
        }

        return result
    }
    const getQuantitativeSubmissionData = (startDate, endDate, frequency, item, old) => {

        var betweenMonths = [];
        let type = 0, past_ = [], future_ = []

        past_ = JSON.parse(JSON.stringify(past))

        // future_ = JSON.parse(JSON.stringify(future))

        if (Math.round(startDate.diff(DateTime.utc().startOf('month'), 'days').days).toFixed(0) === 0) {
            let endDate_ = startDate

            let endDate__ = DateTime.utc().toLocal()
            if (frequency === 1) {

                let check = checkSubmission(item.dcf_, item.level, item.locationId, endDate__.toFormat('LLL-yyyy'), old)

                if (check.result) {

                    future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: endDate__.toFormat('LLL-yyyy'), duedate: endDate__.plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: 0, level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                } else {
                    if (check.data) {

                        future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: endDate__.toFormat('LLL-yyyy'), duedate: endDate__.plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: 0, level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })
                    }

                }
            } else if (frequency === 6) {
                let check = checkSubmission(item.dcf_, item.level, item.locationId, endDate__.toFormat('LLL-yyyy'), old)

                if (check.result) {


                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: endDate__.toFormat('LLL-yyyy'), duedate: endDate__.toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(endDate__, 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                } else {
                    if (check.data) {

                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: endDate__.toFormat('LLL-yyyy'), duedate: endDate__.toFormat('LLL-yyyy'), overdue: 0, level: item.level, locationId: item.locationId, type: 6, company_id: admin_data.id, frequency })
                    }

                }


            }

        }
        else if (Math.round(startDate.diff(DateTime.utc().startOf('month'), 'days').days) < 31) {
            var date = startDate
            console.log(startDate.diff(endDate.startOf('month'), 'days').days)


            let endDate__ = DateTime.utc().toLocal()
            while (date <= endDate) {

                betweenMonths.push(date.toFormat('LLL-yyyy'));
                date = date.plus({ months: 1 })

            }
            console.log(betweenMonths, item.id)
            if (frequency === 1) {
                console.log(splitArray(betweenMonths, 1), 'SP')
                splitArray(betweenMonths, 1).forEach((months, ind) => {
                    console.log(endDate__.month === DateTime.fromFormat(months[0], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[0], 'LLL-yyyy').year)
                    if (endDate__.month === DateTime.fromFormat(months[0], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[0], 'LLL-yyyy').year) {
                        let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0], old)

                        if (check.result) {

                            future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                        } else {
                            if (check.data) {
                                future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                            }

                        }


                    } else {

                        if (Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'months').months) === 1) {

                            // if (endDate__ <= moment(months[0]).add(1, 'month').add(4, 'days')) {
                            let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0], old)


                            if (check.result) {


                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                }

                            }


                        } else {
                            let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0], old)
                            console.log(check, months[0])
                            if (check.result) {


                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {

                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                }

                            }
                        }
                    }

                })
            } else if (frequency === 2) {

                splitArray(betweenMonths, 2).forEach((months) => {
                    if (months.length === 2) {
                        if (endDate__.month === DateTime.fromFormat(months[1], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[1], 'LLL-yyyy').year) {
                            let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[1], old)
                            if (check.result) {

                                future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })


                                }

                            }


                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy'), 'months').months) === 1) {

                                // if (endDate__ <= moment(months[1]).add(1, 'month').add(4, 'days')) {
                                let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[1], old)
                                if (check.result) {

                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })


                                    }

                                }


                            } else {
                                let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[1], old)

                                if (check.result) {
                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 3) {

                splitArray(betweenMonths, 3).forEach((months) => {
                    if (months.length === 3) {

                        if (endDate__.month === DateTime.fromFormat(months[2], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[2], 'LLL-yyyy').year) {
                            let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[2], old)

                            if (check.result) {

                                future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                }

                            }


                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy'), 'months').months) === 1) {

                                // if (endDate__ <= moment(months[2]).add(1, 'month').add(4, 'days')) {
                                let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[2], old)

                                if (check.result) {
                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                    }

                                }



                            } else {

                                let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[2], old)

                                if (check.result) {
                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                } else {
                                    if (check.data) {
                                        console.log(item.site, item.dcf_, months[0] + ' to ' + months[2])
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 4) {
                splitArray(betweenMonths, 12).forEach((months) => {
                    if (months.length === 12) {
                        if (endDate__.month === DateTime.fromFormat(months[11], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[11], 'LLL-yyyy').year) {
                            let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[11], old)

                            if (check.result) {

                                future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                            } else {
                                if (check.data) {
                                    future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                }

                            }


                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy'), 'months').months) === 1) {

                                // if (endDate__ <= moment(months[11]).add(1, 'month').add(4, 'days')) {

                                let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[11], old)

                                if (check.result) {

                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                    }

                                }


                            } else {
                                let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[11], old)

                                if (check.result) {

                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 5) {
                splitArray(betweenMonths, 6).forEach((months) => {
                    if (months.length === 6) {
                        if (endDate__.month === DateTime.fromFormat(months[5], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[5], 'LLL-yyyy').year) {
                            let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[5], old)

                            if (check.result) {


                                future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                }

                            }


                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy'), 'months').months) === 1) {

                                // if (endDate__ <= moment(months[5]).add(1, 'month').add(4, 'days')) {
                                let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[5], old)

                                if (check.result) {


                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                    }

                                }


                                // } else {
                                //     let check = checkSubmission(item.dcf_, item.level,item.locationId, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), old)

                                //     if (check.result) {


                                //         past_.push({ dcf: item.dcf_,entityAssId:item.entityAssId,entityUserAssId:item.id, reporting_period: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), level:item.level,locationId:item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                //     } else {
                                //         if (check.data) {
                                //             past_.push({ dcf: item.dcf_,entityAssId:item.entityAssId,entityUserAssId:item.id, reporting_period: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), level:item.level,locationId:item.locationId, company_id: admin_data.id, frequency })

                                //         }

                                //     }
                                // }
                            } else {
                                let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[5], old)

                                if (check.result) {


                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 6) {
                splitArray(betweenMonths, 1).forEach((months, ind) => {

                    if (endDate__.month === DateTime.fromFormat(months[0], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[0], 'LLL-yyyy').year) {
                        let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0], old)

                        if (check.result) {
                            past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                            check.list.forEach((list) => {
                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: list.response, id: list.id, reject: list.reject, draft: list })

                            })

                        } else {
                            if (check.data) {
                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                            }

                        }


                    } else {
                        let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0], old)

                        if (check.result) {

                            check.list.forEach((list) => {
                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: list.response, id: list.id })

                            })


                        } else {
                            if (check.data) {
                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: months[0], overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                            }

                        }


                    }

                })
            }

        }
        console.log(past_)

        setPast((prev) => ([...prev, ...past_]))

        return { type, months: betweenMonths }
    }
    const getRoles = (info) => {
        if (info.role !== undefined || info.role !== null ) {
            return Object.entries(info.role).filter(i => i[1] === true).map(i => i[0]).join('/ ')
        } else {
            return 'Unknown'
        }
    }
    return (
        <div className="bg-smoke font-lato" >
            <div className="col-12" >
                <div>
                    <div className="col-12 flex align-items-center" style={{ padding: '0px 2rem' }}>
                        <span className="text-big-one"> Welcome {login_data.information.empname} !   </span> <span className="ml-1">{`<${login_data.email}>`} </span>   <Tag className="ml-3 p-tag-blue" >  { login_data.role === 'clientadmin' ? 'Enterprise Admin':  getRoles(login_data.information)} </Tag>
                    </div>
                    <div className="col-12 p-5" style={{
                        justifyContent: 'flex-start'
                    }}>
                        <label className="text-big-one clr-navy flex fs-16 flex justify-content-start"> DATA SUBMISSION AND REVIEW PORTAL
                        </label>
                        <label className="text-micro clr-navy flex  justify-content-start">Your one-stop portal to view and take action on all your sustainability data submission and approvals.</label>

                    </div>
                    {
                        <div className="col-12  justify-content-between">
                            <div className="col-12">
                                <div className="p-card">
                                    <label className="text-big-one clr-gray-900 flex fs-16 fw-7 p-2  flex"> Your Submissions </label>
                                    <div className="col-12 flex justify-content-evenly" style={{ borderTop: '1px solid #E0E0E0' }}>
                                        <div className="col-12 grid">
                                            <div className="col-4 flex justify-content-center" >
                                                <div className=" flex justify-content-center admin-card" style={{ height: 120 }} onMouseLeave={(e) => { e.currentTarget.style.background = '#ffffff'; e.currentTarget.className = ' flex admin-card justify-content-center '; e.target.style.cursor = 'pointer' }} onMouseOver={(e) => { e.preventDefault(); e.currentTarget.style.backgroundColor = '#31597510'; e.currentTarget.className = ' flex justify-content-center admin-card  box-shadow-one' }} onClick={() => { navigate.push({ pathname: '/quantitative_reporter_overdue/' }) }} >
                                                    <div className="flex justify-content-between align-items-center " style={{ flexDirection: 'row' }}>
                                                        <div className="big-number-navy col-2 fs-36">{past.length}</div>
                                                        <div className='col-10  grid justify-content-between'>
                                                            <div className="clr-navy   fs-18 text-bold">Quantitative</div>
                                                            <div className="flex align-items-center"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            {/* onClick={() => { navigate.push({ pathname: '/qualitative_reporter_overall/' })  }} */}
                                            <div className="col-4 flex justify-content-center" >
                                                <div className=" flex justify-content-center admin-card" style={{ height: 120 }} onMouseLeave={(e) => { e.currentTarget.style.background = '#ffffff'; e.currentTarget.className = ' flex admin-card justify-content-center '; e.target.style.cursor = 'pointer' }} onMouseOver={(e) => { e.preventDefault(); e.currentTarget.style.backgroundColor = '#31597510'; e.currentTarget.className = ' flex justify-content-center admin-card box-shadow-one' }} onClick={() => { navigate.push({ pathname: '/qualitative_reporter_overall/' })  }}   >
                                                    <div className="flex justify-content-between align-items-center " style={{ flexDirection: 'row' }}>
                                                        <div className="big-number-navy col-2 fs-36">{0}</div>
                                                        <div className='col-10  grid justify-content-between'>
                                                            <div className="clr-navy   fs-18 text-bold">Qualitative</div>
                                                            <div className="flex align-items-center"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4 flex justify-content-center" >
                                                <div className=" flex justify-content-center admin-card" style={{ height: 120 }} onMouseLeave={(e) => { e.currentTarget.style.background = '#ffffff'; e.currentTarget.className = ' flex admin-card justify-content-center '; e.target.style.cursor = 'pointer' }} onMouseOver={(e) => { e.preventDefault(); e.currentTarget.style.backgroundColor = '#31597510'; e.currentTarget.className = ' flex justify-content-center admin-card  box-shadow-one' }} onClick={() => { navigate.push({ pathname: '/quantitative_reporter_historic/' }) }} >
                                                    <div className="flex justify-content-between align-items-center " style={{ flexDirection: 'row' }}>
                                                        <div className="big-number-navy col-2 fs-36">{submittedQuantitative.filter(i => (i.type > 0 || (i.type === 0 && i.reject === 1))).length}</div>
                                                        <div className='col-10  grid justify-content-between'>
                                                            <div className="clr-navy   fs-18 text-bold">Historic</div>
                                                            <div className="flex align-items-center"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-12">
                                <div className="p-card">
                                    <label className="text-big-one clr-gray-900 flex fs-16 fw-7 p-2  flex"> Your Reviews </label>
                                    <div className="col-12 flex justify-content-evenly" style={{ borderTop: '1px solid #E0E0E0' }}>
                                        <div className="col-12 grid">
                                            <div className="col-4 flex justify-content-center" >
                                                <div className=" flex justify-content-center admin-card" style={{ height: 120 }} onMouseLeave={(e) => { e.currentTarget.style.background = '#ffffff'; e.currentTarget.className = ' flex admin-card justify-content-center '; e.target.style.cursor = 'pointer' }} onMouseOver={(e) => { e.preventDefault(); e.currentTarget.style.backgroundColor = '#31597510'; e.currentTarget.className = ' flex justify-content-center admin-card  box-shadow-one' }} onClick={() => { navigate.push({ pathname: '/quantitative_reviewer_overdue/' }) }} >
                                                    <div className="flex justify-content-between align-items-center " style={{ flexDirection: 'row' }}>
                                                        <div className="big-number-navy col-2 fs-36">{reviewQuantitative.filter(i => i.type === 1).length}</div>
                                                        <div className='col-10  grid justify-content-between'>
                                                            <div className="clr-navy   fs-18 text-bold">Quantitative</div>
                                                            <div className="flex align-items-center"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-4 flex justify-content-center" >
                                                <div className=" flex justify-content-center admin-card" style={{ height: 120 }} onMouseLeave={(e) => { e.currentTarget.style.background = '#ffffff'; e.currentTarget.className = ' flex admin-card justify-content-center '; e.target.style.cursor = 'pointer' }} onMouseOver={(e) => { e.preventDefault(); e.currentTarget.style.backgroundColor = '#31597510'; e.currentTarget.className = ' flex justify-content-center admin-card box-shadow-one' }} onClick={() => { }} >
                                                    <div className="flex justify-content-between align-items-center " style={{ flexDirection: 'row' }}>
                                                        <div className="big-number-navy col-2 fs-36">{0}</div>
                                                        <div className='col-10  grid justify-content-between'>
                                                            <div className="clr-navy   fs-18 text-bold">Qualitative</div>
                                                            <div className="flex align-items-center"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4 flex justify-content-center" >
                                                <div className=" flex justify-content-center admin-card" style={{ height: 120 }} onMouseLeave={(e) => { e.currentTarget.style.background = '#ffffff'; e.currentTarget.className = ' flex admin-card justify-content-center '; e.target.style.cursor = 'pointer' }} onMouseOver={(e) => { e.preventDefault(); e.currentTarget.style.backgroundColor = '#31597510'; e.currentTarget.className = ' flex justify-content-center admin-card  box-shadow-one' }} onClick={() => { navigate.push({ pathname: '/quantitative_reviewer_historic/' }) }} >
                                                    <div className="flex justify-content-between align-items-center " style={{ flexDirection: 'row' }}>
                                                        <div className="big-number-navy col-2 fs-36">{reviewQuantitative.filter(i => (i.type > 1 || (i.type === 1 && i.reject === 2))).length}</div>
                                                        <div className='col-10  grid justify-content-between'>
                                                            <div className="clr-navy   fs-18 text-bold">Historic</div>
                                                            <div className="flex align-items-center"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* <div className="col-12">
                                <div className="p-card">
                                    <label className="text-big-one clr-gray-900 flex fs-16 fw-7 p-2  flex"> Your Approvals </label>
                                    <div className="col-12 flex justify-content-evenly" style={{ borderTop: '1px solid #E0E0E0' }}>
                                        <div className="col-12 grid">
                                            <div className="col-4 flex justify-content-center" >
                                                <div className=" flex justify-content-center admin-card" style={{ height: 120 }} onMouseLeave={(e) => { e.currentTarget.style.background = '#ffffff'; e.currentTarget.className = ' flex admin-card justify-content-center '; e.target.style.cursor = 'pointer' }} onMouseOver={(e) => { e.preventDefault(); e.currentTarget.style.backgroundColor = '#31597510'; e.currentTarget.className = ' flex justify-content-center admin-card  box-shadow-one' }} onClick={() => { navigate.push({ pathname: '/quantitative_approver_overdue/' }) }} >
                                                    <div className="flex justify-content-between align-items-center " style={{ flexDirection: 'row' }}>
                                                        <div className="big-number-navy col-2 fs-36">{approveQuantitative.filter(i => i.type === 2).length}</div>
                                                        <div className='col-10  grid justify-content-between'>
                                                            <div className="clr-navy   fs-18 text-bold">Quantitative</div>
                                                            <div className="flex align-items-center"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-4 flex justify-content-center" >
                                                <div className=" flex justify-content-center admin-card" style={{ height: 120 }} onMouseLeave={(e) => { e.currentTarget.style.background = '#ffffff'; e.currentTarget.className = ' flex admin-card justify-content-center '; e.target.style.cursor = 'pointer' }} onMouseOver={(e) => { e.preventDefault(); e.currentTarget.style.backgroundColor = '#31597510'; e.currentTarget.className = ' flex justify-content-center admin-card box-shadow-one' }} onClick={() => { }} >
                                                    <div className="flex justify-content-between align-items-center " style={{ flexDirection: 'row' }}>
                                                        <div className="big-number-navy col-2 fs-36">{0}</div>
                                                        <div className='col-10  grid justify-content-between'>
                                                            <div className="clr-navy   fs-18 text-bold">Qualitative</div>
                                                            <div className="flex align-items-center"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4 flex justify-content-center" >
                                                <div className=" flex justify-content-center admin-card" style={{ height: 120 }} onMouseLeave={(e) => { e.currentTarget.style.background = '#ffffff'; e.currentTarget.className = ' flex admin-card justify-content-center '; e.target.style.cursor = 'pointer' }} onMouseOver={(e) => { e.preventDefault(); e.currentTarget.style.backgroundColor = '#31597510'; e.currentTarget.className = ' flex justify-content-center admin-card  box-shadow-one' }} onClick={() => { navigate.push({ pathname: '/quantitative_approver_historic/' }) }} >
                                                    <div className="flex justify-content-between align-items-center " style={{ flexDirection: 'row' }}>
                                                        <div className="big-number-navy col-2 fs-36">{approveQuantitative.filter(i => (i.type === 3)).length}</div>
                                                        <div className='col-10  grid justify-content-between'>
                                                            <div className="clr-navy   fs-18 text-bold">Historic</div>
                                                            <div className="flex align-items-center"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4 12H20M20 12L14 6M20 12L14 18" stroke="#315975" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div> */}
                        </div>
                    }
                </div>
            </div>
        </div>
    )

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(UserDashboard, comparisonFn);