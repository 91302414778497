import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const data = [
  {
    category: "Complaints",
    wages: 30,
    working_conditions: 40,
    discrimination: 20,
    harassment: 10,
  },
  {
    category: "Resolved",
    wages: 25,
    working_conditions: 35,
    discrimination: 15,
    harassment: 8,
  },
];

const LabourRightsComplaints = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!chartRef.current) return; // Prevent null ref errors
    renderChart();
  }, []);

  const renderChart = () => {
    const width = 600;
    const height = 400;
    const margin = { top: 50, right: 150, bottom: 100, left: 60 };

    // Clear previous chart
    d3.select(chartRef.current).select("svg").remove();

    // Create SVG canvas
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const keys = [
      "wages",
      "working_conditions",
      "discrimination",
      "harassment",
    ];

    // Stack data
    const stackedData = d3.stack().keys(keys)(data);

    // X and Y scales
    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.category))
      .range([0, chartWidth])
      .padding(0.4);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(stackedData[stackedData.length - 1], (d) => d[1])])
      .nice()
      .range([chartHeight, 0]);

    const color = d3.scaleOrdinal().domain(keys).range([
      "#cfe2f3", // Light blue
      "#6fa8dc", // Blue
      "#f9cb9c", // Peach
      "#e06666", // Red
    ]);

    // Add X-axis
    g.append("g")
      .attr("transform", `translate(0,${chartHeight})`)
      .call(d3.axisBottom(x));

    // Add Y-axis
    g.append("g").call(d3.axisLeft(y));

    // Add X-axis label
    g.append("text")
      .attr("x", chartWidth / 2)
      .attr("y", chartHeight + 40)
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .text("Category");

    // Add Y-axis label
    g.append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -chartHeight / 2)
      .attr("y", -40)
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .text("Number of Complaints");

    // Draw stacked bars
    g.selectAll("g.stack")
      .data(stackedData)
      .enter()
      .append("g")
      .attr("fill", (d) => color(d.key))
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("x", (d) => x(d.data.category))
      .attr("y", (d) => y(d[1]))
      .attr("height", (d) => y(d[0]) - y(d[1]))
      .attr("width", 60) // Bar width set to 60
      .append("title")
      .text((d, i) => `${keys[i % keys.length]}: ${d[1] - d[0]}`);

    // Add labels
    g.selectAll(".label")
      .data(stackedData.flatMap((layer) => layer))
      .enter()
      .append("text")
      .attr("class", "label")
      .attr("x", (d) => x(d.data.category) + 30) // Centering label on the bar
      .attr("y", (d) => y(d[1]) - 5)
      .attr("text-anchor", "middle")
      .style("font-size", "12px")
      .text((d) => d[1] - d[0]);

    // Legend
    const legend = svg
      .append("g")
      .attr(
        "transform",
        `translate(${chartWidth + margin.left + 20},${margin.top})`
      );

    keys.forEach((key, i) => {
      legend
        .append("rect")
        .attr("x", 0 - 50)
        .attr("y", i * 25 + 100)
        .attr("width", 15)
        .attr("height", 15)
        .attr("fill", color(key));

      legend
        .append("text")
        .attr("x", 20 - 50)
        .attr("y", i * 25 + 12 + 100)
        .style("font-size", "12px")
        .text(key.replace(/_/g, " "));
    });
  };

  return (
    <>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Complaints Related to Labour Rights and Resolution Status
      </h3>

      <div
        ref={chartRef}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      />
    </>
  );
};

export default LabourRightsComplaints;
