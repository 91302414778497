import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const AuditSunburst = () => {
  const chartRef = useRef(null);

  // Hardcoded data for the audit comparison
  const data = {
    name: "Audit Findings",
    color: "#a8d5ba", // Subtle light green base color
    children: [
      {
        name: "Site A",
        children: [
          { name: "Internal Audit", value: 75, color: "#a8d5ba" }, // Light Green
          { name: "External Audit", value: 85, color: "#f4b6b2" }, // Light Coral
        ],
        color: "#f4b6b2",
      },
      {
        name: "Site B",
        children: [
          { name: "Internal Audit", value: 65, color: "#a8d5ba" },
          { name: "External Audit", value: 80, color: "#f4b6b2" },
        ],
        color: "#a8d5ba",
      },
      {
        name: "Site C",
        children: [
          { name: "Internal Audit", value: 90, color: "#a8d5ba" },
          { name: "External Audit", value: 92, color: "#f4b6b2" },
        ],
        color: "#f4b6b2",
      },
    ],
  };

  useEffect(() => {
    renderChart();
  }, []);

  const renderChart = () => {
    const width = 600;
    const height = 600;

    // Remove any existing SVG for re-rendering
    d3.select(chartRef.current).select("svg").remove();

    // Create the SVG canvas
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2},${height / 2})`);

    // Define the partition layout and color scale
    const partition = d3
      .partition()
      .size([2 * Math.PI, Math.min(width, height) / 2]);
    const arc = d3
      .arc()
      .startAngle((d) => d.x0)
      .endAngle((d) => d.x1)
      .innerRadius((d) => d.y0)
      .outerRadius((d) => d.y1);

    // Create the root node
    const root = d3.hierarchy(data).sum((d) => d.value);

    // Apply the partition layout to the data
    partition(root);

    // Add the background circle to the center (this should be placed behind the sunburst)
    svg
      .append("circle")
      .attr("r", Math.min(width, height) / 4) // Adjust the radius of the center circle
      .attr("fill", "#d3d3d3") // Subtle light gray background
      .style("stroke", "#fff")
      .style("stroke-width", 1);

    // Draw the sunburst chart segments (above the center circle)
    svg
      .selectAll("path")
      .data(root.descendants())
      .enter()
      .append("path")
      .attr("d", arc)
      .attr("fill", (d) => d.data.color)
      .style("stroke", "#fff")
      .style("stroke-width", 1)
      .append("title")
      .text((d) => `${d.data.name}: ${d.data.value} score`);

    // Add labels to each segment
    svg
      .selectAll("text")
      .data(root.descendants())
      .enter()
      .append("text")
      .attr("transform", (d) => `translate(${arc.centroid(d)})`)
      .style("text-anchor", "middle")
      .style("font-size", "12px")
      .style("fill", "#333") // Darker text for better visibility
      .text((d) => d.data.name);
  };

  return (
    <>
      <h3 style={{ fontSize: "18px" }}>
        Comparison of Internal and External Audit Findings
      </h3>
      <div>
        Comparison of internal and external audit findings across different
        sites.
      </div>
      <div
        ref={chartRef}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      />
    </>
  );
};

export default AuditSunburst;
