import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const data = {
  name: "Departments",
  children: [
    {
      name: "HR",
      children: [
        { name: "Team A", value: 120 },
        { name: "Team B", value: 100 },
        { name: "Team C", value: 80 },
      ],
    },
    {
      name: "IT",
      children: [
        { name: "Team A", value: 150 },
        { name: "Team B", value: 130 },
        { name: "Team C", value: 90 },
      ],
    },
    {
      name: "Sales",
      children: [
        { name: "Team A", value: 200 },
        { name: "Team B", value: 180 },
        { name: "Team C", value: 160 },
      ],
    },
  ],
};

const TrainingHoursTreeChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!chartRef.current) return; // Prevent null ref errors
    renderChart();
  }, []);

  const renderChart = () => {
    const width = 800;
    const height = 500;
    const margin = { top: 20, right: 20, bottom: 20, left: 20 };

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const color = d3.scaleOrdinal(d3.schemeCategory10);

    // Treemap layout
    const treemap = d3
      .treemap()
      .size([
        width - margin.left - margin.right,
        height - margin.top - margin.bottom,
      ])
      .padding(1)
      .round(true);

    // Root node with data
    const root = d3.hierarchy(data).sum((d) => d.value);

    // Generate treemap
    treemap(root);

    // Create groups for each leaf node in the treemap
    const nodes = svg
      .selectAll("g")
      .data(root.leaves())
      .enter()
      .append("g")
      .attr(
        "transform",
        (d) => `translate(${d.x0 + margin.left},${d.y0 + margin.top})`
      );

    // Create rectangles for each node
    nodes
      .append("rect")
      .attr("width", (d) => d.x1 - d.x0)
      .attr("height", (d) => d.y1 - d.y0)
      .style("fill", (d) => color(d.parent.data.name))
      .style("stroke", "#fff")
      .style("stroke-width", 1);

    // Add data labels to each rectangle
    nodes
      .append("text")
      .attr("x", (d) => (d.x1 - d.x0) / 2)
      .attr("y", (d) => (d.y1 - d.y0) / 2)
      .attr("dy", ".35em")
      .attr("text-anchor", "middle")
      .style("font-size", "12px")
      .style("fill", "#fff")
      .text((d) => `${d.data.name}: ${d.data.value}`);

    // Tooltips to display exact value or percentage
    nodes
      .append("title")
      .text(
        (d) =>
          `${d.data.name}\nValue: ${d.data.value}\nPercentage: ${(
            (d.data.value / root.value) *
            100
          ).toFixed(1)}%`
      );
  };

  return (
    <>
      <h3 style={{ fontSize: "18px" }}>
        Training Hours by Department and Team
      </h3>
      <div>
        A tree chart showing the distribution of training hours across different
        departments and their teams. Hover to see the exact value and
        percentage.
      </div>
      <div
        ref={chartRef}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      />
    </>
  );
};

export default TrainingHoursTreeChart;
