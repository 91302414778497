import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const dummyData = [
  { stage: "Reported", value: 500 },
  { stage: "Reviewed", value: -50 },
  { stage: "Assigned", value: -100 },
  { stage: "Resolved", value: -250 },
  { stage: "Closed", value: -100 },
];

const IncidentResolutionWaterfallChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    renderWaterfallChart();
  }, []);

  const renderWaterfallChart = () => {
    const margin = { top: 50, right: 50, bottom: 50, left: 50 };
    const width = 600 - margin.left - margin.right;
    const height = 300 - margin.top - margin.bottom;

    // Clear any existing SVG
    d3.select(chartRef.current).select("svg").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Calculate cumulative values
    let cumulative = 0;
    const dataWithCumulative = dummyData.map((d) => {
      cumulative += d.value;
      return { ...d, cumulative };
    });

    // Define scales
    const xScale = d3
      .scaleBand()
      .domain(dummyData.map((d) => d.stage))
      .range([0, width])
      .padding(0.4);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(dataWithCumulative, (d) => d.cumulative)])
      .range([height, 0]);

    // Add X Axis
    svg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(xScale))
      .selectAll("text")
      .style("font-size", "12px");

    // Add Y Axis
    svg
      .append("g")
      .call(d3.axisLeft(yScale))
      .selectAll("text")
      .style("font-size", "12px");

    // Add bars
    svg
      .selectAll(".bar")
      .data(dataWithCumulative)
      .enter()
      .append("rect")
      .attr("x", (d) => xScale(d.stage))
      .attr("y", (d) =>
        d.value >= 0 ? yScale(d.cumulative) : yScale(d.cumulative - d.value)
      )
      .attr("width", xScale.bandwidth())
      .attr("height", (d) =>
        Math.abs(yScale(d.cumulative - d.value) - yScale(d.cumulative))
      )
      .attr("fill", (d) => (d.value >= 0 ? "#16a085" : "#e74c3c")); // Green for positive, Red for negative

    // Add labels
    svg
      .selectAll(".label")
      .data(dataWithCumulative)
      .enter()
      .append("text")
      .attr("x", (d) => xScale(d.stage) + xScale.bandwidth() / 2)
      .attr("y", (d) => yScale(d.cumulative) - 5)
      .attr("text-anchor", "middle")
      .text((d) => d.cumulative)
      .style("font-size", "12px")
      .style("fill", "#000000");
  };

  return (
    <div>
      <div
        style={{
          fontFamily: "Lato",
          fontSize: "18px",
          fontWeight: 700,
          lineHeight: "19.2px",
          textAlign: "left",
          margin: "25px",
        }}
      >
        Number of Incidents Reported and its status.
      </div>
      <div ref={chartRef} style={{ textAlign: "center" }} />

      {/* Legend */}
      <div
        style={{
          marginTop: "5px",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ fontSize: "14px", marginRight: "5px" }}>
          <span
            style={{
              display: "inline-block",
              width: "10px",
              height: "10px",
              backgroundColor: "#16a085",
              marginRight: "5px",
            }}
          ></span>
          Positive Change
        </div>
        <div style={{ fontSize: "14px" }}>
          <span
            style={{
              display: "inline-block",
              width: "10px",
              height: "10px",
              backgroundColor: "#e74c3c",
              marginRight: "5px",
            }}
          ></span>
          Negative Change
        </div>
      </div>
    </div>
  );
};

export default IncidentResolutionWaterfallChart;
