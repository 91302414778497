import React,{useEffect} from 'react';
import '../Styles/login.scss'
import Swal from 'sweetalert2';
import APIServices from '../service/APIService';
import { API } from '../constants/api_url';
import * as yup from "yup";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ErrorMessage, Formik, Form, Field } from "formik";
import { fetchClientList, fetchUserList } from "../RTK/Background/userProfileList";
import { fetchSiteList } from "../RTK/Background/siteList";
import { resetOverallPendingData, resetPPFData } from "../RTK/Background/pendingData";
import { useDispatch,useSelector } from 'react-redux';
import { fetchApi, resetLoggedUserDetail, setLoggedUserDetail } from "../RTK/Login/userProfile";
import { custompath } from '../constants/pathManagement';


const LoginNew = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const select = useSelector((state) => state.user.userdetail);
    const { from } = location.state || { from: { pathname: '/' } };
    useEffect(() => {
        if (Object.keys(select).length !== 0) {
            if (select.role === "clientuser" || select.role === "clientsupplier") {

                if (select.information.blocked === undefined || !select.information.blocked) {
                    dispatch(fetchUserList(select.clientId));
                    dispatch(fetchSiteList(select.clientId))
                    console.log(from)
                    if (from.pathname === '/') {
                        if (select.role === "clientsupplier") {
                            
                            props.history.push("/client_supplier/homescreen");
                        } else {
                            if(select.clientId === 28){
                                props.history.push("user/home");
                            }else{
                                props.history.push("client_user_new/homescreen");
                            }
                            
                            // props.history.push("/user_dashboard");

                        }
                    } else {
                        history.push(from)
                    }

                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Blocked",
                        text: "You have been blocked from platform, contact admin for futher details",
                        returnInputValueOnDeny: () => {
                            console.log("deny");
                        },
                    });
                    // localStorage.clear();
                    // dispatch(resetLoggedUserDetail());
                }
            }else if(select.role === "clientadmin" ){
                dispatch(fetchUserList(select.id));
                dispatch(fetchSiteList(select.id))
                props.history.push("client_user_new/homescreen");
            }
        }
    }, [select]);
    useEffect(() => {
        // console.log(DateTime.fromISO("2023-12-09T10:35:37.007Z",{zone:'utc'}).toLocal().toFormat('yyyy LLL dd HH mm'))
        // localStorage.clear();
        localStorage.removeItem('token')
        dispatch(resetPPFData([]));
        dispatch(resetOverallPendingData([]));
        dispatch(resetLoggedUserDetail());

    }, []);

    const handleLogin = (values) => {
       
        APIServices.post(API.Login, {
            email: values.email.trim(),
            password: values.password.trim(),
        })
            .then((response) => {
                if (response.status === 200) {
                    localStorage.setItem("token", response.data.token);
                    dispatch(fetchApi(response.data.token));
                    // dispatch(setLoggedUserDetail({ name: 'Gopi' }))
                } else {
                    localStorage.removeItem("token");
                }
            })
            .catch((err) => {
                Swal.fire({
                    position: "center",
                    icon: "warning",
                    title: `Invalid Credentials`,
                    showConfirmButton: false,
                    timer: 1500,
                });
            });
        // }
    };
    const handleForgotPassword = (values) => {

        Swal.fire({
            title: "Enter your login mail ID",
            input: "text",
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Request",
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
                return APIServices.post(API.ResetRequest, { email: login })
                    .then((response) => {
                        if (!response.statusText === 'OK') {

                            // throw new Error(response.error.message);
                        } else {
                            Swal.fire('Check your ' + login + ' inbox for reset link')
                        }


                    })
                    .catch((error) => {
                        console.log(error)
                        Swal.showValidationMessage(`Request failed: check given mail id is correct or contact admin if you issue persist `);
                    });
            },
            allowOutsideClick: () => !Swal.isLoading(),
        })
    };

    const validationsLogin = yup.object().shape({
        email: yup.string().email("Invalid Email ID").required("Email is mandatory"),
        password: yup.string().min(8, "Minimum 8 character required").required("Password is mandatory"),
        // role: yup
        //     .object().shape({ name: yup.string().required() })
        //     .required("Password is mandatory"),
        // companyid: yup
        //     .string()
        //     .min(1, "Enter Enterprise ID")
        //     .required("Enterprise ID is mandatory"),
    });
    return (
        <div className='col-12 flex justify-content-center align-items-center' style={{ background: `url(${require('../assets/images/newui/login_bg.png').default})`,backgroundSize:'cover' , marginTop: -10, height: '100vh' }}>
            <div className="login-container">
                <div className="image-container">
                    <img src={require("../assets/images/eisqr_logo_final_v1.png").default} alt="Login" className="login-image" />
                </div>
                <div className="form-container">
                    <h1 className="login-header">Login</h1>
                    <Formik initialValues={{}} onSubmit={handleLogin} validationSchema={validationsLogin}>
                        <Form  >

                            <div className="form-group">
                                <label form="email" className='fs-16 fw-5 form-label-clr-white' > Email ID </label>

                                <Field name="email" type="email" className="form-field" />

                                <ErrorMessage component="span" name="email" className="form-error" />
                            </div>

                            <div className="form-group">
                                <label   className='fs-16 fw-5 form-label-clr-white' form="email"> Password </label> <Field name="password" type="password" className="form-field"  />
                                <ErrorMessage component="span" name="password" className="form-error" />
                            </div>
                            <button type="submit" className="sign-in-button">Sign in</button>
                            <div style={{ display: "flex", justifyContent: "center", cursor: 'pointer' }} onClick={handleForgotPassword}>
                                <p className="forgot-password">Forgot username or password?</p>
                            </div>
                        

                        </Form>
                    </Formik>
                    {/* <div className="other-options">
                        <p className="no-account">Don't have an account?</p>
                        <p className="sign-up">SIGN UP</p>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default LoginNew;
