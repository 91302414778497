import axios from "axios";
import { baseurl } from "../constants/api_url";
import { DateTime } from "luxon";

const APIServices = axios.create({
    baseURL:baseurl,

    headers:{
        'Accept':'application/json',
'time-zone':DateTime.local().zoneName,
        'Content-Type':'application/json'
    }
})

export default APIServices