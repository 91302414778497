import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const data = [
  { department: "HR", team: "Recruitment", trainingCoverage: 80 },
  { department: "HR", team: "Payroll", trainingCoverage: 65 },
  { department: "IT", team: "Development", trainingCoverage: 90 },
  { department: "IT", team: "Support", trainingCoverage: 55 },
  { department: "Sales", team: "Field", trainingCoverage: 75 },
  { department: "Sales", team: "Corporate", trainingCoverage: 50 },
];

const TrainingCoverageMatrix = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!chartRef.current) return; // Prevent null ref errors
    renderChart();
    return () => d3.select(chartRef.current).select("svg").remove(); // Cleanup on re-render
  }, []);

  const renderChart = () => {
    const width = 600;
    const height = 400;
    const margin = { top: 40, right: 20, bottom: 100, left: 120 };

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.team))
      .range([margin.left, width - margin.right])
      .padding(0.05);

    const y = d3
      .scaleBand()
      .domain(data.map((d) => d.department))
      .range([margin.top, height - margin.bottom])
      .padding(0.05);

    const color = d3
      .scaleLinear()
      .domain([0, 100])
      .range(["#ffcccc", "#66ff66"]); // Red to Green scale

    // Append rectangles for the heatmap matrix
    svg
      .selectAll("rect")
      .data(data)
      .enter()
      .append("rect")
      .attr("x", (d) => x(d.team))
      .attr("y", (d) => y(d.department))
      .attr("width", x.bandwidth())
      .attr("height", y.bandwidth())
      .style("fill", (d) => color(d.trainingCoverage))
      .style("stroke", "#fff")
      .style("stroke-width", "1px")
      .append("title") // Tooltip
      .text((d) => `${d.trainingCoverage}%`);

    // Add X-axis
    svg
      .append("g")
      .attr("transform", `translate(0,${height - margin.bottom})`)
      .call(d3.axisBottom(x))
      .selectAll("text")
      .style("text-anchor", "end")
      .style("font-size", "12px");

    // Add Y-axis
    svg
      .append("g")
      .attr("transform", `translate(${margin.left},0)`)
      .call(d3.axisLeft(y))
      .selectAll("text")
      .style("font-size", "12px");

    // Add X-axis label
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", height - margin.bottom + 60)
      .attr("text-anchor", "middle")
      .style("font-size", "14px")
      .text("Teams");

    // Add Y-axis label
    svg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -height / 2)
      .attr("y", margin.left - 60)
      .attr("text-anchor", "middle")
      .style("font-size", "14px")
      .text("Departments");

    // Add Title
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", margin.top - 10)
      .attr("text-anchor", "middle")
      .style("font-size", "18px")
      .style("font-weight", "bold")
      .text("Training Coverage Matrix");
  };

  return (
    <div>
      <div
        ref={chartRef}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      />
    </div>
  );
};

export default TrainingCoverageMatrix;
