import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Label,
} from "recharts";

// Sample data for energy efficiency progress
const data = [
  {
    facility: "Hosur",
    progress: 50,
  },
  {
    facility: "Mysore",
    progress: 70,
  },
  {
    facility: "Nalgargh",
    progress: 90,
  },
];

const EnergyEfficiencyProgress = () => {
  return (
    <div style={{ width: 600, height: 400 }}>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Energy Efficiency Progress
      </h3>

      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="facility">
            <Label
              value="Facilities"
              position="insideLeft"
              style={{
                textAnchor: "middle",
                fill: "#333",
                fontSize: "14px",
              }}
            />
          </XAxis>
          <YAxis>
            <Label
              value="Percentage Reduction in Energy Consumption"
              angle={-90}
              position="insideLeft"
              style={{ textAnchor: "middle", fill: "#333", fontSize: "14px" }}
            />
          </YAxis>
          <Tooltip />

          <Bar dataKey="progress" barSize={60}>
            {data.map((entry, index) => {
              let fillColor;
              if (entry.progress >= 80) {
                fillColor = "#c8e6c9"; // Light Green for high progress (80% and above)
              } else if (entry.progress >= 60) {
                fillColor = "#dcedc8"; // Lighter Green for moderate progress (60%-79%)
              } else if (entry.progress >= 40) {
                fillColor = "#fff9c4"; // Light Yellow for moderate-low progress (40%-59%)
              } else if (entry.progress >= 20) {
                fillColor = "#ffe0b2"; // Light Orange for low progress (20%-39%)
              } else {
                fillColor = "#ffcdd2"; // Light Red for very low progress (below 20%)
              }
              return <Cell key={`cell-${index}`} fill={fillColor} />;
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default EnergyEfficiencyProgress;
