import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const data = {
  name: "Board",
  children: [
    {
      name: "Audit Committee",
      children: [
        { name: "Member 1", value: 1 },
        { name: "Member 2", value: 1 },
      ],
    },
    {
      name: "Nomination Committee",
      children: [
        { name: "Member 1", value: 1 },
        { name: "Member 2", value: 1 },
        { name: "Member 3", value: 1 },
      ],
    },
    {
      name: "Risk Committee",
      children: [
        { name: "Member 1", value: 1 },
        { name: "Member 2", value: 1 },
        { name: "Member 3", value: 1 },
      ],
    },
  ],
};

const GovernanceSunburst = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!chartRef.current) return; // Prevent null ref errors
    renderChart();
  }, []);

  const renderChart = () => {
    const width = 600;
    const height = 600;
    const radius = Math.min(width, height) / 2;

    // Clear any previous chart rendering
    d3.select(chartRef.current).select("svg").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2},${height / 2})`);

    // Subtle color scheme using pastel colors
    const color = d3
      .scaleOrdinal()
      .domain([
        "Board",
        "Audit Committee",
        "Nomination Committee",
        "Risk Committee",
        "Member",
      ])
      .range([
        "#d9eaf7",
        "#b5d4f4",
        "#92bdf0",
        "#6fa6ec",
        "#4d92e8", // Light blues for committees and board
        "#f9d6cc",
        "#f7b8a4",
        "#f59a7b",
        "#f37d53",
        "#f1602b", // Light corals for members
      ]);

    // Create partition layout
    const partition = d3
      .partition()
      .size([2 * Math.PI, radius])
      .padding(0.03);

    // Create arc generator
    const arc = d3
      .arc()
      .startAngle((d) => d.x0)
      .endAngle((d) => d.x1)
      .innerRadius((d) => d.y0)
      .outerRadius((d) => d.y1);

    // Flatten the data into a hierarchy and calculate size for each segment
    const root = d3.hierarchy(data).sum((d) => d.value);

    partition(root);

    // Draw the sunburst arcs
    svg
      .selectAll("path")
      .data(root.descendants())
      .enter()
      .append("path")
      .attr("d", arc)
      .attr("fill", (d) => color(d.depth))
      .attr("stroke", "#fff")
      .attr("stroke-width", 1)
      .attr("data-name", (d) => d.data.name)
      .attr("data-value", (d) => d.value)
      .on("mouseover", function (event, d) {
        const tooltip = d3.select("#tooltip");
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip
          .html(
            `<strong>${d.data.name}</strong><br/>Members: ${
              d.value
            }<br/>Committee: ${d.parent ? d.parent.data.name : "Board"}`
          )
          .style("left", `${event.pageX + 10}px`)
          .style("top", `${event.pageY - 10}px`);
      })
      .on("mouseout", function () {
        d3.select("#tooltip").transition().duration(200).style("opacity", 0);
      });

    // Append labels (for larger slices, or for important nodes)
    svg
      .selectAll("text")
      .data(root.descendants())
      .enter()
      .append("text")
      .attr("transform", (d) => `translate(${arc.centroid(d)})`)
      .attr("dy", "0.35em")
      .attr("text-anchor", "middle")
      .style("font-size", "12px")
      .style("fill", "#fff")
      .text((d) => (d.depth === 2 ? d.data.name : ""));

    // Tooltip div (for showing detailed info on hover)
    d3.select("body")
      .append("div")
      .attr("id", "tooltip")
      .style("position", "absolute")
      .style("background-color", "#f9f9f9")
      .style("border", "1px solid #ccc")
      .style("border-radius", "4px")
      .style("padding", "10px")
      .style("opacity", 0)
      .style("pointer-events", "none");
  };

  return (
    <>
      <h3 style={{ fontSize: "18px" }}>
        Board Composition and Governance Structure
      </h3>
      <div>
        This sunburst diagram visualizes the composition of the board and
        committees, including their members.
      </div>
      <div
        ref={chartRef}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      />
    </>
  );
};

export default GovernanceSunburst;
