import React from "react";
import { MapContainer, TileLayer, Marker, Popup, Polygon } from "react-leaflet";
import { useEffect, useState } from "react";

// Import Leaflet styles for the map to render correctly
import "leaflet/dist/leaflet.css";

const GeoSpatialMap = () => {
  // Define the map center and zoom level
  const mapCenter = [37.7749, -122.4194]; // Example: San Francisco coordinates
  const zoomLevel = 10;

  // Define the operational sites (as markers)
  const operationalSites = [
    { name: "Site 1", position: [37.7749, -122.4194] },
    { name: "Site 2", position: [37.7849, -122.4294] },
    { name: "Site 3", position: [37.7949, -122.4394] },
  ];

  // Define sensitive zones (polygons) - e.g., wetlands, biodiversity hotspots
  const sensitiveZones = [
    {
      name: "Wetland Area 1",
      coordinates: [
        [37.77, -122.41],
        [37.77, -122.42],
        [37.78, -122.42],
        [37.78, -122.41],
      ],
      color: "rgba(0, 128, 0, 0.4)", // Transparent green for wetlands
    },
    {
      name: "Biodiversity Hotspot",
      coordinates: [
        [37.79, -122.43],
        [37.79, -122.44],
        [37.8, -122.44],
        [37.8, -122.43],
      ],
      color: "rgba(255, 165, 0, 0.4)", // Transparent orange for biodiversity hotspots
    },
  ];

  return (
    <div style={{ width: 600, height: "400px" }}>
      <h3 style={{ margin: "25px", fontSize: "18px" }}>
        Operational Sites and Sensitive Zones
      </h3>
      <MapContainer
        center={mapCenter}
        zoom={zoomLevel}
        style={{ width: "100%", height: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        {/* Sensitive Zones Overlay */}
        {sensitiveZones.map((zone, index) => (
          <Polygon
            key={index}
            positions={zone.coordinates}
            color="green"
            fillColor={zone.color}
            fillOpacity={0.5}
          >
            <Popup>{zone.name}</Popup>
          </Polygon>
        ))}

        {/* Operational Sites Markers */}
        {operationalSites.map((site, index) => (
          <Marker key={index} position={site.position}>
            <Popup>{site.name}</Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default GeoSpatialMap;
