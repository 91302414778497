import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const LostTimeInjuryMatrix = () => {
  const chartRef = useRef(null);

  const data = [
    { site: "Site A", severity: "Minor", count: 5 },
    { site: "Site A", severity: "Moderate", count: 2 },
    { site: "Site A", severity: "Severe", count: 1 },
    { site: "Site B", severity: "Minor", count: 8 },
    { site: "Site B", severity: "Moderate", count: 4 },
    { site: "Site B", severity: "Severe", count: 3 },
    { site: "Site C", severity: "Minor", count: 6 },
    { site: "Site C", severity: "Moderate", count: 1 },
    { site: "Site C", severity: "Severe", count: 0 },
  ];

  useEffect(() => {
    renderChart();
  }, []);

  const renderChart = () => {
    const margin = { top: 40, right: 20, bottom: 40, left: 40 };
    const width = 600;
    const height = 400;

    // Remove any existing SVG for re-rendering
    d3.select(chartRef.current).select("svg").remove();

    // Create the SVG canvas
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const severities = ["Minor", "Moderate", "Severe"];
    const sites = ["Site A", "Site B", "Site C"];

    // Color scale
    const colorScale = d3
      .scaleSequential(d3.interpolateOranges)
      .domain([0, d3.max(data, (d) => d.count)]);

    // Create the X and Y scales
    const xScale = d3
      .scaleBand()
      .domain(severities)
      .range([0, width])
      .padding(0.05);

    const yScale = d3
      .scaleBand()
      .domain(sites)
      .range([0, height])
      .padding(0.05);

    // Append the axis
    svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(xScale));

    svg.append("g").attr("class", "y-axis").call(d3.axisLeft(yScale));

    // Create the cells in the matrix
    svg
      .selectAll("rect")
      .data(data)
      .enter()
      .append("rect")
      .attr("x", (d) => xScale(d.severity))
      .attr("y", (d) => yScale(d.site))
      .attr("width", xScale.bandwidth())
      .attr("height", yScale.bandwidth())
      .attr("fill", (d) => colorScale(d.count))
      .style("stroke", "#fff")
      .style("stroke-width", 1);

    // Add text labels for the count in each cell
    svg
      .selectAll("text")
      .data(data)
      .enter()
      .append("text")
      .attr("x", (d) => xScale(d.severity) + xScale.bandwidth() / 2)
      .attr("y", (d) => yScale(d.site) + yScale.bandwidth() / 2)
      .attr("dy", ".35em") // Vertically center the text
      .attr("text-anchor", "middle")
      .style("font-size", "12px")
      .style("fill", "#fff")
      .text((d) => d.count);

    // Title for the chart
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", -margin.top / 2)
      .attr("text-anchor", "middle")
      .style("font-size", "16px")
      .style("font-weight", "bold")
      .text("Lost Time Injury Matrix");
  };

  return (
    <div
      ref={chartRef}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20px",
      }}
    />
  );
};

export default LostTimeInjuryMatrix;
