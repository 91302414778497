import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as d3 from "d3";
import { Checkbox, FormControlLabel } from "@mui/material";
import APIServices from "../../../service/APIService";
import { API } from "../../../constants/api_url";

const Scope1EmissionsChart = () => {
  const chartRef = useRef(null);

  const emissionsData = [
    { category: "Stationary", year: 2023, emissions: 120 },
    { category: "Fugitive", year: 2023, emissions: 80 },
    { category: "Stationary", year: 2024, emissions: 150 },
    { category: "Fugitive", year: 2024, emissions: 100 },
  ];

  // const [emissionsData, setEmissionsData] = useState([]);

  // const admin_data = useSelector((state) => state.user.admindetail);

  const [visibleCategories, setVisibleCategories] = useState([
    "Stationary",
    "Fugitive",
  ]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await APIServices.get(API.QN_Submit_UP(admin_data.id));
  //       const apiData = response.data;

  //       // Transform the API response to match chart structure
  //       const transformedData = apiData.map((item) => {
  //         // Ensure reporting_period is a string before calling split
  //         const reportingPeriod = Array.isArray(item.reporting_period)
  //           ? item.reporting_period[0] // Assume you want to use the first element if it's an array
  //           : item.reporting_period;

  //         // Check if reportingPeriod is a valid string and has the expected format
  //         const year =
  //           typeof reportingPeriod === "string" && reportingPeriod.includes("-")
  //             ? parseInt(reportingPeriod.split("-")[1]) // Extract year from "YYYY-MM"
  //             : null; // Set to null if it's not a valid string or doesn't include '-'

  //         return {
  //           category: item.category || "Unknown", // Replace with actual field from API
  //           year: year, // Use the extracted year, or null if invalid
  //           emissions: item.response?.[0]?.value || 0, // Replace with actual field for emissions
  //         };
  //       });

  //       setEmissionsData(transformedData);
  //     } catch (error) {
  //       console.error("Error fetching emissions data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  useEffect(() => {
    renderChart();
  }, [visibleCategories]);

  const handleToggle = (category) => {
    setVisibleCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
  };

  const renderChart = () => {
    const width = 600;
    const height = 300;
    const margin = { top: 50, right: 50, bottom: 50, left: 80 };

    // Clear existing content
    d3.select(chartRef.current).selectAll("*").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    // Filter data based on visible categories and only include 2023 and 2024
    const filteredData = emissionsData.filter(
      (d) =>
        visibleCategories.includes(d.category) &&
        (d.year === 2023 || d.year === 2024)
    );

    const nestedData = d3.groups(filteredData, (d) => d.year);

    const stackData = nestedData.map(([year, data]) => ({
      year,
      Stationary: data.find((d) => d.category === "Stationary")?.emissions || 0,
      Fugitive: data.find((d) => d.category === "Fugitive")?.emissions || 0,
    }));

    const subcategories = ["Stationary", "Fugitive"];

    const stackGenerator = d3
      .stack()
      .keys(subcategories)
      .value((d, key) => (visibleCategories.includes(key) ? d[key] : 0));

    const series = stackGenerator(stackData);

    // Scales
    const xScale = d3
      .scaleBand()
      .domain(stackData.map((d) => d.year))
      .range([margin.left, width - margin.right])
      .padding(0.4);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(series, (s) => d3.max(s, (d) => d[1]))])
      .nice()
      .range([height - margin.bottom, margin.top]);

    const colorScale = d3
      .scaleOrdinal()
      .domain(subcategories)
      .range(["#9b59b6", "#8e44ad"]);

    // Axes
    svg
      .append("g")
      .attr("transform", `translate(0, ${height - margin.bottom})`)
      .call(d3.axisBottom(xScale).tickFormat((d) => `${d}`));

    svg.append("g").attr("transform", `translate(${margin.left}, 0)`).call(
      d3.axisLeft(yScale).ticks(6)
      // .tickFormat((d) => `${d} tCO2e`)
    );

    // Bars
    svg
      .append("g")
      .selectAll("g")
      .data(series)
      .enter()
      .append("g")
      .attr("fill", (d) => colorScale(d.key))
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("x", (d) => xScale(d.data.year) + (xScale.bandwidth() - 60) / 2)
      .attr("y", (d) => yScale(d[1]))
      .attr("height", (d) => yScale(d[0]) - yScale(d[1]))
      .attr("width", 60);

    // Axis labels
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", height - margin.bottom + 30)
      .attr("text-anchor", "middle")
      .text("Year");

    svg
      .append("text")
      .attr("x", -height / 2)
      .attr("y", 20)
      .attr("text-anchor", "middle")
      .attr("transform", "rotate(-90)")
      .text("Emissions in tCO2e");
  };

  return (
    <div>
      <div>
        <h3 style={{ margin: "25px", fontSize: "18px" }}>Scope 1 Emissions</h3>
        <p style={{ margin: "25px", fontSize: "14px" }}>
          Direct emissions from the operations that are owned or controlled.
        </p>
      </div>

      {/* Chart */}
      <div ref={chartRef} style={{ textAlign: "center" }}></div>

      {/* Legend with Material-UI Checkboxes */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        {["Stationary", "Fugitive"].map((category) => (
          <FormControlLabel
            key={category}
            control={
              <Checkbox
                checked={visibleCategories.includes(category)}
                onChange={() => handleToggle(category)}
                style={{ color: "#6c3483" }} // Purple checkbox color
              />
            }
            label={category}
          />
        ))}
      </div>
    </div>
  );
};

export default Scope1EmissionsChart;
