import React from "react";

const GreenInitiativeScorecard = () => {
  // Hardcoded project details
  const projectName = "Green Initiative";
  const impactArea = "Environmental Sustainability";
  const target = "Reduce emissions by 20%";
  const actualOutcome = "Achieved 18% reduction";
  const metrics = [
    { label: "Customer Satisfaction", value: "85%" },
    { label: "Sales Growth", value: "$1.2M" },
    { label: "Website Traffic", value: "50k visitors/month" },
  ];
  const qualitativeImpact =
    "Received positive community feedback and media coverage.";

  return (
    <div
      style={{
        border: "1px solid #ddd",
        borderRadius: "8px",
        padding: "20px",
        maxWidth: "800px",
        margin: "0 auto",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <h2
        style={{
          fontSize: "22px",
          textAlign: "center",
          marginBottom: "20px",
          color: "#333",
        }}
      >
        Scorecard Overview
      </h2>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 2fr",
          gap: "15px",
          marginBottom: "20px",
        }}
      >
        <strong style={{ fontSize: "16px" }}>Project Name:</strong>
        <span style={{ fontSize: "16px", color: "#555" }}>{projectName}</span>

        <strong style={{ fontSize: "16px" }}>Impact Area:</strong>
        <span style={{ fontSize: "16px", color: "#555" }}>{impactArea}</span>

        <strong style={{ fontSize: "16px" }}>Target:</strong>
        <span style={{ fontSize: "16px", color: "#555" }}>{target}</span>

        <strong style={{ fontSize: "16px" }}>Actual Outcome:</strong>
        <span style={{ fontSize: "16px", color: "#555" }}>{actualOutcome}</span>
      </div>

      <div style={{ marginBottom: "20px" }}>
        <h3 style={{ fontSize: "18px", marginBottom: "10px", color: "#333" }}>
          Metrics
        </h3>
        <ul style={{ paddingLeft: "20px", color: "#555", fontSize: "16px" }}>
          {metrics.map((metric, index) => (
            <li key={index}>
              <strong>{metric.label}:</strong> {metric.value}
            </li>
          ))}
        </ul>
      </div>

      <div>
        <h3 style={{ fontSize: "18px", marginBottom: "10px", color: "#333" }}>
          Qualitative Impact
        </h3>
        <p style={{ fontSize: "16px", color: "#555" }}>{qualitativeImpact}</p>
      </div>
    </div>
  );
};

export default GreenInitiativeScorecard;
