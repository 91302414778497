import React, { useState, useRef } from "react";

import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CustomLegend } from "./Dashboard";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
export const wasteData = {
  2019: [
    {
      month: "Jan",
      preparedReuse: 20,
      recycled: 30,
      otherRecovery: 10,
      incineration: 15,
      transferLandfill: 25,
      otherDisposal: 10,
      incinerationEnergy: 40,
      total: 150,
    },
    {
      month: "Feb",
      preparedReuse: 15,
      recycled: 25,
      otherRecovery: 12,
      incineration: 10,
      transferLandfill: 20,
      otherDisposal: 8,
      incinerationEnergy: 35,
      total: 145,
    },
    {
      month: "Mar",
      preparedReuse: 18,
      recycled: 28,
      otherRecovery: 15,
      incineration: 12,
      transferLandfill: 22,
      otherDisposal: 9,
      incinerationEnergy: 38,
      total: 162,
    },
    {
      month: "Apr",
      preparedReuse: 22,
      recycled: 30,
      otherRecovery: 11,
      incineration: 14,
      transferLandfill: 24,
      otherDisposal: 10,
      incinerationEnergy: 36,
      total: 167,
    },
    {
      month: "May",
      preparedReuse: 19,
      recycled: 27,
      otherRecovery: 14,
      incineration: 13,
      transferLandfill: 23,
      otherDisposal: 9,
      incinerationEnergy: 39,
      total: 165,
    },
    {
      month: "Jun",
      preparedReuse: 21,
      recycled: 32,
      otherRecovery: 12,
      incineration: 16,
      transferLandfill: 26,
      otherDisposal: 10,
      incinerationEnergy: 37,
      total: 170,
    },
    {
      month: "Jul",
      preparedReuse: 20,
      recycled: 31,
      otherRecovery: 13,
      incineration: 15,
      transferLandfill: 22,
      otherDisposal: 11,
      incinerationEnergy: 38,
      total: 170,
    },
    {
      month: "Aug",
      preparedReuse: 23,
      recycled: 34,
      otherRecovery: 10,
      incineration: 17,
      transferLandfill: 25,
      otherDisposal: 9,
      incinerationEnergy: 41,
      total: 179,
    },
    {
      month: "Sep",
      preparedReuse: 17,
      recycled: 29,
      otherRecovery: 14,
      incineration: 14,
      transferLandfill: 24,
      otherDisposal: 12,
      incinerationEnergy: 37,
      total: 167,
    },
    {
      month: "Oct",
      preparedReuse: 25,
      recycled: 33,
      otherRecovery: 15,
      incineration: 16,
      transferLandfill: 28,
      otherDisposal: 11,
      incinerationEnergy: 39,
      total: 182,
    },
    {
      month: "Nov",
      preparedReuse: 18,
      recycled: 26,
      otherRecovery: 13,
      incineration: 12,
      transferLandfill: 21,
      otherDisposal: 8,
      incinerationEnergy: 36,
      total: 154,
    },
    {
      month: "Dec",
      preparedReuse: 22,
      recycled: 30,
      otherRecovery: 11,
      incineration: 15,
      transferLandfill: 27,
      otherDisposal: 10,
      incinerationEnergy: 42,
      total: 167,
    },
  ],
  2020: [
    {
      month: "Jan",
      preparedReuse: 24,
      recycled: 36,
      otherRecovery: 12,
      incineration: 16,
      transferLandfill: 29,
      otherDisposal: 13,
      incinerationEnergy: 45,
      total: 175,
    },
    {
      month: "Feb",
      preparedReuse: 20,
      recycled: 33,
      otherRecovery: 11,
      incineration: 13,
      transferLandfill: 25,
      otherDisposal: 10,
      incinerationEnergy: 40,
      total: 152,
    },
    {
      month: "Mar",
      preparedReuse: 21,
      recycled: 31,
      otherRecovery: 14,
      incineration: 14,
      transferLandfill: 26,
      otherDisposal: 12,
      incinerationEnergy: 41,
      total: 170,
    },
    {
      month: "Apr",
      preparedReuse: 23,
      recycled: 28,
      otherRecovery: 13,
      incineration: 15,
      transferLandfill: 24,
      otherDisposal: 11,
      incinerationEnergy: 39,
      total: 162,
    },
    {
      month: "May",
      preparedReuse: 19,
      recycled: 29,
      otherRecovery: 16,
      incineration: 12,
      transferLandfill: 23,
      otherDisposal: 9,
      incinerationEnergy: 37,
      total: 165,
    },
    {
      month: "Jun",
      preparedReuse: 25,
      recycled: 32,
      otherRecovery: 12,
      incineration: 17,
      transferLandfill: 28,
      otherDisposal: 13,
      incinerationEnergy: 43,
      total: 180,
    },
    {
      month: "Jul",
      preparedReuse: 21,
      recycled: 30,
      otherRecovery: 13,
      incineration: 16,
      transferLandfill: 25,
      otherDisposal: 12,
      incinerationEnergy: 40,
      total: 165,
    },
    {
      month: "Aug",
      preparedReuse: 26,
      recycled: 34,
      otherRecovery: 15,
      incineration: 18,
      transferLandfill: 27,
      otherDisposal: 14,
      incinerationEnergy: 42,
      total: 176,
    },
    {
      month: "Sep",
      preparedReuse: 22,
      recycled: 30,
      otherRecovery: 14,
      incineration: 16,
      transferLandfill: 23,
      otherDisposal: 11,
      incinerationEnergy: 39,
      total: 168,
    },
    {
      month: "Oct",
      preparedReuse: 27,
      recycled: 35,
      otherRecovery: 13,
      incineration: 17,
      transferLandfill: 26,
      otherDisposal: 10,
      incinerationEnergy: 44,
      total: 182,
    },
    {
      month: "Nov",
      preparedReuse: 24,
      recycled: 32,
      otherRecovery: 15,
      incineration: 15,
      transferLandfill: 24,
      otherDisposal: 11,
      incinerationEnergy: 43,
      total: 176,
    },
    {
      month: "Dec",
      preparedReuse: 22,
      recycled: 31,
      otherRecovery: 14,
      incineration: 16,
      transferLandfill: 25,
      otherDisposal: 12,
      incinerationEnergy: 39,
      total: 171,
    },
  ],
  2021: [
    {
      month: "Jan",
      preparedReuse: 25,
      recycled: 34,
      otherRecovery: 12,
      incineration: 15,
      transferLandfill: 30,
      otherDisposal: 11,
      incinerationEnergy: 43,
      total: 170,
    },
    {
      month: "Feb",
      preparedReuse: 18,
      recycled: 29,
      otherRecovery: 14,
      incineration: 14,
      transferLandfill: 26,
      otherDisposal: 13,
      incinerationEnergy: 38,
      total: 152,
    },
    {
      month: "Mar",
      preparedReuse: 22,
      recycled: 33,
      otherRecovery: 11,
      incineration: 15,
      transferLandfill: 28,
      otherDisposal: 12,
      incinerationEnergy: 42,
      total: 163,
    },
    {
      month: "Apr",
      preparedReuse: 20,
      recycled: 32,
      otherRecovery: 10,
      incineration: 16,
      transferLandfill: 25,
      otherDisposal: 13,
      incinerationEnergy: 40,
      total: 156,
    },
    {
      month: "May",
      preparedReuse: 19,
      recycled: 27,
      otherRecovery: 15,
      incineration: 13,
      transferLandfill: 24,
      otherDisposal: 10,
      incinerationEnergy: 37,
      total: 145,
    },
    {
      month: "Jun",
      preparedReuse: 26,
      recycled: 30,
      otherRecovery: 14,
      incineration: 17,
      transferLandfill: 22,
      otherDisposal: 11,
      incinerationEnergy: 39,
      total: 159,
    },
    {
      month: "Jul",
      preparedReuse: 21,
      recycled: 35,
      otherRecovery: 13,
      incineration: 16,
      transferLandfill: 24,
      otherDisposal: 12,
      incinerationEnergy: 40,
      total: 161,
    },
    {
      month: "Aug",
      preparedReuse: 23,
      recycled: 29,
      otherRecovery: 12,
      incineration: 14,
      transferLandfill: 27,
      otherDisposal: 14,
      incinerationEnergy: 37,
      total: 156,
    },
    {
      month: "Sep",
      preparedReuse: 24,
      recycled: 32,
      otherRecovery: 15,
      incineration: 13,
      transferLandfill: 26,
      otherDisposal: 11,
      incinerationEnergy: 41,
      total: 162,
    },
    {
      month: "Oct",
      preparedReuse: 27,
      recycled: 34,
      otherRecovery: 12,
      incineration: 18,
      transferLandfill: 28,
      otherDisposal: 10,
      incinerationEnergy: 45,
      total: 174,
    },
    {
      month: "Nov",
      preparedReuse: 25,
      recycled: 31,
      otherRecovery: 14,
      incineration: 17,
      transferLandfill: 24,
      otherDisposal: 13,
      incinerationEnergy: 40,
      total: 164,
    },
    {
      month: "Dec",
      preparedReuse: 20,
      recycled: 33,
      otherRecovery: 11,
      incineration: 14,
      transferLandfill: 26,
      otherDisposal: 12,
      incinerationEnergy: 39,
      total: 155,
    },
  ],
  2022: [
    {
      month: "Jan",
      preparedReuse: 22,
      recycled: 28,
      otherRecovery: 13,
      incineration: 15,
      transferLandfill: 23,
      otherDisposal: 11,
      incinerationEnergy: 40,
      total: 152,
    },
    {
      month: "Feb",
      preparedReuse: 26,
      recycled: 30,
      otherRecovery: 10,
      incineration: 16,
      transferLandfill: 27,
      otherDisposal: 12,
      incinerationEnergy: 44,
      total: 165,
    },
    {
      month: "Mar",
      preparedReuse: 19,
      recycled: 34,
      otherRecovery: 15,
      incineration: 14,
      transferLandfill: 22,
      otherDisposal: 9,
      incinerationEnergy: 38,
      total: 151,
    },
    {
      month: "Apr",
      preparedReuse: 21,
      recycled: 29,
      otherRecovery: 13,
      incineration: 12,
      transferLandfill: 25,
      otherDisposal: 10,
      incinerationEnergy: 36,
      total: 146,
    },
    {
      month: "May",
      preparedReuse: 23,
      recycled: 27,
      otherRecovery: 11,
      incineration: 18,
      transferLandfill: 24,
      otherDisposal: 13,
      incinerationEnergy: 41,
      total: 157,
    },
    {
      month: "Jun",
      preparedReuse: 20,
      recycled: 35,
      otherRecovery: 12,
      incineration: 17,
      transferLandfill: 28,
      otherDisposal: 10,
      incinerationEnergy: 40,
      total: 162,
    },
    {
      month: "Jul",
      preparedReuse: 24,
      recycled: 32,
      otherRecovery: 14,
      incineration: 16,
      transferLandfill: 25,
      otherDisposal: 9,
      incinerationEnergy: 37,
      total: 157,
    },
    {
      month: "Aug",
      preparedReuse: 27,
      recycled: 34,
      otherRecovery: 15,
      incineration: 18,
      transferLandfill: 26,
      otherDisposal: 12,
      incinerationEnergy: 45,
      total: 177,
    },
    {
      month: "Sep",
      preparedReuse: 22,
      recycled: 30,
      otherRecovery: 10,
      incineration: 13,
      transferLandfill: 27,
      otherDisposal: 11,
      incinerationEnergy: 40,
      total: 153,
    },
    {
      month: "Oct",
      preparedReuse: 20,
      recycled: 31,
      otherRecovery: 14,
      incineration: 15,
      transferLandfill: 29,
      otherDisposal: 13,
      incinerationEnergy: 38,
      total: 160,
    },
    {
      month: "Nov",
      preparedReuse: 25,
      recycled: 30,
      otherRecovery: 15,
      incineration: 14,
      transferLandfill: 24,
      otherDisposal: 10,
      incinerationEnergy: 39,
      total: 157,
    },
    {
      month: "Dec",
      preparedReuse: 28,
      recycled: 35,
      otherRecovery: 12,
      incineration: 16,
      transferLandfill: 26,
      otherDisposal: 10,
      incinerationEnergy: 42,
      total: 169,
    },
  ],
  2023: [
    {
      month: "Jan",
      preparedReuse: 24,
      recycled: 31,
      otherRecovery: 12,
      incineration: 16,
      transferLandfill: 28,
      otherDisposal: 11,
      incinerationEnergy: 44,
      total: 166,
    },
    {
      month: "Feb",
      preparedReuse: 22,
      recycled: 29,
      otherRecovery: 10,
      incineration: 15,
      transferLandfill: 24,
      otherDisposal: 12,
      incinerationEnergy: 40,
      total: 152,
    },
    {
      month: "Mar",
      preparedReuse: 21,
      recycled: 32,
      otherRecovery: 15,
      incineration: 14,
      transferLandfill: 26,
      otherDisposal: 11,
      incinerationEnergy: 41,
      total: 160,
    },
    {
      month: "Apr",
      preparedReuse: 25,
      recycled: 34,
      otherRecovery: 12,
      incineration: 17,
      transferLandfill: 29,
      otherDisposal: 13,
      incinerationEnergy: 43,
      total: 173,
    },
    {
      month: "May",
      preparedReuse: 26,
      recycled: 33,
      otherRecovery: 14,
      incineration: 18,
      transferLandfill: 27,
      otherDisposal: 10,
      incinerationEnergy: 46,
      total: 174,
    },
    {
      month: "Jun",
      preparedReuse: 24,
      recycled: 30,
      otherRecovery: 13,
      incineration: 15,
      transferLandfill: 28,
      otherDisposal: 11,
      incinerationEnergy: 41,
      total: 162,
    },
    {
      month: "Jul",
      preparedReuse: 20,
      recycled: 31,
      otherRecovery: 15,
      incineration: 16,
      transferLandfill: 23,
      otherDisposal: 9,
      incinerationEnergy: 39,
      total: 153,
    },
    {
      month: "Aug",
      preparedReuse: 23,
      recycled: 33,
      otherRecovery: 11,
      incineration: 17,
      transferLandfill: 26,
      otherDisposal: 10,
      incinerationEnergy: 43,
      total: 163,
    },
    {
      month: "Sep",
      preparedReuse: 27,
      recycled: 35,
      otherRecovery: 10,
      incineration: 19,
      transferLandfill: 29,
      otherDisposal: 12,
      incinerationEnergy: 45,
      total: 177,
    },
    {
      month: "Oct",
      preparedReuse: 22,
      recycled: 30,
      otherRecovery: 14,
      incineration: 16,
      transferLandfill: 25,
      otherDisposal: 13,
      incinerationEnergy: 40,
      total: 160,
    },
    {
      month: "Nov",
      preparedReuse: 24,
      recycled: 28,
      otherRecovery: 12,
      incineration: 15,
      transferLandfill: 27,
      otherDisposal: 11,
      incinerationEnergy: 42,
      total: 159,
    },
    {
      month: "Dec",
      preparedReuse: 25,
      recycled: 36,
      otherRecovery: 13,
      incineration: 18,
      transferLandfill: 30,
      otherDisposal: 10,
      incinerationEnergy: 46,
      total: 178,
    },
  ],
};

const CustomWasteLegend = (props) => {
  const { payload } = props;
  return (
    <ul
      style={{
        display: "flex",
        listStyleType: "none",
        justifyContent: "center",
        padding: 0,
      }}
    >
      {payload.map(
        (entry, index) =>
          index <= 6 && (
            <li
              key={`item-${index}`}
              style={{ color: entry.color, marginRight: 20 }}
            >
              <span
                style={{
                  color: entry.color,
                  marginRight: 4,
                  fontSize: "20px",
                }}
              >
                ■
              </span>
              <span style={{ color: "#555", fontSize: "14px" }}>
                {entry.value}
              </span>
            </li>
          )
      )}
    </ul>
  );
};

export const WasteManagement = () => {
  const [activeMode, setActiveMode] = useState(true);
  const dt = useRef(null);
  const [year1, setYear1] = useState(2019);
  const [year2, setYear2] = useState(2020);

  // Combine data for the selected years
  const combinedData = wasteData[year1].map((item, index) => ({
    month: item.month,
    [`${year1} preparedReuse`]: item.preparedReuse,
    [`${year1} recycled`]: item.recycled,
    [`${year1} otherRecovery`]: item.otherRecovery,
    [`${year1} incineration`]: item.incineration,
    [`${year1} transferLandfill`]: item.transferLandfill,
    [`${year1} otherDisposal`]: item.otherRecovery,
    [`${year1} incinerationEnergy`]: item.otherRecovery,

    [`${year2} preparedReuse`]: wasteData[year2][index].preparedReuse,
    [`${year2} recycled`]: wasteData[year2][index].recycled,
    [`${year2} otherRecovery`]: wasteData[year2][index].otherRecovery,
    [`${year2} incineration`]: wasteData[year2][index].incineration,
    [`${year2} transferLandfill`]: wasteData[year2][index].transferLandfill,
    [`${year2} otherDisposal`]: wasteData[year2][index].otherDisposal,
    [`${year2} incinerationEnergy`]: wasteData[year2][index].incinerationEnergy,
  }));
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "19.2px",
            textAlign: "left",
            margin: "18px 10px 18px 10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          Hazardous waste by disposal modes
          <div
            style={{
              fontFamily: "Lato",
              fontSize: "12px",
              fontWeight: 300,
              lineHeight: "12.2px",
              textAlign: "left",
              margin: "18px 10px 18px 0px",
            }}
          >
            Quantity of Hazardous wastes recycled, reused, incinerated,
            transferred from landfill and other disposal modes.
          </div>
        </div>

        <div
          style={{
            margin: "18px 10px 18px 10px",
            display: "flex",
          }}
        >
          <select
            value={year1}
            onChange={(e) => {
              setYear1(e.target.value);
            }}
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "7.5rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          >
            {Object.keys(wasteData)?.map((i) => {
              return <option value={i}>{i}</option>;
            })}
          </select>
          <select
            value={year2}
            onChange={(e) => {
              setYear2(e.target.value);
            }}
            style={{
              padding: "3px",
              borderRadius: "8px",
              width: "7.5rem",
              border: "1px solid grey",
              height: "30px",
              fontFamily: "lato",
            }}
          >
            {Object.keys(wasteData)?.map((i) => {
              return <option value={i}>{i}</option>;
            })}
          </select>
          <div
            className="buttons"
            style={{
              background: "#F0F2F4",
              borderRadius: "3px",
              width: "4.5rem",
              marginLeft: "10px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: `${!activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
                marginRight: "4px",
              }}
              onClick={() => {
                setActiveMode(false);
              }}
            >
              <i className="pi pi-table fs-19 " />
            </Button>
            <Button
              style={{
                background: `${activeMode ? "#FFFFFF" : "transparent"}`,
                padding: "6px",
                color: "black",
                border: "0px",
              }}
              onClick={() => {
                setActiveMode(true);
              }}
            >
              <i className="pi pi-chart-bar fs-19" />
            </Button>
          </div>
          <Button
            style={{
              padding: "6px",
              color: "white",
              height: "30px",
              marginLeft: "2px",
            }}
            onClick={() => {
              setActiveMode(true);
            }}
          >
            <i className="pi pi-download fs-19" />
          </Button>
        </div>
      </div>
      {activeMode && (
        <ResponsiveContainer height={400} width="100%">
          <ComposedChart data={combinedData}>
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />

            {/* Stacked Bars */}
            <Bar
              dataKey={`${year1} preparedReuse`}
              stackId="a"
              fill="#00634F"
              name="Prepared for reuse"
              barSize={30}
            />
            <Bar
              dataKey={`${year1} recycled`}
              stackId="a"
              fill="#73C3B3"
              name="Recycled"
            />
            <Bar
              dataKey={`${year1} otherRecovery`}
              stackId="a"
              fill="#B0E0D7"
              name="Other recovery treatment"
            />
            <Bar
              dataKey={`${year1} incinerationEnergy`}
              stackId="a"
              fill="#EE5724"
              name="Incineration (With Energy)"
            />
            <Bar
              dataKey={`${year1} incineration`}
              stackId="a"
              fill="#FF9878"
              name="Incineration"
            />
            <Bar
              dataKey={`${year1} transferLandfill`}
              stackId="a"
              fill="#F9DF7F"
              name="Transfer to landfill"
            />
            <Bar
              dataKey={`${year1} otherDisposal`}
              stackId="a"
              fill="#F9DF7F"
              name="Other disposal treatments"
            />

            <Bar
              dataKey={`${year2} preparedReuse`}
              stackId="b"
              fill="#00634F"
              name="Prepared for reuse"
              barSize={30}
            />
            <Bar
              dataKey={`${year2} recycled`}
              stackId="b"
              fill="#73C3B3"
              name="Recycled"
            />
            <Bar
              dataKey={`${year2} otherRecovery`}
              stackId="b"
              fill="#B0E0D7"
              name="Other recovery treatment"
            />
            <Bar
              dataKey={`${year2} incinerationEnergy`}
              stackId="b"
              fill="#EE5724"
              name="Incineration (With Energy)"
            />
            <Bar
              dataKey={`${year2} incineration`}
              stackId="b"
              fill="#FF9878"
              name="Incineration"
            />
            <Bar
              dataKey={`${year2} transferLandfill`}
              stackId="b"
              fill="#F9DF7F"
              name="Transfer to landfill"
            />
            <Bar
              dataKey={`${year2} otherDisposal`}
              stackId="b"
              fill="#F9DF7F"
              name="Other disposal treatments"
            />

            <Legend content={CustomWasteLegend} />
          </ComposedChart>
        </ResponsiveContainer>
      )}
      {!activeMode && (
        <div>
          <DataTable
            ref={dt}
            value={wasteData[year2]}
            tableClassName="font-lato"
          >
            <Column
              header="Year"
              style={{ minWidth: "8%" }}
              field="year"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Prepared Re-Use"
              style={{ minWidth: "8%" }}
              field="preparedReuse"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Recycled"
              style={{ minWidth: "8%" }}
              field="recycled"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Other Recovery"
              style={{ minWidth: "8%" }}
              field="otherRecovery"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Incineration Energy"
              style={{ minWidth: "8%" }}
              field="incinerationEnergy"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Incineration"
              style={{ minWidth: "8%" }}
              field="incineration"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Transfer Land Fill"
              style={{ minWidth: "8%" }}
              field="transferLandfill"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Other Disposal"
              style={{ minWidth: "8%" }}
              field="otherDisposal"
              emptyMessage="No Assignment(s)"
            />
            <Column
              header="Total"
              style={{ minWidth: "8%" }}
              field="total"
              emptyMessage="No Assignment(s)"
            />
          </DataTable>
        </div>
      )}
    </>
  );
};
