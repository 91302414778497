import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const SocialImpact = () => {
  const chartRef = useRef(null);

  // Hardcoded data for the waterfall chart
  const data = [
    { category: "Baseline", value: 1000 },
    { category: "Community Projects", value: 300 },
    { category: "Environmental Programs", value: -200 },
    { category: "Employee Engagement", value: 150 },
    { category: "Net Impact", value: 1250 }, // Cumulative end value
  ];

  useEffect(() => {
    renderChart();
  }, []);

  const renderChart = () => {
    const width = 800;
    const height = 500;
    const margin = { top: 60, right: 40, bottom: 80, left: 80 };

    // Remove any existing SVG for re-rendering
    d3.select(chartRef.current).select("svg").remove();

    // Create SVG canvas
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Calculate cumulative values for the waterfall effect
    let cumulative = 0;
    const formattedData = data.map((d) => {
      const result = { ...d, start: cumulative };
      cumulative += d.value;
      result.end = cumulative;
      return result;
    });

    // Scales
    const xScale = d3
      .scaleBand()
      .domain(data.map((d) => d.category))
      .range([0, chartWidth])
      .padding(0.4);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(formattedData, (d) => Math.max(d.start, d.end))])
      .range([chartHeight, 0])
      .nice();

    // Colors for positive and negative values
    const colors = {
      positive: "#6fa8dc", // Light Blue
      negative: "#e06666", // Light Red
    };

    // Draw bars
    g.selectAll(".bar")
      .data(formattedData)
      .enter()
      .append("rect")
      .attr("x", (d) => xScale(d.category))
      .attr("y", (d) => yScale(Math.max(d.start, d.end)))
      .attr("height", (d) => Math.abs(yScale(d.start) - yScale(d.end)))
      .attr("width", xScale.bandwidth())
      .attr("fill", (d) => (d.value >= 0 ? colors.positive : colors.negative));

    // Add labels on top of bars
    g.selectAll(".label")
      .data(formattedData)
      .enter()
      .append("text")
      .attr("x", (d) => xScale(d.category) + xScale.bandwidth() / 2)
      .attr("y", (d) => yScale(d.end) - 5)
      .attr("text-anchor", "middle")
      .style("font-size", "12px")
      .style("fill", "#555")
      .text((d) => d.end);

    // Add X-axis
    g.append("g")
      .attr("transform", `translate(0,${chartHeight})`)
      .call(d3.axisBottom(xScale));

    // Add Y-axis
    g.append("g").call(d3.axisLeft(yScale));

    // Add X-axis label
    g.append("text")
      .attr("x", chartWidth / 2)
      .attr("y", chartHeight + 50)
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .text("Category");

    // Add Y-axis label
    g.append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -chartHeight / 2)
      .attr("y", -50)
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .text("Amount");

    // // Add chart title
    // svg
    //   .append("text")
    //   .attr("x", width / 2)
    //   .attr("y", margin.top / 2)
    //   .style("text-anchor", "middle")
    //   .style("font-size", "18px")
    //   .style("font-weight", "bold")
    //   .text("Understanding Social Impact");
  };

  return (
    <>
      <h3 style={{ fontSize: "18px" }}>Understanding Social Impact</h3>
      <div style={{ marginTop: "20px", color: "#555" }}>
        A breakdown of social impact by category, showing positive and negative
        contributions.
      </div>
      <div
        ref={chartRef}
        style={{ display: "flex", justifyContent: "center" }}
      ></div>
    </>
  );
};

export default SocialImpact;
