import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { ProgressBar } from "primereact/progressbar";
import { Accordion, AccordionTab } from 'primereact/accordion'
import $ from "jquery";
import { API } from "../../constants/api_url";
import { InputNumber } from "primereact/inputnumber";
import { ContextMenu } from 'primereact/contextmenu';
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from 'primereact/inputtextarea'
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Tag } from "primereact/tag";

import { Editor } from "primereact/editor";
import { InputSwitch } from "primereact/inputswitch";
import { Slider } from "primereact/slider";
import { BGSQ14, BGSQ15, BGSQ21, BGSQ24, BP1EQ2, BP4EQ2, BP7EQ1B, BP7EQ2, BP7LQ1, BP8EQ1, BP8LQ1, BP8LQ2, BP8LQ4, BP8LQ5, BP8LQ6, BP9LQ1, BP2LQ1, BP2LQ2, BP6EQ10, BP6EQ11, BP6EQ12, BP6LQ6, SGXGSQ7, SGXGSQ6, SGXGSQ4 } from "../../components/hardcoded/hardcodedRF";
import { hardcoded } from "../../components/hardcoded/hardcodedid";
import APIServices from "../../service/APIService";
import { AttachmentComponent } from "../../components/Forms/Attachment";
import { DateTime } from "luxon";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { filterAssignmentsByFiscalYear, getRPTextFormat, filterSubmissionsByFiscalYear } from "../../components/BGHF/helper";
import { ReadMoreComponent } from "../../components/Forms/ReadMoreComponent";
window.jQuery = $;
window.$ = $;

const QualitativeConsolidator = () => {

    const login_data = useSelector((state) => state.user.userdetail);
    const admin_data = useSelector((state) => state.user.admindetail);
    const { fymonth } = useSelector((state) => state.user.fyStartMonth);
    const [entitystatus, setEntityStatus] = useState(false)
    const [lock, setLock] = useState(false)

    const [data, setData] = useState({ data1: [] })
    const [response, setResponse] = useState(null)
    const [summary, setSummary] = useState([])
    const [consolidatedialog, setConsolidateDialog] = useState(false)
    const [params, setParams] = useState(JSON.parse(sessionStorage.getItem('dfconsol')).data)
    const [dfuserassignment, setDfUserAssignment] = useState([])
    const [sitelist, setSiteList] = useState([])
    const [submissions, setSubmissions] = useState([])
    const navigate = useHistory()
    const forceUpdate = useForceUpdate();

    const userList = useSelector(state => state.userlist.userList)
    const { id } = useParams();

    const [document, setDocument] = useState(null)
    // const hardcodedrf = ['85', '89', '102', '104', '110', '111', '112', '113', '114', '115', '116', '117', '118', '121', '133', '134', '139', '140','148','149','150','151','181','182','183']
    const [show, setShow] = useState(true)

    useEffect(async () => {

        let uriString = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }
        let site_url = API.LocationOne_UP(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`;


        const promise0 = APIServices.get(API.RF_Edit(params.dfId))
        const promise1 = APIServices.get(API.QL_Submit_UP(admin_data.id) + `?filter%5Bwhere%5D%5BdfId%5D=${params.dfId}`)
        const promise3 = APIServices.get(API.DF_Entity_UP(admin_data.id) + `?filter%5Bwhere%5D%5BdfId%5D=${params.dfId}`)
        const promise4 = APIServices.get(API.DF_Entity_User_UP(admin_data.id) + `?filter%5Bwhere%5D%5BdfId%5D=${params.dfId}`)
        const promise5 = APIServices.get(API.QL_Approval_UP(admin_data.id) + `?filter%5Bwhere%5D%5BdfId%5D=${params.dfId}`)
        const promise2 = APIServices.get(site_url)
        Promise.all([promise0, promise1, promise2, promise3, promise4, promise5]).then((values) => {
            let dfAssignment = values[3].data.length ? values[3].data[0] : { tier0_ids: [], tier1_ids: [], tier2_ids: [], tier3_ids: [] }
            setDfUserAssignment(values[4].data)
            const shapedSite = values[2].data
                .map((item) => {
                    if (item.locationTwos) {
                        item.locationTwos = item.locationTwos.filter(
                            (locationTwo) =>
                                locationTwo.locationThrees &&
                                locationTwo.locationThrees.length > 0
                        );
                    }
                    return item;
                })
                .filter((item) => item.locationTwos && item.locationTwos.length > 0);
            let entity_list = filterHierarchyByTierIds(shapedSite, dfAssignment.tier0_ids, dfAssignment.tier1_ids, dfAssignment.tier2_ids, dfAssignment.tier3_ids)

            let filteredResponse = filterSubmissionsByFiscalYear(values[1].data.reverse().filter(item => entity_list.some(entity => entity.level === item.level && entity.locationId === item.locationId)).map(i => ({ ...i, response: !hardcoded.rf.includes(params.dfId.toString()) ? migrateResponse(JSON.parse(JSON.stringify(values[0].data.data1)), i.response) : i.response })), parseFloat(params.year.split('-')[0]) + 1, fymonth)



            console.log(typeof params.dfId, filterSubmissionsByFiscalYear(values[1].data.reverse().filter(item => entity_list.some(entity => entity.level === item.level && entity.locationId === item.locationId)).map(i => ({ ...i, response: migrateResponse(JSON.parse(JSON.stringify(values[0].data.data1)), i.response) })), parseFloat(params.year.split('-')[0]) + 1, fymonth))
            setSubmissions(filteredResponse)
            let entity_list_ = JSON.parse(JSON.stringify(entity_list))
            if (!hardcoded.rf.includes(params.dfId.toString())) {
                entity_list_.forEach((i) => {
                    i.data = filteredResponse.filter(x => x.level === i.level && x.locationId === i.locationId)
                })
            } else {
                entity_list_.forEach((i) => {
                    i.data = filteredResponse.filter(x => x.level === i.level && x.locationId === i.locationId).map(z => ({ ...z, response: z.response.map((y) => ({ ...y, rp: getRPTextFormat(z.reporting_period) })) })).flatMap(g => g.response)
                })
            }

            let approvalFound = values[5].data.find(item => item.dfId === params.dfId && item.categoryId === params.cat_id && item.topicId === params.top_id && item.indicatorId === params.id && item.reporting_year === (parseFloat(params.year.split('-')[0]) + 1))
            if (approvalFound) {
                setLock(approvalFound.type === 1 ? true : false)
                setResponse(approvalFound)
            }
            setSummary(entity_list_)
            if (!hardcoded.rf.includes(params.dfId.toString())) {
                values[0].data.data1.forEach((i) => {
                    if (i.type !== 'paragraph') {
                        let entity_list_ = [...JSON.parse(JSON.stringify(entity_list)), { id: 0, data: [], name: 'Consolidated Response' }]
                        entity_list_.forEach((ent) => {

                            let findIndex = filteredResponse.filter(item => item.level === ent.level && item.locationId === ent.locationId)
                            console.log(ent, findIndex)
                            if (findIndex.length) {
                                let entityWiseQResponse = []
                                for (let index = 0; index < findIndex.length; index++) {
                                    let findIndex2 = findIndex[index].response.findIndex(x => x.name === i.name)

                                    if (findIndex2 !== -1) {
                                        entityWiseQResponse.push({ data: findIndex[index].response[findIndex2], modifiedOn: findIndex[index].last_modified_on, reportingPeriod: getRPTextFormat(findIndex[index].reporting_period) })


                                    } else {
                                        entityWiseQResponse.push({ data: { type: 'na', label: 'Data Point Not Found' }, modifiedOn: findIndex[index].last_modified_on, reportingPeriod: getRPTextFormat(findIndex[index].reporting_period) })

                                    }
                                }
                                console.log(entityWiseQResponse)
                                ent.data = entityWiseQResponse
                            } else if (ent.id === 0) {

                                if (approvalFound && approvalFound.response) {
                                    let response = migrateResponse(values[0].data.data1, approvalFound.response)
                                    console.log(response)
                                    let findIndex2 = response.findIndex(x => x.name === i.name)
                                    let entityWiseQResponse = []
                                    if (findIndex2 !== -1) {
                                        entityWiseQResponse.push({ data: response[findIndex2], modifiedOn: approvalFound.last_modified_on })


                                    } else {
                                        entityWiseQResponse.push({ data: { type: 'na', label: 'Data Point Not Found' }, modifiedOn: approvalFound.last_modified_on })

                                    }
                                    ent.data = entityWiseQResponse
                                } else {
                                    ent.data = [{ data: i }]
                                }


                            }

                        })
                        i.entity = entity_list_
                    }
                })
            } else {
                values[0].data.data1 = approvalFound.response
            }
            console.log(values[0].data, entity_list_)
            setData(values[0].data)
        })

    }, [admin_data]);
    useEffect(() => {
        console.log(data)
    }, [data])
    const getType = (item) => {
        if (item === 1) {
            return 'text'
        } else if (item === 2) {
            return 'textarea'
        } else if (item === 3) {
            return 'select'
        } else if (item === 4) {
            return 'checkbox-group'
        } else if (item === 5) {
            return 'number'
        } else if (item === 6) {
            return 'radio-group'
        } else if (item === 7) {
            return 'file'
        } else if (item === 'paragraph') {
            return 'paragraph'
        } else if (item === 9) {
            return 'date'
        }
    }
    function rpText(dates) {
        if (dates.length === 0) return "";

        const startDate = DateTime.fromFormat(dates[0], "MM-yyyy");
        const endDate = DateTime.fromFormat(dates[dates.length - 1], "MM-yyyy");

        const startMonthYear = startDate.toFormat('MMM-yyyy');
        const endMonthYear = endDate.toFormat('MMM-yyyy');

        return `${startMonthYear} to ${endMonthYear}`;
    }
    function filterHierarchyByTierIds(hierarchy, tier0Ids, tier1Ids, tier2Ids, tier3Ids) {
        let results = {
            tier0Results: [],
            tier1Results: [],
            tier2Results: [],
            tier3Results: []
        };
        if (tier0Ids.length != 0) {
            results.tier0Results.push({ id: 0, title: 'Corporate', level: 0, locationId: 0 })
        }
        hierarchy.forEach(level1 => {
            if (tier1Ids.includes(level1.id)) {
                level1.level = 1
                level1.locationId = level1.id
                results.tier1Results.push(level1);
            }

            level1.locationTwos.forEach(level2 => {
                if (tier2Ids.includes(level2.id)) {
                    level2.level = 2
                    level2.locationId = level2.id
                    results.tier2Results.push(level2);
                }

                level2.locationThrees.forEach(level3 => {
                    if (tier3Ids.includes(level3.id)) {
                        level3.level = 3
                        level3.locationId = level3.id
                        results.tier3Results.push(level3);
                    }
                });
            });
        });

        return [...results.tier0Results, ...results.tier1Results, ...results.tier2Results, ...results.tier3Results];

    }
    const questionSelected = (item, index) => {
        console.log(data)
        let loc = data
        loc.data1[index].selected = item.selected ? !item.selected : true
        setData(loc);
        forceUpdate()
    }
    const entitySelected = (item, index, ent, entindex) => {
        let loc = data
        loc.data1[index].entity.filter(i => i.data)[entindex].selected = loc.data1[index].entity.filter(i => i.data)[entindex].selected ? !loc.data1[index].entity.filter(i => i.data)[entindex].selected : true
        console.log(loc)
        setData(loc);
        forceUpdate()
    }
    const migrateResponse = (formData, oldData) => {

        formData.forEach((i) => {
            let index = oldData.findIndex((j) => { return getType(j.type) === i.type && i.name === j.name })
            if (index !== -1) {
                if (i.type !== 'checkbox-group' && i.type !== 'radio-group') {

                    i.value = oldData[index].value
                } else {

                    i.values.forEach((k, l) => {
                        k.selected = oldData[index].value.includes(k.label) || oldData[index].value.includes(l)
                    })
                }
            }
        })
        console.log(formData)
        return formData
    }
    const onCheckBoxSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = !items.selected
            }
        })
        forceUpdate()
    }
    const handleFileUpload = (e, item) => {
        let ext = e.target.files[0].name.substr(e.target.files[0].name.lastIndexOf('.'))
        let allowedext = ['.jpg', '.JPG', '.jpeg', '.JPEG', '.png', '.PNG', '.pdf', '.PDF']
        if (allowedext.includes(ext)) {
            let formData = new FormData()
            formData.append('file', e.target.files[0])
            APIServices.post(API.FilesUpload, formData, {
                headers: {
                    'content-type': 'multipart/form-data'

                }
            }).then((res) => {
                res.data.files[0].extension = ext
                if (item.value === undefined) {
                    item['value'] = [res.data.files[0]]
                } else {
                    if (item.multiple) {
                        item['value'].push(res.data.files[0])
                    } else {
                        item['value'] = [res.data.files[0]]
                    }

                }
                forceUpdate()

            })
        } else {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "invalid file format, supported format JPEG,PNG & PDF only",
                showConfirmButton: false,
                timer: 2000,
            });
        }
    }
    const renderItemsTwo = (item, index) => {


        if (item.type === 'checkbox-group') {

            return (
                <div className="flex flex-wrap  gap-3  grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 text-justify fs-16 fw-12'>Q{index + 1}.{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}
                        {item.description !== undefined && item.description.trim().length !== 0 && <i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i>} </label>
                    <div className="col-12">
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="flex text-justify fs-14 fw-5" style={{ marginBottom: 10 }}>
                                    <Checkbox disabled={lock} inputId={"cb" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => { onCheckBoxSelected(item, cbind) }} checked={cb.selected} />
                                    <label htmlFor={"cb" + index + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>

                </div>
            )
        } else if (item.type === 'date') {

            return (
                <div className="flex flex-wrap  gap-3  grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-12 fs-16 fw-5 text-justify'>Q{index + 1}. {item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}
                        {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>
                    <Calendar disabled={lock} placeholder={item.placeholder} className="col-5 fs-14 fw-4" value={(item.value !== null && item.value !== undefined) ? moment(item.value).toDate() : null} onChange={(e) => { onDateSelected(item, e.value) }} />
                </div>
            )
        } else if (item.type === 'number') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-12 fs-16 fw-5 text-justify'>Q{index + 1}.{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>
                    <div className="col-5 fs-14 fw-4" >
                        <InputNumber disabled={lock} min={0} placeholder={item.placeholder} maxFractionDigits={(item.fraction === undefined || item.fraction) ? 0 : item.fraction < 0 ? 0 : item.fraction} onWheel={(e) => e.target.blur()} keyfilter="num" style={{ width: '100%' }} value={item.value} onChange={(e) => { onNumberChange(item, e.value) }} />

                    </div>
                </div>
            )
        } else if (item.type === 'paragraph') {
            return (
                <div className="flex flex-wrap  gap-3 fs-16 fw-5 text-justify justify-content-center" style={{ padding: 10 }}>

                    <label >{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>} </label>

                </div>
            )
        } else if (item.type === 'radio-group') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-12 fs-16 fw-5 text-justify'>Q{index + 1}.{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>
                    <div className="col-12 grid" style={{ padding: 10 }} >
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="p-2 flex text-justify fs-14 fw-5 align-items-center" >
                                    <RadioButton disabled={lock} inputId={"rg" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => onRadioButtonSelected(item, cbind)} checked={cb.selected === true} />

                                    <label htmlFor={"rg" + index + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>

                </div>
            )
        } else if (item.type === 'select') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-12 fw-5 fs-16 text-justify'>Q{index + 1}.{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>


                    <div className="col-5 fw-4 fs-14">
                        <Dropdown disabled={lock} placeholder={item.placeholder} options={item.values} style={{ width: '100%' }} optionLabel='label' optionValue="value" value={item.value} onChange={(e) => { onChangeDropwdown(item, e.value) }} />
                    </div>

                </div>
            )
        } else if (item.type === 'text') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-12 fs-16 fw-5 text-justify'>Q{index + 1}.{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}


                        > help</i></span>} </label>
                    <div className="col-5 fs-14 fw-4" >
                        <InputText disabled={lock} style={{ width: '100%' }} value={item.value} placeholder={item.placeholder} onChange={(e) => { onNumberChange(item, e.target.value) }} />

                    </div>
                </div>
            )
        } else if (item.type === 'textarea') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-12 fs-16 fw-5 text-justify'>Q{index + 1}.{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}

                        > help</i></span>} </label>
                    <div className="col-12 " >
                    { lock ?  <div dangerouslySetInnerHTML={{ __html: item.value }} style={{ width: '100%', maxHeight: 350, overflow: 'auto' }} />

                      :  <Editor  className="text-area col-12" value={item.value} style={{ width: '100%', padding: 10, maxHeight: 350, height: 158, overflow: 'scroll' }} onTextChange={(e) => onNumberChange(item, e.htmlValue)} /> }

                    </div>

                </div>
            )
        } else if (item.type === 'file') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} />
                    <label style={{ display: 'flex' }} className='col-12 fw-5 fs-16 text-justify'>Q{index + 1}.{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.required && <span className="mandatory mr-2">*</span>}  {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={`${item.description}`}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>} </label>
                    <div className="col-12" >
                        {lock === false && <div style={{
                            background: '#f8f9fa',
                            border: '1px solid #ced4da',
                            borderRadius: '6px 6px 0px 0px',
                            padding: '8px'
                        }}>
                            <label htmlFor={'fp' + index} className="fs-14 clr-navy" style={{
                                marginRight: 10,
                                padding: '5px',

                                background: 'white',
                                border: '1px solid cornflowerblue',
                                borderRadius: '10px',

                            }} >
                                <i style={{ fontSize: 15, margin: 5 }} className="pi pi-folder-open clr-navy" />
                                Add Attachment
                            </label>
                            <label
                                onClick={() => { resetFiles(item, index) }}
                                style={{
                                    padding: '5px',
                                    fontSize: '15px',
                                    border: '1px solid indianred',
                                    background: 'white',
                                    borderRadius: '10px',
                                    color: 'indianred'
                                }} >
                                <i style={{ fontSize: 15, margin: 5 }} className="pi pi-undo" />
                                Reset
                            </label>
                            <input type='file' accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.pdf,.PDF,.xls,.xlsx,.ppt,.doc,.docx,.pptx" id={'fp' + index} hidden onChange={(e) => { handleFileUpload(e, item) }} ></input>
                        </div>}
                        {item.value !== undefined && item.value.length !== 0 &&
                            <div className="col-12" style={{
                                maxHeight: 300,
                                overflow: 'auto',
                                border: '1px solid #ced4da'
                            }} >
                                <div style={{
                                    border: '1px solid #6366F170',
                                    borderRadius: '10px'
                                }}>
                                    {item.value.map((file, findex) => {

                                        return (
                                            <>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center', margin: 5
                                                }} >
                                                    <div className="flex align-items-center " style={{ width: '60%' }}>
                                                        {(file.extension === '.pdf' || file.extension === '.PDF') ?
                                                            <div>
                                                                <iframe src={API.Docs + file.originalname} /> </div> :
                                                            <img alt={file.originalname} role="presentation" src={API.Docs + file.originalname} width={100} style={{ borderRadius: 10 }} />}
                                                        <span className="flex flex-column text-left ml-3">
                                                            {file.originalname}
                                                            <small>{new Date().toLocaleDateString()}</small>
                                                        </span>
                                                    </div>
                                                    <Tag value={'View'} onClick={() => { window.open(API.Docs + file.originalname) }} style={{ width: '20%' }} severity="warning" className="px-3 py-2" />
                                                    <Button type="button" icon="pi pi-times" style={{ marginRight: 10 }} className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => removeImage(index, findex)} />
                                                </div>

                                            </>
                                        )
                                    })

                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
        }

    }
    const removeImage = (index, findex) => {

        data.data1[index].value.splice(findex, 1)
        forceUpdate()

    }
    const resetFiles = (item, index) => {

        item.value = []
        forceUpdate()

    }
    const onRadioButtonSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = true
            } else {
                items.selected = false
            }
        })
        forceUpdate()
    }
    const onDateSelected = (item, val) => {

        item.value = val;
        forceUpdate()
    }
    const onNumberChange = (item, val, nan) => {
        console.log(item, val)

        item.value = val;


        forceUpdate()
    }

    const onChangeDropwdown = (item, val) => {
        item.value = val;
        console.log(val)
        item.values.forEach((i) => {
            if (i.label === val) {
                i.selected = true
            } else {
                i.selected = false
            }
        })
        forceUpdate()
    }
    const renderItems = (item, index, id, index2) => {
        console.log(item,id)

        if (item.type === 'checkbox-group') {

            return (
                <div className="p-card col-12 m-0 p-0" style={{ border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />

                    <div className="col-12">
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="flex text-justify fs-14 fw-5" style={{ marginBottom: 10 }}>
                                    <Checkbox disabled={id !== 0 || lock} inputId={"cb" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => { onCheckBoxSelected(item, cbind) }} checked={cb.selected} />
                                    <label htmlFor={"cb" + index + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>

                </div>
            )
        } else if (item.type === 'date') {

            return (
                <div className="p-card col-12 m-0 p-0" style={{ border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />

                    <Calendar disabled={id !== 0 || lock} placeholder={item.placeholder} className="col-12 fs-14 fw-4" value={(item.value !== null && item.value !== undefined) ? moment(item.value).toDate() : null} onChange={(e) => { onDateSelected(item, e.value) }} />
                </div>
            )
        } else if (item.type === 'number') {
            return (
                <div className="p-card col-12 m-0 p-0" style={{ border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />

                    <div className="col-12 fs-14 fw-4" >
                        <InputNumber disabled={id !== 0 || lock} min={0} placeholder={item.placeholder} maxFractionDigits={8} onWheel={(e) => e.target.blur()} keyfilter="num" style={{ width: '100%' }} value={item.value} onChange={(e) => { onNumberChange(item, e.value) }} />

                    </div>
                </div>
            )
        } else if (item.type === 'paragraph') {
            return (
                <div className="flex flex-wrap  gap-3 fs-16 fw-5 text-justify justify-content-center" style={{ padding: 10 }}>

                    <label >{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')}</label>

                </div>
            )
        } else if (item.type === 'radio-group') {
            return (
                <div className="p-card col-12 m-0 p-0" style={{ border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />

                    <div className="col-12 grid m-0" style={{ padding: 10 }} >
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="p-2 flex text-justify fs-14 fw-5 align-items-center" >
                                    <RadioButton disabled={id !== 0 || lock} inputId={"rg" + index + cbind} name={cb.label} value={cb.value} checked={cb.selected === true} onChange={(e) => onRadioButtonSelected(item, cbind, index2)} />

                                    <label htmlFor={"rg" + index + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>

                </div>
            )
        } else if (item.type === 'select') {
            return (
                <div className="p-card col-12 m-0 p-0" style={{ border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />



                    <div className="col-12 fw-4 fs-14">
                        <Dropdown disabled={id !== 0 || lock } placeholder={item.placeholder} options={item.values} style={{ width: '100%' }} optionLabel='label' optionValue="value" value={item.value} onChange={(e) => { onChangeDropwdown(item, e.value) }} />
                    </div>

                </div>
            )
        } else if (item.type === 'text') {
            return (
                <div className="p-card col-12 m-0 p-0" style={{ border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />

                    <div className="col-12 fs-14 fw-4" >
                        <InputText disabled={id !== 0 || lock} style={{ width: '100%' }} value={item.value} placeholder={item.placeholder} onChange={(e) => { onNumberChange(item, e.target.value) }} />

                    </div>
                </div>
            )
        } else if (item.type === 'textarea') {
            return (
                <div className="p-card col-12 m-0 p-0" style={{ border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />

                    <div className="col-12 " >

                        {item.value && id !== 0 && lock && <div dangerouslySetInnerHTML={{ __html: item.value }} style={{ width: '100%', maxHeight: 350, overflow: 'auto' }} />}
                        {id === 0 && !lock &&
                            <Editor className="text-area " value={item.value} style={{ width: '100%', padding: 10, maxHeight: 350, height: 158, overflow: 'scroll' }} onTextChange={(e) => onNumberChange(item, e.htmlValue)} />

                        }
                    </div>

                </div>
            )
        } else if (item.type === 'file') {
            return (
                <div className="p-card col-12 m-0 p-0" style={{ marginBottom: 15, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} />

                    <div className="col-12" >
                        {(id === 0) && !lock &&<div style={{
                            background: '#f8f9fa',
                            border: '1px solid #ced4da',
                            borderRadius: '6px 6px 0px 0px',
                            padding: '8px'
                        }}>
                            <label htmlFor={'fp' + index} className="fs-14 clr-navy" style={{
                                marginRight: 10,
                                padding: '5px',

                                background: 'white',
                                border: '1px solid cornflowerblue',
                                borderRadius: '10px',

                            }} >
                                <i style={{ fontSize: 15, margin: 5 }} className="pi pi-folder-open clr-navy" />
                                Add Attachment
                            </label>
                            <label
                                onClick={() => { resetFiles(item, index) }}
                                style={{
                                    padding: '5px',
                                    fontSize: '15px',
                                    border: '1px solid indianred',
                                    background: 'white',
                                    borderRadius: '10px',
                                    color: 'indianred'
                                }} >
                                <i style={{ fontSize: 15, margin: 5 }} className="pi pi-undo" />
                                Reset
                            </label>
                            <input type='file' accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.pdf,.PDF,.xls,.xlsx,.ppt,.doc,.docx,.pptx" id={'fp' + index} hidden onChange={(e) => { handleFileUpload(e, item) }} ></input>
                        </div>}
                        {item.value !== undefined && item.value !== null && item.value.length !== 0 &&
                            <div className="col-12" style={{
                                maxHeight: 300,
                                overflow: 'auto',
                                border: '1px solid #ced4da'
                            }} >
                                <div style={{
                                    border: '1px solid #6366F170',
                                    borderRadius: '10px'
                                }}>
                                    {item.value.map((file, findex) => {

                                        return (
                                            <>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center', margin: 5
                                                }} >
                                                    <div className="flex align-items-center " style={{ width: '60%' }}>
                                                        {(file.extension === '.pdf' || file.extension === '.PDF') ?
                                                            <div>
                                                                <iframe src={API.Docs + file.originalname} /> </div> :
                                                            <img alt={file.originalname} role="presentation" src={API.Docs + file.originalname} width={100} style={{ borderRadius: 10 }} />}
                                                        <span className="flex flex-column text-left ml-3">
                                                            {file.originalname}
                                                            <small>{new Date().toLocaleDateString()}</small>
                                                        </span>
                                                    </div>
                                                    <Tag value={'View'} onClick={() => { window.open(API.Docs + file.originalname) }} style={{ width: '20%' }} severity="warning" className="px-3 py-2" />
                                                    {(id === 0) && <Button type="button" icon="pi pi-times" style={{ marginRight: 10 }} className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => removeImage(index, findex)} />}
                                                </div>

                                            </>
                                        )
                                    })

                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
        }

    }
    const getPeriodsForAssignment = (startDate, endDate, frequency, fymonth, year) => {

        let start = DateTime.fromISO(startDate).toLocal();
        let fiscalYearStart = null
        if (fymonth === 1) {
            fiscalYearStart = DateTime.fromObject({ year, month: 1, day: 1 });
        } else {
            fiscalYearStart = DateTime.fromObject({ year: year - 1, month: fymonth, day: 1 });
        }
        // If start date is earlier than the fiscal year start, adjust it
        if (start < fiscalYearStart) {
            start = fiscalYearStart;
        }
        // Determine the current year
        const currentYear = DateTime.now().year;

        // If endDate is null, compute based on fiscal year and year condition
        if (!endDate) {


            // Set endDate based on fymonth and the adjusted endYear
            if (fymonth === 1) {
                endDate = DateTime.fromObject({ year, month: 12, day: 31 }); // Dec of the specified year
            } else {
                endDate = DateTime.fromObject({ year, month: fymonth - 1, day: 31 }); // Mar(fymonth-1)-year
            }
        }

        let end = DateTime.fromISO(endDate); // Now `end` is defined either from input or calculated

        let periods = [];

        while (start <= end) {
            let periodEnd = start.plus({ months: frequency - 1 });
            if (periodEnd > end) {
                periodEnd = end;
            }

            // Create a period string
            const period =
                frequency === 1
                    ? start.toFormat('MMM-yyyy')
                    : `${start.toFormat('MMM-yyyy')} to ${periodEnd.toFormat('MMM-yyyy')}`;
            periods.push(period);

            // Move to the next period
            start = start.plus({ months: frequency });
        }

        return periods;
    };
    const statusTemplate = (rowData) => {
        console.log(rowData)

        let totalAssignment = dfuserassignment.filter(i => i.locationId === rowData.locationId && i.level === rowData.level).map(i => getPeriodsForAssignment(i.start_date, i.end_date, i.frequency, fymonth, parseFloat(params.year.split('-')[0]) + 1)).flatMap(i => i)
        let totalSubmission = 0
        console.log(totalAssignment, dfuserassignment.filter(i => i.locationId === rowData.locationId && i.level === rowData.level), filterAssignmentsByFiscalYear(dfuserassignment.filter(i => i.locationId === rowData.locationId && i.level === rowData.level), parseFloat(params.year.split('-')[0]) + 1, fymonth))

        let text = 'No Response'
        if (totalAssignment.length !== 0) {
            totalSubmission = submissions.filter(i => { console.log(totalAssignment.includes(getRPTextFormat(i.reporting_period)), getRPTextFormat(i.reporting_period)); return i.locationId === rowData.locationId && i.level === rowData.level && totalAssignment.includes(getRPTextFormat(i.reporting_period)) }).length
        }
        return (<>{totalSubmission} / {totalAssignment.length}</>)
    }
    const getStatus = (rowData) => {
        console.log(rowData)

        let totalAssignment = dfuserassignment.filter(i => i.locationId === rowData.locationId && i.level === rowData.level).map(i => getPeriodsForAssignment(i.start_date, i.end_date, i.frequency, fymonth, parseFloat(params.year.split('-')[0]) + 1)).flatMap(i => i)
        let totalSubmission = 0
        console.log(totalAssignment, dfuserassignment.filter(i => i.locationId === rowData.locationId && i.level === rowData.level), filterAssignmentsByFiscalYear(dfuserassignment.filter(i => i.locationId === rowData.locationId && i.level === rowData.level), parseFloat(params.year.split('-')[0]) + 1, fymonth))

      
        if (totalAssignment.length !== 0) {
            totalSubmission = submissions.filter(i => { console.log(totalAssignment.includes(getRPTextFormat(i.reporting_period)), getRPTextFormat(i.reporting_period)); return i.locationId === rowData.locationId && i.level === rowData.level && totalAssignment.includes(getRPTextFormat(i.reporting_period)) }).length
        }
        return parseFloat(((totalSubmission / totalAssignment.length) * 100).toFixed(2))
    }
    const getSubmissionCount = (rowData) => {
        console.log(rowData)

        let totalAssignment = dfuserassignment.filter(i => i.locationId === rowData.locationId && i.level === rowData.level).map(i => getPeriodsForAssignment(i.start_date, i.end_date, i.frequency, fymonth, parseFloat(params.year.split('-')[0]) + 1)).flatMap(i => i)
        let totalSubmission = 0
       

      
        if (totalAssignment.length !== 0) {
            totalSubmission = submissions.filter(i => { console.log(totalAssignment.includes(getRPTextFormat(i.reporting_period)), getRPTextFormat(i.reporting_period)); return i.locationId === rowData.locationId && i.level === rowData.level && totalAssignment.includes(getRPTextFormat(i.reporting_period)) }).length
        }
        return  totalSubmission ?   (totalSubmission === totalAssignment.length) ? 'Complete response received' : 'Partial response received'  : 'No response received'
    }
    const reduceResponse = (item) => {
        if (item.type === 'text') {
            return { type: 1, value: item.value === undefined ? '' : item.value, name: item.name }
        } else if (item.type === 'textarea') {

            return { type: 2, value: item.value === undefined ? '<p></p>' : item.value, name: item.name }
        } else if (item.type === 'select') {

            return { type: 3, value: item.value === undefined ? null : item.value, name: item.name }
        } else if (item.type === 'checkbox-group') {
            return { type: 4, value: item.values.map((k, i) => { if (k.selected === true) { return k.value } }).filter((i) => { return i !== undefined }), name: item.name }
        } else if (item.type === 'number') {
            return { type: 5, value: item.value, name: item.name }
        } else if (item.type === 'radio-group') {
            return { type: 6, value: item.values.map((k, i) => { if (k.selected === true) { return k.value } }).filter((i) => { return i !== undefined }), name: item.name }
        } else if (item.type === 'file') {
            return { type: 7, value: item.value, name: item.name }
        } else if (item.type === 'paragraph') {
            return item
        } if (item.type === 'date') {
            return { type: 9, value: item.value, name: item.name }
        }
    }
    const respondenceTemplate = (rowData) => {

        let text = 'NA'
        if (rowData.data.length !== 0) {
            let index = userList.findIndex(i => i.id === rowData.data[0].last_modified_by)
            if (index !== -1) {
                text = userList[index].information.empname
            } else {
                text = 'Not Found'
            }
        }
        return (<>{text}</>)

    }
    const checkResponse = () => {
        let result = 0
        let total = data.data1.filter((i) => { return i.required === true }).length

        data.data1.forEach((item) => {


            if (item.type === 'checkbox-group' && item.required === true) {


                if (item.values.filter((i) => { return i.selected }).length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            } else if (item.type === 'date' && item.required) {

                if (item.value !== undefined && item.value !== null) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'number' && item.required) {

                if (item.value !== undefined && item.value !== null && parseFloat(item.value.toString()) >= 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            } else if (item.type === 'number' && item.required === false) {
                if (item.value !== undefined && item.value !== null) {
                    if (item.value === null || isNaN(item.value)) {
                        result = result + 1
                        item.error = 1
                    } else if (parseFloat(item.value.toString()) < 0) {
                        result = result + 1
                        item.error = 1
                    } else if (parseFloat(item.value.toString()) >= 0) {
                        item.error = 0
                    }
                }
            } else if (item.type === 'radio-group' && item.required) {

                if (item.values.filter((i) => { return i.selected }).length === 1) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'select' && item.required) {

                if (item.values.filter((i) => { return i.selected }).length === 1) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'text' && item.required) {

                if (item.value !== undefined && item.value !== null && item.value.trim().length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'textarea' && item.required) {

                if (item.value !== undefined && item.value !== null && item.value.trim().length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'file' && item.required) {
                if (item.value !== undefined && item.value !== null && item.value.length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }


        })

        return result === total
    }
    const saveResponse = () => {
        let newObj = {}
        newObj['categoryId'] = params.cat_id
        newObj['topicId'] = params.top_id
        newObj['indicatorId'] = params.id
        newObj['reporting_year'] = parseFloat(params.year.split('-')[0]) + 1
        newObj['response'] = hardcoded.rf.includes(params.dfId.toString()) ? data.data1 : data.data1.map((i) => reduceResponse(i))
        newObj['dfId'] = params.dfId


        
        newObj['type'] = 0

        newObj['last_modified_on'] = DateTime.utc()
        newObj['last_modified_by'] = login_data.id
        newObj['user_type'] = 1
        newObj['form_type'] = hardcoded.rf.includes(params.dfId.toString()) ? 1 : 2

        newObj['userProfileId'] = admin_data.id
        console.log(newObj)
        APIServices.post(API.QL_Approval_Custom, newObj).then((res) => {
            console.log(res)
            setResponse((prev)=>({...prev,type:0 }))
        })




    }
    const submitSubmission = () => {
        let newObj = {}
        if (checkResponse || checkHardcoded()) {
            newObj['categoryId'] = params.cat_id
            newObj['topicId'] = params.top_id
            newObj['indicatorId'] = params.id
            newObj['reporting_year'] = parseFloat(params.year.split('-')[0]) + 1
            newObj['response'] = checkHardcoded() ? data.data1 : data.data1.map((i) => reduceResponse(i))
            newObj['dfId'] = params.dfId


            newObj['edit'] = 0
            newObj['type'] = 1
            newObj['last_modified_on'] = DateTime.utc()
            newObj['last_modified_by'] = login_data.id
            newObj['user_type'] = 1
            newObj['form_type'] = hardcoded.rf.includes(params.dfId.toString()) ? 1 : 2

            newObj['userProfileId'] = admin_data.id
            APIServices.post(API.QL_Approval_Custom, newObj).then((res) => {
                console.log(res)
                setResponse((prev)=>({...prev,type:1,edit:0 }))
                setLock(newObj.type === 1 ? true : false)
            })
        }
    }

    const getPercentage = () => {

        let result = 0
        let total = data.data1.filter((i) => i.type !== 'paragraph').length

        data.data1.forEach((item) => {


            if (item.type === 'checkbox-group') {


                if (item.values.filter((i) => { return i.selected }).length !== 0) {
                    result = result + 1

                }
            } else if (item.type === 'date') {

                if (item.value !== undefined && item.value !== null) {
                    result = result + 1

                }
            }
            else if (item.type === 'number') {

                if (item.value !== undefined && item.value !== null && parseFloat(item.value.toString()) >= 0) {
                    result = result + 1

                } else {

                }
            } else if (item.type === 'number') {
                if (item.value !== undefined && item.value !== null) {
                    if (item.value === null || isNaN(item.value)) {
                        result = result + 1

                    } else if (parseFloat(item.value.toString()) < 0) {
                        result = result + 1

                    } else if (parseFloat(item.value.toString()) >= 0) {

                    }
                }
            } else if (item.type === 'radio-group') {

                if (item.values.filter((i) => { return i.selected }).length === 1) {
                    result = result + 1

                } else {

                }
            }
            else if (item.type === 'select') {

                if (item.values.filter((i) => { return i.selected }).length === 1) {
                    result = result + 1

                } else {

                }
            }
            else if (item.type === 'text') {

                if (item.value !== undefined && item.value !== null && item.value.trim().length !== 0) {
                    result = result + 1

                } else {

                }
            }
            else if (item.type === 'textarea') {

                if (item.value !== undefined && item.value !== null && item.value !== '<p></p>' && item.value.trim().length !== 0) {
                    result = result + 1

                } else {

                }
            }
            else if (item.type === 'file') {
                if (item.value !== undefined && item.value !== null && item.value.length !== 0) {
                    result = result + 1

                } else {

                }
            }


        })
        console.log(result, total)
        return parseFloat(((result / total) * 100).toFixed(2))

    }
    const checkHardcoded = () => {
        console.log(id)
        if (hardcoded.rf.includes(params.dfId.toString())) {
            return true
        } else {
            return false
        }

    }
    const displayTable = (id, data) => {
        if (id === 85) {
            return <BGSQ14 data={data} edit={0} setData={() => { forceUpdate() }} rp={true} />
        }else  if (id === 89) {
            return <BGSQ15 data={data} edit={0} setData={() => { forceUpdate() }} rp={true} />
        }else  if (id === 102) {
            return <BGSQ21 data={data} edit={0} setData={() => { forceUpdate() }} rp={true} />
        }else  if (id === 104) {
            return <BP1EQ2 data={data} edit={0} setData={() => { forceUpdate() }} rp={true} />
        }else  if (id === 110) {
            return <BGSQ24 data={data} edit={0} setData={() => { forceUpdate() }} rp={true} />
        }else  if (id === 111) {
            return <BP4EQ2 data={data} edit={0} setData={() => { forceUpdate() }} rp={true} />
        }else  if (id === 112) {
            return <BP7EQ2 data={data} edit={0} setData={() => { forceUpdate() }} rp={true} />
        }else  if (id === 113) {
            return <BP7LQ1 data={data} edit={0} setData={() => { forceUpdate() }} rp={true} />
        }else  if (id === 114) {
            return <BP8LQ1 data={data} edit={0} setData={() => { forceUpdate() }} rp={true} />
        }else  if (id === 115) {
            return <BP8LQ2 data={data} edit={0} setData={() => { forceUpdate() }} rp={true} />
        }else  if (id === 116) {
            return <BP8LQ4 data={data} edit={0} setData={() => { forceUpdate() }} rp={true} />
        }else  if (id === 117) {
            return <BP8LQ5 data={data} edit={0} setData={() => { forceUpdate() }} rp={true} />
        }else  if (id === 118) {
            return <BP8LQ6 data={data} edit={0} setData={() => { forceUpdate() }} rp={true} />
        }else  if (id === 121) {
            return <BP7EQ1B data={data} edit={0} setData={() => { forceUpdate() }} rp={true} />
        }else  if (id === 133) {
            return <BP8EQ1 data={data} edit={0} setData={() => { forceUpdate() }} rp={true} />
        }else  if (id === 134 ) {
            return <BP9LQ1 data={data} edit={0} setData={() => { forceUpdate() }} rp={true} />
        }else  if (id === 139) {
            return <BP2LQ1 data={data} edit={0} setData={() => { forceUpdate() }} rp={true} />
        }else  if (id === 140) {
            return <BP2LQ2 data={data} edit={0} setData={() => { forceUpdate() }} rp={true} />
        }else  if (id === 148) {
            return <BP6EQ10 data={data} edit={0} setData={() => { forceUpdate() }} rp={true} />
        }else  if (id === 149) {
            return <BP6EQ11 data={data} edit={0} setData={() => { forceUpdate() }} rp={true} />
        }else  if (id === 150) {
            return <BP6EQ12 data={data} edit={0} setData={() => { forceUpdate() }} rp={true} />
        }else  if (id === 151) {
            return <BP6LQ6 data={data} edit={0} setData={() => { forceUpdate() }} rp={true} />
        }else  if (id === 181) {
            return <SGXGSQ6 data={data} edit={0} setData={() => { forceUpdate() }} rp={true} />
        }else  if (id === 182) {
            return <SGXGSQ7 data={data} edit={0} setData={() => { forceUpdate() }} rp={true} />
        }else  if (id === 183) {
            return <SGXGSQ4 data={data} edit={0} setData={() => { forceUpdate() }} rp={true} />
        }
    }
    return (

        <div className="grid font-lato" style={{ margim: 10 }} >
            <div className="col-12">
                <div className="fs-20 fw-4  clr-gray-900">
                    <div style={{ padding: 20 }}> <div className='col-12' > Consolidated Response : <span className="fw-7 clr-navy">{data.title}</span> &nbsp; <small style={{ color: 'grey' }}>(DF {params.dfId})</small></div>

                        <div className='col-12' >         Category: <span className="clr-navy fw-7">{params.cat_title}  </span>  </div>
                        <div className="grid m-0 p-0 col-12 justify-content-between ">
                            <div className='col-6' >  Reporting Year :  <span className="fw-7 clr-navy">{params.year}</span>  </div>
                            <div className='col-6 flex justify-content-end align-items-baseline' > <span> Status : </span> <span className={response ? (response.type === 0 ? 'status-tag-gray ml-2' : response.type === 1 ? 'status-tag-green ml-2' : 'ml-2') : 'status-tag-orange ml-2'}>{response ? (response.type === 0  && !response.edit) ? 'Drafted' : response.type === 1 ? 'Submitted' : 'Unlocked For Resubmission' : 'Submission Pending'}</span>  </div>
                           


                        </div>
                    </div>



                </div>
                <div className="bg-white" style={{ padding: 24, borderBottom: '1px solid #E0E0E0' }}  >




                    <div className='col-12 p-0' > The sections below present qualitative inputs {!checkHardcoded() && `(organized by individual questions)`} submitted by various reporting entities as part of the Sustainability Program. As the consolidator, you can review these responses and summarize them in the "Consolidated Response" section. This summary will be included in the required reports. If no inputs are provided by the entities, you may directly enter the necessary details in the "Consolidated Response" section. Additionally, you can monitor the overall response rate from entities.</div>
                    <>
                        {/* <div className="col-12 grid p-0" style={{ margin: '10px 0px 0px 0px' }} >
                            <div className="col-4 fw-5 fs-16 p-0">% of consolidation completed</div>
                            <div className="col-8"> <ProgressBar onClick={() => { setEntityStatus(!entitystatus) }} value={getPercentage()}></ProgressBar></div>

                        </div> */}

                        <div className='col-12 p-0 m-0 mt-3'>
                            <Accordion>
                                <AccordionTab header={'Submission Rate By Entity'} >


                                    {summary.map((entity, index) => {
                                        return (
                                            <div>
                                                <div className="col-12 grid m-0 ">
                                                    <div className="col-4 fw-5 ">{entity.name}</div>
                                                    <div className="col-8"> <ProgressBar value={getStatus(entity)}></ProgressBar></div>

                                                </div>
                                                {summary.length !== index + 1 && <hr className="m-0" />}
                                            </div>




                                        )
                                    })}
                                </AccordionTab >
                            </Accordion>
                        </div>
                    </>
                    {/* <div className="grid col-12">
                        <div className="flex fs-16 col-4" >          <span className="clr-gray-3 fw-4">Summary :&nbsp;</span>  </div>

                        <DataTable className="auto-data-table-height fw-4 fs-16"

                            emptyMessage="No entity found."
                            scrollable value={summary}>
                            <Column header="Entity" field='name' />
                            <Column header="Response Status" body={statusTemplate} />

                        </DataTable>


                    </div> */}
                </div>
            </div>
            <div className="col-12">
                {data.data1 && data.data1.length !== 0 && !checkHardcoded(params.dfId.toString()) ?
                    <div className="bg-white" style={{ padding: 10 }} >
                        {data.data1.map((item, index) => {
                            if (item.type !== 'paragraph') {
                                return (
                                    <div>
                                        <div className="m-3 br-5" style={{ background: '#0F67B1', color: 'white' }} onClick={() => { questionSelected(item, index) }}>
                                            <div className="grid p-2 align-items-center" >
                                                <div className="col-11">

                                                    Q{index + 1}. {item.label.replace(/(<([^>]+)>)/gi, "")
                                                        .replace(/\n/g, " ")
                                                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')}
                                                </div>
                                                <div className="col-1 flex justify-content-center">
                                                    {item.selected ?
                                                        <i className="material-icons">arrow_drop_up</i>
                                                        :
                                                        <i className="material-icons">arrow_drop_down</i>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        {item.entity && item.selected &&
                                            <div className="m-2">
                                                {item.entity.filter(i => i.data).map((ent, entindex) => {
                                                    return (
                                                        <div>
                                                            <div className="m-3 br-5" style={{ background: ent.id === 0 ? '#005284' : '#3FA2F6', color: 'white' }} onClick={() => { entitySelected(item, index, ent, entindex) }}>
                                                                <div className="grid p-2 align-items-center fs-16 fw-7" >
                                                                    <div className="col-4 text-three-dot">
                                                                        {ent.name}
                                                                    </div>
                                                                    <div className="col-3">
                                                                    </div>
                                                                    <div className="col-4 text-bold">
                                                                         <small style={{ fontStyle: 'italic' }}>{getSubmissionCount(ent)}</small>

                                                                        {/* {ent.data && 'Last Updated :' + DateTime.fromISO(ent.data.last_modified_on, { zone: 'utc' }).toLocal().toFormat('dd/LLL/yyyy')} */}
                                                                    </div>

                                                                    <div className="col-1 flex justify-content-center">
                                                                        {ent.selected ?
                                                                            <i className="material-icons">arrow_drop_up</i>
                                                                            :
                                                                            <i className="material-icons">arrow_drop_down</i>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {ent.selected &&


                                                                <div >
                                                                    {(ent.data && ent.data.length !== 0) ?
                                                                        <div id='x' className='flex justify-content-center'>
                                                                            {ent.data.map((item, index2) => {

                                                                                return (
                                                                                    <div style={{ flex: '0 95%' }}>
                                                                                        {ent.id !== 0 && <div className="col-12 justify-content-between flex fw-5 grid m-0" style={{
                                                                                            padding: 10,
                                                                                            background: '#96C9F4',
                                                                                            fontSize: 16, borderRadius: '10px 10px 0px 0px',
                                                                                            fontWeight: 700
                                                                                        }}>
                                                                                            <div className="col-5 text-three-dot">{item.reportingPeriod}
                                                                                            </div>
                                                                                            <div className="col-2 flex justify-content-center"> {lock === false && <i className='pi pi-copy' onClick={() => { if (item.data.type === 'radio-group' || item.data.type === 'checkbox-group' || item.data.type === 'file' || item.data.type === 'select') { console.log(item.data); data.data1[index].values = JSON.parse(JSON.stringify(item.data.values)) } else { data.data1[index].value = JSON.parse(JSON.stringify(item.data.value)) }; console.log(data.data1[index], item.data); forceUpdate() }} />}
                                                                                            </div>
                                                                                            <div className="col-5 flex justify-content-end">
                                                                                                {'Last Updated :' + DateTime.fromISO(item.modifiedOn, { zone: 'utc' }).toLocal().toFormat('dd/LLL/yyyy')}
                                                                                            </div>

                                                                                        </div>}
                                                                                        {renderItems(item.data, index2, ent.id, index)}
                                                                                    </div>
                                                                                )
                                                                            })

                                                                            }

                                                                        </div> :
                                                                        <div id='y' className='flex justify-content-center'>
                                                                            <div style={{ flex: '0 95%' }}>
                                                                                {renderItems(item)}
                                                                            </div>
                                                                        </div>


                                                                    }
                                                                </div>

                                                            }
                                                        </div>
                                                    )
                                                })}

                                            </div>

                                        }
                                    </div>
                                )
                            }
                        })

                        }
                        <div className="m-3 br-5" style={{ background: '#005284', color: 'white' }} onClick={() => { setConsolidateDialog(true) }}>
                            <div className="grid p-2 align-items-center " >
                                <div className="col-12 fw-7">
                                    Consolidated Response
                                </div>                                    </div></div>
                    </div> :
                    <div className="bg-white" style={{ padding: 10 }} >
                        {summary.map(ent => {
                            return (
                                <div>
                                    <div className="m-3 br-5" style={{ background: ent.id === 0 ? '#005284' : 'gainsboro', color: ent.id === 0 && 'white' }} onClick={() => { ent.selected = ent.selected ? !ent.selected : true; forceUpdate() }}>
                                        <div className="grid p-2 align-items-center fs-16 fw-7" >
                                            <div className="col-4 text-three-dot">
                                                {ent.name}
                                            </div>
                                            <div className="col-3">
                                            </div>
                                            <div className="col-4 text-bold">
                                                 <small style={{ fontStyle: 'italic' }}>{getSubmissionCount(ent)}</small>

                                                {/* {ent.data && 'Last Updated :' + DateTime.fromISO(ent.data.last_modified_on, { zone: 'utc' }).toLocal().toFormat('dd/LLL/yyyy')} */}
                                            </div>

                                            <div className="col-1 flex justify-content-center">
                                                {ent.selected ?
                                                    <i className="material-icons">arrow_drop_up</i>
                                                    :
                                                    <i className="material-icons">arrow_drop_down</i>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    {ent.selected &&
                                        <div style={{ margin: 15 }}>
                                            {displayTable(params.dfId, ent.data)}
                                        </div>

                                    }

                                </div>

                            )

                        })

                        }
                        <div className="m-3 br-5" style={{ background: '#005284', color: 'white' }} onClick={() => { setConsolidateDialog(true) }}>
                            <div className="grid p-2 align-items-center" >
                                <div className="col-12 fw-7">
                                    Consolidated Response
                                </div>
                            </div>
                        </div>
                    </div>


                }


                <Dialog style={{ width: '85%' }} header={'Consolidated Response'} visible={consolidatedialog} onHide={() => { setConsolidateDialog(false) }}>
                    {data.data1 && data.data1.length !== 0 && !hardcoded.rf.includes(params.dfId.toString()) &&
                        data.data1.map((item, index) => {
                            return (<>
                                {renderItemsTwo(item, index)}
                                {data.data1.length - 1 !== index && <hr />}
                            </>)
                        })
                    }
                    {hardcoded.rf.includes(params.dfId.toString()) &&
                        <>
                            {params.dfId === 85 ?
                                <div>
                                    <BGSQ14 data={data.data1} rp={true}  edit={!lock ? 1 : 0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                </div>
                               : params.dfId ===  89 ?
                               <div>
                                   <BGSQ15 data={data.data1} rp={true}  edit={!lock ? 1 : 0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                               </div> : params.dfId ===  102 ?
                                   <div>
                                       <BGSQ21 data={data.data1} rp={true}  edit={!lock ? 1 : 0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                   </div> : params.dfId ===  104 ?
                                       <div>
                                           <BP1EQ2 data={data.data1} rp={true}  edit={!lock ? 1 : 0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                       </div> : params.dfId ===  110 ? <div>
                                           <BGSQ24 data={data.data1} rp={true}  edit={!lock ? 1 : 0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                       </div> : params.dfId ===  111 ? <div>
                                           <BP4EQ2 data={data.data1} rp={true}  edit={!lock ? 1 : 0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                       </div> : params.dfId ===  112 ? <div>
                                           <BP7EQ2 data={data.data1} rp={true}  edit={!lock ? 1 : 0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                       </div> : params.dfId ===  113 ? <div>
                                           <BP7LQ1 data={data.data1} rp={true}  edit={!lock ? 1 : 0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                       </div> : params.dfId ===  114 ? <div>
                                           <BP8LQ1 data={data.data1} rp={true}  edit={!lock ? 1 : 0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                       </div> : params.dfId ===  115 ? <div>
                                           <BP8LQ2 data={data.data1} rp={true}  edit={!lock ? 1 : 0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                       </div> : params.dfId ===  116 ? <div>
                                           <BP8LQ4 data={data.data1} rp={true}  edit={!lock ? 1 : 0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                       </div> : params.dfId ===  117 ? <div>
                                           <BP8LQ5 data={data.data1} rp={true}  edit={!lock ? 1 : 0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                       </div> : params.dfId ===  118 ? <div>
                                           <BP8LQ6 data={data.data1} rp={true}  edit={!lock ? 1 : 0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                       </div> : params.dfId ===  121 ? <div>
                                           <BP7EQ1B data={data.data1} rp={true}  edit={!lock ? 1 : 0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                       </div> : params.dfId ===  133 ? <div>
                                           <BP8EQ1 data={data.data1} rp={true}  edit={!lock ? 1 : 0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                       </div> : params.dfId ===  134 ? <div>
                                           <BP9LQ1 data={data.data1} rp={true}  edit={!lock ? 1 : 0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                       </div> : params.dfId ===  139 ? <div>
                                           <BP2LQ1 data={data.data1} rp={true}  edit={!lock ? 1 : 0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                       </div> : params.dfId ===  140 ? <div>
                                           <BP2LQ2 data={data.data1} rp={true}  edit={!lock ? 1 : 0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                       </div> : params.dfId ===  148 ? <div>
                                           <BP6EQ10 data={data.data1} rp={true}  edit={!lock ? 1 : 0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                       </div> : params.dfId ===  149 ? <div>
                                           <BP6EQ11 data={data.data1} rp={true}  edit={!lock ? 1 : 0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                       </div> : params.dfId ===  150 ? <div>
                                           <BP6EQ12 data={data.data1} rp={true}  edit={!lock ? 1 : 0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                       </div> : params.dfId ===  151 ? <div>
                                           <BP6LQ6 data={data.data1} rp={true}  edit={!lock ? 1 : 0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                       </div> : params.dfId ===  181 ? <div>
                                           <SGXGSQ6 data={data.data1} rp={true}  edit={!lock ? 1 : 0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                       </div> : params.dfId ===  182 ? <div>
                                           <SGXGSQ7 data={data.data1} rp={true}  edit={!lock ? 1 : 0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                       </div> : params.dfId ===  183 &&
                                       <div>
                                           <SGXGSQ4 data={data.data1} rp={true}  edit={!lock ? 1 : 0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                       </div>
                            }
                        </>

                    }
                    {!lock && <div className='flex justify-content-end'>

                        <Button label={'Save Response'} className={'mr-2'} onClick={() => { saveResponse() }} />
                        <Button className="mr-2" label={'Submit Response'} onClick={() => { submitSubmission() }} />
                    </div>}
                </Dialog>
            </div>
        </div>
    )

}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(QualitativeConsolidator, comparisonFn);