import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

// Sample data: Emissions breakdown by Scope with positive and negative values
const data = [
  { scope: "Scope 1", emissions: 100 },
  { scope: "Scope 2", emissions: -30 },
  { scope: "Scope 3", emissions: 50 },
  { scope: "Scope 4", emissions: -20 },
  { scope: "Scope 5", emissions: 40 },
];

const ScopeWaterfallChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    renderWaterfallChart();
  }, []);

  const renderWaterfallChart = () => {
    const margin = { top: 20, right: 30, bottom: 60, left: 60 };
    const width = 600 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    // Clear previous chart
    d3.select(chartRef.current).select("svg").remove();

    // Set up the SVG container
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Scales for X and Y axis
    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.scope))
      .range([0, width])
      .padding(0.4);

    const y = d3
      .scaleLinear()
      .domain([
        d3.min(data, (d) => (d.emissions < 0 ? d.emissions : 0)),
        d3.max(data, (d) => d.emissions),
      ])
      .nice()
      .range([height, 0]);

    // Add X-axis
    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x));

    // Add Y-axis
    svg.append("g").call(d3.axisLeft(y));

    // Calculate cumulative emissions for waterfall chart
    let cumulativeValue = 0;
    const bars = svg.selectAll(".bar").data(data).enter().append("g");

    bars
      .append("rect")
      .attr("x", (d) => x(d.scope))
      .attr("width", x.bandwidth())
      .attr("y", (d) =>
        y(Math.max(cumulativeValue, cumulativeValue + d.emissions))
      )
      .attr("height", (d) =>
        Math.abs(y(cumulativeValue) - y(cumulativeValue + d.emissions))
      )
      .attr("fill", (d) => (d.emissions < 0 ? "#ff9999" : "#99cc99")) // Softer colors for negative and positive
      .attr("stroke", "black");

    bars.each(function (d) {
      cumulativeValue += d.emissions;
      d3.select(this)
        .append("text")
        .attr("x", x(d.scope) + x.bandwidth() / 2)
        .attr("y", y(cumulativeValue) - 5)
        .attr("text-anchor", "middle")
        .text(cumulativeValue.toFixed(2));
    });

    // Add axis labels
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", height + 40)
      .attr("text-anchor", "middle")
      .text("Emission Scope");

    svg
      .append("text")
      .attr("x", -height / 2)
      .attr("y", -40)
      .attr("text-anchor", "middle")
      .attr("transform", "rotate(-90)")
      .text("Emissions (e.g., tons of CO2 equivalent)");
  };

  return (
    <div>
      <div>
        <h3 style={{ fontSize: "18px", margin: "25px" }}>
          Emissions Breakdown by Scope
        </h3>
      </div>
      <div ref={chartRef}></div>
    </div>
  );
};

export default ScopeWaterfallChart;
