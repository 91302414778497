import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { API } from "../constants/api_url";
import { PropTypes } from "prop-types";
import { useHistory } from "react-router-dom";
import APIServices from "../service/APIService";
import { Tag } from "primereact/tag";
import { Badge } from "primereact/badge";
import { getLocationData } from "../components/BGHF/helper";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { OverlayPanel } from "primereact/overlaypanel";
import { MultiSelect } from "primereact/multiselect";
import { useRef } from "react";
import { tvssection } from "../assets/tvs/js/tvssection";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import EnvironmentMetrics from "./EnvironmentMetrics";
import Tabs from "@mui/material/Tabs";
import { Tab } from "@mui/material";

import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
const { DateTime } = require("luxon");
export const TVSUserDashboard = () => {
  const dtref1 = useRef(null);
  const dtref2 = useRef(null);
  const dtref3 = useRef(null);
  const statusref = useRef(null);
  const login_data = useSelector((state) => state.user.userdetail);
  const admin_data = useSelector((state) => state.user.admindetail);
  const userList = useSelector((state) => state.userlist.userList);
  const allRoles = useSelector((state) => state.user.allRoles);
  const navigate = useHistory();
  const [refineddata, setRefinedData] = useState([]);
  const [refineddatabk, setRefinedDataBk] = useState([]);
  const [submittedQuantitative, setSubmittedQuantitative] = useState([]);
  const [reviewQuantitative, setReviewQuantitative] = useState([]);
  const [approveQuantitative, setApproveQuantitative] = useState([]);
  const [rawsitelist, setRawSitelist] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [past, setPast] = useState([]);
  const [submission, setSubmission] = useState([]);
  const [assignmentlist, setAssignmentList] = useState([]);
  const { fymonth } = useSelector((state) => state.user.fyStartMonth);
  const [data, setData] = useState([]);
  const [databk, setDataBk] = useState([]);
  const [dcflist, setDcfList] = useState([]);
  const [entityoption, setEntityOption] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activetab, setActiveTab] = useState(6);
  const [quantitativesubmission, setQuantitativeSubmission] = useState([]);
  const [apief, setApiEF] = useState([]);
  const [value, setValue] = useState("SUBMISSIONS");
  const [subcat, setSubCat] = useState({
    one: [],
    two: [],
    three: [],
    four: [],
  });

  const [locList, setLocList] = useState({
    country: [],
    city: [],
    location: [],
  });
  const [goalprops, setGoalProps] = useState({
    scope1_2: 0,
    waterData: [],
    locationData: [],
    scope3: 0,
    scope2Data: [],
    totalScope1: [],
    totalScope1Breakup: [],
  });

  const [section, setSection] = useState([
    { name: "Sustainability", id: 8 },
    { name: "Health", id: 9 },
    { name: "Safety Central", id: 10 },
    { name: "Safety Operational", id: 11 },
    { name: "Supply Chain", id: 12 },
    { name: "Dealership Sustainability", id: 13 },
    { name: "Environmental", id: 14 },
    { name: "Social", id: 15 },
    { name: "Governance", id: 16 },
  ]);
  const forceUpdate = useForceUpdate();
  const entityref = useRef(null);

  const getUser = (id) => {
    let user_name = "Not Found";
    let index = userList.findIndex((i) => i.id === id);
    if (index !== -1) {
      user_name = userList[index].information.empname;
    }
    return user_name;
  };

  const frequency_list = [
    { name: "Monthly", id: 1 },
    { name: "Bi-Monthly", id: 2 },
    { name: "Quartely", id: 3 },
    { name: "Annually", id: 4 },
    { name: "Bi-Annually", id: 5 },
    { name: "Undefined", id: 6 },
  ];
  const [filter, setFilter] = useState({
    year: null,
    country: 0,
    city: null,
    location: null,
  });

  const onFilterChange = (obj, val) => {
    setFilter((prev) => ({ ...prev, [obj]: val }));
  };

  const getDueMonth = (dateStr) => {
    const [from, to] = dateStr.split(" to ");
    if (to) {
      return to;
    }
    return from;
  };
  function getOverdueDays(monthString) {
    console.log(monthString);
    const [startMonth, endMonth] = monthString.split(" to ");

    const month = endMonth ? endMonth : startMonth;
    const [monthValue, year] = month.split("-");
    const endOfMonth = DateTime.fromObject({
      year: parseInt(year),
      month: DateTime.fromFormat(monthValue, "LLL").month,
    }).endOf("month");
    const currentDate = DateTime.local();
    console.log(month, endOfMonth.diff(currentDate, "days").days);
    return endOfMonth.diff(currentDate, "days").days;
  }
  function getFiscalYearsFromStartDate(start_date) {
    console.log(start_date);
    const startDate = DateTime.fromISO(start_date, { zone: "utc" }).toLocal()
    const currentDate = DateTime.now();

    let startFiscalYear, currentFiscalYear;
    const fiscalYears = [];

    if (fymonth === 1) {
      // When fiscal month is January, it's a single year
      startFiscalYear = startDate.year;
      currentFiscalYear = currentDate.year;

      for (let year = startFiscalYear; year <= currentFiscalYear; year++) {
        fiscalYears.push({ name: year, label: `${year}` });
      }
    } else {
      // Normal fiscal year spanning two calendar years
      startFiscalYear =
        startDate.month >= fymonth ? startDate.year : startDate.year - 1;
      currentFiscalYear =
        currentDate.month >= fymonth ? currentDate.year : currentDate.year - 1;

      for (let year = startFiscalYear; year <= currentFiscalYear; year++) {
        const label = `${year}-${(year + 1).toString().slice(-2)}`;
        fiscalYears.push({ name: year + 1, label });
      }

      // Include the current fiscal year only if the current month is before the fiscal year start month
      if (currentDate.month < fymonth) {
        fiscalYears.pop();
      }
    }

    return fiscalYears;
  }

  const filterSubmissionStatus = (
    shapedsite,
    quantitative_submitted,
    filtered_qn_ass,
    selectedYear,
    dcflist
  ) => {
    console.log(dcflist, "FindKeyById");

    let submissionStatus = [];
    let filteredAssignment = selectedYear === 0 ? filtered_qn_ass  :   filterAssignmentsByFiscalYear(
      filtered_qn_ass,
      selectedYear
    );
    console.log(filteredAssignment);
    if (filteredAssignment.length) {
      for (const item of filteredAssignment) {
        let months = getMonthsBetween(
          item.start_date,
          item.end_date,
          item.frequency === 4 ? 12 : item.frequency === 5 ? 6 : item.frequency,
          selectedYear,
          fymonth
        );
        console.log(months);
        let dcfIndex = dcflist.findIndex((i) => i.id === item.dcfId);
        let section = 0;
        if (dcfIndex !== -1) {
          section = dcflist[dcfIndex]?.section;
        }
     
          console.log(item, quantitative_submitted)
        
        for (const mn of months) {
         
     
          let index = quantitative_submitted.findIndex(
            (i) =>
              i.entityUserAssId === item.id &&  
              i.entityAssId === item.entityAssId &&
              i.dcfId === item.dcfId &&
              i.tier0_id === item.tier0_id &&
              i.tier1_id === item.tier1_id &&
              i.tier2_id === item.tier2_id &&
              i.tier3_id === item.tier3_id &&
              mn === getRPTextFormat(i.reporting_period)
          );
      
      if(item.id === 1858){
        console.log(index,item,mn, quantitative_submitted.findIndex(i=> i.entityUserAssId === item.id &&  
          i.entityAssId === item.entityAssId &&
          i.dcfId === item.dcfId &&
          i.tier0_id === item.tier0_id &&
          i.tier1_id === item.tier1_id &&
          i.tier2_id === item.tier2_id &&
          i.tier3_id === item.tier3_id &&
          mn === getRPTextFormat(i.reporting_period))  )
      }
          if (index !== -1) {
       
            let type = quantitative_submitted[index].type;
            let reject = quantitative_submitted[index].reject;
            let status =
              type === 0 && (reject === 0 || reject === null)
                ? 0
                : type === 0 && (reject === 1 || reject === 2)
                ? 1
                : type === 1 && reject === 1
                ? 2
                : type === 1
                ? 3
                : type === 2
                ? 4
                : type === 3
                ? 5
                : null;
            if (status === 0 || status === 1) {
              status =
              getOverdueDays(mn) >= 0
              ? 99
              : getOverdueDays(mn) >= -10
              ? 6 : getOverdueDays(mn) >= -15 ? 9 
              : 7;
            }
            
            submissionStatus.push({
              ...item, entityUserAssId: item.id,self: (item.reviewer_ids && item.reviewer_ids.length )? false : true,
              dueMonth: getDueMonth(mn),
              reporting_period: mn,
              data: quantitative_submitted[index],
              status,
              coverage: getCoverageText(item, shapedsite),
              section,
            });
          } else {
            console.log( item.dcfId,item , mn)
            let status =
              getOverdueDays(mn) >= 0
                ? 100
                : getOverdueDays(mn) >= -10
                ? 6 : getOverdueDays(mn) >= -15 ? 9  
                : 7;

            submissionStatus.push({
              ...item,entityUserAssId: item.id, id:undefined,self: (item.reviewer_ids && item.reviewer_ids.length )? false : true,  
              dueMonth: getDueMonth(mn),
              reporting_period: mn,
              status,
              coverage: getCoverageText(item, shapedsite),
              section,
            });
          }
        }
      }
    }
    return submissionStatus;
  };
  const getRPTextFormat = (item) => {
    if (item.length !== 0) {
      if (item.length >= 2) {
        const startDate = DateTime.fromFormat(item[0], "MM-yyyy").toFormat(
          "LLL-yyyy"
        );
        const endDate = DateTime.fromFormat(
          item[item.length - 1],
          "MM-yyyy"
        ).toFormat("LLL-yyyy");
        return `${startDate} to ${endDate}`;
      } else {
        return DateTime.fromFormat(item[0], "MM-yyyy").toFormat("LLL-yyyy");
      }
    }
  };

  function getFiscalYearsFromStartDate(start_date) {
    console.log(start_date);
    const startDate = DateTime.fromISO(start_date, { zone: "utc" }).plus({
      months: 1,
    });
    const currentDate = DateTime.now();

    let startFiscalYear, currentFiscalYear;
    const fiscalYears = [];

    if (fymonth === 1) {
      // When fiscal month is January, it's a single year
      startFiscalYear = startDate.year;
      currentFiscalYear = currentDate.year;

      for (let year = startFiscalYear; year <= currentFiscalYear; year++) {
        fiscalYears.push({ name: year, label: `${year}` });
      }
    } else {
      // Normal fiscal year spanning two calendar years
      startFiscalYear =
        startDate.month >= fymonth ? startDate.year : startDate.year - 1;
      currentFiscalYear =
        currentDate.month >= fymonth ? currentDate.year : currentDate.year - 1;

      for (let year = startFiscalYear; year <= currentFiscalYear; year++) {
        const label = `${year}-${(year + 1).toString().slice(-2)}`;
        fiscalYears.push({ name: year + 1, label });
      }

      // Include the current fiscal year only if the current month is before the fiscal year start month
      if (currentDate.month < fymonth) {
        fiscalYears.pop();
      }
    }

    return fiscalYears;
  }
  const filterAssignmentsByFiscalYear = (assignments, year) => {
    const { startDate, endDate } = getFiscalYearRange(year, fymonth);
    const currentDate = DateTime.local().startOf("day");

    return assignments.filter((assignment) => {
      console.log(assignment);
      const assignmentStartDate = assignment.start_date
        ? DateTime.fromISO(assignment.start_date, { zone: "utc" }).startOf(
            "day"
          )
        : currentDate;
      const assignmentEndDate = assignment.end_date
        ? DateTime.fromISO(assignment.end_date, { zone: "utc" }).startOf("day")
        : currentDate;

      return (
        (assignmentStartDate >= startDate && assignmentStartDate <= endDate) ||
        (assignmentEndDate >= startDate && assignmentEndDate <= endDate) ||
        (assignmentStartDate <= startDate && assignmentEndDate >= endDate)
      );
    });
  };

  const findIndexByDate = (array, dateToFind) => {
    const targetDate = DateTime.fromFormat(dateToFind, "MM-yyyy");

    if (array.length === 1) {
      return 0; // If array length is 1, return index 0
    }

    let foundIndex = -1;

    // Check for an object with both start and end not null and matching the passed date
    const matchedObject = array.find((obj) => {
      const startDate = DateTime.fromISO(obj.start);
      const endDate = DateTime.fromISO(obj.end);

      return startDate <= targetDate && targetDate <= endDate;
    });

    if (matchedObject) {
      return array.indexOf(matchedObject);
    }

    // Check for an object with end as null and start date lesser than passed date
    const endIsNullObject = array.find((obj) => {
      const startDate = DateTime.fromISO(obj.start);
      return obj.end === null && startDate <= targetDate;
    });

    if (endIsNullObject) {
      return array.indexOf(endIsNullObject);
    }

    // If none of the above conditions match, find the object with the lesser start date
    let lesserStartDateObject = array.reduce((prev, curr) => {
      const prevStartDate = DateTime.fromISO(prev.start);
      const currStartDate = DateTime.fromISO(curr.start);
      return prevStartDate < currStartDate ? prev : curr;
    });

    return array.indexOf(lesserStartDateObject);
  };

  const dcfTemplate = (rowData) => {
    let text = "Not Found";
    let index = dcflist.find((i) => i.id === rowData.reporter);
    if (index) {
      text = index.title;
    }
    return <>{text}</>;
  };

  const sectionTemplate = (rowData) => {
    let text = "Not Found";
    let index = section.find((i) => i.id === rowData.section);
    if (index) {
      text = index.name;
    }
    return <>{text}</>;
  };

  const getFiscalYearRange = (year, fymonth) => {
    let startDate, endDate;
    console.log(fymonth);

    if (fymonth === 1) {
      startDate = DateTime.fromObject({ year, month: 1, day: 1 }).startOf(
        "day"
      );
      endDate = DateTime.fromObject({ year, month: 12, day: 31 }).endOf("day");
    } else {
      startDate = DateTime.fromObject({
        year: year - 1,
        month: fymonth,
        day: 1,
      }).startOf("day");
      endDate = DateTime.fromObject({ year, month: fymonth - 1, day: 1 }).endOf(
        "month"
      );
    }

    return { startDate, endDate };
  };

  function getMonthsBetween(start_date, end_date, frequency, filterYear, fymonth) {
    let fyStartDate, fyEndDate;
  
    if (filterYear === 0) {
      // No fiscal year filtering
      fyStartDate = start_date ? DateTime.fromISO(start_date, { zone: "utc" }).toLocal() : null;
      fyEndDate = end_date ? DateTime.fromISO(end_date, { zone: "utc" }).toLocal() : DateTime.utc().toLocal()
    } else {
      // Set fiscal year boundaries based on filterYear and fymonth
      if (fymonth === 1) {
        // Calendar year: Jan to Dec of the filterYear
        fyStartDate = DateTime.fromObject({ year: filterYear, month: 1, day: 1 });
        fyEndDate = DateTime.fromObject({ year: filterYear, month: 12, day: 31 });
      } else {
        // Fiscal year: Apr (fymonth) of the previous year to Mar (fymonth - 1) of the filterYear
        fyStartDate = DateTime.fromObject({
          year: filterYear - 1,
          month: fymonth,
          day: 1,
        });
        fyEndDate = DateTime.fromObject({
          year: filterYear,
          month: fymonth - 1,
          day: 1,
        }).endOf("month");
      }
    }
  
    // Use assignment start and end dates
    const userStartDate = start_date
      ? DateTime.fromISO(start_date, { zone: "utc" }).toLocal()
      : fyStartDate;
    const userEndDate = end_date
      ? DateTime.fromISO(end_date, { zone: "utc" }).toLocal()
      : fyEndDate;
  
    // Adjusted final start and end dates
    const finalStartDate =
      filterYear !== 0 && userStartDate < fyStartDate ? fyStartDate : userStartDate;
    const finalEndDate =
      filterYear !== 0 && userEndDate > fyEndDate ? fyEndDate : userEndDate;
  
    const months = [];
    let currentMonth = userStartDate;  // Start from the actual assignment start date
  
    // Generate reporting periods from the assignment's start date, within the fiscal year boundaries
    while (currentMonth <= finalEndDate) {
      const periodEnd = currentMonth.plus({ months: frequency - 1 });
  
      // Only include the period if it falls within the fiscal year range
      if (periodEnd >= finalStartDate && periodEnd <= finalEndDate) {
        if (frequency === 1) {
          months.push(currentMonth.toFormat("LLL-yyyy"));
        } else {
          months.push(
            currentMonth.toFormat("LLL-yyyy") +
            " to " +
            periodEnd.toFormat("LLL-yyyy")
          );
        }
      }
      
      // Move to the next period based on frequency
      currentMonth = currentMonth.plus({ months: frequency });
    }
  
    return months;
  }
  
  function findKeyById(tvssection, id) {
    for (const key in tvssection) {
      if (tvssection[key].includes(id)) {
        return parseFloat(key);
      }
    }
    return null;
  }
  useEffect(() => {
    let selectedYear = null;
    let yrOptions = getFiscalYearsFromStartDate(
      admin_data.information.startdate
    );
    if (yrOptions.length !== 0) {
      selectedYear = yrOptions[yrOptions.length - 1].name;
      setFilter((prev) => ({ ...prev, year: 0 }));
    }
    console.log(yrOptions);
    setYearOptions(yrOptions);
    let uriString = {
      include: [
        {
          relation: "locationTwos",
          scope: { include: [{ relation: "locationThrees" }] },
        },
      ],
    };
    let uriString2 = {
      include: [
        {
          relation: "newTopics",
          scope: {
            include: [
              {
                relation: "newMetrics",
                scope: { include: [{ relation: "newDataPoints" }] },
              },
            ],
          },
        },
      ],
    };
    let Overall =
      API.Categories +
      `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`;
    const promise0 = APIServices.get(API.DCF);
    const promise1 = APIServices.get(API.QN_Submit_UP(admin_data.id));
    const promise2 = APIServices.get(
      API.LocationOne_UP(admin_data.id) +
        `?filter=${encodeURIComponent(JSON.stringify(uriString))}`
    );
    const promise3 = APIServices.get(API.DCF_Entity_UP(admin_data.id));
    const promise4 = APIServices.get(API.DF_Entity_UP(admin_data.id));
    const promise5 = APIServices.get(API.SRF_Entity_UP(admin_data.id));
    const promise6 = APIServices.get(API.DCF_Entity_User_UP(admin_data.id));
    const promise7 = APIServices.get(API.DF_Entity_User_UP(admin_data.id));
    const promise8 = APIServices.get(API.SRF_Entity_User_UP(admin_data.id));
    const promise9 = APIServices.get(API.AssignDCFClient_UP(admin_data.id));
    const promise10 = APIServices.get(Overall);
    const promise11 = APIServices.get(API.GetRole_Up(admin_data.id));
    Promise.all([
      promise0,
      promise1,
      promise2,
      promise3,
      promise4,
      promise5,
      promise6,
      promise7,
      promise8,
      promise9,
      promise10,
      promise11,
    ]).then((values) => {
      let newsData = values[11].data.filter((i) => i.status === 2);
      let shapedSite_ = getLocationData(
        values[2].data,
        values[11].data.filter((i) => i.user_id === login_data.id),
        [17]
      )
        .map((item) => {
          if (item.locationTwos) {
            item.locationTwos = item.locationTwos.filter(
              (locationTwo) =>
                locationTwo.locationThrees &&
                locationTwo.locationThrees.length > 0
            );
          }
          return item;
        })
        .filter((item) => item.locationTwos && item.locationTwos.length > 0);

      newsData.forEach((nws) => {
        if (nws.expiryDate) {
          nws.expired = isSameOrFutureLocalDate(nws.expiryDate) ? false : true;
        } else {
          nws.expired = false;
        }
      });

      let quantitative_submitted = values[1].data,
        qn_submit_list = [],
        qn_review_list = [],
        qn_approve_list = [],
        dcf_list = JSON.parse(JSON.stringify(values[0].data)),
        quantitative_ass_list = values[6].data,
        qualitative_ass_list = values[7].data,
        srf_ass_list = values[8].data;
      let quantitative_entity_list = values[3].data,
        qualitative_entity_list = values[4].data,
        srf_entity_list = values[5].data;

      let curated_dcf_ids = [];
      if (values[9].data && values[9].data.length > 0) {
        values[10].data.forEach((cat) => {
          if (cat.newTopics !== undefined) {
            cat.newTopics.forEach((topic) => {
              if (
                topic.newMetrics !== undefined &&
                values[9].data[0].topic_ids.includes(topic.id) &&
                (topic.tag === null || parseFloat(topic.tag) === admin_data.id)
              ) {
                topic.newMetrics.forEach((metric) => {
                  if (
                    Array.isArray(metric.data1) &&
                    values[9].data[0].metric_ids.includes(metric.id) &&
                    metric.data1[0].type !== undefined &&
                    metric.data1[0].type === 0 &&
                    (metric.tag === null || metric.tag === admin_data.id)
                  ) {
                    if (metric.newDataPoints !== undefined) {
                      metric.newDataPoints.forEach((dp) => {
                        if (
                          Array.isArray(dp.data1) &&
                          dp.data1.length !== 0 &&
                          dp.data1[0].datasource !== null &&
                          typeof dp.data1[0].datasource === "number"
                        ) {
                          let dcf_index = values[0].data.findIndex(
                            (i) => i.id === dp.data1[0].datasource
                          );
                          if (
                            !curated_dcf_ids.includes(dp.data1[0].datasource) &&
                            dcf_index !== -1
                          ) {
                            if (
                              values[0].data[dcf_index].tags === null ||
                              !values[0].data[dcf_index].tags.length ||
                              values[0].data[dcf_index].tags.includes(
                                admin_data.id
                              )
                            ) {
                              let dcfIndex = dcf_list.findIndex(
                                (i) => i.id === dp.data1[0].datasource
                              );
                              if (dcfIndex !== -1) {
                                dcf_list[dcfIndex]["section"] = findKeyById(
                                  tvssection,
                                  metric.id
                                );
                                console.log(
                                  dcf_list,
                                  dcfIndex,
                                  dp.data1[0].datasource,
                                  "xyz"
                                );
                              }
                              curated_dcf_ids.push(dp.data1[0].datasource);
                            }
                          }
                        }
                      });
                    }
                  }
                });
              }
            });
          }
        });
      }
      const shapedSite = values[2].data
        .map((item) => {
          if (item.locationTwos) {
            item.locationTwos = item.locationTwos.filter(
              (locationTwo) =>
                locationTwo.locationThrees &&
                locationTwo.locationThrees.length > 0
            );
          }
          return item;
        })
        .filter((item) => item.locationTwos && item.locationTwos.length > 0);
      setSubmission(quantitative_submitted);
      let filtered_qn_ass = quantitative_ass_list.filter(
        (i) =>
          dcf_list.map((j) => j.id).includes(i.dcfId) &&
          curated_dcf_ids.includes(i.dcfId) &&
          checkEnity(i, quantitative_entity_list, shapedSite, "dcfId")
      );
      console.log(filtered_qn_ass);

      // Quantitative
      filtered_qn_ass.forEach((item) => {
        if (item.reporter_ids.includes(login_data.id)) {
          item.dcf_ = dcf_list.filter((k) => {
            return k.id === item.dcfId;
          })[0];
          item.frequency_ = frequency_list.filter((k) => {
            return k.id === item.frequency;
          })[0];
          let st_date = DateTime.fromISO(item.start_date, {
              zone: "utc",
            }).toLocal(),
            ed_date = DateTime.utc().toLocal();
          if (typeof item.end_date === "string") {
            ed_date = DateTime.fromISO(item.end_date, {
              zone: "utc",
            }).toLocal();
          }
          getQuantitativeSubmissionData(
            st_date,
            ed_date,
            item.frequency,
            item,
            quantitative_submitted
          );
        }
      });
      setDcfList(dcf_list.filter((i) => curated_dcf_ids.includes(i.id)));
      setAssignmentList(filtered_qn_ass);
      setRawSitelist(shapedSite);
      setLocList((prev) => ({
        ...prev,
        country: [
          { name: "Enterprise-wide", id: 0 },
          ...shapedSite.map((i) => ({ name: i.name, id: i.id })),
        ],
      }));
      console.log(quantitative_submitted);
      quantitative_submitted.forEach((item) => {
        let filtered_qn_ass_index = filtered_qn_ass.findIndex((i) => {
          console.log(
            i.id,
            item.entityUserAssId,
            i.entityAssId,
            item.entityAssId
          );
          return (
            i.id === item.entityUserAssId && i.entityAssId === item.entityAssId
          );
        });

        if (filtered_qn_ass_index !== -1) {
          console.log(
            filtered_qn_ass[filtered_qn_ass_index],
            filtered_qn_ass[filtered_qn_ass_index].reporter_ids,
            login_data.id,
            filtered_qn_ass[filtered_qn_ass_index].reporter_ids.includes(
              login_data.id
            )
          );
          if (
            filtered_qn_ass[filtered_qn_ass_index].reporter_ids.includes(
              login_data.id
            )
          ) {
            console.log(item);
            qn_submit_list.push(item);
          }
          if (
            filtered_qn_ass[filtered_qn_ass_index].reviewer_ids.includes(
              login_data.id
            )
          ) {
            qn_review_list.push(item);
          }
          if (
            filtered_qn_ass[filtered_qn_ass_index].approver_ids.includes(
              login_data.id
            )
          ) {
            qn_approve_list.push(item);
          }
        }
      });
      let loc = filterSubmissionStatus(
        shapedSite,
        quantitative_submitted,
        filtered_qn_ass,
        0,
        dcf_list
      );
      console.log(loc);
      setSubmittedQuantitative(qn_submit_list);
      setReviewQuantitative(qn_review_list);
      setApproveQuantitative(qn_approve_list);
      setData(loc);
      setDataBk(loc);
    });
  }, []);
  const updateLocationFilter = (obj, val) => {
    let item = { ...filter, [obj]: val };
    let selected_item = { country: 0, city: 0, location: 0 };
    let country_list = [{ name: "Enterprise-wide", id: 0 }];
    let city_list = [{ name: "All Regions", id: 0 }];
    let location_list = [{ name: "All Business Unit", id: 0 }];
    rawsitelist.forEach((country) => {
      country_list.push({ name: country.name, id: country.id });
      if (country.id === item.country || item.country === 0) {
        if (country.locationTwos) {
          country.locationTwos.forEach((city) => {
            city_list.push({ name: city.name, id: city.id });
            if (city.id === item.city || item.city === 0) {
              if (city.locationThrees) {
                city.locationThrees.forEach((site) => {
                  location_list.push({ name: site.name, id: site.id });
                });
              }
            }
          });
        }
      }
    });
    if (obj === "country") {
      item.city = val === 0 ? null : 0;
      item.location = null;
      
    } else if (obj === "city") {
      item.location = val === 0 ? null : 0;
    }

    

    setLocList((prev) => ({
      ...prev,
      country: country_list,
      city: city_list,
      location: location_list,
    }));
    return item
  };

  const filterDataByTierAndLocation = (
    data,
    locationData,
    tier1_id,
    tier2_id,
    tier3_id
  ) => {
    if (tier1_id === 0 && tier2_id === null && tier3_id === null) {
      return data; // If tier is 0, null, null return the given data
    }

    const { countries, regions, businessUnits } = getValidTierIds(
      locationData,
      tier1_id,
      tier2_id,
      tier3_id
    );

    return data.filter((item) => {
      if (tier1_id !== 0 && tier2_id === 0 && tier3_id === null) {
        // Case when we want all regions and sites under a country
        return (
          (item.level === 1 && countries.includes(item.locationId)) ||
          (item.level === 2 && regions.includes(item.locationId)) ||
          (item.level === 3 && businessUnits.includes(item.locationId))
        );
      } else if (tier1_id !== 0 && tier2_id !== 0 && tier3_id === 0) {
        // Case when we want a specific region and all its sites
        return (
          (item.level === 2 && regions.includes(item.locationId)) ||
          (item.level === 3 && businessUnits.includes(item.locationId))
        );
      } else if (tier1_id !== 0 && tier2_id !== 0 && tier3_id !== 0) {
        // Case when we want a specific site
        return item.level === 3 && businessUnits.includes(item.locationId);
      } else {
        // Case when we want only the specific country
        return item.level === 1 && countries.includes(item.locationId);
      }
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    newValue === "SUBMISSIONS"
      ? activeTab(6)
      : newValue === "REVIEWS"
      ? activeTab(3)
      : activeTab(4);
  };

  function CustomTabPanel(props) {
    const { children, value, tabValue, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== tabValue}
        id={`⁠ incident-tabpanel-${tabValue}`}
        aria-labelledby={`⁠ incident-tab-${tabValue}`}
        {...other}
      >
        {value === tabValue && <Box>{children}</Box>}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const customFontStyle = {
    fontFamily: "Lato, sans-serif",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none",
  };

  const TABS = {
    SUBMISSIONS: "SUBMISSIONS",
    REVIEWS: "REVIEWS",
    APPROVALS: "APPROVALS",
  };

  function parseDateString(dateString) {
    if (dateString.includes("to")) {
      const [start, end] = dateString.split("to");
      return { start, end };
    } else {
      return { start: dateString, end: dateString };
    }
  }

  const getValidTierIds = (locationData, tier1_id, tier2_id, tier3_id) => {
    const countries = new Set();
    const regions = new Set();
    const businessUnits = new Set();

    locationData.forEach((country) => {
      if (tier1_id === 0 || tier1_id === country.id) {
        countries.add(country.id);

        country.locationTwos.forEach((region) => {
          if (tier2_id === 0 || tier2_id === region.id) {
            regions.add(region.id);

            region.locationThrees.forEach((businessUnit) => {
              if (
                tier3_id === 0 ||
                (tier2_id === 0 && tier3_id === null) ||
                tier3_id === businessUnit.id
              ) {
                businessUnits.add(businessUnit.id);
              }
            });
          }
        });
      }
    });

    return {
      countries: Array.from(countries),
      regions: Array.from(regions),
      businessUnits: Array.from(businessUnits),
    };
  };

  function isDateInFiscalYear(year, dateString) {
    const { start, end } = parseDateString(dateString);
    let curYear = year;
    if (fymonth !== 1) {
      curYear = year - 1;
    }
    const startDate = DateTime.fromFormat(start.trim(), "MMM-yyyy");
    const endDate = DateTime.fromFormat(end.trim(), "MMM-yyyy");
    const fiscalYearStart = DateTime.fromObject({
      year: curYear,
      month: fymonth,
      day: 1,
    }); // April 1 of the previous year
    const fiscalYearEnd = DateTime.fromObject({
      year: year,
      month: fymonth - 1 || 12,
      day: DateTime.fromObject({ year: year, month: fymonth - 1 || 12 })
        .daysInMonth,
    }); // March 31 of the given year
    console.log(
      (startDate >= fiscalYearStart && startDate <= fiscalYearEnd) ||
        (endDate >= fiscalYearStart && endDate <= fiscalYearEnd) ||
        (startDate <= fiscalYearStart && endDate >= fiscalYearEnd),
      dateString,
      curYear,
      year
    );
    return (
      (startDate >= fiscalYearStart && startDate <= fiscalYearEnd) ||
      (endDate >= fiscalYearStart && endDate <= fiscalYearEnd) ||
      (startDate <= fiscalYearStart && endDate >= fiscalYearEnd)
    );
  }

  const generateMTDArray = (year, fymonth) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let result = [];

    // Determine the fiscal year start and end months
    const fiscalStartMonth = fymonth - 1; // 0-based month index
    const fiscalEndMonth = (fymonth + 11) % 12; // Fiscal year ends 11 months after fyMonth

    // Calculate fiscal start and end year
    let fiscalStartYear = year;
    let fiscalEndYear = year;

    if (fiscalStartMonth > 0) {
      // If the fiscal year does not start in January
      fiscalStartYear = year - 1;
    }
    fiscalEndYear = fiscalStartYear + 1;

    // Generate month-year strings
    for (let i = 0; i < 12; i++) {
      let monthIndex = (fiscalStartMonth + i) % 12;
      let currentYear =
        fiscalStartYear + Math.floor((fiscalStartMonth + i) / 12);
      result.push(`${months[monthIndex]}-${currentYear}`);
      if (currentYear > fiscalEndYear) break;
    }

    return result;
  };
  const getBetweenMonths = (year, fymonth) => {
    const months = [];

    // Handle year input in the format "YYYY" or "YYYY-YY"
    let startYear;
    let endYear;
    if (year.includes("-")) {
      [startYear, endYear] = year.split("-").map((part) => parseInt(part));
      startYear = parseInt(startYear);
      endYear =
        endYear.length === 2 ? parseInt(`20${endYear}`) : parseInt(endYear); // Handle two-digit year
    } else {
      startYear = parseInt(year);
      endYear = startYear;
    }

    // Case 1: FY starts in January
    if (fymonth === 1) {
      for (let i = 1; i <= 12; i++) {
        const month = DateTime.fromObject({ year: endYear, month: i });
        const formattedMonth = month.toFormat("MM-yyyy");
        months.push(formattedMonth);
      }
    } else {
      // Case 2: FY starts in a month other than January
      for (let i = fymonth; i <= 12; i++) {
        const month = DateTime.fromObject({ year: startYear, month: i });
        const formattedMonth = month.toFormat("MM-yyyy");
        months.push(formattedMonth);
      }
      for (let i = 1; i < fymonth; i++) {
        const month = DateTime.fromObject({ year: endYear, month: i });
        const formattedMonth = month.toFormat("MM-yyyy");
        months.push(formattedMonth);
      }
    }

    return months;
  };

  const isDateWithinPeriod = (rp, period) => {
    // Convert date strings to Luxon DateTime objects
    const rpDates = rp.map((dateString) =>
      DateTime.fromFormat(dateString, "MM-yyyy")
    );
    const periodDates = period.map((dateString) =>
      DateTime.fromFormat(dateString, "MM-yyyy")
    );

    // Find the matching dates in rp within the period
    const matchingDates = rpDates.filter((rpDate) =>
      periodDates.some(
        (periodDate) => rpDate.toMillis() === periodDate.toMillis()
      )
    );

    return matchingDates.length > 0
      ? {
          match: true,
          dates: matchingDates.map((date) => date.toFormat("MM-yyyy")),
        }
      : { match: false, dates: null };
  };

  const getEmissionFactorCalculation = (item, year) => {
    let betweenMonths = getBetweenMonths(
      yearOptions.find((i) => i.name === year).label,
      fymonth
    );
    let month_data = [];
    let scope = 0;
    if (item.dcfId === 11) {
      const { match, dates } = isDateWithinPeriod(
        item.reporting_period,
        betweenMonths
      );
      if (match) {
        const standard_index = apief.findIndex((i) => {
          return i.id === item.standard;
        });
        console.log("Matched_SC", standard_index);
        if (standard_index !== -1) {
          item.response.forEach((sc) => {
            if (
              standard_index !== -1 &&
              apief[standard_index].newEfDates !== undefined &&
              apief[standard_index].newEfDates.length !== 0
            ) {
              console.log("Dates_SC", dates);

              let date_index = findIndexByDate(
                apief[standard_index].newEfDates,
                item.reporting_period[0]
              );

              if (
                date_index !== -1 &&
                apief[standard_index].newEfDates[date_index].newEfs !==
                  undefined &&
                apief[standard_index].newEfDates[date_index].newEfs.length !== 0
              ) {
                let cat_index = apief[standard_index].newEfDates[
                  date_index
                ].newEfs.findIndex((i) => {
                  return i.category === 1;
                });
                console.log(
                  apief[standard_index].newEfDates[date_index].newEfs[cat_index]
                );
                if (
                  cat_index !== -1 &&
                  apief[standard_index].newEfDates[date_index].newEfs[cat_index]
                    .newEfItems !== undefined &&
                  apief[standard_index].newEfDates[date_index].newEfs[cat_index]
                    .newEfItems.length !== 0
                ) {
                  let sc_index = apief[standard_index].newEfDates[
                    date_index
                  ].newEfs[cat_index].newEfItems.findIndex((i) => {
                    return (
                      i.subcategory1 === sc.DPA0130 &&
                      i.subcategory2 === sc.DPA0131 &&
                      i.subcategory3 === sc.DPA0132
                    );
                  });
                  console.log(sc_index, sc);
                  if (sc_index !== -1) {
                    let ef =
                      apief[standard_index].newEfDates[date_index].newEfs[
                        cat_index
                      ].newEfItems[sc_index];
                    let ghg = parseFloat(
                      ((parseFloat(sc.DPA0336) * ef.co2e) / 1000).toFixed(3)
                    );
                    console.log(sc.DPA0336, ef);
                    let fuel_type_index = subcat.two.findIndex((i) => {
                      return i.id === sc.DPA0131;
                    });
                    let unit_index = subcat.three.findIndex((i) => {
                      return i.id === sc.DPA0132;
                    });
                    let fuel_cat_index = subcat.one.findIndex((i) => {
                      return i.id === sc.DPA0130;
                    });
                    let unit_type = "NA",
                      cat_type = "NA";
                    let fuel_type = "Other",
                      usage = 0,
                      r = 0,
                      nr = 0;
                    if (fuel_type_index !== -1) {
                      fuel_type = subcat.two[fuel_type_index].title;
                    }
                    if (unit_index !== -1) {
                      unit_type = subcat.three[fuel_type_index].title;
                    }
                    if (fuel_cat_index !== -1) {
                      cat_type = subcat.one[fuel_cat_index].title;
                    }
                    if (unit_index !== -1) {
                      if (unit_type.includes("ton")) {
                        if (fuel_cat_index !== -1) {
                          cat_type = subcat.one[fuel_cat_index].title;

                          if (cat_type.trim().toLowerCase().includes("bio")) {
                            r = parseFloat(
                              (
                                (parseFloat(sc.DPA0336) *
                                  0.82 *
                                  10800 *
                                  4.187) /
                                1000000000
                              ).toFixed(3)
                            );
                          } else {
                            nr = parseFloat(
                              (
                                (parseFloat(sc.DPA0336) *
                                  0.82 *
                                  10800 *
                                  4.187) /
                                1000000000
                              ).toFixed(3)
                            );
                          }
                        }
                        usage = parseFloat((sc.DPA0336 / 0.81).toFixed(3));
                      } else {
                        if (fuel_cat_index !== -1) {
                          if (cat_type.trim().toLowerCase().includes("bio")) {
                            r = parseFloat(
                              (
                                (parseFloat(sc.DPA0336) * 0.82 * 10.8 * 4.187) /
                                1000000000
                              ).toFixed(3)
                            );
                          } else {
                            nr = parseFloat(
                              (
                                (parseFloat(sc.DPA0336) * 0.82 * 10.8 * 4.187) /
                                1000000000
                              ).toFixed(3)
                            );
                          }
                        }
                        usage = parseFloat(sc.DPA0336);
                      }
                    }
                    scope = 1;
                    sc.efdata = {
                      year: item.reporting_period[0].split("-")[1],
                      ef,
                      scope: 1,
                      ghg,
                      cat_type,
                      unit_type,
                      fuel_type,
                      usage,
                      r,
                      nr,
                    };
                    let month_index = month_data.findIndex((i) => {
                      return (
                        i.scope === 1 &&
                        i.fuel_type === fuel_type &&
                        i.unit_type === unit_type &&
                        i.cat_type === cat_type
                      );
                    });
                    // if (month_index === -1) {
                    month_data.push({
                      year: item.reporting_period[0].split("-")[1],
                      ef,
                      scope: 1,
                      ghg,
                      cat_type,
                      unit_type,
                      fuel_type,
                      usage,
                      r,
                      nr,
                    });
                    // } else {
                    //     month_data[month_index].ghg += ghg
                    //     month_data[month_index].usage += usage
                    //     month_data[month_index].r += r
                    //     month_data[month_index].nr += nr
                    // }
                  }
                }
              }
            }
          });
        }
      }
    } else if (item.dcfId === 10) {
      const { match, dates } = isDateWithinPeriod(
        item.reporting_period,
        betweenMonths
      );
      if (match) {
        const standard_index = apief.findIndex((i) => {
          return i.id === item.standard;
        });
        if (standard_index !== -1) {
          item.response.forEach((sc) => {
            if (
              standard_index !== -1 &&
              apief[standard_index].newEfDates !== undefined &&
              apief[standard_index].newEfDates.length !== 0
            ) {
              let date_index = findIndexByDate(
                apief[standard_index].newEfDates,
                item.reporting_period[0]
              );
              console.log(
                date_index,
                apief[standard_index].newEfDates[date_index].newEfs
              );
              if (
                date_index !== -1 &&
                apief[standard_index].newEfDates[date_index].newEfs !==
                  undefined &&
                apief[standard_index].newEfDates[date_index].newEfs.length !== 0
              ) {
                let cat_index = apief[standard_index].newEfDates[
                  date_index
                ].newEfs.findIndex((i) => {
                  return i.category === 5;
                });
                console.log(
                  apief[standard_index].newEfDates[date_index].newEfs[cat_index]
                );
                if (
                  cat_index !== -1 &&
                  apief[standard_index].newEfDates[date_index].newEfs[cat_index]
                    .newEfItems !== undefined &&
                  apief[standard_index].newEfDates[date_index].newEfs[cat_index]
                    .newEfItems.length !== 0
                ) {
                  let sc_index = apief[standard_index].newEfDates[
                    date_index
                  ].newEfs[cat_index].newEfItems.findIndex((i) => {
                    return i.subcategory1 === sc.DPA0136;
                  });
                  console.log(
                    sc_index,
                    sc.DPA0136,
                    apief[standard_index].newEfDates[date_index].newEfs[
                      cat_index
                    ].newEfItems
                  );
                  if (sc_index !== -1) {
                    console.log(
                      apief[standard_index].newEfDates[date_index].newEfs[
                        cat_index
                      ].newEfItems[sc_index],
                      sc
                    );
                    let ef =
                      apief[standard_index].newEfDates[date_index].newEfs[
                        cat_index
                      ].newEfItems[sc_index];
                    let ghg = parseFloat(
                      ((parseFloat(sc.DPA0138) * ef.co2e) / 1000).toFixed(3)
                    );
                    console.log(sc.DPA0138, ef);
                    let gas_type_index = subcat.one.findIndex((i) => {
                      return i.id === sc.DPA0136;
                    });
                    let gas_type = "Other";
                    if (gas_type_index !== -1) {
                      gas_type = subcat.one[gas_type_index].title;
                    }
                    let month_index = month_data.findIndex((i) => {
                      return i.scope === 1 && i.gas_type === gas_type;
                    });
                    // if (month_index === -1) {
                    scope = 1;
                    sc.efdata = {
                      year: item.reporting_period[0].split("-")[1],
                      ef,
                      scope: 1,
                      ghg,
                      gas_type,
                      usage: parseFloat(sc.DPA0138),
                    };
                    month_data.push({
                      year: item.reporting_period[0].split("-")[1],
                      ef,
                      scope: 1,
                      ghg,
                      gas_type,
                      usage: parseFloat(sc.DPA0138),
                    });
                    // } else {
                    //     month_data[month_index].ghg += ghg
                    //     month_data[month_index].usage += parseFloat(sc.DPA0138)
                    // }
                  }
                }
              }
            }
          });
        }
      }
    } else if (item.dcfId === 16) {
      const { match, dates } = isDateWithinPeriod(
        item.reporting_period,
        betweenMonths
      );
      if (match) {
        const standard_index = apief.findIndex((i) => {
          return i.id === item.standard;
        });
        if (standard_index !== -1) {
          item.response.forEach((sc) => {
            if (
              standard_index !== -1 &&
              apief[standard_index].newEfDates !== undefined &&
              apief[standard_index].newEfDates.length !== 0
            ) {
              let date_index = findIndexByDate(
                apief[standard_index].newEfDates,
                item.reporting_period[0]
              );

              if (
                date_index !== -1 &&
                apief[standard_index].newEfDates[date_index].newEfs !==
                  undefined &&
                apief[standard_index].newEfDates[date_index].newEfs.length !== 0
              ) {
                let cat_index = apief[standard_index].newEfDates[
                  date_index
                ].newEfs.findIndex((i) => {
                  return i.category === 11;
                });
                console.log(
                  apief[standard_index].newEfDates[date_index].newEfs[cat_index]
                );
                if (
                  cat_index !== -1 &&
                  apief[standard_index].newEfDates[date_index].newEfs[cat_index]
                    .newEfItems !== undefined &&
                  apief[standard_index].newEfDates[date_index].newEfs[cat_index]
                    .newEfItems.length !== 0
                ) {
                  let sc_index = apief[standard_index].newEfDates[
                    date_index
                  ].newEfs[cat_index].newEfItems.findIndex((i) => {
                    return i.subcategory1 === sc.DPA0287;
                  });

                  if (sc_index !== -1) {
                    let ef =
                      apief[standard_index].newEfDates[date_index].newEfs[
                        cat_index
                      ].newEfItems[sc_index];
                    let ghg = parseFloat(
                      (
                        (parseFloat(sc.DPA0288) *
                          parseFloat(sc.DPA0289) *
                          ef.co2e) /
                        1000
                      ).toFixed(3)
                    );

                    let item_class_index = subcat.one.findIndex((i) => {
                      return i.id === sc.DPA0287;
                    });
                    let item_class = "Other";
                    if (item_class_index !== -1) {
                      item_class = subcat.one[item_class_index].title;
                    }
                    let month_index = month_data.findIndex((i) => {
                      return i.scope === 1 && i.item_class === item_class;
                    });
                    // if (month_index === -1) {
                    scope = 1;
                    sc.efdata = {
                      year: item.reporting_period[0].split("-")[1],
                      ef,
                      scope: 1,
                      ghg,
                      item_class,
                    };
                    month_data.push({
                      year: item.reporting_period[0].split("-")[1],
                      ef,
                      scope: 1,
                      ghg,
                      item_class,
                    });
                    // } else {
                    //     month_data[month_index].ghg += ghg
                    // }
                  }
                }
              }
            }
          });
        }
      }
    } else if (item.dcfId === 15) {
      const { match, dates } = isDateWithinPeriod(
        item.reporting_period,
        betweenMonths
      );
      if (match) {
        const standard_index = apief.findIndex((i) => {
          return i.id === item.standard;
        });
        if (standard_index !== -1) {
          item.response.forEach((sc) => {
            if (
              standard_index !== -1 &&
              apief[standard_index].newEfDates !== undefined &&
              apief[standard_index].newEfDates.length !== 0
            ) {
              let date_index = findIndexByDate(
                apief[standard_index].newEfDates,
                item.reporting_period[0]
              );

              if (
                date_index !== -1 &&
                apief[standard_index].newEfDates[date_index].newEfs !==
                  undefined &&
                apief[standard_index].newEfDates[date_index].newEfs.length !== 0
              ) {
                if (!sc.DP_MODE) {
                  let cat_index = apief[standard_index].newEfDates[
                    date_index
                  ].newEfs.findIndex((i) => {
                    return i.category === 1;
                  });
                  console.log(
                    apief[standard_index].newEfDates[date_index].newEfs[
                      cat_index
                    ]
                  );
                  if (
                    cat_index !== -1 &&
                    apief[standard_index].newEfDates[date_index].newEfs[
                      cat_index
                    ].newEfItems !== undefined &&
                    apief[standard_index].newEfDates[date_index].newEfs[
                      cat_index
                    ].newEfItems.length !== 0
                  ) {
                    let sc_index = -1;
                    if (sc.DPA0141 === 2) {
                      sc_index = apief[standard_index].newEfDates[
                        date_index
                      ].newEfs[cat_index].newEfItems.findIndex((i) => {
                        return (
                          i.subcategory1 === 1 &&
                          i.subcategory2 === sc.DPA0141 &&
                          i.subcategory3 === 7
                        );
                      });
                    } else if (sc.DPA0141 === 13) {
                      sc_index = apief[standard_index].newEfDates[
                        date_index
                      ].newEfs[cat_index].newEfItems.findIndex((i) => {
                        return (
                          i.subcategory1 === 4 &&
                          i.subcategory2 === sc.DPA0141 &&
                          i.subcategory3 === 51
                        );
                      });
                    } else if (sc.DPA0141 === 19) {
                      sc_index = apief[standard_index].newEfDates[
                        date_index
                      ].newEfs[cat_index].newEfItems.findIndex((i) => {
                        return (
                          i.subcategory1 === 4 &&
                          i.subcategory2 === sc.DPA0141 &&
                          i.subcategory3 === 75
                        );
                      });
                    }
                    if (sc_index !== -1) {
                      let ef =
                        apief[standard_index].newEfDates[date_index].newEfs[
                          cat_index
                        ].newEfItems[sc_index];
                      let ghg = parseFloat(
                        ((parseFloat(sc.DPA0143) * ef.co2e) / 1000).toFixed(3)
                      );

                      let fuel_type_index = subcat.two.findIndex((i) => {
                        return i.id === sc.DPA0141;
                      });
                      let fuel_type = "Other";
                      if (fuel_type_index !== -1) {
                        fuel_type = subcat.two[fuel_type_index].title;
                      }
                      let month_index = month_data.findIndex((i) => {
                        return (
                          i.scope === 1 &&
                          i.type === "byFuel" &&
                          i.fuel_type === fuel_type
                        );
                      });
                      // if (month_index === -1) {
                      scope = 1;
                      sc.efdata = {
                        year: item.reporting_period[0].split("-")[1],
                        ef,
                        scope: 1,
                        ghg,
                        fuel_type,
                        type: "byFuel",
                      };
                      month_data.push({
                        year: item.reporting_period[0].split("-")[1],
                        ef,
                        scope: 1,
                        ghg,
                        fuel_type,
                        type: "byFuel",
                      });
                      // } else {
                      //     month_data[month_index].ghg += ghg
                      // }
                    }
                  }
                } else {
                  let cat_index = apief[standard_index].newEfDates[
                    date_index
                  ].newEfs.findIndex((i) => {
                    return i.category === sc.DPGMode;
                  });
                  console.log(
                    apief[standard_index].newEfDates[date_index].newEfs[
                      cat_index
                    ]
                  );
                  if (
                    cat_index !== -1 &&
                    apief[standard_index].newEfDates[date_index].newEfs[
                      cat_index
                    ].newEfItems !== undefined &&
                    apief[standard_index].newEfDates[date_index].newEfs[
                      cat_index
                    ].newEfItems.length !== 0
                  ) {
                    let sc_index = apief[standard_index].newEfDates[
                      date_index
                    ].newEfs[cat_index].newEfItems.findIndex((i) => {
                      return (
                        i.subcategory1 === sc.DPA0140 &&
                        i.subcategory2 === sc.DPA0139 &&
                        i.subcategory3 === sc.DPA0141 &&
                        i.subcategory4 === sc.DPA0339
                      );
                    });

                    if (sc_index !== -1) {
                      let ef =
                        apief[standard_index].newEfDates[date_index].newEfs[
                          cat_index
                        ].newEfItems[sc_index];
                      let ghg = parseFloat(
                        ((parseFloat(sc.DPA0144) * ef.co2e) / 1000).toFixed(3)
                      );

                      let fuel_type_index = subcat.two.findIndex((i) => {
                        return i.id === sc.DPA0139;
                      });
                      let fuel_type = "Other";
                      if (fuel_type_index !== -1) {
                        fuel_type = subcat.two[fuel_type_index].title;
                      }
                      let month_index = month_data.findIndex((i) => {
                        return (
                          i.scope === 1 &&
                          i.fuel_type === fuel_type &&
                          i.type === "byDistance"
                        );
                      });
                      // if (month_index === -1) {
                      scope = 1;
                      sc.efdata = {
                        year: item.reporting_period[0].split("-")[1],
                        ef,
                        scope: 1,
                        ghg,
                        fuel_type,
                        type: "byDistance",
                      };
                      month_data.push({
                        year: item.reporting_period[0].split("-")[1],
                        ef,
                        scope: 1,
                        ghg,
                        fuel_type,
                        type: "byDistance",
                      });
                      // } else {
                      //     month_data[month_index].ghg += ghg
                      // }
                    }
                  }
                }
              }
            }
          });
        }
      }
    } else if (item.dcfId === 257) {
      const { match, dates } = isDateWithinPeriod(
        item.reporting_period,
        betweenMonths
      );
      if (match) {
        const standard_index = apief.findIndex((i) => {
          return i.id === item.standard;
        });
        if (standard_index !== -1) {
          item.response.forEach((sc) => {
            if (
              standard_index !== -1 &&
              apief[standard_index].newEfDates !== undefined &&
              apief[standard_index].newEfDates.length !== 0
            ) {
              let date_index = findIndexByDate(
                apief[standard_index].newEfDates,
                item.reporting_period[0]
              );
              console.log(sc, item.id);
              if (
                date_index !== -1 &&
                apief[standard_index].newEfDates[date_index].newEfs !==
                  undefined &&
                apief[standard_index].newEfDates[date_index].newEfs.length !== 0
              ) {
                let cat_index = apief[standard_index].newEfDates[
                  date_index
                ].newEfs.findIndex((i, j) => {
                  return j === 0;
                });
                console.log(
                  apief[standard_index].newEfDates[date_index].newEfs[cat_index]
                );
                if (
                  cat_index !== -1 &&
                  apief[standard_index].newEfDates[date_index].newEfs[cat_index]
                    .newEfItems !== undefined &&
                  apief[standard_index].newEfDates[date_index].newEfs[cat_index]
                    .newEfItems.length !== 0
                ) {
                  let sc_index = apief[standard_index].newEfDates[
                    date_index
                  ].newEfs[cat_index].newEfItems.findIndex((i) => {
                    return (
                      i.subcategory1 === sc.DPAN095 &&
                      i.subcategory2 === sc.DPAN096 &&
                      i.subcategory3 === sc.DPAN099
                    );
                  });
                  console.log(sc_index);
                  if (sc_index !== -1) {
                    let ef =
                      apief[standard_index].newEfDates[date_index].newEfs[
                        cat_index
                      ].newEfItems[sc_index];
                    let ghg = 0;

                    console.log(sc.DPA0138, ef);
                    let source_index = subcat.two.findIndex((i) => {
                      return i.id === sc.DPAN096;
                    });
                    let type_index = subcat.one.findIndex((i) => {
                      return i.id === sc.DPAN095;
                    });
                    let type = "Other",
                      source_type = "Other",
                      r = 0,
                      nr = 0;
                    if (type_index !== -1) {
                      type = subcat.one[type_index].title;
                    }
                    if (source_index !== -1) {
                      source_type = subcat.two[source_index].title;
                      console.log(source_type, sc.DPAN096);
                      if (source_type.trim().toLowerCase().includes("non")) {
                        ghg = parseFloat(
                          ((parseFloat(sc.DPAN098) * ef.co2e) / 1000).toFixed(3)
                        );
                        nr = parseFloat(sc.DPAN098 / 1000);
                      } else {
                        r = parseFloat(sc.DPAN098 / 1000);
                      }
                    }
                    let month_index = month_data.findIndex((i) => {
                      return (
                        i.scope === 2 &&
                        i.source_type === source_type &&
                        i.type === type
                      );
                    });
                    // if (month_index === -1) {
                    scope = 2;
                    sc.efdata = {
                      year: item.reporting_period[0].split("-")[1],
                      ef,
                      scope: 2,
                      ghg,
                      type,
                      source_type,
                      r,
                      nr,
                    };
                    month_data.push({
                      year: item.reporting_period[0].split("-")[1],
                      ef,
                      scope: 2,
                      ghg,
                      type,
                      source_type,
                      r,
                      nr,
                    });
                    // } else {
                    //     month_data[month_index].ghg += ghg
                    //     month_data[month_index].r += r
                    //     month_data[month_index].nr += nr
                    // }
                  } else {
                    if (
                      subcat.one.map((i) => i.id).includes(sc.DPAN095) &&
                      subcat.two.map((i) => i.id).includes(sc.DPAN096) &&
                      subcat.three.map((i) => i.id).includes(sc.DPAN099)
                    ) {
                      let source_type = "",
                        r = 0,
                        nr = 0,
                        type = "";
                      let source_index = subcat.two.findIndex((i) => {
                        return i.id === sc.DPAN096;
                      });
                      let type_index = subcat.one.findIndex((i) => {
                        return i.id === sc.DPAN095;
                      });
                      type = subcat.one[type_index].title;
                      source_type = subcat.two[source_index].title;
                      if (!source_type.trim().toLowerCase().includes("non")) {
                        r = parseFloat(sc.DPAN098 / 1000);
                      }
                      let month_index = month_data.findIndex((i) => {
                        return (
                          i.scope === 2 &&
                          i.source_type === source_type &&
                          i.type === type
                        );
                      });
                      // if (month_index === -1) {
                      scope = 2;
                      sc.efdata = {
                        year: item.reporting_period[0].split("-")[1],
                        ef: {},
                        scope: 2,
                        ghg: 0,
                        type,
                        source_type,
                        r,
                        nr,
                      };
                      month_data.push({
                        year: item.reporting_period[0].split("-")[1],
                        ef: {},
                        scope: 2,
                        ghg: 0,
                        type,
                        source_type,
                        r,
                        nr,
                      });
                      // } else {
                      //     month_data[month_index].ghg += 0
                      //     month_data[month_index].r += r
                      //     month_data[month_index].nr += nr
                      // }
                    }
                  }
                }
              }
            }
          });
        }
      }
    } else if (item.dcfId === 15) {
      const { match, dates } = isDateWithinPeriod(
        item.reporting_period,
        betweenMonths
      );
      if (match) {
        const standard_index = apief.findIndex((i) => {
          return i.id === item.standard;
        });
        if (standard_index !== -1) {
          item.response.forEach((sc) => {
            if (
              standard_index !== -1 &&
              apief[standard_index].newEfDates !== undefined &&
              apief[standard_index].newEfDates.length !== 0
            ) {
              let date_index = findIndexByDate(
                apief[standard_index].newEfDates,
                item.reporting_period[0]
              );
              console.log(
                date_index,
                apief[standard_index].newEfDates[date_index].newEfs
              );
              if (
                date_index !== -1 &&
                apief[standard_index].newEfDates[date_index].newEfs !==
                  undefined &&
                apief[standard_index].newEfDates[date_index].newEfs.length !== 0
              ) {
                if (sc.DP_MODE === true) {
                  let cat_index = apief[standard_index].newEfDates[
                    date_index
                  ].newEfs.findIndex((i) => {
                    return i.category === sc.DPGMode;
                  });
                  console.log(
                    apief[standard_index].newEfDates[date_index].newEfs[
                      cat_index
                    ]
                  );
                  if (
                    cat_index !== -1 &&
                    apief[standard_index].newEfDates[date_index].newEfs[
                      cat_index
                    ].newEfItems !== undefined &&
                    apief[standard_index].newEfDates[date_index].newEfs[
                      cat_index
                    ].newEfItems.length !== 0
                  ) {
                    let sc_index = apief[standard_index].newEfDates[
                      date_index
                    ].newEfs[cat_index].newEfItems.findIndex((i) => {
                      return (
                        i.subcategory1 === sc.DPA0140 &&
                        i.subcategory2 === sc.DPA0139 &&
                        i.subcategory3 === sc.DPA0141 &&
                        i.subcategory4 === sc.DPA0339
                      );
                    });
                    console.log(sc_index);
                    if (sc_index !== -1) {
                      console.log(
                        apief[standard_index].newEfDates[date_index].newEfs[
                          cat_index
                        ].newEfItems[sc_index],
                        sc
                      );
                      let ef =
                        apief[standard_index].newEfDates[date_index].newEfs[
                          cat_index
                        ].newEfItems[sc_index];
                      let ghg = parseFloat(
                        ((parseFloat(sc.DPA0144) * ef.co2e) / 1000).toFixed(3)
                      );

                      scope = 1;
                      sc.efdata = {
                        year: item.reporting_period[0].split("-")[1],
                        ef,
                        scope: 1,
                        ghg,
                        usage: parseFloat(sc.DPA0144),
                      };
                      month_data.push({
                        year: item.reporting_period[0].split("-")[1],
                        ef,
                        scope: 1,
                        ghg,
                        usage: parseFloat(sc.DPA0144),
                      });
                    }
                  }
                } else {
                  let cat_index = apief[standard_index].newEfDates[
                    date_index
                  ].newEfs.findIndex((i) => {
                    return i.category === 1;
                  });

                  if (
                    cat_index !== -1 &&
                    apief[standard_index].newEfDates[date_index].newEfs[
                      cat_index
                    ].newEfItems !== undefined &&
                    apief[standard_index].newEfDates[date_index].newEfs[
                      cat_index
                    ].newEfItems.length !== 0
                  ) {
                    let sc_index = -1;
                    if (sc.DPA0141 === 2) {
                      sc_index = apief[standard_index].newEfDates[
                        date_index
                      ].newEfs[cat_index].newEfItems.findIndex((i) => {
                        return (
                          i.subcategory1 === 1 &&
                          i.subcategory2 === 2 &&
                          i.subcategory3 === 7
                        );
                      });
                    } else if (sc.DPA0141 === 13) {
                      sc_index = apief[standard_index].newEfDates[
                        date_index
                      ].newEfs[cat_index].newEfItems.findIndex((i) => {
                        return (
                          i.subcategory1 === 4 &&
                          i.subcategory2 === sc.DPA0141 &&
                          i.subcategory3 === 51
                        );
                      });
                    } else {
                      sc_index = apief[standard_index].newEfDates[
                        date_index
                      ].newEfs[cat_index].newEfItems.findIndex((i) => {
                        return (
                          i.subcategory1 === 4 &&
                          i.subcategory2 === sc.DPA0141 &&
                          i.subcategory3 === 75
                        );
                      });
                    }
                    if (sc_index !== -1) {
                      let ef =
                        apief[standard_index].newEfDates[date_index].newEfs[
                          cat_index
                        ].newEfItems[sc_index];
                      let ghg = parseFloat(
                        ((parseFloat(sc.DPA0143) * ef.co2e) / 1000).toFixed(3)
                      );
                      scope = 1;
                      sc.efdata = {
                        year: item.reporting_period[0].split("-")[1],
                        ef,
                        scope: 1,
                        ghg,
                        usage: parseFloat(sc.DPA0143),
                      };
                      month_data.push({
                        year: item.reporting_period[0].split("-")[1],
                        ef,
                        scope: 1,
                        ghg,
                        usage: parseFloat(sc.DPA0143),
                      });
                    }
                  }
                }
              }
            }
          });
        }
      }
    } else if (item.dcfId === 36) {
      const { match, dates } = isDateWithinPeriod(
        item.reporting_period,
        betweenMonths
      );
      if (match) {
        const standard_index = apief.findIndex((i) => {
          return i.id === item.standard;
        });
        if (standard_index !== -1) {
          item.response.forEach((sc) => {
            if (sc.DPA0291 === "Road") {
              if (
                standard_index !== -1 &&
                apief[standard_index].newEfDates !== undefined &&
                apief[standard_index].newEfDates.length !== 0
              ) {
                let date_index = findIndexByDate(
                  apief[standard_index].newEfDates,
                  item.reporting_period[0]
                );
                console.log(
                  date_index,
                  apief[standard_index].newEfDates[date_index].newEfs
                );
                if (
                  date_index !== -1 &&
                  apief[standard_index].newEfDates[date_index].newEfs !==
                    undefined &&
                  apief[standard_index].newEfDates[date_index].newEfs.length !==
                    0
                ) {
                  let cat_index = apief[standard_index].newEfDates[
                    date_index
                  ].newEfs.findIndex((i) => {
                    return i.category === 6;
                  });
                  console.log(
                    apief[standard_index].newEfDates[date_index].newEfs[
                      cat_index
                    ]
                  );
                  if (
                    cat_index !== -1 &&
                    apief[standard_index].newEfDates[date_index].newEfs[
                      cat_index
                    ].newEfItems !== undefined &&
                    apief[standard_index].newEfDates[date_index].newEfs[
                      cat_index
                    ].newEfItems.length !== 0
                  ) {
                    let sc_index = apief[standard_index].newEfDates[
                      date_index
                    ].newEfs[cat_index].newEfItems.findIndex((i) => {
                      return (
                        i.subcategory1 === sc.DPA0295 &&
                        i.subcategory2 === sc.DPA0337 &&
                        i.subcategory3 === sc.DPA0338 &&
                        i.subcategory4 === sc.DPA0338G
                      );
                    });
                    console.log(
                      sc_index,
                      apief[standard_index].newEfDates[date_index].newEfs[
                        cat_index
                      ].newEfItems
                    );
                    if (sc_index !== -1) {
                      console.log(
                        apief[standard_index].newEfDates[date_index].newEfs[
                          cat_index
                        ].newEfItems[sc_index],
                        sc
                      );
                      let ef =
                        apief[standard_index].newEfDates[date_index].newEfs[
                          cat_index
                        ].newEfItems[sc_index];
                      let ghg = parseFloat(
                        ((parseFloat(sc.DPA0292) * ef.co2e) / 1000).toFixed(3)
                      );

                      scope = 3;
                      sc.efdata = {
                        year: item.reporting_period[0].split("-")[1],
                        ef,
                        scope: 3,
                        ghg,
                        usage: parseFloat(sc.DPA0292),
                      };
                      month_data.push({
                        year: item.reporting_period[0].split("-")[1],
                        ef,
                        scope: 3,
                        ghg,
                        usage: parseFloat(sc.DPA0292),
                      });
                    }
                  }
                }
              }
            } else if (sc.DPA0291 === "Air") {
              scope = 3;
              let ghg = sc.DP_co2e_mt;
              month_data.push({
                year: item.reporting_period[0].split("-")[1],
                scope: 3,
                ghg,
              });
            } else if (sc.DPA0291 === "Rail") {
              scope = 3;
              let ghg = parseFloat(sc.DPA0292) * sc.DP_KM * 0.00116;
              month_data.push({
                year: item.reporting_period[0].split("-")[1],
                scope: 3,
                ghg,
              });
            }
          });
        }
      }
    } else if (item.dcfId === 292) {
      console.log(item);
      const { match, dates } = isDateWithinPeriod(
        item.reporting_period,
        betweenMonths
      );
      if (match) {
        const standard_index = apief.findIndex((i) => {
          return i.id === item.standard;
        });
        if (standard_index !== -1) {
          item.response.forEach((sc) => {
            if (
              standard_index !== -1 &&
              apief[standard_index].newEfDates !== undefined &&
              apief[standard_index].newEfDates.length !== 0
            ) {
              dates.forEach((rpm) => {
                let date_index = findIndexByDate(
                  apief[standard_index].newEfDates,
                  rpm
                );

                if (
                  date_index !== -1 &&
                  apief[standard_index].newEfDates[date_index].newEfs !==
                    undefined &&
                  apief[standard_index].newEfDates[date_index].newEfs.length !==
                    0
                ) {
                  let cat_index = apief[standard_index].newEfDates[
                    date_index
                  ].newEfs.findIndex((i) => {
                    return i.category === 42;
                  });
                  console.log(
                    apief[standard_index].newEfDates[date_index].newEfs[
                      cat_index
                    ]
                  );
                  if (
                    cat_index !== -1 &&
                    apief[standard_index].newEfDates[date_index].newEfs[
                      cat_index
                    ].newEfItems !== undefined &&
                    apief[standard_index].newEfDates[date_index].newEfs[
                      cat_index
                    ].newEfItems.length !== 0
                  ) {
                    let sc_index = -1;

                    if (sc.DPAN1150 === 3) {
                      sc_index = apief[standard_index].newEfDates[
                        date_index
                      ].newEfs[cat_index].newEfItems.findIndex((i) => {
                        return (
                          i.subcategory1 === sc.DPAN1151 &&
                          i.subcategory2 === sc.DPAN1152 &&
                          i.subcategory3 === sc.DPAN1155
                        );
                      });
                    } else {
                      if (sc.DPAN1151 === 316 || sc.DPAN1151 === 317) {
                        sc_index = apief[standard_index].newEfDates[
                          date_index
                        ].newEfs[cat_index].newEfItems.findIndex((i) => {
                          return (
                            i.subcategory1 === sc.DPAN1151 &&
                            i.subcategory2 === sc.DPAN1152 &&
                            i.subcategory3 === sc.DPAN1154 &&
                            i.subcategory4 === sc.DPAN1155
                          );
                        });
                      } else {
                        sc_index = apief[standard_index].newEfDates[
                          date_index
                        ].newEfs[cat_index].newEfItems.findIndex((i) => {
                          return (
                            i.subcategory1 === sc.DPAN1151 &&
                            i.subcategory2 === sc.DPAN1152 &&
                            i.subcategory3 === sc.DPAN1153 &&
                            i.subcategory4 === sc.DPAN1155
                          );
                        });
                      }
                    }

                    const mode_options = [
                      { name: "Road", id: 1 },
                      { name: "Air", id: 2 },
                      { name: "Rail", id: 3 },
                      { name: "Sea", id: 4 },
                    ];
                    if (sc_index !== -1) {
                      scope = 3;
                      let ef =
                        apief[standard_index].newEfDates[date_index].newEfs[
                          cat_index
                        ].newEfItems[sc_index];
                      let ghg = parseFloat(
                        (
                          (parseFloat(sc.DPAN1156) *
                            parseFloat(sc.DPAN1157) *
                            ef.co2e) /
                          dates.length /
                          1000
                        ).toFixed(3)
                      );

                      sc.efdata = {
                        year: item.reporting_period[0].split("-")[1],
                        ef,
                        scope: 3,
                        ghg,
                        usage: 0,
                      };
                      month_data.push({
                        year: item.reporting_period[0].split("-")[1],
                        ef,
                        scope: 3,
                        ghg,
                        usage: 0,
                      });
                    }
                  }
                }
              });
            }
          });
        }
      }
    } else if (item.dcfId === 293) {
      console.log(item);
      const { match, dates } = isDateWithinPeriod(
        item.reporting_period,
        betweenMonths
      );
      if (match) {
        const standard_index = apief.findIndex((i) => {
          return i.id === item.standard;
        });
        if (standard_index !== -1) {
          item.response.forEach((sc) => {
            if (
              standard_index !== -1 &&
              apief[standard_index].newEfDates !== undefined &&
              apief[standard_index].newEfDates.length !== 0
            ) {
              dates.forEach((rpm) => {
                let date_index = findIndexByDate(
                  apief[standard_index].newEfDates,
                  rpm
                );

                if (
                  date_index !== -1 &&
                  apief[standard_index].newEfDates[date_index].newEfs !==
                    undefined &&
                  apief[standard_index].newEfDates[date_index].newEfs.length !==
                    0
                ) {
                  let cat_index = apief[standard_index].newEfDates[
                    date_index
                  ].newEfs.findIndex((i) => {
                    return i.category === 42;
                  });
                  console.log(
                    apief[standard_index].newEfDates[date_index].newEfs[
                      cat_index
                    ]
                  );
                  if (
                    cat_index !== -1 &&
                    apief[standard_index].newEfDates[date_index].newEfs[
                      cat_index
                    ].newEfItems !== undefined &&
                    apief[standard_index].newEfDates[date_index].newEfs[
                      cat_index
                    ].newEfItems.length !== 0
                  ) {
                    let sc_index = -1;

                    if (sc.DPAN1208 === 3) {
                      sc_index = apief[standard_index].newEfDates[
                        date_index
                      ].newEfs[cat_index].newEfItems.findIndex((i) => {
                        return (
                          i.subcategory1 === sc.DPAN1209 &&
                          i.subcategory2 === sc.DPAN1210 &&
                          i.subcategory3 === sc.DPAN1213
                        );
                      });
                    } else {
                      if (sc.DPAN1209 === 316 || sc.DPAN1209 === 317) {
                        sc_index = apief[standard_index].newEfDates[
                          date_index
                        ].newEfs[cat_index].newEfItems.findIndex((i) => {
                          return (
                            i.subcategory1 === sc.DPAN1209 &&
                            i.subcategory2 === sc.DPAN1210 &&
                            i.subcategory3 === sc.DPAN1212 &&
                            i.subcategory4 === sc.DPAN1213
                          );
                        });
                      } else {
                        sc_index = apief[standard_index].newEfDates[
                          date_index
                        ].newEfs[cat_index].newEfItems.findIndex((i) => {
                          return (
                            i.subcategory1 === sc.DPAN1209 &&
                            i.subcategory2 === sc.DPAN1210 &&
                            i.subcategory3 === sc.DPAN1211 &&
                            i.subcategory4 === sc.DPAN1213
                          );
                        });
                      }
                    }

                    const mode_options = [
                      { name: "Road", id: 1 },
                      { name: "Air", id: 2 },
                      { name: "Rail", id: 3 },
                      { name: "Sea", id: 4 },
                    ];
                    if (sc_index !== -1) {
                      scope = 3;
                      let ef =
                        apief[standard_index].newEfDates[date_index].newEfs[
                          cat_index
                        ].newEfItems[sc_index];
                      let ghg = parseFloat(
                        (
                          (parseFloat(sc.DPAN1214) *
                            parseFloat(sc.DPAN1215) *
                            ef.co2e) /
                          dates.length /
                          1000
                        ).toFixed(3)
                      );

                      sc.efdata = {
                        year: item.reporting_period[0].split("-")[1],
                        ef,
                        scope: 3,
                        ghg,
                        usage: 0,
                      };
                      month_data.push({
                        year: item.reporting_period[0].split("-")[1],
                        ef,
                        scope: 3,
                        ghg,
                        usage: 0,
                      });
                    }
                  }
                }
              });
            }
          });
        }
      }
    }

    item.ghg = month_data.map((i) => i.ghg).reduce((a, b) => a + b, 0);
    item.scope = scope;

    // DPA0292

    return month_data;
  };

  const renderData = (submission, filter) => {
    let filteredSubmissions = filterDataByTierAndLocation(
      submission.filter((i) =>
        isDateInFiscalYear(filter.year, getRPTextFormat(i.reporting_period))
      ),
      rawsitelist,
      filter.country,
      filter.city,
      filter.location
    );

    let scope1_2 = 0,
      scope3 = 0;
    let totalScope1 = [
      { name: "Stationary Combustion", value: 0 },
      { name: "Fugitive", value: 0 },
      { name: "Mobile Combustion", value: 0 },
    ];
    let months = generateMTDArray(filter.year, fymonth).map((i) => ({
      month: i,
      renewable: 0,
      nonrenewable: 0,
    }));
    let months_ = generateMTDArray(filter.year, fymonth).map((i) => ({
      month: i,
      stationary: 0,
      fugitive: 0,
      mobile: 0,
    }));
    let months__ = generateMTDArray(filter.year, fymonth).map((i) => ({
      month: i,
      data: filteredSubmissions.filter((x) => {
        return (
          getRPTextFormat(x.reporting_period) === i &&
          (x.dcfId === 246 || x.dcfId === 247)
        );
      }),
    }));

    for (const sub of filteredSubmissions) {
      if (sub.dcfId === 10 || sub.dcfId === 11 || sub.dcfId === 15) {
        let calcValue = Number(
          getEmissionFactorCalculation(sub, filter.year)
            .map((i) => i.ghg)
            .reduce((a, b) => a + b, 0)
            .toFixed(2)
        );
        let monthindex = months_.findIndex(
          (i) => i.month === getRPTextFormat(sub.reporting_period)
        );

        if (sub.dcfId === 11) {
          totalScope1[0].value += calcValue;
          if (monthindex !== -1) {
            months_[monthindex].stationary += calcValue;
          }
        } else if (sub.dcfId === 10) {
          totalScope1[1].value += calcValue;
          if (monthindex !== -1) {
            months_[monthindex].fugitive += calcValue;
          }
        } else if (sub.dcfId === 15) {
          totalScope1[2].value += calcValue;
          if (monthindex !== -1) {
            months_[monthindex].mobile += calcValue;
          }
        }
        scope1_2 += calcValue;
      } else if (sub.dcfId === 36 || sub.dcfId === 16) {
        scope3 += getEmissionFactorCalculation(sub, filter.year)
          .map((i) => i.ghg)
          .reduce((a, b) => a + b, 0);
      } else if (sub.dcfId === 257) {
        let calcdata = getEmissionFactorCalculation(sub, filter.year);

        let monthindex = months.findIndex(
          (i) => i.month === getRPTextFormat(sub.reporting_period)
        );
        if (monthindex !== -1) {
          months[monthindex].renewable += calcdata
            .map((i) => i.r)
            .reduce((a, b) => a + b, 0);
          months[monthindex].nonrenewable += calcdata
            .map((i) => i.nr)
            .reduce((a, b) => a + b, 0);
        } else {
          console.log(
            months.map((i) => getRPTextFormat([i.month])),
            getRPTextFormat(sub.reporting_period)
          );
        }
      }
    }
    console.log(months_);

    setGoalProps((prev) => ({
      ...prev,
      waterData: months__,
      totalScope1,
      monthArray: months,
      totalScope1Breakup: months_,
      scope2Data: months,
      scope1_2: scope1_2.toFixed(2),
      scope3: scope3.toFixed(2),
    }));
    forceUpdate();
  };

  const isSameOrFutureLocalDate = (utcDateString) => {
    // Parse the given UTC date string
    const givenDate = DateTime.fromISO(utcDateString, {
      zone: "utc",
    }).toLocal();
    console.log(givenDate.toFormat("dd-LLL-yyyy"));
    // Get the current local date
    const currentLocalDate = DateTime.local();

    // Compare the dates ignoring the time part
    return givenDate.startOf("day") >= currentLocalDate.startOf("day");
  };
  const checkEnity = (rowData, entity_list, rawsite, obj) => {
    let index = entity_list.findIndex((k) => k[obj] === rowData[obj]);
    if (index !== -1) {
      let entity = entity_list[index];
      console.log(entity);
      if (rowData.level === 0) {
        return entity.tier0_ids.includes(0);
      } else if (rowData.level === 1) {
        return (
          entity.tier1_ids.includes(rowData.locationId) &&
          getCoverageText(rowData, rawsite)
        );
      } else if (rowData.level === 2) {
        return (
          entity.tier2_ids.includes(rowData.locationId) &&
          getCoverageText(rowData, rawsite)
        );
      } else if (rowData.level === 3) {
        return (
          entity.tier3_ids.includes(rowData.locationId) &&
          getCoverageText(rowData, rawsite)
        );
      }
    } else {
      return false;
    }
  };
  const getCoverageText_ = (rowData, rawsitelist) => {
    console.log(rowData);
    let text = "";
    if (rowData.country === 0 && rowData.city === 0 && rowData.location === 0) {
      text = "Corporate";
    } else if (
      rowData.country !== 0 &&
      rowData.city === 0 &&
      rowData.location === 0
    ) {
      let country_index = rawsitelist.findIndex(
        (i) => i.id === rowData.country
      );
      console.log(country_index);
      if (country_index !== -1) {
        text = rawsitelist[country_index].name;
      }
    } else if (
      rowData.country !== 0 &&
      rowData.city !== 0 &&
      rowData.location === 0
    ) {
      let city_index = rawsitelist
        .flatMap((i) =>
          i.locationTwos.flatMap((j) =>
            j.locationThrees.map((k) => {
              return {
                site_id: k.id,
                site_name: k.name,
                city_id: j.id,
                city_name: j.name,
                country_id: i.id,
                country_name: i.name,
              };
            })
          )
        )
        .findIndex((i) => {
          return i.city_id === rowData.city;
        });
      if (city_index !== -1) {
        text = rawsitelist.flatMap((i) =>
          i.locationTwos.flatMap((j) =>
            j.locationThrees.map((k) => {
              return {
                site_id: k.id,
                site_name: k.name,
                city_id: j.id,
                city_name: j.name,
                country_id: i.id,
                country_name: i.name,
              };
            })
          )
        )[city_index].city_name;
      }
    } else {
      let site_index = rawsitelist
        .flatMap((i) =>
          i.locationTwos.flatMap((j) =>
            j.locationThrees.map((k) => {
              return {
                site_id: k.id,
                site_name: k.name,
                city_id: j.id,
                city_name: j.name,
                country_id: i.id,
                country_name: i.name,
              };
            })
          )
        )
        .findIndex((i) => {
          return i.site_id === rowData.location;
        });
      if (site_index !== -1) {
        text = rawsitelist.flatMap((i) =>
          i.locationTwos.flatMap((j) =>
            j.locationThrees.map((k) => {
              return {
                site_id: k.id,
                site_name: k.name,
                city_id: j.id,
                city_name: j.name,
                country_id: i.id,
                country_name: i.name,
              };
            })
          )
        )[site_index].site_name;
      }
    }

    return text;
  };
  const getCoverageText = (rowData, rawsitelist) => {
    let text = "";
    console.log(rowData);
    if (rowData.level === 0) {
      text = "Corporate";
    } else if (rowData.level === 1) {
      let country_index = rawsitelist.findIndex(
        (i) => i.id === rowData.locationId
      );
      if (country_index !== -1) {
        text = rawsitelist[country_index].name;
      }
    } else if (rowData.level === 2) {
      let city_index = rawsitelist
        .flatMap((i) =>
          i.locationTwos.flatMap((j) =>
            j.locationThrees.map((k) => {
              return {
                site_id: k.id,
                site_name: k.name,
                city_id: j.id,
                city_name: j.name,
                country_id: i.id,
                country_name: i.name,
              };
            })
          )
        )
        .findIndex((i) => {
          return i.city_id === rowData.locationId;
        });
      if (city_index !== -1) {
        text = rawsitelist.flatMap((i) =>
          i.locationTwos.flatMap((j) =>
            j.locationThrees.map((k) => {
              return {
                site_id: k.id,
                site_name: k.name,
                city_id: j.id,
                city_name: j.name,
                country_id: i.id,
                country_name: i.name,
              };
            })
          )
        )[city_index].city_name;
      }
    } else if (rowData.level === 3) {
      let site_index = rawsitelist
        .flatMap((i) =>
          i.locationTwos.flatMap((j) =>
            j.locationThrees.map((k) => {
              return {
                site_id: k.id,
                site_name: k.name,
                city_id: j.id,
                city_name: j.name,
                country_id: i.id,
                country_name: i.name,
              };
            })
          )
        )
        .findIndex((i) => {
          return i.site_id === rowData.locationId;
        });
      if (site_index !== -1) {
        text = rawsitelist.flatMap((i) =>
          i.locationTwos.flatMap((j) =>
            j.locationThrees.map((k) => {
              return {
                site_id: k.id,
                site_name: k.name,
                city_id: j.id,
                city_name: j.name,
                country_id: i.id,
                country_name: i.name,
              };
            })
          )
        )[site_index].site_name;
      }
    }
    return text;
  };
  const compareArrays = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };
  const splitArray = (array, chunkSize) => {
    return array.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / chunkSize);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);
  };
  const getRPLuxon = (months) => {
    if (months.includes("to")) {
      let startDate = DateTime.fromFormat(
        months.split("to")[0].trim(),
        "LLL-yyyy"
      );
      let endDate = DateTime.fromFormat(
        months.split("to")[1].trim(),
        "LLL-yyyy"
      );
      let rp = [];
      while (startDate <= endDate) {
        rp.push(startDate.toFormat("LL-yyyy"));
        startDate = startDate.plus({ months: 1 });
      }
      return rp;
    } else {
      return [DateTime.fromFormat(months, "LLL-yyyy").toFormat("LL-yyyy")];
    }
  };
  const checkSubmission = (dcf, level, locationId, rp, old) => {
    // let rps = getRP(rp)
    let rps = getRPLuxon(rp);

    let result = {};
    let loc = JSON.parse(JSON.stringify(old));

    let index = loc.findIndex((i) => {
      return (
        compareArrays(i.reporting_period, rps) &&
        i.level === level &&
        i.locationId === locationId &&
        i.dcfId === dcf.id &&
        i.type === 0
      );
    });
    let index2 = loc.findIndex((i) => {
      return (
        compareArrays(i.reporting_period, rps) &&
        i.level === level &&
        i.locationId === locationId &&
        i.dcfId === dcf.id &&
        i.type !== 0
      );
    });

    if (index === -1) {
      result = { result: false, data: index2 === -1 ? true : false };
    } else {
      result = {
        result: true,
        data: loc[index],
        list: loc.filter((i) => {
          return (
            compareArrays(i.reporting_period, rps) &&
            i.level === level &&
            i.locationId === locationId &&
            i.dcfId === dcf.id &&
            i.type === 0
          );
        }),
      };
    }

    return result;
  };
  function updateImgTags(htmlString) {
    // Regular expression to match <p> tags containing <img> tags
    const regex = /<p>(\s*<img[^>]*>\s*)<\/p>/g;

    // Replace matched <p> tags with the desired class and update <img> tag width
    return htmlString.replace(regex, (match, imgTag) => {
      // Update the <img> tag with width="30%"
      const updatedImgTag = imgTag.replace(
        /<img([^>]*)>/,
        '<img$1 width="30%">'
      );

      // Return the updated <p> tag with class and updated <img> tag
      return `<p class="flex justify-content-center">${updatedImgTag}</p>`;
    });
  }
  const getQuantitativeSubmissionData = (
    startDate,
    endDate,
    frequency,
    item,
    old
  ) => {
    var betweenMonths = [];
    let type = 0,
      past_ = [],
      future_ = [];

    past_ = JSON.parse(JSON.stringify(past));

    // future_ = JSON.parse(JSON.stringify(future))

    if (
      Math.round(
        startDate.diff(DateTime.utc().startOf("month"), "days").days
      ).toFixed(0) === 0
    ) {
      let endDate_ = startDate;

      let endDate__ = DateTime.utc().toLocal();
      if (frequency === 1) {
        let check = checkSubmission(
          item.dcf_,
          item.level,
          item.locationId,
          endDate__.toFormat("LLL-yyyy"),
          old
        );

        if (check.result) {
          future_.push({
            standard: item.standard === null ? 0 : item.standard,
            dcf: item.dcf_,
            entityAssId: item.entityAssId,
            entityUserAssId: item.id,
            reporting_period: endDate__.toFormat("LLL-yyyy"),
            duedate: endDate__.plus({ months: 1 }).toFormat("LLL-yyyy"),
            overdue: 0,
            level: item.level,
            locationId: item.locationId,
            company_id: admin_data.id,
            frequency,
            response: check.data.response,
            id: check.data.id,
            reject: check.data.reject,
            draft: check.data,
          });
        } else {
          if (check.data) {
            future_.push({
              standard: item.standard === null ? 0 : item.standard,
              dcf: item.dcf_,
              entityAssId: item.entityAssId,
              entityUserAssId: item.id,
              reporting_period: endDate__.toFormat("LLL-yyyy"),
              duedate: endDate__.plus({ months: 1 }).toFormat("LLL-yyyy"),
              overdue: 0,
              level: item.level,
              locationId: item.locationId,
              company_id: admin_data.id,
              frequency,
            });
          }
        }
      } else if (frequency === 6) {
        let check = checkSubmission(
          item.dcf_,
          item.level,
          item.locationId,
          endDate__.toFormat("LLL-yyyy"),
          old
        );

        if (check.result) {
          past_.push({
            standard: item.standard === null ? 0 : item.standard,
            dcf: item.dcf_,
            entityAssId: item.entityAssId,
            entityUserAssId: item.id,
            reporting_period: endDate__.toFormat("LLL-yyyy"),
            duedate: endDate__.toFormat("LLL-yyyy"),
            overdue: Math.round(endDate__.diff(endDate__, "days").days),
            level: item.level,
            locationId: item.locationId,
            company_id: admin_data.id,
            frequency,
            response: check.data.response,
            id: check.data.id,
            reject: check.data.reject,
            draft: check.data,
          });
        } else {
          if (check.data) {
            past_.push({
              standard: item.standard === null ? 0 : item.standard,
              dcf: item.dcf_,
              entityAssId: item.entityAssId,
              entityUserAssId: item.id,
              reporting_period: endDate__.toFormat("LLL-yyyy"),
              duedate: endDate__.toFormat("LLL-yyyy"),
              overdue: 0,
              level: item.level,
              locationId: item.locationId,
              type: 6,
              company_id: admin_data.id,
              frequency,
            });
          }
        }
      }
    } else if (
      Math.round(startDate.diff(DateTime.utc().startOf("month"), "days").days) <
      31
    ) {
      var date = startDate;
      console.log(startDate.diff(endDate.startOf("month"), "days").days);

      let endDate__ = DateTime.utc().toLocal();
      while (date <= endDate) {
        betweenMonths.push(date.toFormat("LLL-yyyy"));
        date = date.plus({ months: 1 });
      }
      console.log(betweenMonths, item.id);
      if (frequency === 1) {
        console.log(splitArray(betweenMonths, 1), "SP");
        splitArray(betweenMonths, 1).forEach((months, ind) => {
          console.log(
            endDate__.month ===
              DateTime.fromFormat(months[0], "LLL-yyyy").month &&
              endDate__.year === DateTime.fromFormat(months[0], "LLL-yyyy").year
          );
          if (
            endDate__.month ===
              DateTime.fromFormat(months[0], "LLL-yyyy").month &&
            endDate__.year === DateTime.fromFormat(months[0], "LLL-yyyy").year
          ) {
            let check = checkSubmission(
              item.dcf_,
              item.level,
              item.locationId,
              months[0],
              old
            );

            if (check.result) {
              future_.push({
                standard: item.standard === null ? 0 : item.standard,
                dcf: item.dcf_,
                entityAssId: item.entityAssId,
                entityUserAssId: item.id,
                reporting_period: months[0],
                duedate: DateTime.fromFormat(months[0], "LLL-yyyy")
                  .plus({ months: 1 })
                  .toFormat("LLL-yyyy"),
                overdue: Math.round(
                  endDate__.diff(
                    DateTime.fromFormat(months[0], "LLL-yyyy").plus({
                      months: 1,
                    }),
                    "days"
                  ).days
                ),
                level: item.level,
                locationId: item.locationId,
                company_id: admin_data.id,
                frequency,
                response: check.data.response,
                id: check.data.id,
                reject: check.data.reject,
                draft: check.data,
              });
            } else {
              if (check.data) {
                future_.push({
                  standard: item.standard === null ? 0 : item.standard,
                  dcf: item.dcf_,
                  entityAssId: item.entityAssId,
                  entityUserAssId: item.id,
                  reporting_period: months[0],
                  duedate: DateTime.fromFormat(months[0], "LLL-yyyy")
                    .plus({ months: 1 })
                    .toFormat("LLL-yyyy"),
                  overdue: Math.round(
                    endDate__.diff(
                      DateTime.fromFormat(months[0], "LLL-yyyy").plus({
                        months: 1,
                      }),
                      "days"
                    ).days
                  ),
                  level: item.level,
                  locationId: item.locationId,
                  company_id: admin_data.id,
                  frequency,
                });
              }
            }
          } else {
            if (
              Math.round(
                endDate__.diff(
                  DateTime.fromFormat(months[0], "LLL-yyyy"),
                  "months"
                ).months
              ) === 1
            ) {
              // if (endDate__ <= moment(months[0]).add(1, 'month').add(4, 'days')) {
              let check = checkSubmission(
                item.dcf_,
                item.level,
                item.locationId,
                months[0],
                old
              );

              if (check.result) {
                past_.push({
                  standard: item.standard === null ? 0 : item.standard,
                  dcf: item.dcf_,
                  entityAssId: item.entityAssId,
                  entityUserAssId: item.id,
                  reporting_period: months[0],
                  duedate: DateTime.fromFormat(months[0], "LLL-yyyy")
                    .plus({ months: 1 })
                    .toFormat("LLL-yyyy"),
                  overdue: Math.round(
                    endDate__.diff(
                      DateTime.fromFormat(months[0], "LLL-yyyy").plus({
                        months: 1,
                      }),
                      "days"
                    ).days
                  ),
                  level: item.level,
                  locationId: item.locationId,
                  company_id: admin_data.id,
                  frequency,
                  response: check.data.response,
                  id: check.data.id,
                  reject: check.data.reject,
                  draft: check.data,
                });
              } else {
                if (check.data) {
                  past_.push({
                    standard: item.standard === null ? 0 : item.standard,
                    dcf: item.dcf_,
                    entityAssId: item.entityAssId,
                    entityUserAssId: item.id,
                    reporting_period: months[0],
                    duedate: DateTime.fromFormat(months[0], "LLL-yyyy")
                      .plus({ months: 1 })
                      .toFormat("LLL-yyyy"),
                    overdue: Math.round(
                      endDate__.diff(
                        DateTime.fromFormat(months[0], "LLL-yyyy").plus({
                          months: 1,
                        }),
                        "days"
                      ).days
                    ),
                    level: item.level,
                    locationId: item.locationId,
                    company_id: admin_data.id,
                    frequency,
                  });
                }
              }
            } else {
              let check = checkSubmission(
                item.dcf_,
                item.level,
                item.locationId,
                months[0],
                old
              );
              console.log(check, months[0]);
              if (check.result) {
                past_.push({
                  standard: item.standard === null ? 0 : item.standard,
                  dcf: item.dcf_,
                  entityAssId: item.entityAssId,
                  entityUserAssId: item.id,
                  reporting_period: months[0],
                  duedate: DateTime.fromFormat(months[0], "LLL-yyyy")
                    .plus({ months: 1 })
                    .toFormat("LLL-yyyy"),
                  overdue: Math.round(
                    endDate__.diff(
                      DateTime.fromFormat(months[0], "LLL-yyyy").plus({
                        months: 1,
                      }),
                      "days"
                    ).days
                  ),
                  level: item.level,
                  locationId: item.locationId,
                  company_id: admin_data.id,
                  frequency,
                  response: check.data.response,
                  id: check.data.id,
                  reject: check.data.reject,
                  draft: check.data,
                });
              } else {
                if (check.data) {
                  past_.push({
                    standard: item.standard === null ? 0 : item.standard,
                    dcf: item.dcf_,
                    entityAssId: item.entityAssId,
                    entityUserAssId: item.id,
                    reporting_period: months[0],
                    duedate: DateTime.fromFormat(months[0], "LLL-yyyy")
                      .plus({ months: 1 })
                      .toFormat("LLL-yyyy"),
                    overdue: Math.round(
                      endDate__.diff(
                        DateTime.fromFormat(months[0], "LLL-yyyy").plus({
                          months: 1,
                        }),
                        "days"
                      ).days
                    ),
                    level: item.level,
                    locationId: item.locationId,
                    company_id: admin_data.id,
                    frequency,
                  });
                }
              }
            }
          }
        });
      } else if (frequency === 2) {
        splitArray(betweenMonths, 2).forEach((months) => {
          if (months.length === 2) {
            if (
              endDate__.month ===
                DateTime.fromFormat(months[1], "LLL-yyyy").month &&
              endDate__.year === DateTime.fromFormat(months[1], "LLL-yyyy").year
            ) {
              let check = checkSubmission(
                item.dcf_,
                item.level,
                item.locationId,
                months[0] + " to " + months[1],
                old
              );
              if (check.result) {
                future_.push({
                  standard: item.standard === null ? 0 : item.standard,
                  dcf: item.dcf_,
                  entityAssId: item.entityAssId,
                  entityUserAssId: item.id,
                  reporting_period: months[0] + " to " + months[1],
                  duedate: DateTime.fromFormat(months[1], "LLL-yyyy")
                    .plus({ months: 1 })
                    .toFormat("LLL-yyyy"),
                  overdue: Math.round(
                    endDate__.diff(
                      DateTime.fromFormat(months[1], "LLL-yyyy").plus({
                        months: 1,
                      }),
                      "days"
                    ).days
                  ),
                  level: item.level,
                  locationId: item.locationId,
                  company_id: admin_data.id,
                  frequency,
                  response: check.data.response,
                  id: check.data.id,
                  reject: check.data.reject,
                  draft: check.data,
                });
              } else {
                if (check.data) {
                  future_.push({
                    standard: item.standard === null ? 0 : item.standard,
                    dcf: item.dcf_,
                    entityAssId: item.entityAssId,
                    entityUserAssId: item.id,
                    reporting_period: months[0] + " to " + months[1],
                    duedate: DateTime.fromFormat(months[1], "LLL-yyyy")
                      .plus({ months: 1 })
                      .toFormat("LLL-yyyy"),
                    overdue: Math.round(
                      endDate__.diff(
                        DateTime.fromFormat(months[1], "LLL-yyyy").plus({
                          months: 1,
                        }),
                        "days"
                      ).days
                    ),
                    level: item.level,
                    locationId: item.locationId,
                    company_id: admin_data.id,
                    frequency,
                  });
                }
              }
            } else {
              if (
                Math.round(
                  endDate__.diff(
                    DateTime.fromFormat(months[1], "LLL-yyyy"),
                    "months"
                  ).months
                ) === 1
              ) {
                // if (endDate__ <= moment(months[1]).add(1, 'month').add(4, 'days')) {
                let check = checkSubmission(
                  item.dcf_,
                  item.level,
                  item.locationId,
                  months[0] + " to " + months[1],
                  old
                );
                if (check.result) {
                  past_.push({
                    standard: item.standard === null ? 0 : item.standard,
                    dcf: item.dcf_,
                    entityAssId: item.entityAssId,
                    entityUserAssId: item.id,
                    reporting_period: months[0] + " to " + months[1],
                    duedate: DateTime.fromFormat(months[1], "LLL-yyyy")
                      .plus({ months: 1 })
                      .toFormat("LLL-yyyy"),
                    overdue: Math.round(
                      endDate__.diff(
                        DateTime.fromFormat(months[1], "LLL-yyyy").plus({
                          months: 1,
                        }),
                        "days"
                      ).days
                    ),
                    level: item.level,
                    locationId: item.locationId,
                    company_id: admin_data.id,
                    frequency,
                    response: check.data.response,
                    id: check.data.id,
                    reject: check.data.reject,
                    draft: check.data,
                  });
                } else {
                  if (check.data) {
                    past_.push({
                      standard: item.standard === null ? 0 : item.standard,
                      dcf: item.dcf_,
                      entityAssId: item.entityAssId,
                      entityUserAssId: item.id,
                      reporting_period: months[0] + " to " + months[1],
                      duedate: DateTime.fromFormat(months[1], "LLL-yyyy")
                        .plus({ months: 1 })
                        .toFormat("LLL-yyyy"),
                      overdue: Math.round(
                        endDate__.diff(
                          DateTime.fromFormat(months[1], "LLL-yyyy").plus({
                            months: 1,
                          }),
                          "days"
                        ).days
                      ),
                      level: item.level,
                      locationId: item.locationId,
                      company_id: admin_data.id,
                      frequency,
                    });
                  }
                }
              } else {
                let check = checkSubmission(
                  item.dcf_,
                  item.level,
                  item.locationId,
                  months[0] + " to " + months[1],
                  old
                );

                if (check.result) {
                  past_.push({
                    standard: item.standard === null ? 0 : item.standard,
                    dcf: item.dcf_,
                    entityAssId: item.entityAssId,
                    entityUserAssId: item.id,
                    reporting_period: months[0] + " to " + months[1],
                    duedate: DateTime.fromFormat(months[1], "LLL-yyyy")
                      .plus({ months: 1 })
                      .toFormat("LLL-yyyy"),
                    overdue: Math.round(
                      endDate__.diff(
                        DateTime.fromFormat(months[1], "LLL-yyyy").plus({
                          months: 1,
                        }),
                        "days"
                      ).days
                    ),
                    level: item.level,
                    locationId: item.locationId,
                    company_id: admin_data.id,
                    frequency,
                    response: check.data.response,
                    id: check.data.id,
                    reject: check.data.reject,
                    draft: check.data,
                  });
                } else {
                  if (check.data) {
                    past_.push({
                      standard: item.standard === null ? 0 : item.standard,
                      dcf: item.dcf_,
                      entityAssId: item.entityAssId,
                      entityUserAssId: item.id,
                      reporting_period: months[0] + " to " + months[1],
                      duedate: DateTime.fromFormat(months[1], "LLL-yyyy")
                        .plus({ months: 1 })
                        .toFormat("LLL-yyyy"),
                      overdue: Math.round(
                        endDate__.diff(
                          DateTime.fromFormat(months[1], "LLL-yyyy").plus({
                            months: 1,
                          }),
                          "days"
                        ).days
                      ),
                      level: item.level,
                      locationId: item.locationId,
                      company_id: admin_data.id,
                      frequency,
                    });
                  }
                }
              }
            }
          }
        });
      } else if (frequency === 3) {
        splitArray(betweenMonths, 3).forEach((months) => {
          if (months.length === 3) {
            if (
              endDate__.month ===
                DateTime.fromFormat(months[2], "LLL-yyyy").month &&
              endDate__.year === DateTime.fromFormat(months[2], "LLL-yyyy").year
            ) {
              let check = checkSubmission(
                item.dcf_,
                item.level,
                item.locationId,
                months[0] + " to " + months[2],
                old
              );

              if (check.result) {
                future_.push({
                  standard: item.standard === null ? 0 : item.standard,
                  dcf: item.dcf_,
                  entityAssId: item.entityAssId,
                  entityUserAssId: item.id,
                  reporting_period: months[0] + " to " + months[2],
                  duedate: DateTime.fromFormat(months[2], "LLL-yyyy")
                    .plus({ months: 1 })
                    .toFormat("LLL-yyyy"),
                  overdue: Math.round(
                    endDate__.diff(
                      DateTime.fromFormat(months[2], "LLL-yyyy").plus({
                        months: 1,
                      }),
                      "days"
                    ).days
                  ),
                  level: item.level,
                  locationId: item.locationId,
                  company_id: admin_data.id,
                  frequency,
                  response: check.data.response,
                  id: check.data.id,
                  reject: check.data.reject,
                  draft: check.data,
                });
              } else {
                if (check.data) {
                  future_.push({
                    standard: item.standard === null ? 0 : item.standard,
                    dcf: item.dcf_,
                    entityAssId: item.entityAssId,
                    entityUserAssId: item.id,
                    reporting_period: months[0] + " to " + months[2],
                    duedate: DateTime.fromFormat(months[2], "LLL-yyyy")
                      .plus({ months: 1 })
                      .toFormat("LLL-yyyy"),
                    overdue: Math.round(
                      endDate__.diff(
                        DateTime.fromFormat(months[2], "LLL-yyyy").plus({
                          months: 1,
                        }),
                        "days"
                      ).days
                    ),
                    level: item.level,
                    locationId: item.locationId,
                    company_id: admin_data.id,
                    frequency,
                  });
                }
              }
            } else {
              if (
                Math.round(
                  endDate__.diff(
                    DateTime.fromFormat(months[2], "LLL-yyyy"),
                    "months"
                  ).months
                ) === 1
              ) {
                // if (endDate__ <= moment(months[2]).add(1, 'month').add(4, 'days')) {
                let check = checkSubmission(
                  item.dcf_,
                  item.level,
                  item.locationId,
                  months[0] + " to " + months[2],
                  old
                );

                if (check.result) {
                  past_.push({
                    standard: item.standard === null ? 0 : item.standard,
                    dcf: item.dcf_,
                    entityAssId: item.entityAssId,
                    entityUserAssId: item.id,
                    reporting_period: months[0] + " to " + months[2],
                    duedate: DateTime.fromFormat(months[2], "LLL-yyyy")
                      .plus({ months: 1 })
                      .toFormat("LLL-yyyy"),
                    overdue: Math.round(
                      endDate__.diff(
                        DateTime.fromFormat(months[2], "LLL-yyyy").plus({
                          months: 1,
                        }),
                        "days"
                      ).days
                    ),
                    level: item.level,
                    locationId: item.locationId,
                    company_id: admin_data.id,
                    frequency,
                    response: check.data.response,
                    id: check.data.id,
                    reject: check.data.reject,
                    draft: check.data,
                  });
                } else {
                  if (check.data) {
                    past_.push({
                      standard: item.standard === null ? 0 : item.standard,
                      dcf: item.dcf_,
                      entityAssId: item.entityAssId,
                      entityUserAssId: item.id,
                      reporting_period: months[0] + " to " + months[2],
                      duedate: DateTime.fromFormat(months[2], "LLL-yyyy")
                        .plus({ months: 1 })
                        .toFormat("LLL-yyyy"),
                      overdue: Math.round(
                        endDate__.diff(
                          DateTime.fromFormat(months[2], "LLL-yyyy").plus({
                            months: 1,
                          }),
                          "days"
                        ).days
                      ),
                      level: item.level,
                      locationId: item.locationId,
                      company_id: admin_data.id,
                      frequency,
                    });
                  }
                }
              } else {
                let check = checkSubmission(
                  item.dcf_,
                  item.level,
                  item.locationId,
                  months[0] + " to " + months[2],
                  old
                );

                if (check.result) {
                  past_.push({
                    standard: item.standard === null ? 0 : item.standard,
                    dcf: item.dcf_,
                    entityAssId: item.entityAssId,
                    entityUserAssId: item.id,
                    reporting_period: months[0] + " to " + months[2],
                    duedate: DateTime.fromFormat(months[2], "LLL-yyyy")
                      .plus({ months: 1 })
                      .toFormat("LLL-yyyy"),
                    overdue: Math.round(
                      endDate__.diff(
                        DateTime.fromFormat(months[2], "LLL-yyyy").plus({
                          months: 1,
                        }),
                        "days"
                      ).days
                    ),
                    level: item.level,
                    locationId: item.locationId,
                    company_id: admin_data.id,
                    frequency,
                    response: check.data.response,
                    id: check.data.id,
                    reject: check.data.reject,
                    draft: check.data,
                  });
                } else {
                  if (check.data) {
                    console.log(
                      item.site,
                      item.dcf_,
                      months[0] + " to " + months[2]
                    );
                    past_.push({
                      standard: item.standard === null ? 0 : item.standard,
                      dcf: item.dcf_,
                      entityAssId: item.entityAssId,
                      entityUserAssId: item.id,
                      reporting_period: months[0] + " to " + months[2],
                      duedate: DateTime.fromFormat(months[2], "LLL-yyyy")
                        .plus({ months: 1 })
                        .toFormat("LLL-yyyy"),
                      overdue: Math.round(
                        endDate__.diff(
                          DateTime.fromFormat(months[2], "LLL-yyyy").plus({
                            months: 1,
                          }),
                          "days"
                        ).days
                      ),
                      level: item.level,
                      locationId: item.locationId,
                      company_id: admin_data.id,
                      frequency,
                    });
                  }
                }
              }
            }
          }
        });
      } else if (frequency === 4) {
        splitArray(betweenMonths, 12).forEach((months) => {
          if (months.length === 12) {
            if (
              endDate__.month ===
                DateTime.fromFormat(months[11], "LLL-yyyy").month &&
              endDate__.year ===
                DateTime.fromFormat(months[11], "LLL-yyyy").year
            ) {
              let check = checkSubmission(
                item.dcf_,
                item.level,
                item.locationId,
                months[0] + " to " + months[11],
                old
              );

              if (check.result) {
                future_.push({
                  standard: item.standard === null ? 0 : item.standard,
                  dcf: item.dcf_,
                  entityAssId: item.entityAssId,
                  entityUserAssId: item.id,
                  reporting_period: months[0] + " to " + months[11],
                  duedate: DateTime.fromFormat(months[11], "LLL-yyyy")
                    .plus({ months: 1 })
                    .toFormat("LLL-yyyy"),
                  overdue: Math.round(
                    endDate__.diff(
                      DateTime.fromFormat(months[11], "LLL-yyyy").plus({
                        months: 1,
                      }),
                      "days"
                    ).days
                  ),
                  level: item.level,
                  locationId: item.locationId,
                  company_id: admin_data.id,
                  frequency,
                  response: check.data.response,
                  id: check.data.id,
                  reject: check.data.reject,
                  draft: check.data,
                });
              } else {
                if (check.data) {
                  future_.push({
                    standard: item.standard === null ? 0 : item.standard,
                    dcf: item.dcf_,
                    entityAssId: item.entityAssId,
                    entityUserAssId: item.id,
                    reporting_period: months[0] + " to " + months[11],
                    duedate: DateTime.fromFormat(months[11], "LLL-yyyy")
                      .plus({ months: 1 })
                      .toFormat("LLL-yyyy"),
                    overdue: Math.round(
                      endDate__.diff(
                        DateTime.fromFormat(months[11], "LLL-yyyy").plus({
                          months: 1,
                        }),
                        "days"
                      ).days
                    ),
                    level: item.level,
                    locationId: item.locationId,
                    company_id: admin_data.id,
                    frequency,
                  });
                }
              }
            } else {
              if (
                Math.round(
                  endDate__.diff(
                    DateTime.fromFormat(months[11], "LLL-yyyy"),
                    "months"
                  ).months
                ) === 1
              ) {
                // if (endDate__ <= moment(months[11]).add(1, 'month').add(4, 'days')) {

                let check = checkSubmission(
                  item.dcf_,
                  item.level,
                  item.locationId,
                  months[0] + " to " + months[11],
                  old
                );

                if (check.result) {
                  past_.push({
                    standard: item.standard === null ? 0 : item.standard,
                    dcf: item.dcf_,
                    entityAssId: item.entityAssId,
                    entityUserAssId: item.id,
                    reporting_period: months[0] + " to " + months[11],
                    duedate: DateTime.fromFormat(months[11], "LLL-yyyy")
                      .plus({ months: 1 })
                      .toFormat("LLL-yyyy"),
                    overdue: Math.round(
                      endDate__.diff(
                        DateTime.fromFormat(months[11], "LLL-yyyy").plus({
                          months: 1,
                        }),
                        "days"
                      ).days
                    ),
                    level: item.level,
                    locationId: item.locationId,
                    company_id: admin_data.id,
                    frequency,
                    response: check.data.response,
                    id: check.data.id,
                    reject: check.data.reject,
                    draft: check.data,
                  });
                } else {
                  if (check.data) {
                    past_.push({
                      standard: item.standard === null ? 0 : item.standard,
                      dcf: item.dcf_,
                      entityAssId: item.entityAssId,
                      entityUserAssId: item.id,
                      reporting_period: months[0] + " to " + months[11],
                      duedate: DateTime.fromFormat(months[11], "LLL-yyyy")
                        .plus({ months: 1 })
                        .toFormat("LLL-yyyy"),
                      overdue: Math.round(
                        endDate__.diff(
                          DateTime.fromFormat(months[11], "LLL-yyyy").plus({
                            months: 1,
                          }),
                          "days"
                        ).days
                      ),
                      level: item.level,
                      locationId: item.locationId,
                      company_id: admin_data.id,
                      frequency,
                    });
                  }
                }
              } else {
                let check = checkSubmission(
                  item.dcf_,
                  item.level,
                  item.locationId,
                  months[0] + " to " + months[11],
                  old
                );

                if (check.result) {
                  past_.push({
                    standard: item.standard === null ? 0 : item.standard,
                    dcf: item.dcf_,
                    entityAssId: item.entityAssId,
                    entityUserAssId: item.id,
                    reporting_period: months[0] + " to " + months[11],
                    duedate: DateTime.fromFormat(months[11], "LLL-yyyy")
                      .plus({ months: 1 })
                      .toFormat("LLL-yyyy"),
                    overdue: Math.round(
                      endDate__.diff(
                        DateTime.fromFormat(months[11], "LLL-yyyy").plus({
                          months: 1,
                        }),
                        "days"
                      ).days
                    ),
                    level: item.level,
                    locationId: item.locationId,
                    company_id: admin_data.id,
                    frequency,
                    response: check.data.response,
                    id: check.data.id,
                    reject: check.data.reject,
                    draft: check.data,
                  });
                } else {
                  if (check.data) {
                    past_.push({
                      standard: item.standard === null ? 0 : item.standard,
                      dcf: item.dcf_,
                      entityAssId: item.entityAssId,
                      entityUserAssId: item.id,
                      reporting_period: months[0] + " to " + months[11],
                      duedate: DateTime.fromFormat(months[11], "LLL-yyyy")
                        .plus({ months: 1 })
                        .toFormat("LLL-yyyy"),
                      overdue: Math.round(
                        endDate__.diff(
                          DateTime.fromFormat(months[11], "LLL-yyyy").plus({
                            months: 1,
                          }),
                          "days"
                        ).days
                      ),
                      level: item.level,
                      locationId: item.locationId,
                      company_id: admin_data.id,
                      frequency,
                    });
                  }
                }
              }
            }
          }
        });
      } else if (frequency === 5) {
        splitArray(betweenMonths, 6).forEach((months) => {
          if (months.length === 6) {
            if (
              endDate__.month ===
                DateTime.fromFormat(months[5], "LLL-yyyy").month &&
              endDate__.year === DateTime.fromFormat(months[5], "LLL-yyyy").year
            ) {
              let check = checkSubmission(
                item.dcf_,
                item.level,
                item.locationId,
                months[0] + " to " + months[5],
                old
              );

              if (check.result) {
                future_.push({
                  standard: item.standard === null ? 0 : item.standard,
                  dcf: item.dcf_,
                  entityAssId: item.entityAssId,
                  entityUserAssId: item.id,
                  reporting_period: months[0] + " to " + months[5],
                  duedate: DateTime.fromFormat(months[5], "LLL-yyyy")
                    .plus({ months: 1 })
                    .toFormat("LLL-yyyy"),
                  overdue: Math.round(
                    endDate__.diff(
                      DateTime.fromFormat(months[5], "LLL-yyyy").plus({
                        months: 1,
                      }),
                      "days"
                    ).days
                  ),
                  level: item.level,
                  locationId: item.locationId,
                  company_id: admin_data.id,
                  frequency,
                  response: check.data.response,
                  id: check.data.id,
                  reject: check.data.reject,
                  draft: check.data,
                });
              } else {
                if (check.data) {
                  future_.push({
                    standard: item.standard === null ? 0 : item.standard,
                    dcf: item.dcf_,
                    entityAssId: item.entityAssId,
                    entityUserAssId: item.id,
                    reporting_period: months[0] + " to " + months[5],
                    duedate: DateTime.fromFormat(months[5], "LLL-yyyy")
                      .plus({ months: 1 })
                      .toFormat("LLL-yyyy"),
                    overdue: Math.round(
                      endDate__.diff(
                        DateTime.fromFormat(months[5], "LLL-yyyy").plus({
                          months: 1,
                        }),
                        "days"
                      ).days
                    ),
                    level: item.level,
                    locationId: item.locationId,
                    company_id: admin_data.id,
                    frequency,
                  });
                }
              }
            } else {
              if (
                Math.round(
                  endDate__.diff(
                    DateTime.fromFormat(months[5], "LLL-yyyy"),
                    "months"
                  ).months
                ) === 1
              ) {
                // if (endDate__ <= moment(months[5]).add(1, 'month').add(4, 'days')) {
                let check = checkSubmission(
                  item.dcf_,
                  item.level,
                  item.locationId,
                  months[0] + " to " + months[5],
                  old
                );

                if (check.result) {
                  past_.push({
                    standard: item.standard === null ? 0 : item.standard,
                    dcf: item.dcf_,
                    entityAssId: item.entityAssId,
                    entityUserAssId: item.id,
                    reporting_period: months[0] + " to " + months[5],
                    duedate: DateTime.fromFormat(months[5], "LLL-yyyy")
                      .plus({ months: 1 })
                      .toFormat("LLL-yyyy"),
                    overdue: Math.round(
                      endDate__.diff(
                        DateTime.fromFormat(months[5], "LLL-yyyy").plus({
                          months: 1,
                        }),
                        "days"
                      ).days
                    ),
                    level: item.level,
                    locationId: item.locationId,
                    company_id: admin_data.id,
                    frequency,
                    response: check.data.response,
                    id: check.data.id,
                    reject: check.data.reject,
                    draft: check.data,
                  });
                } else {
                  if (check.data) {
                    past_.push({
                      standard: item.standard === null ? 0 : item.standard,
                      dcf: item.dcf_,
                      entityAssId: item.entityAssId,
                      entityUserAssId: item.id,
                      reporting_period: months[0] + " to " + months[5],
                      duedate: DateTime.fromFormat(months[5], "LLL-yyyy")
                        .plus({ months: 1 })
                        .toFormat("LLL-yyyy"),
                      overdue: Math.round(
                        endDate__.diff(
                          DateTime.fromFormat(months[5], "LLL-yyyy").plus({
                            months: 1,
                          }),
                          "days"
                        ).days
                      ),
                      level: item.level,
                      locationId: item.locationId,
                      company_id: admin_data.id,
                      frequency,
                    });
                  }
                }

                // } else {
                //     let check = checkSubmission(item.dcf_, item.level,item.locationId, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), old)

                //     if (check.result) {

                //         past_.push({ dcf: item.dcf_,entityAssId:item.entityAssId,entityUserAssId:item.id, reporting_period: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), level:item.level,locationId:item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                //     } else {
                //         if (check.data) {
                //             past_.push({ dcf: item.dcf_,entityAssId:item.entityAssId,entityUserAssId:item.id, reporting_period: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), level:item.level,locationId:item.locationId, company_id: admin_data.id, frequency })

                //         }

                //     }
                // }
              } else {
                let check = checkSubmission(
                  item.dcf_,
                  item.level,
                  item.locationId,
                  months[0] + " to " + months[5],
                  old
                );

                if (check.result) {
                  past_.push({
                    standard: item.standard === null ? 0 : item.standard,
                    dcf: item.dcf_,
                    entityAssId: item.entityAssId,
                    entityUserAssId: item.id,
                    reporting_period: months[0] + " to " + months[5],
                    duedate: DateTime.fromFormat(months[5], "LLL-yyyy")
                      .plus({ months: 1 })
                      .toFormat("LLL-yyyy"),
                    overdue: Math.round(
                      endDate__.diff(
                        DateTime.fromFormat(months[5], "LLL-yyyy").plus({
                          months: 1,
                        }),
                        "days"
                      ).days
                    ),
                    level: item.level,
                    locationId: item.locationId,
                    company_id: admin_data.id,
                    frequency,
                    response: check.data.response,
                    id: check.data.id,
                    reject: check.data.reject,
                    draft: check.data,
                  });
                } else {
                  if (check.data) {
                    past_.push({
                      standard: item.standard === null ? 0 : item.standard,
                      dcf: item.dcf_,
                      entityAssId: item.entityAssId,
                      entityUserAssId: item.id,
                      reporting_period: months[0] + " to " + months[5],
                      duedate: DateTime.fromFormat(months[5], "LLL-yyyy")
                        .plus({ months: 1 })
                        .toFormat("LLL-yyyy"),
                      overdue: Math.round(
                        endDate__.diff(
                          DateTime.fromFormat(months[5], "LLL-yyyy").plus({
                            months: 1,
                          }),
                          "days"
                        ).days
                      ),
                      level: item.level,
                      locationId: item.locationId,
                      company_id: admin_data.id,
                      frequency,
                    });
                  }
                }
              }
            }
          }
        });
      } else if (frequency === 6) {
        splitArray(betweenMonths, 1).forEach((months, ind) => {
          if (
            endDate__.month ===
              DateTime.fromFormat(months[0], "LLL-yyyy").month &&
            endDate__.year === DateTime.fromFormat(months[0], "LLL-yyyy").year
          ) {
            let check = checkSubmission(
              item.dcf_,
              item.level,
              item.locationId,
              months[0],
              old
            );

            if (check.result) {
              past_.push({
                standard: item.standard === null ? 0 : item.standard,
                dcf: item.dcf_,
                entityAssId: item.entityAssId,
                entityUserAssId: item.id,
                reporting_period: months[0],
                duedate: DateTime.fromFormat(months[0], "LLL-yyyy")
                  .plus({ months: 1 })
                  .toFormat("LLL-yyyy"),
                overdue: Math.round(
                  endDate__.diff(
                    DateTime.fromFormat(months[0], "LLL-yyyy"),
                    "days"
                  ).days
                ),
                level: item.level,
                locationId: item.locationId,
                company_id: admin_data.id,
                frequency,
              });

              check.list.forEach((list) => {
                past_.push({
                  standard: item.standard === null ? 0 : item.standard,
                  dcf: item.dcf_,
                  entityAssId: item.entityAssId,
                  entityUserAssId: item.id,
                  reporting_period: months[0],
                  duedate: DateTime.fromFormat(months[0], "LLL-yyyy")
                    .plus({ months: 1 })
                    .toFormat("LLL-yyyy"),
                  overdue: Math.round(
                    endDate__.diff(
                      DateTime.fromFormat(months[0], "LLL-yyyy"),
                      "days"
                    ).days
                  ),
                  level: item.level,
                  locationId: item.locationId,
                  company_id: admin_data.id,
                  frequency,
                  response: list.response,
                  id: list.id,
                  reject: list.reject,
                  draft: list,
                });
              });
            } else {
              if (check.data) {
                past_.push({
                  standard: item.standard === null ? 0 : item.standard,
                  dcf: item.dcf_,
                  entityAssId: item.entityAssId,
                  entityUserAssId: item.id,
                  reporting_period: months[0],
                  duedate: DateTime.fromFormat(months[0], "LLL-yyyy")
                    .plus({ months: 1 })
                    .toFormat("LLL-yyyy"),
                  overdue: Math.round(
                    endDate__.diff(
                      DateTime.fromFormat(months[0], "LLL-yyyy"),
                      "days"
                    ).days
                  ),
                  level: item.level,
                  locationId: item.locationId,
                  company_id: admin_data.id,
                  frequency,
                });
              }
            }
          } else {
            let check = checkSubmission(
              item.dcf_,
              item.level,
              item.locationId,
              months[0],
              old
            );

            if (check.result) {
              check.list.forEach((list) => {
                past_.push({
                  standard: item.standard === null ? 0 : item.standard,
                  dcf: item.dcf_,
                  entityAssId: item.entityAssId,
                  entityUserAssId: item.id,
                  reporting_period: months[0],
                  duedate: DateTime.fromFormat(months[0], "LLL-yyyy")
                    .plus({ months: 1 })
                    .toFormat("LLL-yyyy"),
                  overdue: Math.round(
                    endDate__.diff(
                      DateTime.fromFormat(months[0], "LLL-yyyy"),
                      "days"
                    ).days
                  ),
                  level: item.level,
                  locationId: item.locationId,
                  company_id: admin_data.id,
                  frequency,
                  response: list.response,
                  id: list.id,
                });
              });
            } else {
              if (check.data) {
                past_.push({
                  standard: item.standard === null ? 0 : item.standard,
                  dcf: item.dcf_,
                  entityAssId: item.entityAssId,
                  entityUserAssId: item.id,
                  reporting_period: months[0],
                  duedate: months[0],
                  overdue: Math.round(
                    endDate__.diff(
                      DateTime.fromFormat(months[0], "LLL-yyyy"),
                      "days"
                    ).days
                  ),
                  level: item.level,
                  locationId: item.locationId,
                  company_id: admin_data.id,
                  frequency,
                });
              }
            }
          }
        });
      }
    }
    console.log(past_);

    setPast((prev) => [...prev, ...past_]);

    return { type, months: betweenMonths };
  };

  const updateFilter = (obj,val ) => {
    let loc = { ...filter, [obj]: val };

    if (obj === "year") {
      let filteredEntity = filterDataByTierAndLocation( assignmentlist,rawsitelist,filter.country,filter.city,filter.location)

      let data = filterSubmissionStatus(
        rawsitelist,
        submission,
        filteredEntity,
        val,
        dcflist
      );

      setData(data);
      setDataBk(data);
      setFilter((prev) => ({
        ...prev,
        entity: "All",
        dcf: 0,
        searchstr: "",
        [obj]: val,
      }));
    }else{
      let item = updateLocationFilter(obj,val)
     
      let filteredEntity = filterDataByTierAndLocation( assignmentlist,rawsitelist,item.country,item.city,item.location)
     
      let data = filterSubmissionStatus(
        rawsitelist,
        submission,
        filteredEntity,
        filter.year,
        dcflist
      );
      setFilter((prev)=>({...prev,...item}))
      setData(data);
      setDataBk(data);
    }
  };

  function toggleReadMore() {
    const contentDiv = document.getElementById("readmore");
    const button = document.getElementById("toggleButton");
    if (contentDiv.classList.contains("expanded")) {
      contentDiv.classList.remove("expanded");
      button.textContent = "Read More";
    } else {
      contentDiv.classList.add("expanded");
      button.textContent = "Read Less";
    }
  }
  const getRoles = (info) => {
    if (info.role !== undefined || info.role !== null) {
      return Object.entries(info.role)
        .filter((i) => i[1] === true)
        .map((i) => i[0])
        .join("/ ");
    } else {
      return "Unknown";
    }
  };

  const entityRowFilterTemplate = (options) => {
    let allentity = JSON.parse(JSON.stringify(data));
    let allOptions = allentity.map((i) => i.coverage);
    let entOptions = [];
    allOptions.forEach((i) => {
      if (!entOptions.includes(i)) {
        entOptions.push(i);
      }
    });

    console.log(allentity);
    return (
      <MultiSelect
        panelClassName={"hidefilter"}
        value={options.value}
        options={entOptions}
        filter
        onChange={(e) => options.filterCallback(e.value)}
        placeholder="Any"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
      />
    );
  };
  const getCoverageTextById = (rowData, rawsitelist) => {
    let text = "";
    console.log(rowData);
    if (rowData.tier0_id !== null) {
      text = "Corporate";
    } else if (rowData.tier1_id !== null) {
      let country_index = rawsitelist.findIndex(
        (i) => i.id === rowData.tier1_id
      );
      if (country_index !== -1) {
        text = rawsitelist[country_index].name;
      }
    } else if (rowData.tier2_id !== null) {
      let city_index = rawsitelist
        .flatMap((i) =>
          i.locationTwos.flatMap((j) =>
            j.locationThrees.map((k) => {
              return {
                site_id: k.id,
                site_name: k.name,
                city_id: j.id,
                city_name: j.name,
                country_id: i.id,
                country_name: i.name,
              };
            })
          )
        )
        .findIndex((i) => {
          return i.city_id === rowData.tier2_id;
        });
      if (city_index !== -1) {
        text = rawsitelist.flatMap((i) =>
          i.locationTwos.flatMap((j) =>
            j.locationThrees.map((k) => {
              return {
                site_id: k.id,
                site_name: k.name,
                city_id: j.id,
                city_name: j.name,
                country_id: i.id,
                country_name: i.name,
              };
            })
          )
        )[city_index].city_name;
      }
    } else if (rowData.tier3_id !== null) {
      let site_index = rawsitelist
        .flatMap((i) =>
          i.locationTwos.flatMap((j) =>
            j.locationThrees.map((k) => {
              return {
                site_id: k.id,
                site_name: k.name,
                city_id: j.id,
                city_name: j.name,
                country_id: i.id,
                country_name: i.name,
              };
            })
          )
        )
        .findIndex((i) => {
          return i.site_id === rowData.tier3_id;
        });
      if (site_index !== -1) {
        text = rawsitelist.flatMap((i) =>
          i.locationTwos.flatMap((j) =>
            j.locationThrees.map((k) => {
              return {
                site_id: k.id,
                site_name: k.name,
                city_id: j.id,
                city_name: j.name,
                country_id: i.id,
                country_name: i.name,
              };
            })
          )
        )[site_index].site_name;
      }
    }
    return text;
  };

  const statusBodyTemplate = (rowData) => {
   console.log(rowData)
    switch (rowData.status) {
      case 6:
        return <Badge severity="warning" value="Due Now" />;
      case 7:
        return <Badge severity="danger" value="Overdue" />;
      case 9:
        return <Badge severity="info" value="Due Soon" />;
    
        return "Other";
    }
  };

  const actionBodyTemplate = (rowData) => {
    switch (rowData.status) {
      case 1:
        return (
          <span
            style={{ cursor: "pointer" }}
            className="fw-7 fs-16 clr-navy"
            onClick={() => {
              window.open(
                window.location.origin +
                  "/data_input_past/" +
                  rowData.dcfId +
                  "/" +
                  rowData.data.id
              );
            }}
          >
            {dcflist.find((i) => i.id === rowData.dcfId).title}  <Tag style={{ padding: "4px 8px" }} className={'status-tag-'}>
                Requires Resubmission
            </Tag>
          </span>
        );
      case 3:
        return (
          <span
            style={{ cursor: "pointer" }}
            className="fw-7 fs-16 clr-navy"
            onClick={() => {
              window.open(
                window.location.origin +
                  "/data_input_reviewer/" +
                  rowData.dcfId +
                  "/" +
                  rowData.data.id
              );
            }}
          >
            {dcflist.find((i) => i.id === rowData.dcfId).title}
           
          </span>
        );
      case 4:
        return (
          <span
            style={{ cursor: "pointer" }}
            className="fw-7 fs-16 clr-navy"
            onClick={() => {
              window.open(
                window.location.origin +
                  "/data_input_approver/" +
                  rowData.dcfId +
                  "/" +
                  rowData.data.id
              );
            }}
          >
            {dcflist.find((i) => i.id === rowData.dcfId).title}
          </span>
        );
      case 5:
        return (
          <span
            className="fw-7 fs-16 clr-navy"
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open(
                window.location.origin +
                  "/data_input_view/" +
                  rowData.dcfId +
                  "/" +
                  rowData.data.id
              );
            }}
          >
            {dcflist.find((i) => i.id === rowData.dcfId).title}
          </span>
        );
      case 6:
        return (
          <span
            className="fw-7 fs-16 clr-navy"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (rowData?.data) {
                window.open(
                  window.location.origin +
                    "/data_input_past/" +
                    rowData.dcfId +
                    "/" +
                    rowData.data.id
                );
              } else {
                window.open(
                  window.location.origin + "/data_input_newer/" + rowData.dcfId
                ).sessionStorage.setItem('newer', JSON.stringify({ ...rowData ,company_id: admin_data.id}))
              }
            }}
          >
            {dcflist.find((i) => i.id === rowData.dcfId).title}
           
          </span>
        );
      case 7:
        return (
          <span
            className="fw-7 fs-16 clr-navy"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (rowData?.data) {
                window.open(
                  window.location.origin +
                    "/data_input_past/" +
                    rowData.dcfId +
                    "/" +
                    rowData.data.id
                );
              } else {
            
                window.open(
                  window.location.origin + "/data_input_newer/" + rowData.dcfId
                ).sessionStorage.setItem('newer', JSON.stringify({ ...rowData, company_id: admin_data.id  }))
              }
            }}
          >
            {dcflist.find((i) => i.id === rowData.dcfId).title}
            {rowData?.data && <Tag style={{ display: 'inline-block', marginLeft:5 }} className={'status-tag-gray'}>
                Drafted
            </Tag>}
          </span>
        );
      case 99:
        return (
          <span
            style={{ cursor: "pointer" }}
            className="fw-7 fs-16 clr-navy"
            onClick={() => {
              window.open(
                window.location.origin +
                  "/data_input_past/" +
                  rowData.dcfId +
                  "/" +
                  rowData.data.id
              );
            }}
          >
            {dcflist.find((i) => i.id === rowData.dcfId).title}
             <Tag style={{ display: 'inline-block', marginLeft:5 }} className={'status-tag-gray'}>
                Drafted
            </Tag>
          </span>
        );
      case 100:
        return (
          <span
            style={{ cursor: "pointer" }}
            className="fw-7 fs-16 clr-navy"
            onClick={() => {
              window.open(
                window.location.origin + "/data_input_newer/" + rowData.dcfId
              ).sessionStorage.setItem('newer', JSON.stringify({ ...rowData, company_id: admin_data.id  }))
            }}
          >
            {dcflist.find((i) => i.id === rowData.dcfId).title}
          </span>
        );
      default:
        return "";
    }
  };

  const dcfRowFilterTemplate = (options) => {
    let allentity = JSON.parse(JSON.stringify(data));
    let IdOptions = allentity.map((i) => i.dcfId);
    let dcfOptions = dcflist
      .filter((i) => IdOptions.includes(i.id))
      .map((i) => {
        return { title: i.title, id: i.id };
      });

    console.log(allentity);
    return (
      <MultiSelect
        panelClassName={"hidefilter"}
        filter
        value={options.value}
        options={dcfOptions}
        optionValue="id"
        optionLabel="title"
        onChange={(e) => options.filterCallback(e.value)}
        placeholder="Any"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
      />
    );
  };

  const rpRowFilterTemplate = (options) => {
    let allentity = JSON.parse(JSON.stringify(data));
    let allOptions = allentity.map((i) => i.reporting_period);
    let entOptions = [];
    allOptions.forEach((i) => {
      if (!entOptions.includes(i)) {
        entOptions.push(i);
      }
    });

    console.log(allentity);
    return (
      <MultiSelect
        panelClassName={"hidefilter"}
        value={options.value}
        options={entOptions}
        filter
        onChange={(e) => options.filterCallback(e.value)}
        placeholder="Any"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
      />
    );
  };

  const statusRowFilterTemplate = (options) => {
    let allStatusOptions = [
      
      { name: "Submissions Overdue", id: 7 },
      { name: "Submission Due", id: 6 },
      { name: "Due Soon", id: 9 }
   
      
    ];

    return (
      <MultiSelect
        panelClassName={"hidefilter"}
        value={options.value}
        options={allStatusOptions}
        optionLabel="name"
        optionValue="id"
        filter
        onChange={(e) => options.filterCallback(e.value)}
        placeholder="Any"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
      />
    );
  };

  const sectionRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        panelClassName={"hidefilter"}
        value={options.value}
        options={section}
        optionLabel="name"
        optionValue="id"
        filter
        onChange={(e) => options.filterCallback(e.value)}
        placeholder="Any"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
      />
    );
  };
  const activeTab = (status) => {
    if (status === activetab) {
    } else {
      if (status !== 6) {
        let filteredArray = databk.filter((i) => i.status === status);
        setData(filteredArray);
        setActiveTab(status);
      } else {
        let filteredArray = databk.filter((i) => [6, 7].includes(i.status));
        setData(filteredArray);
        setActiveTab(status);
      }
    }
  };

  const getCountByStatus = (code,type) => {
    console.log(databk.filter(i=>i.dcfId === 47))
    // let filtered = dt.current.getVirtualScroller().props.items.map(i => i.id)
          if(type === 'reporter'){
            const arr = JSON.parse(JSON.stringify(databk));
            return arr?.filter((i) => code.includes(i.status) && i.reporter_ids && i.reporter_ids.includes(login_data.id)  ).length;
         
          }else    if(type === 'reviewer'){
            const arr = JSON.parse(JSON.stringify(databk));
            return arr?.filter((i) => code.includes(i.status) && i.reviewer_ids && i.reviewer_ids.includes(login_data.id)  ).length;
         
          }else    if(type === 'approver'){
            const arr = JSON.parse(JSON.stringify(databk));
            return arr?.filter((i) => code.includes(i.status) && i.approver_ids && i.approver_ids.includes(login_data.id)  ).length;
         
          }
    
  };

  return (
    <div className="bg-smoke font-lato ">
      <div className="col-12">

        <div>
        <div className="col-12 flex align-items-center" >
             <span className="text-big-one"> Hello  {login_data?.role === 'clientadmin' ?  login_data?.information?.companyname : login_data?.information?.empname} ! </span>   <span className="ml-1">{`<${login_data.email}>`} </span>   
                    </div>
          <div
            className="col-12 "
            style={{
              justifyContent: "flex-start",
            }}
          >
            <label className="text-big-one clr-navy flex fw-7 flex justify-content-start">
              
              My Actions
            </label>
            <label className="fs-14 clr-navy flex  justify-content-start">
            This page lists your tasks for the Sustainability Reporting process, including submissions, reviews, or approvals, as applicable. Please complete each action to keep the reporting process on track.
            </label>
          </div>
          <div className="flex">
            <div className=" flex ">
              <div className="col-8 grid m-0">
                <div className="col-12 ">Select Year :</div>
                <div className="col-4">
                  <Dropdown
                    optionLabel="label"
                    optionValue="name"
                    value={filter.year}
                    style={{ width: 200, borderRadius: 0 }}
                    options={[ {name:0,label:'All Years'} ,...yearOptions]}
                    className="ml-2"
                    onChange={(e) => {
                      updateFilter( "year",e.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-5 grid m-0">
              <div className="col-12">Location :</div>
              <div className="grid m-0 col-12">
                <div className="col-4">
                  <Dropdown
                    value={filter.country}
                    options={locList.country}
                    onChange={(e) => {
                      updateFilter("country", e.value);
                    }}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select Country"
                    style={{ width: "100%", borderRadius: 0 }}
                  />
                </div>
                {filter.country !== 0 &&
                  (filter.city !== null || filter.city === 0) && (
                    <div className="col-4">
                      <Dropdown
                        value={filter.city}
                        options={locList.city}
                        onChange={(e) => {
                          updateFilter("city", e.value);
                        }}
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Select Region"
                        disabled={!locList.city.length}
                        style={{ width: "100%", borderRadius: 0 }}
                      />
                    </div>
                  )}
                {filter.city !== 0 && filter.location !== null && (
                  <div className="col-4">
                    <Dropdown
                      value={filter.location}
                      options={locList.location}
                      onChange={(e) => {
                        updateFilter("location", e.value);
                      }}
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Select Business Unit"
                      disabled={!locList.location.length}
                      style={{ width: "100%", borderRadius: 0 }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="incident report table"
            >
              <Tab
                label={
                  <Typography variant="body1" style={customFontStyle}>
                    Submissions <span>({getCountByStatus([6,7,9],'reporter' )})</span>
                  </Typography>
                }
                value={TABS.SUBMISSIONS}
              />
              <Tab
                label={
                  <Typography variant="body1" style={customFontStyle}>
                    Reviews <span>({getCountByStatus([3],'reviewer')})</span>
                  </Typography>
                }
                value={TABS.REVIEWS}
              />
              <Tab
                label={
                  <Typography variant="body1" style={customFontStyle}>
                    Approvals <span>({getCountByStatus([4],'approver')})</span>
                  </Typography>
                }
                value={TABS.APPROVALS}
              />
            </Tabs>
            <CustomTabPanel value={value} tabValue={TABS.SUBMISSIONS}>
              <div className="m-3 fs-14 fw-5">
                Listed below are all the submissions required from your end.
                Click on an item to open a submission form.
              </div>
              <div>
                <DataTable
                  ref={dtref1}
                  value={data.filter( i =>  (i.reporter_ids && i.reporter_ids.includes(login_data.id) && (i.status === 6 || i.status === 7 || i.status === 9  )) )}
                  tableClassName="font-lato"
                  filters={{
                    dcfId: { value: null, matchMode: "in" },
                    coverage: { value: null, matchMode: "in" },
                    reporting_period: { value: null, matchMode: "in" },
                    status: { value: null, matchMode: "in" },
                    section: { value: null, matchMode: "in" },
                  }}
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  rows={10}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="({currentPage} of {totalPages})"
                  paginator
                  scrollable
                  emptyMessage="No Assignment(s)"
                >
                  <Column
                    header="Timeline"
                    style={{ minWidth: "8%" }}
                    field="status"
                    showFilterMatchModes={false}
                    showApplyButton={true}
                    filterElement={statusRowFilterTemplate}
                    body={statusBodyTemplate}
                    filter
                  />

                  <Column
                    header="Required Submissions"
                    field="dcfId"
                    style={{ minWidth: "20%" }}
                    body={actionBodyTemplate}
                    showFilterMatchModes={false}
                    showApplyButton={true}
                    filterElement={dcfRowFilterTemplate}
                    filter
                  />

                  <Column
                    header="Section"
                    style={{ minWidth: "8%" }}
                    field="section"
                    showFilterMatchModes={false}
                    showApplyButton={true}
                    filterElement={sectionRowFilterTemplate}
                    body={sectionTemplate}
                    filter
                  />

                  <Column
                    header="Reporting Entity"
                    field="coverage"
                    style={{ minWidth: "10%" }}
                    showFilterMatchModes={false}
                    showApplyButton={true}
                    filterElement={entityRowFilterTemplate}
                    filter
                  />

                  <Column
                    header="Reporting Period"
                    style={{ minWidth: "8%" }}
                    field="reporting_period"
                    showFilterMatchModes={false}
                    showApplyButton={true}
                    filterElement={rpRowFilterTemplate}
                    filter
                  />

                  {/* <Column
                    header="DCF"
                    field="dcfId"
                    style={{ minWidth: "20%" }}
                    body={dcfTemplate}
                    showFilterMatchModes={false}
                    showApplyButton={true}
                    filterElement={dcfRowFilterTemplate}
                    filter
                  /> */}
                </DataTable>
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} tabValue={TABS.REVIEWS}>
              <div>
                <DataTable
                  ref={dtref2}
                  value={data.filter( i =>  (i.reviewer_ids && i.reviewer_ids.includes(login_data.id) && (i.status === 3 )) )}
                  tableClassName="font-lato"
                  filters={{
                    dcfId: { value: null, matchMode: "in" },
                    coverage: { value: null, matchMode: "in" },
                    reporting_period: { value: null, matchMode: "in" },
                    status: { value: null, matchMode: "in" },
                    section: { value: null, matchMode: "in" },
                  }}
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  rows={10}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="({currentPage} of {totalPages})"
                  paginator
                  scrollable
                  emptyMessage="No Assignment(s)"
                >
                  <Column
                    header="Timeline"
                    style={{ minWidth: "8%" }}
                    field="status"
                    showFilterMatchModes={false}
                    showApplyButton={true}
                    filterElement={statusRowFilterTemplate}
                    body={statusBodyTemplate}
                    filter
                  />

                  <Column
                    header="Required Submission"
                    style={{ minWidth: "8%" }}
                    body={actionBodyTemplate}
                  />

                  <Column
                    header="Section"
                    style={{ minWidth: "8%" }}
                    field="section"
                    showFilterMatchModes={false}
                    showApplyButton={true}
                    filterElement={sectionRowFilterTemplate}
                    body={sectionTemplate}
                    filter
                  />

                  <Column
                    header="Reporting Entity"
                    field="coverage"
                    style={{ minWidth: "10%" }}
                    showFilterMatchModes={false}
                    showApplyButton={true}
                    filterElement={entityRowFilterTemplate}
                    filter
                  />

                  <Column
                    header="Reporting Period"
                    style={{ minWidth: "8%" }}
                    field="reporting_period"
                    showFilterMatchModes={false}
                    showApplyButton={true}
                    filterElement={rpRowFilterTemplate}
                    filter
                  />

                  <Column
                    header="Reporter"
                    field="reporter"
                    style={{ minWidth: "20%" }}
                    body={dcfTemplate}
                    showFilterMatchModes={false}
                    showApplyButton={true}
                    filterElement={dcfRowFilterTemplate}
                    filter
                  />
                </DataTable>
              </div>
            </CustomTabPanel>  
            <CustomTabPanel value={value} tabValue={TABS.APPROVALS}>
              <div>
                <DataTable
                  ref={dtref3}
                  value={data.filter( i =>  (i.approver_ids && i.approver_ids.includes(login_data.id) && (i.status === 4 )) )}
                  tableClassName="font-lato"
                  filters={{
                    dcfId: { value: null, matchMode: "in" },
                    coverage: { value: null, matchMode: "in" },
                    reporting_period: { value: null, matchMode: "in" },
                    status: { value: null, matchMode: "in" },
                    section: { value: null, matchMode: "in" },
                  }}
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  rows={10}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="({currentPage} of {totalPages})"
                  paginator
                  scrollable
                  emptyMessage="No Assignment(s)"
                >
                  <Column
                    header="Status"
                    style={{ minWidth: "8%" }}
                    field="status"
                    showFilterMatchModes={false}
                    showApplyButton={true}
                    filterElement={statusRowFilterTemplate}
                    body={statusBodyTemplate}
                    filter
                  />

                  <Column
                    header="Required Submission"
                    style={{ minWidth: "8%" }}
                    body={actionBodyTemplate}
                  />

                  <Column
                    header="Section"
                    style={{ minWidth: "8%" }}
                    field="section"
                    showFilterMatchModes={false}
                    showApplyButton={true}
                    filterElement={sectionRowFilterTemplate}
                    body={sectionTemplate}
                    filter
                  />

                  <Column
                    header="Reporting Entity"
                    field="coverage"
                    style={{ minWidth: "10%" }}
                    showFilterMatchModes={false}
                    showApplyButton={true}
                    filterElement={entityRowFilterTemplate}
                    filter
                  />

                  <Column
                    header="Reporting Period"
                    style={{ minWidth: "8%" }}
                    field="reporting_period"
                    showFilterMatchModes={false}
                    showApplyButton={true}
                    filterElement={rpRowFilterTemplate}
                    filter
                  />

                  {/* <Column
                    header="DCF"
                    field="dcfId"
                    style={{ minWidth: "20%" }}
                    body={dcfTemplate}
                    showFilterMatchModes={false}
                    showApplyButton={true}
                    filterElement={dcfRowFilterTemplate}
                    filter
                  /> */}
                </DataTable>
              </div>
            </CustomTabPanel>
          </div>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(TVSUserDashboard, comparisonFn);
