import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const data = [
  { year: "2023", Fatalities: 3, Recordable: 25, NearMisses: 35 },
  { year: "2024", Fatalities: 4, Recordable: 22, NearMisses: 40 },
];

const annotations = [
  { year: "2023", text: "New safety protocols introduced" },
  { year: "2024", text: "Increased training programs" },
];

const InjuryStackedColumnChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    renderChart();
  }, []);

  const renderChart = () => {
    const width = 600;
    const height = 400;
    const margin = { top: 50, right: 30, bottom: 120, left: 80 }; // Increased bottom margin for X-axis label

    // Clear any previous chart
    d3.select(chartRef.current).select("svg").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Stack data
    const keys = ["Fatalities", "Recordable", "NearMisses"];
    const stackedData = d3.stack().keys(keys)(data);

    // X Scale
    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.year))
      .range([0, chartWidth])
      .padding(0.4); // Reduced padding for larger bars

    // Y Scale
    const y = d3
      .scaleLinear()
      .domain([0, d3.max(stackedData[stackedData.length - 1], (d) => d[1])])
      .nice()
      .range([chartHeight, 0]);

    // Color Scale
    const color = d3
      .scaleOrdinal()
      .domain(keys)
      .range(["#7fc97f", "#beaed4", "#fdc086"]); // Subtle colors

    // Draw Axes
    g.append("g")
      .attr("transform", `translate(0,${chartHeight})`)
      .call(d3.axisBottom(x).tickSize(0))
      .selectAll("text")
      .style("text-anchor", "middle");

    g.append("g").call(d3.axisLeft(y));

    // Add X-Axis Label
    g.append("text")
      .attr("x", chartWidth / 2)
      .attr("y", chartHeight + 40)
      .style("text-anchor", "middle")
      .text("Year")
      .style("font-size", "14px");

    // Add Y-Axis Label
    g.append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -chartHeight / 2)
      .attr("y", -60)
      .style("text-anchor", "middle")
      .text("Number")
      .style("font-size", "14px");

    // Add Bars
    g.selectAll("g.stack")
      .data(stackedData)
      .enter()
      .append("g")
      .attr("fill", (d) => color(d.key))
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("x", (d) => x(d.data.year) + (x.bandwidth() - 60) / 2) // Centering fixed-width bars
      .attr("y", (d) => y(d[1]))
      .attr("height", (d) => y(d[0]) - y(d[1]))
      .attr("width", 60) // Fixed bar width
      .append("title") // Tooltip
      .text((d) => `Value: ${d[1] - d[0]}`);

    // Add Annotations
    annotations.forEach((annotation) => {
      const xPos = x(annotation.year) + x.bandwidth() / 2;

      // Draw line
      g.append("line")
        .attr("x1", xPos)
        .attr("x2", xPos)
        .attr("y1", chartHeight)
        .attr("y2", chartHeight - 50)
        .attr("stroke", "black")
        .attr("stroke-dasharray", "4");

      // Draw text
      g.append("text")
        .attr("x", xPos)
        .attr("y", chartHeight - 60)
        .attr("text-anchor", "middle")
        .style("font-size", "12px")
        .style("fill", "black")
        .text(annotation.text);
    });

    // Add Legend
    const legend = svg
      .append("g")
      .attr(
        "transform",
        `translate(${width / 2 - 80},${height - margin.bottom + 20})`
      ); // Centered below the chart

    keys.forEach((key, index) => {
      legend
        .append("rect")
        .attr("x", index * 100)
        .attr("y", 0)
        .attr("width", 15)
        .attr("height", 15)
        .attr("fill", color(key));

      legend
        .append("text")
        .attr("x", index * 100 + 20)
        .attr("y", 12)
        .style("font-size", "12px")
        .text(key);
    });
  };

  return (
    <>
      <h3 style={{ fontSize: "18px", margin: "25px" }}>
        Number of Incidents Reported
      </h3>

      <div
        ref={chartRef}
        style={{ display: "flex", justifyContent: "center" }}
      />
    </>
  );
};

export default InjuryStackedColumnChart;
